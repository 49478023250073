import green from './light_green.png';
import none from './light_none.png';
import notfound from './light_notfound.png';
import red from './light_red.png';
import white from './light_white.png';
import yellow from './light_yellow.png';
import MDRangerWhite from './mdrangerwhite.png';
import MDRangerECGWhite from './mdr_ecg_white.png';
import MDRangerECG from './mdr_ecg_logo.png';
import MDRangerECGFooter from './mdr_ecg_footer.png';
import over90 from './over90.png';

export default {
  green,
  none,
  notfound,
  red,
  white,
  yellow,
  MDRangerWhite,
  MDRangerECGWhite,
  MDRangerECG,
  MDRangerECGFooter,
  over90,
};
