import styled from 'styled-components';

interface TitleInterface {
  cursor?: string;
}

export const Title = styled.h1<TitleInterface>`
  font-size: 26px;
  color: var(--almost-black);
  cursor: ${(props) => props.cursor || 'default'};

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
