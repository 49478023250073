import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  > span {
    align-self: flex-end;
    margin-bottom: 8px;
  }
  > .conditionalPercentile {
    align-self: flex-end;
  }
`;
