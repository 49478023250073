import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  Element,
  TooltipBody,
  TooltipContainer,
  TooltipContent,
  TooltipTitle,
  TrendDown,
  TrendUp,
} from './style';
import LineChartInterface from './interface';
import CircleTicks from '../CircleTicks';
import {
  formatCurrencyToNumber,
  hasCurrencySymbol,
} from '../../../utils/trendAnalysis';
import { formatValue } from '../../../utils/executiveSummary';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const colors = [
  'rgba(92, 198, 246, 1)',
  'rgba(0, 114, 175, 1)',
  'rgba(0, 88, 135, 1)',
  'rgba(0, 53, 94, 1)',
];

const types = ['q25', 'q50', 'q75', 'q90'];
const circleTicksLabels = [
  '25th Percentile',
  'Median',
  '75th Percentile',
  '90th Percentile',
];

function buildChartData(data: any, isPdf: boolean) {
  const dataYears = Object.keys(data.years);
  const datasets: any[] = [];
  let hasCurrency = false;

  types.forEach((type, index) => {
    const dataset = {
      label: type,
      fill: false,
      data: dataYears.map((year) => {
        const value = data.years[year] ? data.years[year][type] : null;

        if (typeof value === 'string' && hasCurrencySymbol(value)) {
          hasCurrency = true;
        }

        return formatCurrencyToNumber(value);
      }),
      borderColor: colors[index],
      backgroundColor: colors[index],
      borderWidth: isPdf ? 2 : 4,
      spanGap: true,
    };
    datasets.push(dataset);
  });

  const labels: string[] = Array(4).fill('');

  dataYears.forEach((key, index) => {
    labels[index] = key;
  });

  return {
    labels,
    datasets,
    hasCurrency,
  };
}

function LineChart({ benchObject, id, isPdf = false }: LineChartInterface) {
  const { datasets, labels, hasCurrency } = buildChartData(benchObject, isPdf);
  const data = { labels, datasets };
  function buildTooltip(context: any) {
    const { datasetIndex, dataIndex } = context;
    const currentValue = context.parsed.y;
    const previousValue = data.datasets[datasetIndex].data[dataIndex - 1];
    const percentDifference =
      ((currentValue - previousValue) / previousValue) * 100;
    const formattedPercent = Math.abs(percentDifference).toFixed(0);
    const previousYear = data.labels[dataIndex - 1];

    let label = '';
    let icon = null;
    if (previousYear !== undefined) {
      if (percentDifference > 0) {
        icon = <TrendUp />;
        label = ` ${formattedPercent}% more than in ${previousYear}`;
      } else if (percentDifference < 0) {
        icon = <TrendDown />;
        label = ` ${formattedPercent}% less than in ${previousYear}`;
      } else {
        label = ` Same as in ${previousYear}`;
      }
    }

    const title = `Value: ${
      hasCurrency ? formatValue(currentValue, true) : currentValue
    }`;

    return (
      <TooltipContainer>
        <TooltipTitle>{title}</TooltipTitle>
        <TooltipBody>
          {icon}
          <TooltipContent>{label}</TooltipContent>
        </TooltipBody>
      </TooltipContainer>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function TooltipContentComponent(dataTooltip: any) {
    return renderToStaticMarkup(<div>{buildTooltip(dataTooltip)}</div>);
  }

  const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('#chartjs-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.style.background = '#151624BF';
      tooltipEl.style.borderRadius = '4px';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(10%, -30px)';
      tooltipEl.style.transition = 'all .1s ease';

      chart.canvas.parentNode
        .querySelector('#line-chart-ticks')
        .append(tooltipEl);

      return tooltipEl;
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context: any) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    if (tooltip?.dataPoints?.length > 0) {
      const chartInfo = tooltip.dataPoints[0];

      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      if (tooltip.body) {
        tooltipEl.innerHTML = '';
        tooltipEl.innerHTML = TooltipContentComponent(chartInfo);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
      tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
      tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
    }
  };

  const options = {
    responsive: true,
    aspectRatio: 4,
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest' as const,
        external: externalTooltipHandler,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        position: 'bottom' as const,
        ticks: {
          padding: 10,
          crossAlign: 'near' as const,
          color: '#040404',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
        border: {
          color: '#C1C1C1',
        },
        grid: {
          drawBorder: true,
        },
      },
      y: {
        grid: {
          lineWidth: 1,
          borderColor: '#C1C1C1',
        },
        beginAtZero: false,
        ticks: {
          callback(value: any) {
            return hasCurrency ? formatValue(value, true) : value;
          },
          padding: 10,
          crossAlign: 'far' as const,
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
    },
  };

  const pdfOptions = {
    responsive: true,
    devicePixelRatio: 10,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        position: 'bottom' as const,
        ticks: {
          padding: 10,
          crossAlign: 'near' as const,
          color: '#040404',
          backdropPadding: 50,
          font: {
            size: 8,
            weight: 'bold',
          },
        },
        border: {
          color: '#C1C1C1',
        },
        grid: {
          drawBorder: true,
        },
      },
      y: {
        grid: {
          lineWidth: 1,
          borderColor: '#C1C1C1',
        },
        beginAtZero: false,
        ticks: {
          callback(value: any) {
            return hasCurrency ? formatValue(value, true) : value;
          },
          crossAlign: 'far' as const,
          color: '#040404',
          font: {
            size: 8,
            weight: 'normal',
          },
        },
      },
    },
  };

  return (
    <Element id={id}>
      <CircleTicks
        id="line-chart-ticks"
        colors={colors}
        labels={circleTicksLabels}
        fontSize={isPdf ? '10px' : '16px'}
        size={isPdf ? '8px' : '16px'}
      />
      <Line options={isPdf ? pdfOptions : options} data={data} />
    </Element>
  );
}

export default LineChart;
