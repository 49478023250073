import styled from 'styled-components';

interface AverageTextInterface {
  fontSize?: number;
  color?: string;
  fontWeight?: string;
}

export const Page = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fefefe;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 100%;
  align-items: center;
  padding: 20px 187px;

  &.usingBenchmarks {
    width: 100%;
    background-color: var(--primary-white-gray);
    align-items: baseline;

    > :last-child {
      color: #000;
      max-width: 80%;
      font-weight: 400;
      line-height: 1.5;
      padding-bottom: 30px;
    }
  }

  &.tableChart {
    align-items: baseline;
  }

  &.multiGenerator {
    width: 30%;
    align-items: baseline;
    background: #fefefe;
    padding: var(--s-4x);

    > :first-child {
      margin-bottom: 10px;
      margin-top: 0;
    }

    > :nth-child(2) {
      font-size: 16px;
      font-weight: normal;
      margin-top: 0;
    }

    > :nth-child(3) {
      margin-bottom: 0;
    }
  }

  &.multiTable {
    width: 100%;
    padding: 0;

    > :first-child {
      margin: 0;
    }
  }

  &.lastSection {
    padding-bottom: 60px;
  }

  &.image {
    width: 250px;
  }

  > .guides {
    justify-content: space-between;
  }

  &.emptyTable {
    padding: 0;
    height: 394px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
  }

  &.general {
    align-items: start;
    width: 100%;

    > :first-child {
      align-items: baseline;
      padding: 0;
    }
  }

  &.generatePDF {
    padding: 0;
    align-items: flex-start;
    > :first-child {
      margin-top: 30px;
      height: 45px;
      width: 350px;
      background-color: transparent;
      box-shadow: none;
      border: solid 2px var(--primary-dark-border);
      color: var(--primary-dark-border);
      font-weight: 700;
      cursor: pointer;
      border-radius: 2px;
    }
  }

  &.emphasisSection {
    background-color: var(--primary-white-gray);
  }
`;

export const VerticalLine = styled.hr`
  border-left: 1px solid var(--ion-color-light-darken);
  margin: 0 30px;
  height: 60vh;
`;

export const BulletPoint = styled.li`
  line-height: 20px;
`;

export const Points = styled.ul`
  padding: 10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageCard = styled.div`
  width: 200px;
`;

export const Row = styled.section`
  display: flex;
  flex-direction: row;
  height: max-content;
  width: 100%;

  &.formType {
    > :nth-child(2) {
      margin: 0;
    }
  }

  &.dropDown {
    margin-top: 10px;
    align-items: center;
    width: 380px;
  }

  &.evaluating {
    padding: 0;
    align-items: baseline;
    justify-content: space-between;

    > :first-child {
      margin-top: 0;
    }
  }

  &.generateInput {
    border-radius: 2px;
    margin-top: 20px;
    align-items: center;
    width: 380px;

    > :first-child {
      width: 50%;
    }

    > :nth-child(2) {
      border-radius: 4px;
      margin-right: 0;
    }

    input {
      border-radius: 4px;
    }
  }

  > .generateMulti {
    width: 90px;
    font-size: 18px;
    margin: 10px 0;
  }
`;

export const Text = styled.span<AverageTextInterface>`
  font-family: inherit;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize ?? 12}px;
  color: ${(props) => props.color ?? '#242424'};
  margin-bottom: 10px;
  text-align: justify;
`;
