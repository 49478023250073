/* eslint-disable */
import Api from './api';
import keysToCamel from '../utils/keysToCamelCase';
import singleContractResponseHandler from '../utils/singleContractResponse'

export default class Analytics {
  static async getExecSum(lookBack: number | string, hsid: string, mdr_id: string) {
    return Promise.resolve(
      Api.get(`/api/analytics-pdf?hsid=${hsid}&lookback=${lookBack}&mdr_id=${mdr_id}`)
        .then((response) => ({ failed: false, data: (hsid === 'svcs' ? response.data : response.data) }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getSingleContract(lookBack: number | string, hsid: string, mdrid?: string) {
    return Promise.resolve(
      Api.get(`/api/analytics-pdf?hsid=${hsid}&lookback=${lookBack}&mdr_id=${mdrid}`)
        .then((response) => ({ failed: false, data: singleContractResponseHandler(response.data) }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
};
