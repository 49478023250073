import styled from 'styled-components';

interface BlueTextProps {
  alignSelf?: 'flex-end' | 'center' | 'flex-start';
}

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-pdf-height);
  padding: 0;
  top: 0;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 30px;
  margin: 20px 0;

  > .summaryColumn {
    margin: 15px 0;
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: justify;
  width: 100%;
`;

export const InformationColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .summaryRow {
    width: 50%;
    > :first-child {
      font-weight: normal;
      margin-left: 10px;
    }
    > :last-child {
      margin-left: auto;
    }
  }
`;

export const InformationRow = styled(InformationContainer)`
  margin-bottom: 0;
  > :first-child {
    font-weight: bold;
    margin-right: 8px;
  }
`;

export const InformationText = styled.span<{ fontWeight?: 'bold' | 'normal' }>`
  font-size: 9px;
  text-align: justify;
  margin-bottom: 3px;
  font-weight: ${(props) => props.fontWeight};
`;

export const BlueSectionText = styled.h1<BlueTextProps>`
  font-family: inherit;
  font-size: 11px;
  font-weight: bold;
  color: var(--ecg-royal-blue);
  align-self: ${(props) => props.alignSelf ?? 'center'};
`;

export const PercentPayingTable = styled.table`
  margin-bottom: 20px;
  padding-right: 50px;
`;

export const PercentTableRow = styled.tr`
  > :first-child {
    width: 60%;
    text-align: left;
  }
  > :nth-child(n + 2) {
    text-align: right;
  }
`;

export const PercentHeaderTile = styled.th`
  margin-bottom: 5px;
  font-family: inherit;
  font-weight: 600;
  font-size: 9px;
`;

export const PercentTableTile = styled.td`
  font-family: inherit;
  font-size: 9px;
`;
