import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../atoms/Modal';
import { Body, Column, ModalOpenButton } from './style';
import Subtitle from '../../atoms/Subtitle';
import ConfirmButton from '../../atoms/ConfirmButton';
import Input from '../../molecules/Input';
import RateConversionModalInterface from './interface';
import CoveragePerDiemCalculation from '../../../utils/CoveragePerDiemCalculation';

function RateConversionModal({ result }: RateConversionModalInterface) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [weekday, setWeekday] = useState<number>(0);
  const [weekend, setWeekend] = useState<number>(0);
  const [holiday, setHoliday] = useState<number>(0);
  const [yearly, setAnnual] = useState<number>(0);
  const [monthly, setMonthly] = useState<number>(0);
  const [weekly, setWeekly] = useState<number>(0);

  const { t } = useTranslation('rate-comparison');

  const calc = () => {
    const coverageResult = CoveragePerDiemCalculation(
      weekday,
      weekend,
      holiday,
      weekly,
      monthly,
      yearly,
    );

    result(coverageResult);
    setIsOpen(false);
  };
  return (
    <>
      <ModalOpenButton onClick={() => setIsOpen(true)}>
        {t('rate-conversion-open')}
      </ModalOpenButton>
      <Modal
        isOpen={isOpen}
        Children={
          <Body>
            <Column>
              <Subtitle text={t('rate-conversion-title')} />
              <Subtitle text={t('rate-conversion-subtitle')} />
            </Column>
            <Column>
              <Input
                title={t('rate-conversion-weekday')}
                titleSide="left"
                width={80}
                type="number"
                focus
                callback={(value: string) => setWeekday(Number(value))}
              />
              <Input
                title={t('rate-conversion-weekend')}
                titleSide="left"
                width={80}
                type="number"
                callback={(value: string) => setWeekend(Number(value))}
              />
              <Input
                title={t('rate-conversion-holiday')}
                titleSide="left"
                width={80}
                type="number"
                callback={(value: string) => setHoliday(Number(value))}
              />
              <Input
                title={t('rate-conversion-annual-rate')}
                titleSide="left"
                width={80}
                type="number"
                callback={(value: string) => setAnnual(Number(value))}
              />
              <Input
                title={t('rate-conversion-monthly-rate')}
                titleSide="left"
                width={80}
                callback={(value: string) => setMonthly(Number(value))}
              />
              <Input
                title={t('rate-conversion-weekly-rate')}
                titleSide="left"
                width={80}
                callback={(value: string) => setWeekly(Number(value))}
              />
            </Column>
            <ConfirmButton onClick={calc} label={t('rate-conversion-close')} />
          </Body>
        }
      />
    </>
  );
}

export default RateConversionModal;
