/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Input,
  CircularInput,
  Label,
  CustomInputCheckbox,
  CustomInputBox,
  CheckIcon,
  CustomInputContainer,
} from './style';
import CheckboxInterface from './interface';

function Checkbox({
  label,
  onChange,
  initialValue,
  format,
  control,
  disabled,
  propRef,
  propName,
  height,
  width,
  style,
}: CheckboxInterface) {
  const [checked, setChecked] = useState<boolean>(false);
  const checkRef = propRef ?? useRef<HTMLInputElement>(null);

  const handleClick = (status: boolean) => {
    setChecked(status);
    return onChange(status);
  };

  useEffect(() => {
    if (initialValue) {
      setChecked(initialValue);
      onChange(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (control !== undefined && control !== checked) {
      setChecked(control);
    }
  }, [control]);

  useEffect(() => {}, [checked]);

  const defaultCheck = (id: string, isChecked: boolean, handle: Function) => (
    <Input
      id={id}
      onChange={(event) => handle(event.target.checked)}
      checked={isChecked}
      disabled={disabled}
      ref={checkRef}
      name={propName}
    />
  );

  const customCheck = (id: string, isChecked: boolean, handle: Function) => (
    <CustomInputContainer>
      <CustomInputCheckbox
        type="checkbox"
        id={id}
        checked={isChecked}
        ref={checkRef}
        name={propName}
        onChange={(event: any) => handle(event.target.checked)}
        disabled={disabled}
      />
      <CustomInputBox>
        <CheckIcon />
      </CustomInputBox>
    </CustomInputContainer>
  );

  const circularCheck = (id: string, isChecked: boolean, handle: Function) => (
    <CircularInput
      id={id}
      onChange={(event) => handle(event.target.checked)}
      checked={isChecked}
      disabled={disabled}
      ref={checkRef}
      name={propName}
      $minHeight={height}
      $minWidth={width}
      $inputStyle={style}
    />
  );

  const selectCheck = (
    id: string,
    styleCheck: string,
    isChecked: boolean,
    handle: Function,
  ) => {
    switch (styleCheck) {
      case 'circular':
        return circularCheck(id, isChecked, handle);
      case 'custom':
        return customCheck(id, isChecked, handle);
      default:
        return defaultCheck(id, isChecked, handle);
    }
  };

  return (
    <Container>
      {selectCheck(label, format ?? '', checked, handleClick)}
      <Label $labelStyle={style} htmlFor={label}>
        {label}
      </Label>
    </Container>
  );
}

export default Checkbox;
