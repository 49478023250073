import Axios from 'axios';
import store from '../hooks/redux/store';
import Environment from '../config/environment';

const api = Axios.create({
  baseURL: Environment.environment,
});

function select(state: any) {
  return state.auth.token;
}

store.subscribe(() => {
  const token = select(store.getState());
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
});

// in this function we will do the configuration before each request
api.interceptors.request.use((config) => config);

// in this function we will do the configuration after each request
api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export default api;
