import React from 'react';

import {
  Page,
  ColumnsContainer,
  AppendixColumn,
  AverageTextHolder,
  AverageText,
} from '../style';

import ReportHeader from '../../../../organisms/Reports/Header';
import ReportFooter from '../../../../organisms/Reports/Footer';

export interface AppendixBPropsInterface {
  textData: any;
  nowDate: string;
}

function AppendixB(props: AppendixBPropsInterface) {
  const { textData, nowDate } = props;

  const columnNumber = Math.ceil(textData.length / 50);

  const divideAppendixColumns = () => {
    const pages: any = [];

    let page: any = [];
    Array(columnNumber)
      .fill('')
      .map((__, columnIndex: number) => {
        const bottom = columnIndex * 50;
        const top = (columnIndex + 1) * 50;
        const last = textData.length;
        if (top > textData.length) {
          return page.push([...textData.slice(bottom, last)]);
        }
        page.push([...textData.slice(bottom, top)]);
        if (page.length === 3) {
          pages.push(page);
          page = [];
        }
        return null;
      });
    if (pages.length > 0) pages.push(page);
    return pages;
  };

  const appendixPages = divideAppendixColumns();
  return (
    <>
      {appendixPages.map((page: Array<string[]>) => (
        <Page className="appendixBPage">
          <ReportHeader title="" />
          <AverageTextHolder height={40} direction="row">
            <AverageText fontWeight="bold">
              Appendix B: MD Ranger Service Benchmarks
            </AverageText>
          </AverageTextHolder>
          <ColumnsContainer>
            {page.map((column: string[]) => (
              <AppendixColumn>
                {column.map((benchtitle: string) => (
                  <AverageText
                    fontSize={benchtitle.length > 35 ? 6 : 7}
                    className="appendixRow"
                  >
                    {benchtitle}
                  </AverageText>
                ))}
              </AppendixColumn>
            ))}
          </ColumnsContainer>
          <ReportFooter nowDate={nowDate} />
        </Page>
      ))}
    </>
  );
}

export default AppendixB;
