import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  height: 550px;
  position: fixed;
  left: 50%;
  top: 100px;
  margin-left: -300px;
  opacity: 1;
  padding: 20px;
  border: 2px solid #333;
  z-index: 9999;
  background-color: #fff;

  > :nth-child(1) {
    align-items: center !important;

    > :nth-child(1) {
      font-size: 18px;
      width: max-content;
      line-height: 0;
    }

    > :nth-child(2) {
      font-size: 18px;
      width: max-content;
      line-height: 0;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const ModalOpenButton = styled.button`
  width: max-content;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--primary-light-blue);
  font-size: 16px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
