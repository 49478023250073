import styled from 'styled-components';
import { FaAngleRight } from 'react-icons/fa6';

interface WrapperProps {
  isOpen: boolean;
}
interface IconProps {
  isOpen: boolean;
}
interface TableHeaderCellProps {
  textAlign: 'left' | 'center' | 'right';
}

interface TableRowProps {
  isLast: boolean;
}

interface TableCellProps {
  textAlign: 'left' | 'center' | 'right';
}

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 100%;
  max-width: 1400px;
  background-color: #fff;
  padding: 30px 25px;
  margin-bottom: 50px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.23);
`;
export const BenchMarkTitle = styled.h2`
  font-size: 22px;
  color: var(--almost-black);
  cursor: pointer;
`;
export const TableWrapper = styled.div<WrapperProps>`
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? '100%' : 0)};
  max-width: ${(props) => (props.isOpen ? '100%' : 0)};
  transition: max-height 0.5s ease-in-out, max-width 0.3s ease-in-out;
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
`;
export const TableHeader = styled.th<TableHeaderCellProps>`
  text-align: ${(props) => props.textAlign};
  background-color: var(--primary-light-gray);
  padding: 10px;
  border-bottom: solid 2px var(--secondary-light-gray);
  border-right: solid 1px var(--secondary-light-gray);
  font-weight: normal;
`;
export const Characteristic = styled.tr`
  padding: 10px;
  font-weight: bold;
  color: var(--ecg-royal-blue);
  white-space: nowrap;
`;
export const TableRow = styled.tr<TableRowProps>`
  border-bottom: ${(props) =>
    props.isLast ? 'solid 1px var(--secondary-light-gray)' : 'none'};
`;
export const TableCell = styled.td<TableCellProps>`
  text-align: ${(props) => props.textAlign};
  padding: 10px;
  font-size: 14px;
`;
export const AngleIcon = styled(FaAngleRight)<IconProps>`
  color: var(--almost-black);
  font-weight: lighter;
  font-size: 16px;
  margin-right: 15px;
  transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : '')};
  transition: transform 0.3s ease;
`;
