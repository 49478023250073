import styled from 'styled-components';
import { FaFilePdf } from 'react-icons/fa6';

interface PrintBenchmarksProps {
  isSubmitting: boolean;
}

export const Page = styled.div`
  width: 100vw;
  height: max-content;
  padding: 3vh 10vw;
`;

export const PageTitle = styled.h3`
  color: var(--almost-black);
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const PrintBenchmarks = styled.h4<PrintBenchmarksProps>`
  max-width: 1400px;
  color: var(--ecg-royal-blue);
  font-weight: lighter;
  text-align: right;
  cursor: pointer;
  padding-right: ${(props) => (props.isSubmitting ? '130px' : 0)};
`;

export const PdfIcon = styled(FaFilePdf)`
  color: var(--ecg-royal-blue);
  margin-right: 5px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #fff;
  height: max-content;
  width: 100%;
  max-width: 1400px;
  min-width: 400px;
  padding: 30px;
`;

export const CommonPageText = styled.p`
  font-family: inherit;
  font-size: 14px;
  color: var(--almost-black);
  text-align: justify;
  line-height: 1.5;
`;

export const AssumptionsContainer = styled.div`
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
`;

export const AssumptionsTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 16px;
`;

export const List = styled.ul`
  list-style-type: disc;
`;

export const Item = styled.li`
  font-size: 14px;
  color: var(--almost-black);
  margin-bottom: 8px;
  line-height: 1.5;
`;
