/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import ConfirmButton from '../../components/atoms/ConfirmButton';
import Checkbox from '../../components/atoms/Checkbox';
import DoubleCheckBoxInput from '../../components/molecules/DoubleCheckBoxInput';
import Input from '../../components/molecules/Input';
import Title from '../../components/atoms/Title';
import Subtitle from '../../components/atoms/Subtitle';
import CheckboxMenu from '../../components/molecules/CheckboxMenu';
import AccountService from '../../services/accountPage';

import {
  Page,
  Section,
  Line,
  MenuContainer,
  ContainerHider,
  Container,
  AlertSection,
} from './style';
import MultiSelectDropDown from '../../components/atoms/MultiSelectDropDown';
import Footer from '../../components/organisms/Footer';

function AccountPage() {
  const { t } = useTranslation('account-page');

  const [csrfToken, setCsrfToken] = useState<string>('');

  const [organization, setOrganization] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [telephone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [displayDefaultBenchmarks, setDisplayDefaultBenchmarks] =
    useState<boolean>(false);
  const [displayFacilityMenu, setDisplayFacilityMenu] =
    useState<boolean>(false);
  const [characteristics, setCharacteristics] = useState<{
    [key: string]: string;
  }>({});
  const [facilitiesList, setFacilitiesList] = useState<{
    [key: string]: string;
  }>({});
  const [selectedFacilities, setselectedFacilities] = useState<string[]>([]);
  const [automatically, setAutomatically] = useState<boolean>(false);
  const [pickPercentile, setPickPercentile] = useState<boolean>(true);
  const [facilities, setFacilities] = useState<string[]>([]);

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [retypeNew, setRetypeNew] = useState<string>('');

  const [selectedCharacteristics, setSelectedCharacteristics] = useState<
    string[]
  >([]);

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isFailure, setIsFailure] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const INPUT_COLOR = '#f4f5f9';

  const getAccountData = async () => {
    const response = await AccountService.getAccountData();

    if (!response.failed) {
      setOrganization(
        response.data.organization
          ? String(response.data.organization)
          : String(''),
      );
      setFirstName(
        response.data.firstname ? String(response.data.firstname) : String(''),
      );
      setLastName(
        response.data.lastname ? String(response.data.lastname) : String(''),
      );
      setPhone(
        response.data.telephone ? String(response.data.telephone) : String(''),
      );
      setEmail(response.data.email ? String(response.data.email) : String(''));
      setFacilitiesList(response.data.facilities || {});
      setPickPercentile(String(response.data.rcrPercentile) === '50');
      setAutomatically(String(response.data.archive) === '1');

      const char = response.data.characteristics;
      const transformedCharacteristics: any = {};

      Object.entries(char).forEach(([key, value]: any) => {
        if (value === 1) {
          transformedCharacteristics[key] = String(value);
        }
      });

      setCharacteristics(transformedCharacteristics || {});

      setDisplayDefaultBenchmarks(String(response.data.allBenchmarks) === '1');
      setFacilities(response.data.mdrId ? response.data.mdrId : []);
      setDisplayFacilityMenu(String(response.data.facilityMatch) === '1');
      setCsrfToken(response.data.csrfToken);
    }
  };

  const appendFormData = (form: FormData, key: string, value?: any) => {
    if (value !== undefined) {
      form.append(key, value);
    }
  };

  const options = {
    all_hospitals: 'All Hospitals',
    trauma: 'Trauma Status',
    beds: 'Bed Size',
    urbanrural: 'Urban/Rural',
    adc: 'ADC',
    teaching: 'Teaching Status',
  };

  const filteredFacilities = facilities.filter((value) => value !== '');

  const handleSelectedChange = (selected: string[]) => {
    setSelectedCharacteristics(selected);
  };

  const makeUpdateBody = () => {
    const formData = new FormData();
    const benchmarkFields = [
      'all_hospitals',
      'trauma',
      'beds',
      'urbanrural',
      'adc',
      'teaching',
    ];

    appendFormData(formData, 'firstname', firstName);
    appendFormData(formData, 'lastname', lastName);
    appendFormData(formData, 'telephone', telephone);
    appendFormData(formData, 'email', email);
    appendFormData(formData, 'csrf_token', csrfToken);
    if (displayDefaultBenchmarks) {
      appendFormData(formData, 'all_benchmarks', 1);
      appendFormData(formData, 'facility_match', 0);
    } else {
      if (!displayFacilityMenu && selectedCharacteristics.length > 0) {
        appendFormData(formData, 'facility_match', 0);
        appendFormData(formData, 'all_benchmarks', 0);
        setselectedFacilities([]);
        setFacilities([]);

        benchmarkFields.forEach((field) => {
          const value = selectedCharacteristics.includes(field) ? 1 : 0;
          appendFormData(
            formData,
            field === 'allHospitals' ? 'all_hospitals' : field,
            value,
          );
        });
      }
      if (displayFacilityMenu && selectedFacilities) {
        setCharacteristics({});
        setSelectedCharacteristics([]);

        appendFormData(formData, 'all_benchmarks', 0);
        benchmarkFields.forEach((field) => {
          appendFormData(
            formData,
            field === 'allHospitals' ? 'all_hospitals' : field,
            0,
          );
        });
        appendFormData(formData, 'facility_match', 1);
        appendFormData(formData, 'mdr_id', selectedFacilities);
      }
    }

    if (currentPassword && newPassword && retypeNew) {
      appendFormData(formData, 'pswd_old', currentPassword);
      appendFormData(formData, 'pswd_new1', newPassword);
      appendFormData(formData, 'pswd_new2', retypeNew);
    }

    appendFormData(formData, 'rcrpercentile', pickPercentile ? 50 : 75);
    appendFormData(formData, 'archive', automatically ? 1 : 0);
    return formData;
  };

  const handleCallback = (selectedValues: any) => {
    setselectedFacilities(selectedValues);
  };

  const handleSubmit = async () => {
    const body = makeUpdateBody();
    const request = await AccountService.updateAccountData(body);
    if (!request.failed) {
      setIsSuccess(true);
    } else {
      setIsFailure(true);
      setErrorMessage(request.data);
    }
  };

  useEffect(() => {
    getAccountData().then(() => {});
  }, []);

  useEffect(() => {
    const selectedChars = Object.keys(characteristics).filter(
      (key) => characteristics[key] === '1',
    );
    setSelectedCharacteristics(selectedChars);
  }, [characteristics]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailure(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [isSuccess, isFailure]);

  useEffect(() => {}, [pickPercentile, automatically]);

  return (
    <Page>
      <input type="hidden" name="csrf_token" value={csrfToken} />
      <Section className="headerSection">
        <Title text={t('account')} />
        <Line />
        <Subtitle text={t('organization')} />
        <Subtitle text={organization} />
      </Section>
      <Section>
        <Input
          callback={setFirstName}
          value={firstName}
          title={t('first_name')}
          titleSide="upper"
          inputColor={INPUT_COLOR}
        />
        <Input
          callback={setLastName}
          value={lastName}
          title={t('last_name')}
          titleSide="upper"
          inputColor={INPUT_COLOR}
        />
        <Input
          callback={setPhone}
          value={telephone}
          title={t('phone')}
          titleSide="upper"
          type="tel"
          inputColor={INPUT_COLOR}
        />
        <Input
          callback={setEmail}
          value={email}
          title={t('email')}
          titleSide="upper"
          inputColor={INPUT_COLOR}
        />
      </Section>
      <Section>
        <Title text={t('preferences')} />
        <Subtitle text={t('search')} fontSize={18} />
        <Checkbox
          id="defaultBenchmarks"
          label={t('default_charcteristics')}
          onChange={(event: boolean) => {
            setDisplayDefaultBenchmarks(event);
          }}
          format="circular"
          initialValue={displayDefaultBenchmarks}
          control={displayDefaultBenchmarks}
        />
        <Checkbox
          id="customBenchmarks"
          label={t('custom_charcteristics')}
          onChange={(event: boolean) => {
            setDisplayDefaultBenchmarks(!event);
          }}
          format="circular"
          initialValue={!displayDefaultBenchmarks}
          control={!displayDefaultBenchmarks}
        />
        <Container>
          <ContainerHider $isHidden={displayDefaultBenchmarks} />
          <Checkbox
            id="displayCharacteristics"
            label={t('display_characteristcs')}
            onChange={(event: boolean) => {
              setDisplayFacilityMenu(!event);
            }}
            format="circular"
            initialValue={!displayFacilityMenu}
            control={!displayFacilityMenu}
            height="15px"
            width="15px"
          />
          <MenuContainer $paddingLeft={20}>
            <ContainerHider $isHidden={displayFacilityMenu} />
            <CheckboxMenu
              options={options}
              initialValues={characteristics}
              onSelectedChange={handleSelectedChange}
            />
          </MenuContainer>
          <MenuContainer className="matchFacility">
            <Checkbox
              id="matchFacility"
              label={t('match_facility')}
              onChange={(event: boolean) => {
                setDisplayFacilityMenu(event);
              }}
              format="circular"
              initialValue={displayFacilityMenu}
              control={displayFacilityMenu}
              height="15px"
              width="15px"
            />
          </MenuContainer>
          <MenuContainer className="selectContainer">
            <ContainerHider $isHidden={!displayFacilityMenu} />
            <MultiSelectDropDown
              dropDownId="facility-dropdown"
              callBack={handleCallback}
              styleCheck="chip"
              dataItems={facilitiesList}
              initialValues={filteredFacilities ?? undefined}
            />
          </MenuContainer>
        </Container>

        <DoubleCheckBoxInput
          className="doublePercentile"
          direction="column"
          firstLabel={t('50_percent')}
          onChange={setPickPercentile}
          secondLabel={t('75_percent')}
          startValue={pickPercentile}
          title={t('rate_comparison')}
        />
        <Section className="archive">
          <Checkbox
            id="automaticallyArchive"
            label={t('automatically_archive')}
            initialValue={automatically}
            onChange={setAutomatically}
          />
        </Section>
      </Section>
      <Section className="changePassword">
        <Title text={t('change_password_title')} />
        <Line />
        <Input
          callback={setCurrentPassword}
          title={t('current_label')}
          titleSide="upper"
          inputColor={INPUT_COLOR}
          type="password"
        />

        <Input
          callback={setNewPassword}
          title={t('new_label')}
          titleSide="upper"
          placeholder="At least 8 characters"
          inputColor={INPUT_COLOR}
          type="password"
        />
        <Input
          callback={setRetypeNew}
          title={t('retype_new_label')}
          titleSide="upper"
          placeholder="At least 8 characters"
          inputColor={INPUT_COLOR}
          type="password"
        />
        <ConfirmButton
          label="Save"
          onClick={() => handleSubmit()}
          color="#fff"
        />
      </Section>
      <AlertSection>
        {isSuccess && (
          <Alert severity="success" color="info">
            Profile updated successfully.
          </Alert>
        )}
        {isFailure && <Alert severity="error">{errorMessage}</Alert>}
      </AlertSection>
      <Footer />
    </Page>
  );
}

export default AccountPage;
