/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
import store from 'src/hooks/redux/store';
import PdfMicroService from 'src/services/pdfMicroService';
import GeneralServices from '../services/generalServices';
import MDRangerService from '../services/mdrangerServices';
import Facilities from '../contracts/models/Facilities.model';
import {
  calcPercentClinicalResult,
  calcProfCollectionsperWorkRVUs,
  calcClinicalCompPerWorkRVU,
  calcTotalCompensation,
  calcAdjustedValue,
  calcTotalClinicalComp,
} from '../utils/totalCompensation';

export const TotalCompensationContext = React.createContext<{
  showProduction: boolean;
  setShowProduction?: React.Dispatch<React.SetStateAction<boolean>>;
  dignity: boolean;
  setDignity?: React.Dispatch<React.SetStateAction<boolean>>;
  service: string;
  setService?: React.Dispatch<React.SetStateAction<string>>;
  services: string[];
  setServices?: React.Dispatch<React.SetStateAction<string[]>>;
  clinicalFTE: string;
  setClinicalFTE?: React.Dispatch<React.SetStateAction<string>>;
  regionsList: Facilities;
  setRegionsList?: React.Dispatch<React.SetStateAction<Facilities>>;
  region: string;
  setRegion?: React.Dispatch<React.SetStateAction<string>>;
  physiciansList: Facilities;
  setPhysiciansList?: React.Dispatch<React.SetStateAction<Facilities>>;
  physicianType: string;
  setPhysicianType?: React.Dispatch<React.SetStateAction<string>>;
  physicianFTE: string;
  setPhysicianFTE?: React.Dispatch<React.SetStateAction<string>>;
  percentClinicalResult: string;
  setPercentClinicalResult?: React.Dispatch<React.SetStateAction<string>>;
  clinicalCompensation: string;
  setClinicalCompensation?: React.Dispatch<React.SetStateAction<string>>;
  adjustedClinicalCompensation: string;
  setAdjustedClinicalCompensation?: React.Dispatch<
    React.SetStateAction<string>
  >;
  paymentPerWork: string;
  setPaymentPerWork?: React.Dispatch<React.SetStateAction<string>>;
  bonusIncentives: string;
  setBonusIncentives?: React.Dispatch<React.SetStateAction<string>>;
  adjustedBonusIncentives: string;
  setAdjustedBonusIncentives?: React.Dispatch<React.SetStateAction<string>>;
  others: string;
  setOthers?: React.Dispatch<React.SetStateAction<string>>;
  othersReimbursements: string;
  setOthersReimbursements?: React.Dispatch<React.SetStateAction<string>>;
  details: string;
  setDetails?: React.Dispatch<React.SetStateAction<string>>;
  detailsReimbursements: string;
  setDetailsReimbursements?: React.Dispatch<React.SetStateAction<string>>;
  submiting: boolean;
  setSubmiting?: React.Dispatch<React.SetStateAction<boolean>>;
  hcValues: number[];
  setHcValues?: React.Dispatch<React.SetStateAction<number[]>>;
  traumaValues: Array<'1' | '0'>;
  setTraumaValues?: React.Dispatch<React.SetStateAction<Array<'1' | '0'>>>;
  serviceCall: string[];
  setServiceCall?: React.Dispatch<React.SetStateAction<string[]>>;
  hdValues: number[];
  setHdValues?: React.Dispatch<React.SetStateAction<number[]>>;
  hhValues: number[];
  setHhValues?: React.Dispatch<React.SetStateAction<number[]>>;
  hrValues: number[];
  setHrValues?: React.Dispatch<React.SetStateAction<number[]>>;
  serviceAdmin: string[];
  setServiceAdmin?: React.Dispatch<React.SetStateAction<string[]>>;
  professionalCollections: string;
  setProfessionalCollections?: React.Dispatch<React.SetStateAction<string>>;
  adjustedProfessionalCollections: string;
  setAdjustedProfessionalCollections?: React.Dispatch<
    React.SetStateAction<string>
  >;
  workRVUs: string;
  setWorkRVUs?: React.Dispatch<React.SetStateAction<string>>;
  adjustedWorkRVUs: string;
  setAdjustedWorkRVUs?: React.Dispatch<React.SetStateAction<string>>;
  profCollectionsperWorkRVUs: string;
  setProfCollectionsperWorkRVUs?: React.Dispatch<React.SetStateAction<string>>;
  clinicalCompPerWorkRVU: string;
  setClinicalCompPerWorkRVU?: React.Dispatch<React.SetStateAction<string>>;
  adjustedTotalRVUs: string;
  setAdjustedTotalRVUs?: React.Dispatch<React.SetStateAction<string>>;
  profCollectionsperTotalRVUs: string;
  setProfCollectionsperTotalRVUs?: React.Dispatch<React.SetStateAction<string>>;
  clinicalCompperTotalRVU: string;
  setClinicalCompperTotalRVU?: React.Dispatch<React.SetStateAction<string>>;
  annualCall: number;
  setAnnualCall?: React.Dispatch<React.SetStateAction<number>>;
  callDays: number;
  setCallDays?: React.Dispatch<React.SetStateAction<number>>;
  annualAdmin: number;
  setAnnualAdmin?: React.Dispatch<React.SetStateAction<number>>;
  totalCompensation: string;
  setTotalCompensation?: React.Dispatch<React.SetStateAction<string>>;
  totalCompAdjusted: string;
  setTotalCompAdjusted?: React.Dispatch<React.SetStateAction<string>>;
  totalClinicalComp: string;
  setTotalClinicalComp?: React.Dispatch<React.SetStateAction<string>>;
  totalAdjustedClinicalComp: string;
  setTotalAdjustedClinicalComp?: React.Dispatch<React.SetStateAction<string>>;
  reportTitle: string | null;
  setReportTitle?: React.Dispatch<React.SetStateAction<string | null>>;
  counterParty: string | null;
  setCounterParty?: React.Dispatch<React.SetStateAction<string | null>>;
  startDate: string | null;
  setStartDate?: React.Dispatch<React.SetStateAction<string | null>>;
  endDate: string | null;
  setEndDate?: React.Dispatch<React.SetStateAction<string | null>>;
  facility: string | null;
  setFacility?: React.Dispatch<React.SetStateAction<string | null>>;
  experienceYears: string | null;
  setExperienceYears?: React.Dispatch<React.SetStateAction<string | null>>;
  notes: string | null;
  setNotes?: React.Dispatch<React.SetStateAction<string | null>>;
  hospitalName: string;
  setHospitalName?: React.Dispatch<React.SetStateAction<string>>;
  fmvApproval: boolean;
  setFmvApproval?: React.Dispatch<React.SetStateAction<boolean>>;
  previousApproval: string;
  setPreviousApproval?: React.Dispatch<React.SetStateAction<string>>;
  additionalText: string;
  setAdditionalText?: React.Dispatch<React.SetStateAction<string>>;
  signature: boolean;
  setSignature?: React.Dispatch<React.SetStateAction<boolean>>;
  handleDignityReportInputChange: Function;
  handleReportGeneratorInputsChange: Function;
  loadServices: Function;
  loadSelfInfo: Function;
  handleSubmit: Function;
  resetValues: Function;
  checkToShowAdjustedFields: Function;
  firstPlusButtonPosition: Function;
}>({
  showProduction: false,
  dignity: false,
  service: '',
  services: [],
  regionsList: {},
  region: '',
  physiciansList: {},
  physicianType: '',
  clinicalFTE: '',
  physicianFTE: '',
  percentClinicalResult: '',
  clinicalCompensation: '',
  adjustedClinicalCompensation: '',
  paymentPerWork: '',
  bonusIncentives: '',
  adjustedBonusIncentives: '',
  others: '',
  othersReimbursements: '',
  details: '',
  detailsReimbursements: '',
  submiting: false,
  hcValues: [],
  traumaValues: [],
  serviceCall: [],
  hdValues: [],
  hhValues: [],
  hrValues: [],
  serviceAdmin: [],
  professionalCollections: '',
  adjustedProfessionalCollections: '',
  workRVUs: '',
  adjustedWorkRVUs: '',
  profCollectionsperWorkRVUs: '',
  clinicalCompPerWorkRVU: '',
  adjustedTotalRVUs: '',
  profCollectionsperTotalRVUs: '',
  clinicalCompperTotalRVU: '',
  annualCall: 0,
  callDays: 0,
  annualAdmin: 0,
  totalCompensation: '',
  totalCompAdjusted: '',
  totalClinicalComp: '',
  totalAdjustedClinicalComp: '',
  reportTitle: null,
  counterParty: null,
  startDate: null,
  endDate: null,
  facility: null,
  experienceYears: null,
  notes: null,
  hospitalName: '',
  fmvApproval: false,
  previousApproval: '',
  additionalText: '',
  signature: true,
  handleDignityReportInputChange: Function,
  handleReportGeneratorInputsChange: Function,
  loadServices: Function,
  loadSelfInfo: Function,
  handleSubmit: Function,
  resetValues: Function,
  checkToShowAdjustedFields: Function,
  firstPlusButtonPosition: Function,
});

const TotalCompensationcontextProvider: React.FC = ({ children }) => {
  const [showProduction, setShowProduction] = useState<boolean>(false);
  const [dignity, setDignity] = useState<boolean>(false);
  const [service, setService] = useState<string>('');
  const [services, setServices] = useState<string[]>([]);
  const [clinicalFTE, setClinicalFTE] = useState<string>('');
  const [regionsList, setRegionsList] = useState<Facilities>({});
  const [region, setRegion] = useState<string>('');
  const [physiciansList, setPhysiciansList] = useState<Facilities>({});
  const [physicianType, setPhysicianType] = useState<string>('');
  const [physicianFTE, setPhysicianFTE] = useState<string>('');
  const [percentClinicalResult, setPercentClinicalResult] =
    useState<string>('');
  const [clinicalCompensation, setClinicalCompensation] = useState<string>('');
  const [adjustedClinicalCompensation, setAdjustedClinicalCompensation] =
    useState<string>('');
  const [paymentPerWork, setPaymentPerWork] = useState<string>('');
  const [bonusIncentives, setBonusIncentives] = useState<string>('');
  const [adjustedBonusIncentives, setAdjustedBonusIncentives] =
    useState<string>('');
  const [others, setOthers] = useState<string>('');
  const [othersReimbursements, setOthersReimbursements] = useState<string>('');
  const [details, setDetails] = useState<string>('');
  const [detailsReimbursements, setDetailsReimbursements] =
    useState<string>('');
  const [submiting, setSubmiting] = useState<boolean>(false);

  const [hcValues, setHcValues] = useState<number[]>([]);
  const [traumaValues, setTraumaValues] = useState<Array<'1' | '0'>>([]);
  const [serviceCall, setServiceCall] = useState<string[]>([]);
  const [hdValues, setHdValues] = useState<number[]>([]);
  const [hhValues, setHhValues] = useState<number[]>([]);
  const [hrValues, setHrValues] = useState<number[]>([]);
  const [serviceAdmin, setServiceAdmin] = useState<string[]>([]);

  const [professionalCollections, setProfessionalCollections] =
    useState<string>('');
  const [adjustedProfessionalCollections, setAdjustedProfessionalCollections] =
    useState<string>('');
  const [workRVUs, setWorkRVUs] = useState<string>('');
  const [adjustedWorkRVUs, setAdjustedWorkRVUs] = useState<string>('');
  const [profCollectionsperWorkRVUs, setProfCollectionsperWorkRVUs] =
    useState<string>('');
  const [clinicalCompPerWorkRVU, setClinicalCompPerWorkRVU] =
    useState<string>('');
  const [adjustedTotalRVUs, setAdjustedTotalRVUs] = useState<string>('');
  const [profCollectionsperTotalRVUs, setProfCollectionsperTotalRVUs] =
    useState<string>('');
  const [clinicalCompperTotalRVU, setClinicalCompperTotalRVU] =
    useState<string>('');

  const [annualCall, setAnnualCall] = useState<number>(0);
  const [callDays, setCallDays] = useState<number>(0);
  const [annualAdmin, setAnnualAdmin] = useState<number>(0);

  const [totalCompensation, setTotalCompensation] = useState<string>('');
  const [totalCompAdjusted, setTotalCompAdjusted] = useState<string>('');
  const [totalClinicalComp, setTotalClinicalComp] = useState<string>('');
  const [totalAdjustedClinicalComp, setTotalAdjustedClinicalComp] =
    useState<string>('');

  const [reportTitle, setReportTitle] = useState<string | null>(null);
  const [counterParty, setCounterParty] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [facility, setFacility] = useState<string | null>(null);
  const [experienceYears, setExperienceYears] = useState<string | null>(null);
  const [notes, setNotes] = useState<string | null>(null);

  const [hospitalName, setHospitalName] = useState<string>('');
  const [fmvApproval, setFmvApproval] = useState<boolean>(false);
  const [previousApproval, setPreviousApproval] = useState<string>('');
  const [additionalText, setAdditionalText] = useState<string>('');
  const [signature, setSignature] = useState<boolean>(true);

  const handleDignityReportInputChange = (inputValues: any) => {
    setReportTitle?.(inputValues.reportTitle);
    setHospitalName?.(inputValues.hospitalName);
    setFmvApproval?.(inputValues.fmvApproval);
    setPreviousApproval?.(inputValues.previousApproval);
    setAdditionalText?.(inputValues.additionalText);
  };

  const handleReportGeneratorInputsChange = (inputValues: any) => {
    setReportTitle?.(inputValues.reportTitle);
    setCounterParty?.(inputValues.counterParty);
    setStartDate?.(inputValues.startDate);
    setEndDate?.(inputValues.endDate);
    setFacility?.(inputValues.facility);
    setNotes?.(inputValues.notes);
    setSignature?.(inputValues.signature);
    setExperienceYears?.(inputValues.experienceYears);
  };

  const loadServices = async () => {
    const response: any = await MDRangerService.getAll({ type: 'salary' });
    if (!response.failed) {
      setServices?.(response.data.services ?? []);
      setRegionsList?.(response.data.regions ?? {});
      setPhysiciansList?.(response.data.physiciantypes ?? {});
    }
  };

  const loadSelfInfo = async () => {
    const response: any = await GeneralServices.self();

    if (!response.failed) {
      setDignity?.(response.data.dignity);
    }
  };

  const resetValues = () => {
    setShowProduction?.(false);
    setPhysicianFTE?.('');
    setClinicalFTE?.('');
    setClinicalCompensation?.('');
    setPaymentPerWork?.('');
    setBonusIncentives?.('');
    setOthers?.('');
    setDetails?.('');
    setDetailsReimbursements?.('');
    setTotalCompensation?.('');
    setProfessionalCollections?.('');
    setWorkRVUs?.('');
    setProfCollectionsperWorkRVUs?.('');
    setClinicalCompPerWorkRVU?.('');
    setProfCollectionsperTotalRVUs?.('');
    setClinicalCompperTotalRVU?.('');
  };

  const makePdfBody = () => {
    // make sure url encondement wont be bugged in the micro pdf page
    const andURLEncoder = '%26';
    const checkServiceEnconde = service.replace('&', andURLEncoder);
    const checkServiceCallEnconde = serviceCall.map((call) =>
      call.replace('&', andURLEncoder),
    );
    const checkServiceAdminEnconde = serviceAdmin.map((call) =>
      call.replace('&', andURLEncoder),
    );

    const body = {
      service: checkServiceEnconde,
      FTEVal: physicianFTE,
      region: regionsList[region],
      physiciantype: physicianType,
      clinicalFTEVal: clinicalFTE,
      clinicalFTE,
      clinicalCompVal: clinicalCompensation,
      clinicalCompAdjustedVal: adjustedClinicalCompensation,
      paymentPerWorkRVUVal: paymentPerWork,
      workRVUsVal: workRVUs,
      workRVUsAdjustedVal: adjustedWorkRVUs,
      profCollectionsVal: professionalCollections,
      profCollectionsAdjustedVal: adjustedProfessionalCollections,
      clinicalCompPerWorkRVUVal: clinicalCompPerWorkRVU,
      percentCollectionsPaymentsVal: profCollectionsperWorkRVUs,
      HC: hcValues,
      trauma: traumaValues,
      'service-call': checkServiceCallEnconde,
      HD: hdValues,
      HH: hhValues,
      HR: hrValues,
      totalCallVal: annualCall,
      callDays,
      totalAdminVal: annualAdmin,
      totalCompVal: totalCompensation,
      totalCompAdjustedVal: totalCompAdjusted,
      totalClinicalCompVal: totalClinicalComp,
      totalClinicalCompAdjustedVal: totalAdjustedClinicalComp,
      bonusVal: bonusIncentives,
      adjustedBonusVal: adjustedBonusIncentives,
      otherVal: others,
      detailsVal: details!.replace(/\n/g, '%0D%0A'),
      otherReimbursementsVal: othersReimbursements,
      detailsReimbursementsVal: detailsReimbursements!.replace(/\n/g, '%0D%0A'),
      'service-admin': checkServiceAdminEnconde,
      'report-title': reportTitle,
      'report-counterparty': counterParty,
      'report-start': startDate,
      'report-end': endDate,
      'report-facility': facility,
      experienceYears,
      'report-notes': notes!.replace(/\n/g, '%0D%0A'),
      'report-approval': signature,
      containsText: true,
      hospname: hospitalName,
      prevappy: fmvApproval,
      prevappid: previousApproval,
      checkrm: additionalText,
    };
    return body;
  };

  const handleSubmit = async () => {
    const body: any = makePdfBody();
    const paramsArray: string[] = [];
    Object.keys(body).map((key) => {
      if (body[key] === '') return null;
      if (Array.isArray(body[key])) {
        if (!body[key].length) return null;
        return body[key].map((value: string | number) =>
          paramsArray.push(`${key}[]=${value}`),
        );
      }
      return paramsArray.push(`${key}=${body[key]}`);
    });

    // Change the url building in the render page everytime a variable is changed here
    const paramsString = paramsArray.join('&');
    const accessToken = store.getState().auth.token;
    const microUrl = `/tcr-pdf?token=${accessToken}&adjustedBonus=${adjustedBonusIncentives}&${paramsString}`;
    const prefix = window.location.href.split('/total-compensation')[0];

    setSubmiting(true);
    const pdfTitle = `${service}TotalCompensationReport2024.pdf`;
    const buildResponse = await PdfMicroService.buildPdf(
      `${prefix}${microUrl}`,
      pdfTitle,
    );

    if (!buildResponse.failed) {
      window.open(buildResponse.data.url, '_blank');
    }
    setSubmiting(false);
  };

  const checkToShowAdjustedFields = (fte: string) => {
    if (Number(fte) && Number(fte) < 1) {
      return true;
    }
    return false;
  };

  const firstPlusButtonPosition = () => {
    const addClinicalAdjusted = checkToShowAdjustedFields(clinicalFTE) ? 2 : 0;
    if (!service) {
      return 9 + addClinicalAdjusted;
    }
    return 8 + addClinicalAdjusted;
  };

  useEffect(() => {
    resetValues();
  }, [service]);

  useEffect(() => {
    loadServices().then(null);
    loadSelfInfo().then(null);
  }, []);

  useEffect(() => {
    const adjusted = calcAdjustedValue(clinicalCompensation, clinicalFTE);
    setAdjustedClinicalCompensation?.(adjusted);
  }, [clinicalCompensation, clinicalFTE]);

  // calculatiions using the input fields values
  useEffect(() => {
    setPercentClinicalResult?.(
      calcPercentClinicalResult(clinicalFTE, physicianFTE),
    );
  }, [clinicalFTE, physicianFTE]);

  useEffect(() => {
    setProfCollectionsperWorkRVUs?.(
      calcProfCollectionsperWorkRVUs(professionalCollections, workRVUs),
    );
  }, [professionalCollections, workRVUs]);

  useEffect(() => {
    setClinicalCompPerWorkRVU?.(
      calcClinicalCompPerWorkRVU(clinicalCompensation, clinicalFTE, workRVUs),
    );
  }, [clinicalCompensation, clinicalFTE, workRVUs]);

  useEffect(() => {
    const totalComp = calcTotalCompensation(
      Number(physicianFTE),
      Number(clinicalCompensation),
      Number(annualCall),
      Number(annualAdmin),
      Number(bonusIncentives),
      Number(others),
    );
    setTotalCompensation?.(totalComp !== 0 ? String(totalComp) : '');
    const adjustedTotalComp = calcAdjustedValue(
      String(totalComp),
      String(physicianFTE),
    );
    setTotalCompAdjusted?.(adjustedTotalComp);
  }, [
    physicianFTE,
    clinicalFTE,
    clinicalCompensation,
    annualCall,
    annualAdmin,
    bonusIncentives,
    others,
    details,
  ]);
  useEffect(() => {
    const adjusted = calcAdjustedValue(bonusIncentives, clinicalFTE);
    setAdjustedBonusIncentives?.(adjusted);
  }, [clinicalFTE, bonusIncentives]);

  useEffect(() => {
    const adjusted = calcAdjustedValue(professionalCollections, clinicalFTE);
    setAdjustedProfessionalCollections?.(adjusted);
  }, [clinicalFTE, professionalCollections]);

  useEffect(() => {
    const adjusted = calcAdjustedValue(workRVUs, clinicalFTE);
    setAdjustedWorkRVUs?.(adjusted);
  }, [clinicalFTE, workRVUs]);

  useEffect(() => {
    const totalClinical = calcTotalClinicalComp(
      Number(clinicalCompensation),
      Number(bonusIncentives),
      Number(annualCall),
    );
    setTotalClinicalComp(totalClinical !== 0 ? String(totalClinical) : '');
    const adjustedTotalClinical = calcAdjustedValue(
      String(totalClinical),
      clinicalFTE,
    );
    setTotalAdjustedClinicalComp(adjustedTotalClinical);
  }, [clinicalFTE, clinicalCompensation, bonusIncentives, annualCall]);

  return (
    <TotalCompensationContext.Provider
      value={{
        showProduction,
        setShowProduction,
        dignity,
        setDignity,
        service,
        setService,
        services,
        setServices,
        regionsList,
        setRegionsList,
        region,
        setRegion,
        physiciansList,
        setPhysiciansList,
        physicianType,
        setPhysicianType,
        clinicalFTE,
        setClinicalFTE,
        physicianFTE,
        setPhysicianFTE,
        percentClinicalResult,
        setPercentClinicalResult,
        clinicalCompensation,
        setClinicalCompensation,
        adjustedClinicalCompensation,
        setAdjustedClinicalCompensation,
        paymentPerWork,
        setPaymentPerWork,
        bonusIncentives,
        setBonusIncentives,
        adjustedBonusIncentives,
        setAdjustedBonusIncentives,
        others,
        setOthers,
        othersReimbursements,
        setOthersReimbursements,
        details,
        setDetails,
        detailsReimbursements,
        setDetailsReimbursements,
        submiting,
        setSubmiting,
        hcValues,
        setHcValues,
        traumaValues,
        setTraumaValues,
        serviceCall,
        setServiceCall,
        hdValues,
        setHdValues,
        hhValues,
        setHhValues,
        hrValues,
        setHrValues,
        serviceAdmin,
        setServiceAdmin,
        professionalCollections,
        setProfessionalCollections,
        adjustedProfessionalCollections,
        setAdjustedProfessionalCollections,
        workRVUs,
        setWorkRVUs,
        adjustedWorkRVUs,
        setAdjustedWorkRVUs,
        profCollectionsperWorkRVUs,
        setProfCollectionsperWorkRVUs,
        clinicalCompPerWorkRVU,
        setClinicalCompPerWorkRVU,
        adjustedTotalRVUs,
        setAdjustedTotalRVUs,
        profCollectionsperTotalRVUs,
        setProfCollectionsperTotalRVUs,
        clinicalCompperTotalRVU,
        setClinicalCompperTotalRVU,
        annualCall,
        setAnnualCall,
        callDays,
        setCallDays,
        annualAdmin,
        setAnnualAdmin,
        totalCompensation,
        setTotalCompensation,
        totalCompAdjusted,
        setTotalCompAdjusted,
        totalClinicalComp,
        setTotalClinicalComp,
        totalAdjustedClinicalComp,
        setTotalAdjustedClinicalComp,
        reportTitle,
        setReportTitle,
        counterParty,
        setCounterParty,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        facility,
        setFacility,
        experienceYears,
        setExperienceYears,
        notes,
        setNotes,
        hospitalName,
        setHospitalName,
        fmvApproval,
        setFmvApproval,
        previousApproval,
        setPreviousApproval,
        additionalText,
        setAdditionalText,
        signature,
        setSignature,
        handleDignityReportInputChange,
        handleReportGeneratorInputsChange,
        loadServices,
        loadSelfInfo,
        handleSubmit,
        resetValues,
        checkToShowAdjustedFields,
        firstPlusButtonPosition,
      }}
    >
      {children}
    </TotalCompensationContext.Provider>
  );
};

export default TotalCompensationcontextProvider;
