import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MDRangerService from 'src/services/mdrangerServices';
import Modal from '../../atoms/Modal';
import { Body, Column, CloseButton } from './style';
import SearchInput from '../../atoms/SearchInput';
import Input from '../../molecules/Input';
import ConfirmButton from '../../atoms/ConfirmButton';
import TotalCompensationCoverageModalInterface from '../TotalCompensationCoverageModal/interface';

function TotalCompensationAdministrativeModal({
  isOpen,
  onHandle,
  onClose,
  customAdminPaymentFields,
  pageService,
}: TotalCompensationCoverageModalInterface) {
  const { t } = useTranslation('total-compensation');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const [servicesList, setServicesList] = useState<string[]>([]);
  const [servicceSelected, setServiceSelected] = useState<string | undefined>();
  const [annualHours, setAnnualHours] = useState<string>('');
  const [hourlyRate, setHourlyRate] = useState<string>('');
  const [annualPayment, setAnnualPayment] = useState<string>('');

  const [disableFactorsInnput, setDisableFactorsInput] =
    useState<boolean>(false);
  const [disableAnnualPayment, setDisableAnnualPayment] =
    useState<boolean>(false);

  const searchRef = useRef<HTMLInputElement>(null);
  const annualInputRef = useRef<HTMLInputElement>(null);
  const rateInputRef = useRef<HTMLInputElement>(null);
  const paymentInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    onHandle(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    setModalOpen(isOpen);
    if (customAdminPaymentFields) {
      setServiceSelected(customAdminPaymentFields?.service as string);
      setAnnualHours(String(customAdminPaymentFields?.annualHours));
      setHourlyRate(String(customAdminPaymentFields?.hourlyRate));
      setAnnualPayment(String(customAdminPaymentFields?.annualPayment));
    }
  }, [isOpen]);

  const loadServices = async () => {
    const response: any = await MDRangerService.getAllWithBenchmarks(
      'HD,HR,HR',
    );

    if (!response.failed) {
      setServicesList(response.data.services);
    }
  };

  const numberAnnualHours = () => Number(annualHours);
  const numberHourlyRate = () => Number(hourlyRate);
  const numberAnnualPayment = () => Number(annualPayment);

  function calcAnnualPayment(hours: string, rate: string) {
    if (!Number(hours) || !Number(rate)) return '';

    const numberHours = Number(hours);
    const numberRate = Number(rate);
    return String(numberHours * numberRate);
  }

  useEffect(() => {
    if (isOpen && pageService) {
      loadServices().then(null);
    }
  }, [isOpen]);

  useEffect(() => {
    const result = calcAnnualPayment(annualHours, hourlyRate);
    setAnnualPayment(result);
    return setDisableAnnualPayment(
      numberAnnualHours() !== 0 || numberHourlyRate() !== 0,
    );
  }, [annualHours, hourlyRate]);

  useEffect(() => {
    if (
      numberAnnualPayment() !== 0 &&
      !(numberAnnualHours() !== 0 || numberHourlyRate() !== 0)
    ) {
      return setDisableFactorsInput(true);
    }

    return setDisableFactorsInput(false);
  }, [annualPayment]);

  const handleInputException = () => {
    const text = t('no_service_selected');

    window.alert(text);
  };

  const validateSaveClose = () => {
    if (
      (numberAnnualHours() !== 0 ||
        numberHourlyRate() !== 0 ||
        numberAnnualPayment() !== 0) &&
      !servicceSelected
    ) {
      handleInputException();
      return false;
    }
    return true;
  };

  const closeHandle = () => {
    const auxBody = {
      service: servicceSelected,
      annualHours: numberAnnualHours(),
      hourlyRate: numberHourlyRate(),
      annualPayment: numberAnnualPayment(),
    };

    setServiceSelected('');
    setAnnualHours('');
    setHourlyRate('');
    setAnnualPayment('');

    setModalOpen(false);
    return auxBody;
  };

  useEffect(() => {
    function handler(event: any) {
      const isEvent = event.keyCode === 9;
      if (searchRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        annualInputRef!.current!.focus();
      }
      if (annualInputRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        rateInputRef!.current!.focus();
      }
      if (rateInputRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        paymentInputRef!.current!.focus();
      }
      if (paymentInputRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        submitButtonRef!.current!.focus();
      }
    }
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, []);

  return (
    <Modal
      isOpen={isModalOpen}
      Children={
        <Body>
          <CloseButton onClick={() => closeHandle()} />
          <SearchInput
            placeholder={servicceSelected || t('search_service')}
            callback={(value: string) => setServiceSelected(value)}
            width={300}
            options={servicesList}
            searchRef={searchRef}
          />
          <Column>
            <Input
              title={t('annual_hours')}
              titleSide="left"
              width={80}
              type="number"
              value={annualHours}
              callback={(value: string) => setAnnualHours(value)}
              disabled={disableFactorsInnput || !servicceSelected}
              propRef={annualInputRef}
            />
            <Input
              title={t('hourly_rate')}
              titleSide="left"
              width={80}
              type="currency"
              value={hourlyRate}
              callback={(value: string) => setHourlyRate(value)}
              disabled={disableFactorsInnput || !servicceSelected}
              propRef={rateInputRef}
            />
            <Input
              title={t('annual_payment')}
              titleSide="left"
              width={80}
              type="currency"
              value={annualPayment}
              callback={(value: string) => setAnnualPayment(value)}
              disabled={disableAnnualPayment || !servicceSelected}
              propRef={paymentInputRef}
            />
          </Column>
          <ConfirmButton
            onClick={() => {
              if (validateSaveClose()) {
                onClose(closeHandle());
              }
            }}
            label={t('total-compensation-close')}
            propRef={submitButtonRef}
          />
        </Body>
      }
    />
  );
}

export default TotalCompensationAdministrativeModal;
