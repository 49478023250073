import Api from './api';
import keysToCamel from '../utils/keysToCamelCase';
import GetTotalCompParamsBody from '../contracts/models/GetTotalCompParamsBody.model';

interface paramsBody {
  service?: string;
  baseCompVal?: number;
  workRVUsVal?: number;
  totalRVUsVal?: number;
  profCollectionsVal?: number;
  percentCollectionsPaymentsVal?: number;
  netProfessionalCollectionsPerTotalRVUVal?: number;
  clinicalCompPerWorkRVUVal?: number;
  HC?: number[];
  'service-call'?: string[];
  HD?: Number[];
  HH?: Number[];
  HR?: number[];
  'service-admin'?: string[];
}

export default class TotalCompensation {
  static async getBaseCompensationOld(paramsBody: paramsBody) {
    return Promise.resolve(
      Api.get('/api/totalcompensation', {
        params: { ...paramsBody },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getBaseCompensation(
    service: string,
    values: GetTotalCompParamsBody,
    region?: string,
    physicianType?: string,
    serviceCall?: string[],
    trauma?: '1' | '0',
    signal?: AbortController,
  ) {
    return Promise.resolve(
      Api.get('/api/totalcompensation', {
        params: {
          service,
          ...values,
          region,
          physiciantype: physicianType,
          'service-call': serviceCall ?? [],
          trauma: trauma ?? '0',
        },
        signal: signal?.signal,
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getCompensationAdminPayment(
    service: string,
    HDValues: number[],
    HHValues: number[],
    HRValues: number[],
    serviceAdmin: string[],
    region?: string,
    physicianType?: string,
  ) {
    return Promise.resolve(
      Api.get('/api/totalcompensation', {
        params: {
          service,
          HD: HDValues,
          HH: HHValues,
          HR: HRValues,
          'service-admin': serviceAdmin,
          region,
          physiciantype: physicianType,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async pdf(queryString: string) {
    return Promise.resolve(
      Api.get(`/api/totalcompensation-pdf?${queryString}`)
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
}
