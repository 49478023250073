import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import BarChartInterface from './interface';
import SubTitle from '../Subtitle';
import {
  OuterContainer,
  ChartContainer,
  TickLabel,
  TicksContainer,
  TicksRow,
} from './style';

function BarChart({
  id,
  xValues,
  yValues,
  rowWidth,
  tickNumber,
  width,
  marginRight,
  minHeight,
  bottomText,
}: BarChartInterface) {
  function displayTickLabels(labels: string[]) {
    return labels.map((label: string) => {
      const [name, value, percentage] = label.split(';');
      return (
        <TicksRow rowWidth={rowWidth}>
          <TickLabel>{name}</TickLabel>
          <TickLabel>{value}</TickLabel>
          <TickLabel>{percentage}</TickLabel>
        </TicksRow>
      );
    });
  }

  function formattValue(tick: number | string): string {
    const value = typeof tick === 'string' ? parseFloat(tick) : tick;

    if (Number.isNaN(value)) {
      return '';
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
    });

    return `$${formatter.format(value)}`;
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  return (
    <OuterContainer numLabels={xValues.length ?? 0} minHeight={minHeight}>
      <TicksContainer>{displayTickLabels(xValues as string[])}</TicksContainer>
      <ChartContainer
        numLabels={xValues.length}
        width={width}
        marginRight={marginRight}
        minHeight={minHeight}
      >
        <Bar
          id={`${id}-chart`}
          data={{
            labels: xValues as string[],
            datasets: [
              {
                barPercentage: 1,
                categoryPercentage: 1,
                data: yValues,
                backgroundColor: '#4099CE',
                maxBarThickness: 11,
              },
            ],
          }}
          options={{
            devicePixelRatio: 10,
            layout: {
              padding: {
                right: 15,
              },
            },
            animation: false,
            responsive: true,
            indexAxis: 'y' as const,
            maintainAspectRatio: false,
            scales: {
              x: {
                beginAtZero: true,
                grid: {
                  color: '#040404',
                  lineWidth: 0.3,
                  borderWidth: 0,
                },
                ticks: {
                  autoSkip: false,
                  display: true,
                  maxTicksLimit: tickNumber ?? 6,
                  stepSize: 0.4,
                  color: '#040404',
                  padding: -12,
                  maxRotation: 0,
                  callback: (value) => formattValue(value),
                  align: 'center',
                  font: {
                    size: 6,
                    lineHeight: 6,
                  },
                },
              },
              y: {
                grid: {
                  display: false,
                },
                ticks: {
                  autoSkip: true,
                  display: false,
                  crossAlign: 'far',
                  font: {
                    size: 6,
                    weight: 'bold',
                  },
                  color: '#040404',
                  align: 'center',
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
        {bottomText ? (
          <SubTitle className="bottomText" text={bottomText} fontSize={8} />
        ) : null}
      </ChartContainer>
    </OuterContainer>
  );
}

export default BarChart;
