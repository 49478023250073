import React, { useState, useEffect } from 'react';
import TotalCompensation from '../totalCompensation';
import TotalCompensationTemplate from '../../components/templates/Reports/TotalCompensation';

function TotalCompensationPDF() {
  const [pdfData, setPdfData] = useState<any>();

  const getRcrData = async () => {
    const PARAMS_MANUALLY_TAKEN = 2;
    const searchUrl = window.location.search;

    const queryString = searchUrl
      .split('&')
      .splice(PARAMS_MANUALLY_TAKEN)
      .join('&');

    const params = new URLSearchParams(searchUrl);
    const callDays = params.get('callDays');
    const response = await TotalCompensation.pdf(queryString);

    if (!response.failed) {
      setPdfData({
        ...response.data,
        callDays,
      });
    }
  };

  useEffect(() => {
    getRcrData().then(null);
  }, []);

  return pdfData ? <TotalCompensationTemplate data={pdfData} /> : null;
}

export default TotalCompensationPDF;
