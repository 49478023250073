import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UsingBenchmarkPage from 'src/pages/UsingBenchmark';
import MultiCampusPage from 'src/pages/MultiCampus';
import RateComparisonLoader from 'src/pages/RateComparisonLoader';
import TrendAnalysisPage from 'src/pages/TrendAnalysis';
import FacilityTotals from 'src/services/Reports/facilityTotals';
import AccountPage from '../pages/AccountPage';
import ContractSummaryPdf from '../services/Reports/contractSummary';
import ExecSummaryPDF from '../services/Reports/execSummary';
import FacilityPage from '../pages/FacilityPage';
import Home from '../pages/Home';
import PDFBuilder from '../pages/PDFBuilder';
import RateComparisonPDF from '../services/Reports/rateComparison';
import SearchNonSalaryPdf from '../services/Reports/searchNonSalary';
import SearchSalaryPdf from '../services/Reports/searchSalaryPdf';
import SingleContractPDF from '../services/Reports/singleContract';
import SingleServiceReportPDF from '../services/Reports/SingleServicePDF';
import TotalCompensation from '../pages/TotalCompensation';
import TotalCompensationPDF from '../services/Reports/totalCompensation';
import TrendAnalysisPDF from '../services/Reports/trendAnalysis';
import FacTotalsPage from '../pages/FacTotalsPage';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rate-comparison" element={<RateComparisonLoader />} />
        <Route path="/total-compensation" element={<TotalCompensation />} />
        <Route path="/facility" element={<FacilityPage />} />
        <Route path="/execsum-pdf" element={<ExecSummaryPDF />} />
        <Route path="/pdf-builder" element={<PDFBuilder />} />
        <Route path="/rcr-pdf" element={<RateComparisonPDF />} />
        <Route path="/tcr-pdf" element={<TotalCompensationPDF />} />
        <Route path="/ssr-pdf" element={<SingleServiceReportPDF />} />
        <Route path="/search-pdf" element={<SearchSalaryPdf />} />
        <Route path="/search-non-pdf" element={<SearchNonSalaryPdf />} />
        <Route path="/allfac-pdf" element={<ContractSummaryPdf />} />
        <Route path="/single-contract-pdf" element={<SingleContractPDF />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/using-benchmarks" element={<UsingBenchmarkPage />} />
        <Route path="/multi-campus" element={<MultiCampusPage />} />
        <Route path="/trends" element={<TrendAnalysisPage />} />
        <Route path="/trends-pdf" element={<TrendAnalysisPDF />} />
        <Route path="/factotals" element={<FacTotalsPage />} />
        <Route path="/factotals-pdf" element={<FacilityTotals />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
