/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { Container, Row, Helper, RowSlices } from './style';
import PlusButton from '../../../atoms/PlusButton';
import Input from '../../Input';
import ComparisonConditionalMultipleInputInterface from './interface';
import Loader from '../../../atoms/DotLoader';
import RateComparison from '../../../../services/rateComparison';
import LightColors from '../../../../constants/lightColors';
import ConditionalPercentileIcon from '../../ConditionalPercentileIcon';
import Subtitle from '../../../atoms/Subtitle';
import ConditionalPercentileIconDignity from '../ConditionalPercentileIcon';

function ComparisonConditionalMultipleInput({
  inputsPlaceholder,
  buttonPlaceholder,
  width,
  selectedService,
  identifiers,
  trauma,
  inputsCallback,
  values,
  hasIndicator,
  hiddenInputs,
  hiddenMessage,
  withoutButton,
  helper,
  slices,
}: ComparisonConditionalMultipleInputInterface) {
  const [show, setShow] = useState<boolean>(false);

  const [firstInput, setFirstInput] = useState<string>('');
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  const [firstLight, setFirstLight] = useState<string | undefined>();
  const [firstLightSlices, setFirstLightSlices] = useState<string[]>([]);

  const [secondInput, setSecondInput] = useState<string>('');
  const [secondLoading, setSecondLoading] = useState<boolean>(false);
  const [secondLight, setSecondLight] = useState<string | undefined>();
  const [secondLightSlices, setSecondLightSlices] = useState<string[]>([]);

  const [thirdInput, setThirdInput] = useState<string>('');
  const [thirdLoading, setThirdLoading] = useState<boolean>(false);
  const [thirdLight, setThirdLight] = useState<string | undefined>();
  const [thirdLightSlices, setThirdLightSlices] = useState<string[]>([]);

  const [fourthInput, setFourthInput] = useState<string>('');
  const [fourthLoading, setFourthLoading] = useState<boolean>(false);
  const [fourthLight, setFourthLight] = useState<string | undefined>();
  const [fourthLightSlices, setFourthLightSlices] = useState<string[]>([]);

  const NONELIGHT = LightColors.none;
  const NOTFOUND = LightColors.notfound;

  const notCurrency = ['HH'];

  const isCurrency = (identifier: string) =>
    notCurrency.includes(identifier) ? 'number' : 'currency';

  const hasDollar = (value: string) => value.includes('$');

  const buttonHandle = (clicked: boolean) => {
    if (!clicked) {
      setSecondInput('');
      setThirdInput('');
      setFirstInput('');
      setFourthInput('');
    }

    setShow(clicked);
  };

  const findResult = async (identifier: string, value: string) =>
    RateComparison.get(
      selectedService,
      identifier,
      value,
      trauma ? 1 : 0,
      slices.length > 0 ? slices.join(',') : undefined,
    );

  const parseFormattedValue = (value: string): number =>
    parseInt(value.replace(/[$,]/g, ''), 10);

  const handleChangeInput = async (
    index: number,
    value: string,
    setLoading: Function,
    setValue: Function,
    setLight: Function,
    setLightSlices: Function,
  ) => {
    let usedValue = value;
    if (!usedValue || !usedValue.length) {
      setValue('');
      return false;
    }

    setLoading(true);

    if (hasDollar(value)) {
      const parsedValue = parseFormattedValue(value);
      usedValue = parsedValue.toString();

      if (parsedValue <= 0) {
        setLoading(false);
        setValue(usedValue);
        return setLight(NOTFOUND);
      }
    }

    setValue(usedValue);

    if (
      !hasIndicator ||
      (hasIndicator && hasIndicator.includes(identifiers[index]))
    ) {
      const response = await findResult(identifiers[index], usedValue ?? '');

      if (response.failed) {
        setLoading(false);
        return setLight(NONELIGHT);
      }

      const responseColor: LightColors =
        response.data?.[0]?.[identifiers[index]] ?? NONELIGHT;

      setLight(responseColor);
      if (slices.length > 0 && responseColor !== NONELIGHT) {
        const indicators = Object.keys(responseColor);
        const indicatorSlices = Object.values(responseColor) ?? [];

        setLight(indicators);
        setLightSlices(indicatorSlices);
      }

      setLoading(false);
    }
    return true;
  };

  useEffect(() => {
    inputsCallback({
      firstInput,
      secondInput,
      thirdInput,
      fourthInput,
    });
  }, [firstInput, secondInput, thirdInput, fourthInput]);

  useEffect(() => {
    if (values && values?.length >= 1 && firstInput) {
      handleChangeInput(
        0,
        firstInput,
        setFirstLoading,
        setFirstInput,
        setFirstLight,
        setFirstLightSlices,
      );
    }
    if (values && values?.length >= 2 && secondInput) {
      handleChangeInput(
        1,
        secondInput,
        setSecondLoading,
        setSecondInput,
        setSecondLight,
        setSecondLightSlices,
      );
    }

    if (values && values?.length >= 3 && thirdInput) {
      handleChangeInput(
        2,
        thirdInput,
        setThirdLoading,
        setThirdInput,
        setThirdLight,
        setThirdLightSlices,
      );
    }

    if (values && values?.length >= 4 && fourthInput) {
      handleChangeInput(
        3,
        fourthInput,
        setFourthLoading,
        setFourthInput,
        setFourthLight,
        setFourthLightSlices,
      );
    }
  }, [trauma, slices]);

  function ShowResponseIcon(loading: boolean, light: any, input: string) {
    return (
      <>
        {loading && input && <Loader />}
        {light && input && !loading ? (
          <ConditionalPercentileIconDignity percentileIndicator={light} />
        ) : null}
      </>
    );
  }

  function ShowResponseIconSlices(
    loading: boolean,
    light: any,
    input: string,
    conditionalSlices: string[],
  ) {
    return (
      <>
        <div style={{ marginTop: -15 }}>{loading && input && <Loader />}</div>
        {light &&
          input &&
          !loading &&
          (Array.isArray(light) ? (
            // eslint-disable-next-line react/destructuring-assignment
            light.map((item, index) => (
              <ConditionalPercentileIcon
                key={item}
                percentileIndicator={item}
                // @ts-ignore
                slicesIndicator={conditionalSlices[index]}
              />
            ))
          ) : (
            <ConditionalPercentileIcon percentileIndicator={light} />
          ))}
      </>
    );
  }

  const buildIndicatorValue = (
    firstValue?: string,
    secondValue?: string,
  ): string => {
    if (!firstValue || !secondValue) {
      return '';
    }
    const value = parseInt(firstValue, 10) - parseInt(secondValue, 10);
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
    });

    return `$${formatter.format(value)}`;
  };

  function checkIndicator(
    identifier: string,
    loading: boolean,
    light: any,
    input: string,
    conditionalSlices: string[],
  ): any {
    if (hasIndicator && hasIndicator.length > 0) {
      if (hasIndicator.includes(identifier)) {
        return ShowResponseIconSlices(loading, light, input, conditionalSlices);
      }
    } else {
      return ShowResponseIcon(loading, light, input);
    }
    return null;
  }

  useEffect(() => {
    buttonHandle(false);
  }, [selectedService]);

  useEffect(() => {
    if (withoutButton) {
      setShow(true);
    }
  }, [withoutButton]);

  return (
    <Container $withouButton={withoutButton}>
      {!withoutButton && (
        <PlusButton
          placeholder={buttonPlaceholder ?? ''}
          callback={buttonHandle}
          toggleable
          bind={show}
        />
      )}
      {show && (
        <>
          {values &&
            values?.length >= 1 &&
            !hiddenInputs?.includes(identifiers[0]) && (
              <Row>
                <Input
                  focus
                  title={inputsPlaceholder[0]}
                  callback={(event: any) =>
                    handleChangeInput(
                      0,
                      event,
                      setFirstLoading,
                      setFirstInput,
                      setFirstLight,
                      setFirstLightSlices,
                    )
                  }
                  type={isCurrency(identifiers[0])}
                  width={width}
                  value={values![0]!}
                />
                {checkIndicator(
                  identifiers[0],
                  firstLoading,
                  firstLight,
                  firstInput,
                  firstLightSlices,
                )}
              </Row>
            )}
          {helper && helper.length >= 1 && show ? (
            <Helper>{helper[0]}</Helper>
          ) : null}
          {values &&
            values?.length >= 2 &&
            !hiddenInputs?.includes(identifiers[1]) && (
              <Row>
                <Input
                  title={inputsPlaceholder[1]}
                  callback={(event: any) =>
                    handleChangeInput(
                      1,
                      event,
                      setSecondLoading,
                      setSecondInput,
                      setSecondLight,
                      setSecondLightSlices,
                    )
                  }
                  type={isCurrency(identifiers[1])}
                  width={width}
                  value={values![1]!}
                />
                {checkIndicator(
                  identifiers[1],
                  secondLoading,
                  secondLight,
                  secondInput,
                  secondLightSlices,
                )}
              </Row>
            )}
          {helper && helper.length >= 2 && show ? (
            <Helper>{helper[1]}</Helper>
          ) : null}
          {values &&
            values?.length >= 3 &&
            !hiddenInputs?.includes(identifiers[2]) && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <Subtitle text={inputsPlaceholder[2]} />
                <RowSlices $marginBottom={(slices.length > 0).toString()}>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                  >
                    <Input
                      callback={(event: any) =>
                        handleChangeInput(
                          2,
                          event,
                          setThirdLoading,
                          setThirdInput,
                          setThirdLight,
                          setThirdLightSlices,
                        )
                      }
                      type={
                        hasIndicator?.includes(identifiers[2])
                          ? 'text'
                          : isCurrency(identifiers[2])
                      }
                      width={width}
                      value={
                        hasIndicator?.includes(identifiers[2])
                          ? buildIndicatorValue(values![0]!, values![1]!)
                          : values![2]!
                      }
                      disabled={hasIndicator?.includes(identifiers[2])}
                    />
                  </div>
                  {checkIndicator(
                    identifiers[2],
                    thirdLoading,
                    thirdLight,
                    thirdInput,
                    thirdLightSlices,
                  )}
                </RowSlices>
              </div>
            )}
          {helper && helper.length >= 3 && show ? (
            <Helper>{helper[2]}</Helper>
          ) : null}
          {values &&
            values?.length >= 4 &&
            !hiddenInputs?.includes(identifiers[3]) && (
              <Row>
                <Input
                  title={inputsPlaceholder[3]}
                  callback={(event: any) =>
                    handleChangeInput(
                      3,
                      event,
                      setFourthLoading,
                      setFourthInput,
                      setFourthLight,
                      setFourthLightSlices,
                    )
                  }
                  type={isCurrency(identifiers[3])}
                  width={width}
                  value={values![3]!}
                />
                {checkIndicator(
                  identifiers[3],
                  fourthLoading,
                  fourthLight,
                  fourthInput,
                  fourthLightSlices,
                )}
              </Row>
            )}
          {helper && helper.length >= 4 && show ? (
            <Helper>{helper[3]}</Helper>
          ) : null}
          {hiddenMessage && <span>{hiddenMessage}</span>}
        </>
      )}
    </Container>
  );
}

export default ComparisonConditionalMultipleInput;
