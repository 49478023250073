import React, { useState, useEffect } from 'react';
import SearchNonSalaryTemplate from '../../components/templates/Reports/SearchNonSalary';
import SearchBenchmarks from '../searchBenchmarks';

function SearchNonSalaryPdf() {
  const [pdfData, setPdfData] = useState<any>();

  const getSearchPdfData = async () => {
    const queryParams = window.location.href
      .split('/search-non-pdf?')[1]
      .split('&')
      .splice(1)
      .join('&');

    const response = await SearchBenchmarks.pdf(queryParams);

    if (!response.failed) {
      setPdfData(response.data);
    }
  };

  useEffect(() => {
    getSearchPdfData().then(null);
  }, []);

  return pdfData ? <SearchNonSalaryTemplate data={pdfData} /> : null;
}

export default SearchNonSalaryPdf;
