import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TotalCompensation from 'src/services/totalCompensation';
import {
  Container,
  Row,
  FieldsHolder,
  IconsHolder,
  EditIcon,
  DeleteIcon,
} from './style';
import PlusButton from '../../atoms/PlusButton';
import Input from '../Input';
import Loader from '../../atoms/DotLoader';
import CompensationAdministrativePaymentInputsInterface from './interface';
import AdmistrativePaymentsFields from '../../../contracts/models/AdmnistrativePaymentFields.model';
import TotalCompensationAdministrativeModal from '../../organisms/TotalCompensationAdministrativeModal';
import ConditionalPercentileIcon from '../ConditionalPercentileIcon';

function CompensationAdministrativePaymentInputs({
  buttonPlaceholder,
  sumCallback,
  hdCallback,
  hhCallback,
  hrCallback,
  serviceCallback,
  width,
  pageService,
  pageRegion,
  pagePhysicianType,
}: CompensationAdministrativePaymentInputsInterface) {
  const { t } = useTranslation('total-compensation');

  interface Response {
    loadingHD: boolean;
    lightHD?: string | undefined;

    loadingHH: boolean;
    lightHH?: string | undefined;

    loadingHR: boolean;
    lightHR?: string | undefined;
  }

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const [inputReturn, setInputReturn] = useState<AdmistrativePaymentsFields[]>(
    [],
  );
  const [InputEdit, setInputEdit] = useState<
    AdmistrativePaymentsFields | undefined
  >();
  const [editingIndex, setEditingIndex] = useState<number | undefined>();
  const [responseSignals, setResponseSignals] = useState<Response[]>([]);

  const handlePlusButton = (status: boolean) => {
    setModalOpen(status);
  };

  useEffect(() => {
    let sum = 0;
    const tempHDArray: number[] = [];
    const tempHHArray: number[] = [];
    const tempHRArray: number[] = [];
    const tempServiceArray: string[] = [];
    inputReturn.map(
      (
        item: AdmistrativePaymentsFields,
        // eslint-disable-next-line array-callback-return
      ) => {
        sum += item?.annualPayment as number;
        tempHDArray.push(item?.annualPayment!);
        tempServiceArray.push(item?.service!);
        if (item?.annualHours) tempHHArray.push(item?.annualHours!);
        if (item?.hourlyRate) tempHRArray.push(item?.hourlyRate!);
      },
    );
    sumCallback(sum);
    hdCallback(tempHDArray);
    serviceCallback(tempServiceArray);
    hhCallback(tempHHArray);
    hrCallback(tempHRArray);
  }, [inputReturn]);

  useEffect(() => {
    setInputReturn([]);
  }, [pageService, pageRegion, pagePhysicianType]);

  const makeWholeSignalBody = (hdLight: any, hhLight: any, hrLight: any) => {
    const wholeBody = {} as Response;

    wholeBody.loadingHD = false;
    wholeBody.lightHD = hdLight;

    wholeBody.loadingHH = false;
    wholeBody.lightHH = hhLight;

    wholeBody.loadingHR = false;
    wholeBody.lightHR = hrLight;

    return wholeBody;
  };

  const editResponseItem = (newItem: Response, index: number) => {
    const aux = responseSignals;
    aux.splice(index, 1, newItem);
    setResponseSignals([...aux]);
  };

  const getBaseCompensation = async (
    serviceAdmin: string[],
    HDValues: number[],
    HHValues: number[],
    HRValues: number[],
    index: number,
  ) => {
    const response = await TotalCompensation.getCompensationAdminPayment(
      pageService!,
      HDValues,
      HHValues,
      HRValues,
      serviceAdmin,
      pageRegion,
      pagePhysicianType,
    );

    if (response.failed) {
      return editResponseItem(
        { loadingHD: false, loadingHH: false, loadingHR: false },
        index,
      );
    }
    const hdLight = response.data?.HD?.[0];

    const hhLight = response.data?.HH?.[0];

    const hrLight = response.data?.HR?.[0];

    const validatedSignal = makeWholeSignalBody(hdLight, hhLight, hrLight);

    return editResponseItem(validatedSignal, index);
  };

  const addArrayElement = (element: AdmistrativePaymentsFields) => {
    const currentLen = inputReturn.length;
    setInputReturn((prev) => [...prev, element]);
    setResponseSignals((prev) => [
      ...prev,
      { loadingHD: true, loadingHH: true, loadingHR: true },
    ]);
    getBaseCompensation(
      [element.service!],
      [element.annualPayment!],
      [element.annualHours!],
      [element.hourlyRate!],
      currentLen,
    ).then(null);
  };

  const editArrayElement = (
    editBody: AdmistrativePaymentsFields,
    index: number,
  ) => {
    const aux = inputReturn;
    aux.splice(index, 1, editBody);
    setInputReturn([...aux]);
    editResponseItem(
      { loadingHD: true, loadingHH: true, loadingHR: true },
      index,
    );
    getBaseCompensation(
      [editBody.service!],
      [editBody.annualPayment!],
      [editBody.annualHours!],
      [editBody.hourlyRate!],
      index,
    ).then(null);
  };

  const handleModalClose = (modalReturn: AdmistrativePaymentsFields) => {
    if (modalReturn.service && modalReturn.annualPayment) {
      if (editingIndex !== undefined) {
        editArrayElement(modalReturn, editingIndex);
        setInputEdit(undefined);
        return setEditingIndex(undefined);
      }
      if (modalReturn.service) {
        return addArrayElement(modalReturn);
      }
    }
    return null;
  };

  const deleteFields = (index: number) => {
    const aux = inputReturn;
    aux.splice(index, 1);
    setInputReturn([...aux]);

    const auxResponse = responseSignals;
    auxResponse.splice(index, 1);
    setResponseSignals([...auxResponse]);
  };

  const showResponseIcon = (loading: boolean, light?: any) => (
    <>
      {loading && <Loader />}
      {light && !loading ? (
        <ConditionalPercentileIcon percentileIndicator={light} />
      ) : null}
    </>
  );

  return (
    <Container>
      <TotalCompensationAdministrativeModal
        isOpen={isModalOpen}
        onHandle={setModalOpen}
        onClose={handleModalClose}
        customAdminPaymentFields={InputEdit}
        pageService={pageService!}
      />
      <PlusButton
        placeholder={buttonPlaceholder}
        callback={handlePlusButton}
        bind={false}
      />
      <div>
        {inputReturn.map((item: AdmistrativePaymentsFields, index: number) => (
          <FieldsHolder>
            <IconsHolder>
              <EditIcon
                onClick={() => {
                  setInputEdit(item);
                  setEditingIndex(index as number);
                  setModalOpen(true);
                }}
              />
              <DeleteIcon onClick={() => deleteFields(index)} />
            </IconsHolder>
            {(item.annualHours as number) > 0 &&
            (item?.hourlyRate as number) > 0 ? (
              <div key={`adminPaymentResult-${item.service}`}>
                <Row>
                  <Input
                    title={t('hourly_rate')}
                    subtitle={item.service}
                    callback={() => null}
                    type="currency"
                    value={item.hourlyRate}
                    width={width}
                    disabled
                  />
                  {showResponseIcon(
                    responseSignals[index]?.loadingHR,
                    responseSignals[index]?.lightHR,
                  )}
                </Row>
                <Row>
                  <Input
                    title={t('annual_hours')}
                    subtitle={item.service}
                    callback={() => null}
                    type="number"
                    value={item.annualHours}
                    width={width}
                    disabled
                  />
                  {showResponseIcon(
                    responseSignals[index]?.loadingHH,
                    responseSignals[index]?.lightHH,
                  )}
                </Row>
              </div>
            ) : (
              false
            )}
            <Row>
              <Input
                title={t('annual_payment')}
                subtitle={item.service}
                callback={() => null}
                type="currency"
                value={item.annualPayment}
                width={width}
                disabled
              />
              {showResponseIcon(
                responseSignals[index]?.loadingHD,
                responseSignals[index]?.lightHD,
              )}
            </Row>
          </FieldsHolder>
        ))}
      </div>
    </Container>
  );
}

export default CompensationAdministrativePaymentInputs;
