import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  reducers: {
    changeToken(state, { payload }) {
      return { ...state, token: payload };
    },
  },
  name: 'auth',
  initialState: {
    token: '',
  },
});

export const { changeToken } = slice.actions;

export const currentToken = (state: { token: string }) => state.token;

export default slice.reducer;
