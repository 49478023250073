/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Fade from 'react-reveal';
import { Container, ButtonContainer, CloseButton } from './style';
import Title from '../../atoms/Title';
import SubTitle from '../../atoms/Subtitle';
import Modal from '../../atoms/Modal';
import MessageModalInterface from './interface';

function MessageModal({
  title,
  fisrtMessage,
  buttonLabel,
  handleClose,
}: MessageModalInterface) {
  const ShowSubText = (text: string) => text && <SubTitle text={text} />;

  return (
    <Modal
      isOpen
      Children={
        <Fade top cascade>
          <Container>
            {title && <Title text={title} />}
            {ShowSubText(fisrtMessage!)}
            <ButtonContainer>
              <CloseButton onClick={() => handleClose(false)}>
                {buttonLabel}
              </CloseButton>
            </ButtonContainer>
          </Container>
        </Fade>
      }
    />
  );
}

export default MessageModal;
