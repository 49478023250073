import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import SubTitle from '../Subtitle';
import { Container, MenuContainer, SelectContainer } from './style';
import SelectDropDownInterface from './interface';

function SelectDropDown({
  title,
  callBack,
  dataItems,
  startValueKey,
  styleCheck,
  placeholder,
}: SelectDropDownInterface) {
  const [pickedKey, setPickedKey] = useState<string>(startValueKey ?? '');

  const [selectKeys, setSelectKeys] = useState<string[]>([]);
  const [selectValues, setSelectValues] = useState<string[]>([]);

  const handleChange = (event: any) => {
    callBack({
      id: event.target.value,
      value: dataItems[event.target.value],
    });

    return setPickedKey(event.target.value);
  };

  useEffect(() => {
    if (startValueKey) {
      callBack({ id: startValueKey, value: dataItems[startValueKey] });
    }

    setPickedKey(startValueKey ?? '');
  }, [startValueKey]);

  useEffect(() => {
    if (Object?.keys(dataItems).length === 0) {
      callBack({
        id: null,
        value: null,
      });
    }

    setSelectValues(Object.values(dataItems ?? {}));
    const keys = Object.keys(dataItems ?? {});
    return setSelectKeys([...keys]);
  }, [dataItems]);

  const defaultSelect = () => (
    <MenuContainer
      id="dropdown"
      value={pickedKey as string}
      onChange={handleChange}
      displayEmpty
      renderValue={() => <MenuItem>{dataItems?.[pickedKey]}</MenuItem>}
      inputProps={{ 'aria-label': 'Without label' }}
      style={{ alignSelf: 'start' }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {selectKeys.map((item: any, index: number) => (
        <MenuItem key={`menu-item-${item}`} value={selectKeys[index]}>
          {selectValues[index]}
        </MenuItem>
      ))}
    </MenuContainer>
  );

  const checkboxSelect = () => (
    <SelectContainer
      id="dropdown"
      value={pickedKey as string}
      onChange={handleChange}
      displayEmpty
      renderValue={() => (
        <MenuItem
          sx={{ padding: '0' }}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {pickedKey && dataItems?.[pickedKey] ? (
            <>
              <Checkbox
                key={`${pickedKey}-checkbox`}
                checked
                sx={{
                  '&.Mui-checked': {
                    color: 'var(--ecg-royal-blue)',
                  },
                }}
              />
              <span style={{ textWrap: 'balance' }}>
                {dataItems?.[pickedKey]}
              </span>
            </>
          ) : (
            <span style={{ paddingLeft: 10, color: '#838a8e' }}>
              {placeholder}
            </span>
          )}
        </MenuItem>
      )}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {selectKeys.map((item: any, index: number) => (
        <MenuItem key={`menu-item-${item}`} value={selectKeys[index]}>
          <Checkbox
            key={`${item}-checkbox`}
            checked={selectKeys[index] === pickedKey}
            sx={{
              '&.Mui-checked': {
                color: 'var(--ecg-royal-blue)',
              },
            }}
          />
          {selectValues[index]}
        </MenuItem>
      ))}
    </SelectContainer>
  );

  const selectType = () => {
    switch (styleCheck) {
      case 'checkbox':
        return checkboxSelect();
      default:
        return defaultSelect();
    }
  };

  return (
    <Container>
      {title && <SubTitle text={title} />}
      <FormControl
        sx={{
          m: 1,
          minWidth: 250,
          justifyContent: 'center',
          margin: 0,
        }}
      >
        {selectType()}
      </FormControl>
    </Container>
  );
}

export default SelectDropDown;
