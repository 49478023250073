import styled from 'styled-components';
import { MdAdd, MdCheck } from 'react-icons/md';

export const AddIcon = styled(MdAdd)``;

export const CheckIcon = styled(MdCheck)``;

export const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
