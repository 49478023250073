import styled from 'styled-components';

interface BoxHiderProps {
  blur: boolean;
}

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 90%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  > span {
    font-size: 12px;
    color: var(--secondary-light-gray);
    font-style: italic;
    margin-left: 15px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > :nth-child(2) {
    margin-left: 35px;
  }

  > :nth-child(3) {
    margin-top: 10px;
    margin-left: 30px;
  }
`;

export const Box = styled.section`
  position: relative;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 90%;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 23%);
  overflow: auto;
`;

export const BoxHider = styled.div<BoxHiderProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.blur ? 'rgba(242,243,245, 0.5)' : 'transparent'};
  cursor: not-allowed;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-background);
  width: 100vw;

  > :nth-child(4) {
    margin-top: 100px;
  }

  > :nth-child(3) {
    padding-top: 0px;
    padding-left: 0px;
  }

  *${Box}, ${Section} {
    margin-bottom: 20px;
  }
`;
