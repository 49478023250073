import Api from './api';
import keysToCamel from '../utils/keysToCamelCase';

export default class TrendAnalysis {
  static async getSlices(service: string, type: string, precheck: 1 | 0) {
    return Promise.resolve(
      Api.get('/api/trending', {
        params: {
          service,
          type,
          precheck,
        },
      })
        .then((response) => ({
          failed: false,
          data: response.data,
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getHiddenTypes(service: string, precheck: 1 | 0) {
    return Promise.resolve(
      Api.get('/api/trending', {
        params: {
          service,
          precheck,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getTrends(
    service: string,
    type: string,
    slice: string,
    years: string,
  ) {
    return Promise.resolve(
      Api.get('/api/trending', {
        params: {
          service,
          type,
          slice,
          years,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async pdf(queryString: string) {
    return Promise.resolve(
      Api.get(`/api/trending?${queryString}`)
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
}
