import React from 'react';
import CompensationNonBenchmarkInterface from './interface';
import {
  BenchmarkService,
  ComparisonService,
  Container,
  ContainerNon,
  Information,
  Line,
  NonBenchTitle,
} from './style';
import MDRangerServicesConstants from '../../../constants/services';

function CompensationNonBenchmark({
  benchmarkTitle,
  benchmarkObject,
  service,
  benchValue,
  benchmarkId,
}: CompensationNonBenchmarkInterface) {
  const getSliceMessage = (slice: string, slicedetail: string) => {
    switch (slice) {
      case 'gach':
        return <ComparisonService>All Acute Hospitals</ComparisonService>;
      case 'allfacs':
        return <ComparisonService>All Facility Types</ComparisonService>;
      case 'gach and trauma_flag':
        return (
          <>
            <ComparisonService>All Acute Hospitals</ComparisonService>
            <ComparisonService>Trauma Designation: Trauma</ComparisonService>
          </>
        );
      case 'allfacs and trauma_flag':
        return (
          <>
            <ComparisonService>All Acute Hospitals</ComparisonService>
            <ComparisonService>Trauma Designation: Trauma</ComparisonService>
          </>
        );
      default:
        return (
          slicedetail && <ComparisonService>{slicedetail}</ComparisonService>
        );
    }
  };

  return (
    <ContainerNon id={benchmarkId}>
      <Container>
        <NonBenchTitle>
          {MDRangerServicesConstants[benchmarkTitle]}
        </NonBenchTitle>
        <BenchmarkService>
          {`Benchmark Service: ${
            benchmarkObject.searchService ?? benchmarkObject.service
          }`}
        </BenchmarkService>
        {benchmarkObject?.region ? (
          <BenchmarkService>
            {`Region: ${benchmarkObject.region}`}
          </BenchmarkService>
        ) : null}
        {service !== benchmarkObject.service ? (
          <ComparisonService>
            {`Comparison Service: ${service}`}
          </ComparisonService>
        ) : null}
        {getSliceMessage(benchmarkObject?.slice, benchmarkObject?.slicedetail)}
        <Information>{`Rate: ${benchValue}`}</Information>
      </Container>
      <Line />
    </ContainerNon>
  );
}

export default CompensationNonBenchmark;
