import React, { useState, useEffect } from 'react';
import Analytics from '../analytics';

import AnalyticsAnnualSummaries from '../../components/templates/Reports/AnalyticsAnnualSummaries';

function ContractSummaryPDF() {
  const [pdfData, setPdfData] = useState<any>();
  const [docType, setDoctype] = useState<string>('');

  const getPdfData = async () => {
    const searchUrl = window.location.search;
    const urlParams = new URLSearchParams(searchUrl);
    const lookback = urlParams.get('lookback');
    const type = urlParams.get('hsid') ?? '';
    const mdrId = urlParams.get('mdr_id') ?? 'SELECTALL';

    const response = await Analytics.getExecSum(
      lookback ?? 12,
      type ?? '',
      mdrId,
    );

    if (!response.failed) {
      setPdfData(response.data);
      setDoctype(type);
    }
  };
  useEffect(() => {
    getPdfData().then(null);
  }, []);

  return pdfData ? (
    <AnalyticsAnnualSummaries data={pdfData} type={docType} />
  ) : null;
}

export default ContractSummaryPDF;
