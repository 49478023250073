import Api from './api';
import keysToCamel from '../utils/keysToCamelCase';

type TGetAllServicesProps = {
  type?: string;
  trends?: number;
};

export default class MDRangerService {
  static async getAll(params?: TGetAllServicesProps) {
    return Promise.resolve(
      Api.get('/api/services', {
        params: {
          type: params?.type ?? '',
          trends: params?.trends ?? 0,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getAllWithBenchmarks(benchmarks: string) {
    return Promise.resolve(
      Api.get('/api/services', {
        params: {
          benchmarks,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
}
