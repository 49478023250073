import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  height: auto;

  > :nth-child(n + 2) {
    margin-left: 35px;
  }
  > span {
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    color: #ed7a23;
    margin-top: 20px;
    max-width: 350px;
    text-align: left;
  }
`;

export const Row = styled.div<{ $marginBottom: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.$marginBottom ? '20px' : '0px')};

  > :nth-child(2) {
    margin-left: 20px;
  }
`;
