import React from 'react';
import VideoInterface from './interface';
import { VideoContainer, VideoElement } from './style';

function Video({ src, width, height }: VideoInterface) {
  return (
    <VideoContainer>
      <VideoElement controls src={src} width={width} height={height} />
    </VideoContainer>
  );
}

export default Video;
