import React, { useEffect, useState, useRef } from 'react';
import { Element, Container, Subtitle } from './style';
import InputInterface from './interface';
import handleMask from '../../../utils/phoneMask';

function Input({
  placeholder,
  callback,
  type,
  width,
  subtitle,
  value,
  focus,
  disabled,
  propRef,
  delay,
}: InputInterface) {
  const [eventValue, setEventValue] = useState<string>('');
  const inputRef = propRef ?? useRef<HTMLInputElement>(null);

  const hasChanged = (checkValue: any) =>
    (checkValue || checkValue === '') &&
    checkValue !== eventValue &&
    inputRef != null;

  const handleEventValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    deleted?: boolean,
  ) => {
    const input = event.target.value;
    switch (type) {
      case 'tel':
        setEventValue(deleted ? handleMask(input, 1) : handleMask(input));
        break;
      default:
        setEventValue(deleted ? input.slice(0, -1) : input);
        break;
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => callback(eventValue),
      delay ?? 500,
    );
    return () => clearTimeout(delayDebounceFn);
  }, [eventValue]);

  useEffect(() => {
    if (hasChanged(value)) {
      const castValue = String(value);
      // @ts-ignore
      inputRef!.current!.value = castValue;
      setEventValue(castValue as string);
    }
  }, [value]);

  useEffect(() => {
    if (focus) {
      // @ts-ignore
      inputRef!.current!.focus();
    }
  }, []);

  const invalidChar = (event: any) => {
    if (event.target.type === 'number' && ['e', 'E', '+'].includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Container className="inputAtom">
      <Element
        onWheel={(event: any) => event.target.blur()}
        onKeyDownCapture={(event: any) => {
          if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
          }
        }}
        onKeyDown={invalidChar}
        ref={inputRef}
        placeholder={placeholder}
        onChange={(event) => handleEventValue(event)}
        type={type}
        width={width}
        disabled={disabled}
        value={eventValue}
      />
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    </Container>
  );
}

export default Input;
