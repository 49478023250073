import styled from 'styled-components';
import { FaEdit, FaTrash } from 'react-icons/fa';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > :nth-child(2) {
    margin-left: 35px;
  }

  > :nth-child(3) {
    margin-top: 10px;
    margin-left: 0px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: max-content;
  align-items: center;

  > :nth-child(1) {
    margin: 0px 20px 0px 20px;
  }
  > .conditionalPercentile {
    align-self: flex-end;
  }
`;

export const FieldsHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  height: max-content;
  padding: 20px 0px;

  border: 1px solid var(--light-background);
  border-radius: 4px;
  margin: 10px 0px 10px 30px;
`;

export const IconsHolder = styled.div`
  margin-left: auto;
  margin-right: 15px;
`;

export const EditIcon = styled(FaEdit)`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  cursor: pointer;
  color: var(--secondary-light-gray);
`;

export const DeleteIcon = styled(FaTrash)`
  height: 22px;
  width: 22px;
  cursor: pointer;
  color: var(--secondary-light-gray);
`;
