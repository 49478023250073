import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { Document, Page, PageParent } from './style';
import ReportHeader from '../../../organisms/Reports/Header';
import ReportFooter from '../../../organisms/Reports/Footer';
import appendChildToElement from '../../../../utils/appendChildToElement';
import TitledLineChart from '../../../molecules/TitledLineChart';
import TitledTrendTable from '../../../molecules/TitledTrendTable';
import TitledVerticalBarChart from '../../../molecules/TitledVerticalBarChart';

function TrendAnalysisTemplate(data: any) {
  const currentDate = format(new Date(), 'MM/dd/yyyy');
  let page = 0;

  const PARENTPAGEPREFIX = 'trend-parentpage';
  const PAGEPREFIX = 'trend-page';

  const states: any = { ...data }.data;
  const benchObj = states.benchmarks;

  const buildPage = (pageIndex: number) => (
    <PageParent key={pageIndex} id={`${PARENTPAGEPREFIX}-${pageIndex}`}>
      <ReportHeader title="Trend Analysis" />
      <Page id={`${PAGEPREFIX}-${pageIndex}`} />
      <ReportFooter nowDate={currentDate} />
    </PageParent>
  );

  const removeNotUsedPage = () => {
    for (let i = 0; i < 10; i += 1) {
      const currentPage = document!.getElementById(`${PAGEPREFIX}-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`${PARENTPAGEPREFIX}-${i}`)?.remove();
      }
    }
  };

  const buildContent = async () => {
    benchObj.map((item: any) => {
      const chart = document.getElementById(`${item.benchmark}trendChart`);
      page = appendChildToElement(chart, PAGEPREFIX, page);

      const table = document.getElementById(`${item.benchmark}trendTable`);
      page = appendChildToElement(table, PAGEPREFIX, page);
      return null;
    });
  };

  const renderBenchObject = (item: any) => {
    const dataYears = Object.keys(item.years);
    const yearsKeys = Object.keys(item.years[dataYears[0]]);
    const yearsValues = Object.values(item.years[dataYears[0]]) as number[];

    if (dataYears.length === 1) {
      return (
        <>
          <TitledVerticalBarChart
            id={`${item.benchmark}trendChart`}
            xValues={yearsKeys}
            yValues={yearsValues}
            chartYear={dataYears[0]}
            title={`${item.service}`}
            subtitle={`${item.benchmark}`}
            sliceName={`${item.slicename}`}
          />
          <TitledTrendTable
            id={`${item.benchmark}trendTable`}
            title="Values"
            values={item}
            years={Object.keys(item.years)}
            isPdf
          />
        </>
      );
    }
    return (
      <>
        <TitledLineChart
          id={`${item.benchmark}trendChart`}
          title={`${item.service}`}
          subtitle={`${item.benchmark}`}
          sliceName={`${item.slicename}`}
          values={item}
        />
        <TitledTrendTable
          id={`${item.benchmark}trendTable`}
          title="Values"
          values={item}
          years={Object.keys(item.years)}
          isPdf
        />
      </>
    );
  };

  useEffect(() => {
    buildContent().then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <Document>
      {benchObj.map((item: any) => renderBenchObject(item))}
      {Array(10)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default TrendAnalysisTemplate;
