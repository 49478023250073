export const EDColumns = [
  {
    name: 'Campuses',
    selector: (row: any) => row.campuses,
    grow: 0.6,
  },
  {
    name: 'Single Campus Coverage Rates',
    selector: (row: any) => row.singleCampusCoverageRates,
    grow: 1.2,
  },
  {
    name: 'Addition for Each Incremental Campus',
    selector: (row: any) => row.additionForIncrementalCampus,
    grow: 1,
  },
  {
    name: 'Multi-Campus Total',
    selector: (row: any) => row.multiCampusTotal,
    grow: 1,
  },
  {
    name: 'Average Per Campus',
    selector: (row: any) => row.avePerCampus,
    grow: 1,
  },
  {
    name: 'Math',
    selector: (row: any) => row.math,
    grow: 3,
    wrap: 'normal',
  },
  {
    name: 'Discount per Campus',
    selector: (row: any) => row.discountPerCampus,
    grow: 1,
  },
];

export const EDRows = [
  {
    campuses: '1',
    singleCampusCoverageRates: '$1,500',
    additionForIncrementalCampus: '$0',
    multiCampusTotal: '$1,500',
    avePerCampus: '$1,500',
    math: 'No adjustment',
    discountPerCampus: '0%',
  },
  {
    campuses: '2',
    singleCampusCoverageRates: '$3,000',
    additionForIncrementalCampus: '$660',
    multiCampusTotal: '$2,160',
    avePerCampus: '$1,080',
    math: 'Add one increment factor (44% of single campus rate)',
    discountPerCampus: '-28%',
  },
  {
    campuses: '3',
    singleCampusCoverageRates: '$4,500',
    additionForIncrementalCampus: '$1,320',
    multiCampusTotal: '$2,820',
    avePerCampus: '$940',
    math: 'Add two increment factors (2 x 44% of single campus rate)',
    discountPerCampus: '-37%',
  },
  {
    campuses: '4',
    singleCampusCoverageRates: '$6,000',
    additionForIncrementalCampus: '$1,980',
    multiCampusTotal: '$3,480',
    avePerCampus: '$870',
    math: 'Add three increment factors (3 x 44% of single campus rate)',
    discountPerCampus: '-42%',
  },
];

export const MDColumns = [
  {
    name: 'Campuses',
    selector: (row: any) => row.campuses,
    grow: 0.6,
  },
  {
    name: 'Hours if Individual Contracts',
    selector: (row: any) => row.hoursIndividualContracts,
    grow: 1.2,
  },
  {
    name: 'Addition for Each Incremental Campus',
    selector: (row: any) => row.additionForIncrementalCampus,
    grow: 1,
  },
  {
    name: 'Total Hours for Multi-Campus Position',
    selector: (row: any) => row.hoursMultiCampusTotal,
    grow: 1,
  },
  {
    name: 'Average Hours per Campus',
    selector: (row: any) => row.avehoursPerCampus,
    grow: 1,
  },
  {
    name: 'Math',
    selector: (row: any) => row.math,
    grow: 3,
    wrap: 'normal',
  },
  {
    name: 'Discount per Campus',
    selector: (row: any) => row.discountPerCampus,
    grow: 1,
  },
];

export const MDRows = [
  {
    campuses: '1',
    hoursIndividualContracts: '200',
    additionForIncrementalCampus: '0',
    hoursMultiCampusTotal: '200',
    avehoursPerCampus: '200',
    math: 'No adjustment',
    discountPerCampus: '0%',
  },
  {
    campuses: '2',
    hoursIndividualContracts: '400',
    additionForIncrementalCampus: '64',
    hoursMultiCampusTotal: '264',
    avehoursPerCampus: '132',
    math: 'Add one increment factor (32% of single campus rate)',
    discountPerCampus: '-34%',
  },
  {
    campuses: '3',
    hoursIndividualContracts: '600',
    additionForIncrementalCampus: '128',
    hoursMultiCampusTotal: '328',
    avehoursPerCampus: '109',
    math: 'Add two increment factors (2 x 32% of single campus rate)',
    discountPerCampus: '-45%',
  },
  {
    campuses: '4',
    hoursIndividualContracts: '800',
    additionForIncrementalCampus: '192',
    hoursMultiCampusTotal: '392',
    avehoursPerCampus: '98',
    math: 'Add three increment factors (3 x 32% of single campus rate)',
    discountPerCampus: '-51%',
  },
];

export const exampleColumns = [
  {
    name: 'Number of Campuses',
    selector: (row: any) => row.numberCampuses,
    grow: 1,
  },
  {
    name: 'Median',
    selector: (row: any) => row.medianRadiology,
    grow: 0,
  },
  {
    name: '75th',
    selector: (row: any) => row.seventyFiveRadiology,
    grow: 0,
  },
  {
    name: '90th',
    selector: (row: any) => row.ninetyRadiology,
    grow: 0,
  },
  {
    name: 'Median',
    selector: (row: any) => row.medianCampus,
    grow: 0,
  },
  {
    name: '75th',
    selector: (row: any) => row.seventyFiveCampus,
    grow: 0,
  },
  {
    name: '90th',
    selector: (row: any) => row.ninetyCampus,
    grow: 0,
  },
];

export const exampleRows = [
  {
    numberCampuses: '1',
    medianRadiology: '$780',
    seventyFiveRadiology: '$1000',
    ninetyRadiology: '$1700',
    medianCampus: '$780',
    seventyFiveCampus: '$1000',
    ninetyCampus: '$1700',
  },
  {
    numberCampuses: '2',
    medianRadiology: '$1123',
    seventyFiveRadiology: '$1430',
    ninetyRadiology: '$2,448 ',
    medianCampus: '$561',
    seventyFiveCampus: '$715',
    ninetyCampus: '$1224',
  },
  {
    numberCampuses: '5',
    medianRadiology: '$2153',
    seventyFiveRadiology: '$2760',
    ninetyRadiology: '$4692',
    medianCampus: '$431',
    seventyFiveCampus: '$552',
    ninetyCampus: '$938',
  },
];
