import React from 'react';

import {
  Document,
  Page,
  AverageTextHolder,
  AverageText,
  AppendixPointscontainer,
} from '../../style';

import AppendixTexts from './appendixC-texts.json';
import ReportFooter from '../../../../../organisms/Reports/Footer';
import ReportHeader from '../../../../../organisms/Reports/Header';

function AppendixC({ appendixType, nowDate }: any) {
  interface text {
    [test: string]: string;
  }

  const t = (key: string) => {
    const json: text = AppendixTexts;
    return json[key];
  };

  return (
    <Document>
      <Page>
        <ReportHeader title="" />
        <AverageTextHolder marginTop={20} alignContent="flex-start">
          <AverageText fontSize={11} fontWeight="bold">
            {t('section_title')}
          </AverageText>
        </AverageTextHolder>
        <AverageTextHolder marginTop={10} alignContent="flex-start" height={85}>
          <AverageText fontSize={9} fontWeight="bold">
            {t('reporting_methodology')}
          </AverageText>
          <AverageText fontSize={9}>{t('reporting_text')}</AverageText>
        </AverageTextHolder>
        <AverageTextHolder
          marginTop={10}
          alignContent="flex-start"
          height={310}
        >
          <AverageText fontSize={9} fontWeight="bold">
            {t('antitrust_compliance')}
          </AverageText>
          <AverageText fontSize={9}>{t('antitrust_text')}</AverageText>
          <AppendixPointscontainer>
            <AverageTextHolder direction="row">
              <div className="bigdot" />
              <AverageText fontSize={9}>{t('three_months')}</AverageText>
            </AverageTextHolder>
            <AverageTextHolder direction="row">
              <div className="bigdot" />
              <AverageText fontSize={9}>{t('any_statistic')}</AverageText>
            </AverageTextHolder>
            <AverageTextHolder direction="row">
              <div className="bigdot" />
              <AverageText fontSize={9}>{t('no_statistic')}</AverageText>
            </AverageTextHolder>
          </AppendixPointscontainer>
          <AverageText fontSize={9}>{t('weve_obtained')}</AverageText>
          <AverageText fontSize={9}>{t('bench_only_reported')}</AverageText>
          <AverageText fontSize={9}>{t('data_set_sufficiently')}</AverageText>
          <AverageText fontSize={9}>{t('more_information')}</AverageText>
        </AverageTextHolder>
        <AverageTextHolder marginTop={10} alignContent="flex-start" height={95}>
          <AverageText fontSize={9} fontWeight="bold">
            {t('data_collection')}
          </AverageText>
          <AverageText fontSize={9}>{t('for_emergency')}</AverageText>
          <AppendixPointscontainer>
            <AverageTextHolder marginTop={10} direction="row">
              <div className="bigdot" />
              <AverageText fontSize={9}>
                {t('primary_payment_rate')}
              </AverageText>
            </AverageTextHolder>
            <AverageTextHolder direction="row">
              <div className="bigdot" />
              <AverageText fontSize={9}>
                {t('primary_payment_method')}
              </AverageText>
            </AverageTextHolder>
            <AverageTextHolder direction="row">
              <div className="bigdot" />
              <AverageText fontSize={9}>
                {t('secondary_payment_methods_amounts')}
              </AverageText>
            </AverageTextHolder>
          </AppendixPointscontainer>
        </AverageTextHolder>
        <ReportFooter nowDate={nowDate} />
      </Page>
      <Page>
        <ReportHeader title="" />
        {appendixType === 'cc' || appendixType === 'hb' ? (
          <AverageTextHolder marginTop={10} alignContent="flex-start">
            <AppendixPointscontainer>
              <AverageTextHolder direction="row">
                <div className="bigdot" />
                <AverageText fontSize={9}>
                  {t('unsponsored_patients')}
                </AverageText>
              </AverageTextHolder>
              <AverageTextHolder direction="row">
                <div className="bigdot" />
                <AverageText fontSize={9}>{t('restrictions_call')}</AverageText>
              </AverageTextHolder>
              <AverageTextHolder direction="row">
                <div className="bigdot" />
                <AverageText fontSize={9}>
                  {t('call_coverage_limited')}
                </AverageText>
              </AverageTextHolder>
            </AppendixPointscontainer>
            <AverageTextHolder
              marginTop={10}
              alignContent="flex-start"
              height={75}
            >
              <AverageText fontSize={9}>
                {t('majority_call_coverage')}
              </AverageText>
              <AverageText fontSize={9}>
                {t('for_medical_direction')}
              </AverageText>
            </AverageTextHolder>
            <AppendixPointscontainer>
              <AverageTextHolder marginTop={10} direction="row">
                <div className="bigdot" />
                <AverageText fontSize={9}>{t('physician_primary')}</AverageText>
              </AverageTextHolder>
              <AverageTextHolder direction="row">
                <div className="bigdot" />
                <AverageText fontSize={9}>
                  {t('primary_payment_method')}
                </AverageText>
              </AverageTextHolder>
              <AverageTextHolder direction="row">
                <div className="bigdot" />
                <AverageText fontSize={9}>
                  {t('minimum_and_maximum')}
                </AverageText>
              </AverageTextHolder>
              <AverageTextHolder direction="row">
                <div className="bigdot" />
                <AverageText fontSize={9}>{t('limits_on_maximum')}</AverageText>
              </AverageTextHolder>
              <AverageTextHolder direction="row">
                <div className="bigdot" />
                <AverageText fontSize={9}>{t('any_additional')}</AverageText>
              </AverageTextHolder>
            </AppendixPointscontainer>
          </AverageTextHolder>
        ) : null}

        <AverageTextHolder marginTop={10} alignContent="flex-start">
          <AverageText fontSize={9} fontWeight="bold">
            {t('rate_comparison')}
          </AverageText>
        </AverageTextHolder>

        {appendixType === 'ad' || appendixType === 'cc' ? (
          <AverageTextHolder
            marginTop={10}
            alignContent="flex-start"
            height={appendixType === 'cc' ? 190 : 340}
            gap={10}
          >
            <AverageText fontSize={9}>{t('md_selects')}</AverageText>
            <AverageText fontSize={9}>{t('payment_methods')}</AverageText>
            <AverageText fontSize={9}>
              {t('producing_these_reports')}
            </AverageText>
            {appendixType !== 'cc' && (
              <AverageText fontSize={9}>
                {t('to_calculate_coverage')}
              </AverageText>
            )}
            {appendixType !== 'cc' && (
              <AverageText fontSize={9}>{t('certain_facilities')}</AverageText>
            )}
          </AverageTextHolder>
        ) : null}
        {appendixType !== 'cc' ? (
          <AverageTextHolder
            marginTop={10}
            alignContent="flex-start"
            height={178}
            gap={10}
          >
            <AverageText fontSize={9}>{t('reporting_text')}</AverageText>
            <AverageText fontSize={9}>
              {t('dollar_values_weighted')}
            </AverageText>
            <AverageText fontSize={9}>{t('non_dollar_values')}</AverageText>
            <AverageText fontSize={9}>{t('every_reported')}</AverageText>
            <AverageText fontSize={9}>{t('all_annual_payment')}</AverageText>
          </AverageTextHolder>
        ) : null}

        <ReportFooter nowDate={nowDate} />
      </Page>
      {appendixType === 'cc' ? (
        <Page>
          <ReportHeader title="" />
          <AverageTextHolder
            marginTop={20}
            alignContent="flex-start"
            height={300}
            gap={10}
          >
            <AverageText fontSize={9}>{t('to_calculate_coverage')}</AverageText>
            <AverageText fontSize={9}>{t('certain_facilities')}</AverageText>
            <AverageText fontSize={9}>{t('reporting_text')}</AverageText>
            <AverageText fontSize={9}>
              {t('dollar_values_weighted')}
            </AverageText>
            <AverageText fontSize={9}>{t('non_dollar_values')}</AverageText>
            <AverageText fontSize={9}>{t('every_reported')}</AverageText>
            <AverageText fontSize={9}>{t('all_annual_payment')}</AverageText>
          </AverageTextHolder>
          <ReportFooter nowDate={nowDate} />
        </Page>
      ) : null}
    </Document>
  );
}

export default AppendixC;
