import React from 'react';
import SearchBenchmarkInterface from './interface';
import SearchInput from '../../atoms/SearchInput';
import { Container } from './style';

function SearchBenchmark({
  placeholder,
  callback,
  options,
}: SearchBenchmarkInterface) {
  return (
    <Container>
      <SearchInput
        width={66.9}
        height={60}
        paddingTop={10}
        isBenchmark
        placeholder={placeholder}
        callback={callback}
        options={options}
      />
    </Container>
  );
}

export default SearchBenchmark;
