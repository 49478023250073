import React, { useEffect } from 'react';

import ReportFooter from 'src/components/organisms/Reports/Footer';
import ReportHeader from 'src/components/organisms/Reports/Header';

import addChildtoParent from 'src/utils/addChildToParent';
import {
  Document,
  PageParent,
  PageChild,
  TableContainer,
  BenchMarkTitle,
  TableWrapper,
  Table,
  TableHead,
  TableBody,
  TableHeader,
  Characteristic,
  TableCell,
} from '../style';

type TablePagesProps = {
  reportData: any[];
  footerText: string;
};

function TablePages({ reportData, footerText }: TablePagesProps) {
  const PARENT_PAGE_PREFIX = 'facility-parent-page';
  const CHILD_PAGE_PREFIX = 'facility-child-page';

  let page = 0;

  const buildTable = (benchName: string, benchData: any, tableId: string) => (
    <TableContainer id={tableId}>
      <BenchMarkTitle>{benchName}</BenchMarkTitle>
      <TableWrapper>
        <Table>
          <TableHead>
            <tr style={{ maxWidth: '445px' }}>
              <TableHeader>Characteristc</TableHeader>
              <TableHeader>Providers</TableHeader>
              <TableHeader>Facilities</TableHeader>
              <TableHeader>Mean</TableHeader>
              <TableHeader>25%</TableHeader>
              <TableHeader>50%</TableHeader>
              <TableHeader>75%</TableHeader>
              <TableHeader>90%</TableHeader>
            </tr>
          </TableHead>
          <TableBody>
            {Object.entries(benchData).map(([characteristic, rows]) => (
              <>
                <Characteristic>
                  <TableCell>{characteristic}</TableCell>
                </Characteristic>
                {(rows as any[]).map((row: any, index: number) => (
                  <tr
                    style={{
                      maxWidth: '445px',
                      borderBottom:
                        index === (rows as any[]).length - 1
                          ? '1px solid #ddd'
                          : '',
                    }}
                  >
                    <TableCell>{row.slice}</TableCell>
                    <TableCell>{row.providers}</TableCell>
                    <TableCell>{row.facilities}</TableCell>
                    <TableCell>{row.mean}</TableCell>
                    <TableCell>{row.q25}</TableCell>
                    <TableCell>{row.q50}</TableCell>
                    <TableCell>{row.q75}</TableCell>
                    <TableCell>{row.q90}</TableCell>
                  </tr>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </TableContainer>
  );
  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  const buildPage = (pageIndex: number) => (
    <PageParent id={`${PARENT_PAGE_PREFIX}-${pageIndex}`}>
      <ReportHeader title="" />
      <PageChild id={`${CHILD_PAGE_PREFIX}-${pageIndex}`} />
      <ReportFooter customText={footerText} />
    </PageParent>
  );

  const buildContent = async (data: any[]) => {
    data.forEach((serviceSection: any) =>
      Object.entries(serviceSection).forEach(([benchName, benchData]) => {
        const tableId = buildId();
        const tableContent = buildTable(benchName, benchData, tableId);
        page = addChildtoParent(
          tableId,
          tableContent,
          CHILD_PAGE_PREFIX,
          page,
          30,
        );
        page += 1;
      }),
    );
  };

  const removeNotUsedPage = () => {
    for (let i = 0; i < 50; i += 1) {
      const currentPage = document!.getElementById(`${CHILD_PAGE_PREFIX}-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`${PARENT_PAGE_PREFIX}-${i}`)?.remove();
      }
    }
  };

  useEffect(() => {
    buildContent(reportData).then(() => removeNotUsedPage());
  }, []);

  return (
    <Document id="totalFacs">
      {Array(50)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default TablePages;
