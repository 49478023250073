import React from 'react';
import CleanInput from '../../atoms/CleanInput';
import SearchServicesInterface from './interface';

function SearchService({
  placeholder,
  callback,
  options,
}: SearchServicesInterface) {
  return (
    <CleanInput
      placeholder={placeholder}
      callback={callback}
      options={options}
    />
  );
}

export default SearchService;
