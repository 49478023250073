const environment = {
  production: {
    environment: process.env.REACT_APP_API_BASE_URL_PROD,
    microPdf: process.env.REACT_APP_MICRO_PDF_API_BASE_URL,
  },
  staging: {
    environment: process.env.REACT_APP_API_BASE_URL_STAGING,
    microPdf: process.env.REACT_APP_MICRO_PDF_API_BASE_URL,
  },
  dev: {
    environment: process.env.REACT_APP_API_BASE_URL_DEV,
    microPdf: process.env.REACT_APP_MICRO_PDF_API_BASE_URL,
  },
  local: {
    environment: process.env.REACT_APP_API_BASE_URL_LOCAL,
    microPdf: process.env.REACT_APP_MICRO_PDF_API_BASE_URL_LOCAL,
  },
};

let key: 'local' | 'production' | 'staging' | 'dev' = 'local';

if (process.env.REACT_APP_STAGE) {
  // @ts-ignore
  key = process.env.REACT_APP_STAGE;
}

export default environment[key];
