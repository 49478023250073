import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  width: 100vw;
  max-width: 100vw;
  height: 245px;
  background-color: var(--ecg-soft-powder-blue);
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-evenly;
  flex-direction: row;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
`;
