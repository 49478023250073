export default {
  HC: 'ED Call Coverage',
  HR: 'Administrative Hourly Rate',
  HH: 'Administrative Annual Hours',
  HS: 'Total Annual Payments, Excluding Medical Direction',
  HD: 'Administrative Annual Payment',
  LH: 'Clinical Hourly Rate',
  HP: 'Telemedicine, With Per Episode Payment - Monthly',
  HQ: 'Telemedicine, No Per Episode Payment - Monthly',
  HT: 'Telemedicine, With & Without Per Episode, Monthly',
  EP: 'Per Episode',
  EV: 'Per Activation',
  EC: 'Per Procedure',
  DS: 'Diagnostic Testing Rate',
  US: 'Unsponsored Care Percentage',
  UG: 'Unit Guarantee & Fee for Service Percentage',
} as any;
