import React from 'react';

import { Document, Page, AverageTextHolder, AverageText } from '../style';

import ReportHeader from '../../../../organisms/Reports/Header';
import ReportFooter from '../../../../organisms/Reports/Footer';

export interface HbrSpecialProps {
  aboutMd: string;
  since2009: string;
  ourBenchmarkds: string;
  thanksGrowing: string;
  benchmarksCalculated: string;
  calculatedExclude: string;
  makeSeparateDetermination: string;
  allBenchShow: string;
  fullyComplies: string;
  hospitalBased: string;
  generallyContainVariables: string;
  generallySpecifyClinical: string;
  hbReportsContain: string;
  totalAnnualPayments: string;
  mdUsesTotal: string;
  excludingMedicalDirection: string;
  someHospitalBased: string;
  netAnnualPayments: string;
  forSeveralServices: string;
  perDiemEquivalent: string;
  dividingTheAnnual: string;
  annualRanges: string;
  containPaymentsServices: string;
  perDiemRanges: string;
  onlyIncludeSpecifically: string;
  subscribingHospitalsReport: string;
  anyQuestions: string;
  best: string;
  mdTeam: string;
  nowDate: string;
}

function HbrSpecialPages(props: HbrSpecialProps) {
  const BOLD_TEXT_SIZE = 11;
  const AVERAGE_TEXT_SIZE = 9.5;
  const {
    aboutMd,
    since2009,
    ourBenchmarkds,
    thanksGrowing,
    benchmarksCalculated,
    calculatedExclude,
    makeSeparateDetermination,
    allBenchShow,
    fullyComplies,
    hospitalBased,
    generallyContainVariables,
    generallySpecifyClinical,
    hbReportsContain,
    totalAnnualPayments,
    mdUsesTotal,
    excludingMedicalDirection,
    someHospitalBased,
    netAnnualPayments,
    forSeveralServices,
    perDiemEquivalent,
    dividingTheAnnual,
    annualRanges,
    containPaymentsServices,
    perDiemRanges,
    onlyIncludeSpecifically,
    subscribingHospitalsReport,
    anyQuestions,
    best,
    mdTeam,
    nowDate,
  } = props;

  const email = 'info@mdranger.com';
  return (
    <Document>
      <Page>
        <ReportHeader title="" />
        <AverageTextHolder
          alignContent="flex-start"
          marginTop={10}
          height={120}
        >
          <AverageText fontWeight="bold" fontSize={BOLD_TEXT_SIZE}>
            {aboutMd}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>{since2009}</AverageText>
        </AverageTextHolder>
        <AverageTextHolder
          alignContent="flex-start"
          marginTop={10}
          height={350}
        >
          <AverageText fontWeight="bold" fontSize={BOLD_TEXT_SIZE}>
            {ourBenchmarkds}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>
            {thanksGrowing}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>
            {benchmarksCalculated}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>
            {calculatedExclude}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>
            {makeSeparateDetermination}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>{allBenchShow}</AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>
            {fullyComplies}
          </AverageText>
        </AverageTextHolder>
        <ReportFooter nowDate={nowDate} />
      </Page>
      <Page>
        <ReportHeader title="" />
        <AverageTextHolder
          marginTop={10}
          alignContent="flex-start"
          height={400}
        >
          <AverageText fontWeight="bold" fontSize={BOLD_TEXT_SIZE}>
            {hospitalBased}
          </AverageText>
          <AverageText fontSize={9}>{generallyContainVariables}</AverageText>
          <AverageText fontSize={9}>{generallySpecifyClinical}</AverageText>
          <AverageText fontSize={9}>{hbReportsContain}</AverageText>
        </AverageTextHolder>
        <AverageTextHolder alignContent="flex-start" marginTop={10} height={90}>
          <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
            {totalAnnualPayments}
          </AverageText>

          <AverageText className="paddingLeftText" fontSize={9}>
            {mdUsesTotal}
          </AverageText>
        </AverageTextHolder>
        <ReportFooter nowDate={nowDate} />
      </Page>
      <Page>
        <ReportHeader title="" />
        <AverageTextHolder alignContent="flex-start" marginTop={10} height={82}>
          <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
            {excludingMedicalDirection}
          </AverageText>
          <AverageText className="paddingLeftText" fontSize={9}>
            {someHospitalBased}
          </AverageText>
        </AverageTextHolder>
        <AverageTextHolder alignContent="flex-start" marginTop={10}>
          <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
            {netAnnualPayments}
          </AverageText>

          <AverageText className="paddingLeftText" fontSize={9}>
            {forSeveralServices}
          </AverageText>
        </AverageTextHolder>
        <AverageTextHolder alignContent="flex-start" marginTop={10}>
          <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
            {perDiemEquivalent}
          </AverageText>

          <AverageText className="paddingLeftText" fontSize={9}>
            {dividingTheAnnual}
          </AverageText>
        </AverageTextHolder>
        <AverageTextHolder alignContent="flex-start" marginTop={10}>
          <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
            {annualRanges}
          </AverageText>

          <AverageText className="paddingLeftText" fontSize={9}>
            {containPaymentsServices}
          </AverageText>
        </AverageTextHolder>
        <AverageTextHolder alignContent="flex-start" marginTop={10}>
          <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
            {perDiemRanges}
          </AverageText>

          <AverageText className="paddingLeftText" fontSize={9}>
            {onlyIncludeSpecifically}
          </AverageText>
        </AverageTextHolder>
        <ReportFooter nowDate={nowDate} />
      </Page>
      <Page>
        <ReportHeader title="" />
        <AverageTextHolder
          alignContent="flex-start"
          marginTop={35}
          height={140}
        >
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>
            {subscribingHospitalsReport}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>
            {anyQuestions + email}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>{best}</AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>{mdTeam}</AverageText>
        </AverageTextHolder>
        <ReportFooter nowDate={nowDate} />
      </Page>
    </Document>
  );
}

export default HbrSpecialPages;
