import React from 'react';
import { useDispatch } from 'react-redux';
import Router from './routes/router';
import GlobalContextProvider from './hooks/user';
import { changeToken } from './hooks/redux/slices/auth';

function App() {
  const dispatch = useDispatch();

  const splitHref = window.location.href.split('token=');

  if (splitHref[1]) {
    dispatch(changeToken(splitHref[1].split('&')[0]));
  }

  return (
    <GlobalContextProvider>
      <Router />
    </GlobalContextProvider>
  );
}

export default App;
