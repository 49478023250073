import React, { useEffect } from 'react';
import { format } from 'date-fns';
import {
  Document,
  PageParent,
  Page,
  InformationContainer,
  InformationColumn,
  InformationRow,
  InformationText,
  BlueSectionText,
  PercentPayingTable,
  PercentTableRow,
  PercentHeaderTile,
  PercentTableTile,
} from './style';
import ReportHeader from '../../../organisms/Reports/Header';
import ReportFooter from '../../../organisms/Reports/Footer';
import PdfCharts from '../../../molecules/PdfCharts';

import addChildtoParent from '../../../../utils/addChildToParent';

function SingleContractTemplate({ data }: any) {
  const states = data;
  // const [servicesTitles, setServicesTitles] = useState<string[]>([]);
  const PARENTPAGEPREFIX = 'single-contract-parentpage';
  const PAGEPREFIX = 'single-contract-page';
  const currentDate = format(new Date(), 'MM/dd/yyyy');

  let page = 0;

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  const makeInfoTextBold = (firstText: string, secondText: string) => (
    <InformationRow>
      <InformationText>{firstText}</InformationText>
      <InformationText>{secondText}</InformationText>
    </InformationRow>
  );

  const topSectionInformation = (id: string, currentService: any) => (
    <InformationContainer id={id}>
      <InformationColumn>
        <BlueSectionText alignSelf="flex-start">
          {currentService.service ?? ''}
        </BlueSectionText>
        {currentService?.title &&
          makeInfoTextBold('Title:', currentService.title)}
        {currentService?.counterparty &&
          makeInfoTextBold('Counterparty:', currentService.counterparty)}
        {(currentService?.start || currentService?.end) &&
          makeInfoTextBold(
            'Effective dates:',
            `${currentService?.start ?? ''} – ${currentService?.end ?? ''}`,
          )}
        {currentService?.facility &&
          makeInfoTextBold('Facility:', currentService.facility)}
      </InformationColumn>
    </InformationContainer>
  );

  const addTitleSortedChartSection = (
    titleName: string,
    serviceSection: any,
  ) => {
    const benchArray = serviceSection.benchmarks?.[titleName];
    const sectionTitleId = buildId();
    const secTionTitleContent = (
      <BlueSectionText alignSelf="flex-start" id={sectionTitleId}>
        {titleName}
      </BlueSectionText>
    );

    page = addChildtoParent(
      sectionTitleId,
      secTionTitleContent,
      PAGEPREFIX,
      page,
      165,
    );

    benchArray?.forEach((bench: any) => {
      const chartId = buildId();
      const chartContent = (
        <PdfCharts
          benchmarkObject={bench}
          benchValue={bench.userValue ?? 0}
          service={serviceSection.service ?? ''}
          chartId={chartId}
        />
      );

      page = addChildtoParent(chartId, chartContent, PAGEPREFIX, page);
    });
  };

  const buildSummarySection = (summaryData: any, id: string) => (
    <InformationColumn id={id} className="summaryColumn">
      {summaryData?.map((summaryObj: any) =>
        summaryObj ? (
          <InformationColumn>
            <InformationText fontWeight="bold">{`${summaryObj?.service}: ${summaryObj?.surveytype}`}</InformationText>
            {summaryObj?.showpay?.map((payRow: any, index: number) => (
              <InformationRow className="summaryRow">
                <InformationText>
                  {`${index + 1}. ${payRow?.paymethod}`}
                </InformationText>
                <InformationText>{payRow?.payformat}</InformationText>
              </InformationRow>
            ))}
          </InformationColumn>
        ) : null,
      )}
    </InformationColumn>
  );

  const addSummarySection = (serviceSection: any) => {
    const summaryInfo = serviceSection.summary ?? [];
    const summaryId = buildId();
    const summaryContent = buildSummarySection(summaryInfo, summaryId);

    page = addChildtoParent(summaryId, summaryContent, PAGEPREFIX, page);
  };

  const buildPercentTabeRow = (serviceData: any, serviceName: string) => (
    <PercentTableRow>
      <PercentTableTile>{serviceName}</PercentTableTile>
      <PercentTableTile>{serviceData?.HB ?? 'N/A'}</PercentTableTile>
      <PercentTableTile>{serviceData?.AD ?? 'N/A'}</PercentTableTile>
      <PercentTableTile>{serviceData?.CC ?? 'N/A'}</PercentTableTile>
    </PercentTableRow>
  );

  const buildPercentPayingTable = (tableId: string, serviceSection: any) => (
    <PercentPayingTable id={tableId}>
      <thead>
        <PercentTableRow>
          <PercentHeaderTile>
            Percent of subscriber hospitals paying for
          </PercentHeaderTile>
          <PercentHeaderTile>Stipend</PercentHeaderTile>
          <PercentHeaderTile>Admin</PercentHeaderTile>
          <PercentHeaderTile>Coverage</PercentHeaderTile>
        </PercentTableRow>
      </thead>
      <tbody>
        {Object.keys(serviceSection.percentpaying).map((serviceName: string) =>
          buildPercentTabeRow(
            serviceSection?.percentpaying?.[serviceName],
            serviceName,
          ),
        )}
      </tbody>
    </PercentPayingTable>
  );

  const addPercentTableSection = (serviceSection: any) => {
    if (!serviceSection.percentpaying) return null;

    const percentPayingSection = buildId();
    const secTionTitleContent = (
      <BlueSectionText alignSelf="flex-start" id={percentPayingSection}>
        Comparison to MD Ranger Benchmarks
      </BlueSectionText>
    );

    const tableId = buildId();
    const tableContent = buildPercentPayingTable(tableId, serviceSection);

    page = addChildtoParent(
      tableId,
      <>
        {secTionTitleContent}
        {tableContent}
      </>,
      PAGEPREFIX,
      page,
    );
    return null;
  };

  const buildPage = (pageIndex: number) => (
    <PageParent id={`${PARENTPAGEPREFIX}-${pageIndex}`}>
      <ReportHeader title="" />
      <Page id={`${PAGEPREFIX}-${pageIndex}`} />
      <ReportFooter nowDate={currentDate} />
    </PageParent>
  );

  const removeNotUsedPage = () => {
    for (let i = 0; i < 200; i += 1) {
      const currentPage = document!.getElementById(`${PAGEPREFIX}-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`${PARENTPAGEPREFIX}-${i}`)?.remove();
      }
    }
  };

  const buildPageContent = async () => {
    await states.forEach(async (serviceSection: any) => {
      const topSectionId = buildId();
      const topSectionContent = topSectionInformation(
        topSectionId,
        serviceSection,
      );

      page = addChildtoParent(
        topSectionId,
        topSectionContent,
        PAGEPREFIX,
        page,
      );
      addSummarySection(serviceSection);
      addPercentTableSection(serviceSection);
      Object.keys(serviceSection.benchmarks)?.map((titleName: string) =>
        addTitleSortedChartSection(titleName, serviceSection),
      );

      page += 1;
    });
  };

  useEffect(() => {
    buildPageContent().then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <Document id="documentBody">
      {Array(200)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default SingleContractTemplate;
