import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;

  > :nth-child(n) {
    margin: 0;
    height: 35px;
    width: fit-content !important;
  }
`;
