import React, { useRef, useEffect } from 'react';
import Checkbox from '../../atoms/Checkbox';
import { MenuContainer } from './style';
import CheckboxMenuInterface from './interface';

function CheckboxMenu({
  initialValues,
  onSelectedChange,
  options,
  style,
  disabledOptions,
}: CheckboxMenuInterface) {
  const itemsRef = useRef<Array<HTMLInputElement>>([]);

  const handleClick = () => {
    const checkedNames = itemsRef.current
      .filter((item) => item?.checked)
      .map((item) => item?.name ?? '');
    onSelectedChange(checkedNames);
  };

  useEffect(() => {}, []);

  const disableByHidden = (name: string) => disabledOptions?.includes(name);
  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  return (
    <MenuContainer>
      {Object.keys(options).map((name, index) => (
        <Checkbox
          id={buildId()}
          key={name}
          onChange={() => {
            handleClick();
          }}
          label={options[name]}
          // @ts-ignore
          propRef={(element: HTMLInputElement) => {
            itemsRef.current[index] = element;
          }}
          propName={name}
          disabled={disableByHidden(name)}
          initialValue={name in initialValues && !disableByHidden(name)}
          style={style}
        />
      ))}
    </MenuContainer>
  );
}

export default CheckboxMenu;
