import React from 'react';
import { GeneralTextContainer, GeneralContentText } from '../style';

export interface SecondPageProps {
  dearSubscriber: string;
  letterFirstParagraph: string;
  letterSecondParagraph: string;
  letterThirdParagraph: string;
  letterFirstPoint: string;
  letterSecondPoint: string;
  letterThirdPoint: string;
  letterFourthPoint: string;
  letterFourthParagraph: string;
  letterFifthParagraph: string;
  sincerely: string;
  mdTeam: string;
  id: string;
}

function SecondPage(pageProps: SecondPageProps) {
  const {
    dearSubscriber,
    letterFirstParagraph,
    letterSecondParagraph,
    letterThirdParagraph,
    letterFirstPoint,
    letterSecondPoint,
    letterThirdPoint,
    letterFourthPoint,
    letterFourthParagraph,
    letterFifthParagraph,
    sincerely,
    mdTeam,
    id,
  } = pageProps;

  return (
    <div id={id}>
      <GeneralContentText>{dearSubscriber}</GeneralContentText>
      <GeneralTextContainer marginTop={10}>
        <GeneralContentText>{letterFirstParagraph}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={10}>
        <GeneralContentText>{letterSecondParagraph}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={10}>
        <GeneralContentText>{letterThirdParagraph}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={10}>
        <GeneralContentText>{letterFirstPoint}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={10}>
        <GeneralContentText>{letterSecondPoint}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={10}>
        <GeneralContentText>{letterThirdPoint}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={10}>
        <GeneralContentText>{letterFourthPoint}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={10}>
        <GeneralContentText>{letterFourthParagraph}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={10}>
        <GeneralContentText>{letterFifthParagraph}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={8}>
        <GeneralContentText>{sincerely}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={8}>
        <GeneralContentText>{mdTeam}</GeneralContentText>
      </GeneralTextContainer>
    </div>
  );
}

export default SecondPage;
