import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaQuestionCircle,
  FaInfoCircle,
  FaExternalLinkAlt,
  FaPhone,
} from 'react-icons/fa';
import { Footer as Body, Section, Column, Row } from './style';
import SvgIcon from '../../atoms/SvgIcon';
import FooterItem from '../../molecules/FooterItem';
import ThoroughText from '../../atoms/ThoroughText';
import Environment from '../../../config/environment';
import Images from '../../../assets/images';

function Footer() {
  const { t } = useTranslation('footer');
  const image = Images.MDRangerECGFooter;

  return (
    <Body>
      <Column>
        <Row>
          <Section>
            <SvgIcon Svg={image} alt={t('alt')} size={266} />
          </Section>
          <Section />
          <Section>
            <FooterItem
              text={t('phone')}
              Icon={FaPhone}
              link="tel:650-692-8873"
            />
          </Section>
          <Section>
            <FooterItem
              text={t('website')}
              textColor="var(--ecg-royal-blue)"
              iconColor="var(--ecg-royal-blue)"
              Icon={FaExternalLinkAlt}
              link="https://www.mdranger.com/"
            />
            <FooterItem
              text={t('team')}
              textColor="var(--ecg-royal-blue)"
              iconColor="var(--ecg-royal-blue)"
              Icon={FaInfoCircle}
              link="https://www.mdranger.com/company"
            />
            <FooterItem
              text={t('help')}
              textColor="var(--ecg-royal-blue)"
              iconColor="var(--ecg-royal-blue)"
              Icon={FaQuestionCircle}
              link={`${Environment.environment}help`}
            />
          </Section>
        </Row>
        <Column>
          <ThoroughText text={t('copyright_1')} />
          <ThoroughText text={t('copyright_2')} />
          <ThoroughText
            link={`${Environment.environment}/privacy.html`}
            text={t('policy')}
          />
        </Column>
      </Column>
    </Body>
  );
}

export default Footer;
