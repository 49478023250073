import styled from 'styled-components';

interface ContainerProps {
  $withouButton?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > :nth-child(2) {
    margin-left: ${(props) => (props.$withouButton ? '0px' : '35px')};
    padding: ${(props) => (props.$withouButton ? '0px' : 'auto')};
    margin-top: ${(props) => (props.$withouButton ? '17px' : 'auto')};
  }

  > :nth-child(3) {
    margin-left: 30px;
  }
`;

export const Row = styled.div`
  display: flex;

  > :nth-child(2) {
    margin-left: 20px;
  }
`;

export const Helper = styled.span`
  font-style: italic;
  padding-left: 6px;
  font-size: 12px;
`;
