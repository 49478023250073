import styled from 'styled-components';

export const Page = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100vw;

  > .headerSection {
    > :first-child {
      font-weight: normal;
      margin-bottom: 15px;
    }

    > :nth-child(n + 3) {
      margin: 5px 0;
      width: max-content;
    }

    > :last-child {
      font-weight: normal;
    }
    margin-bottom: 10px;
  }

  > .changePassword {
    margin-bottom: 20px;

    > :last-child {
      margin-top: 35px;
      height: 45px;
      width: 100%;
      background-color: transparent;
      box-shadow: none;
      border: solid 3px var(--ecg-royal-blue);
      color: var(--ecg-royal-blue);
      font-weight: 700;

      > :first-child {
        color: var(--ecg-royal-blue);
      }
    }
  }
`;

export const Section = styled.section`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: max-content;
  min-width: 550px;
  width: 30%;

  > .doublePercentile {
    > :nth-child(2) {
      padding-left: 15px;
    }
  }

  > .selectContainer {
    padding-left: 15px;
  }

  &.archive {
    padding-top: 20px;
  }
  > .checkInput {
    margin: 10px 0px;
  }
`;

export const AlertSection = styled.section`
  background-color: transparent;
  margin-top: 20px;
  margin-bottom: 60px;
  min-width: 550px;
  width: 30%;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  position: relative;
  flex-direction: column;
  margin-left: 40px;

  > .checkInput {
    margin: 10px 0px;
  }
`;

export const MenuContainer = styled.div<{ $paddingLeft?: number }>`
  display: flex;
  width: 100%;
  height: max-content;
  position: relative;
  flex-direction: column;

  &.matchFacility {
    margin-top: 20px;
  }
  > .checkInput {
    margin-bottom: 15px;
  }
`;

export const ContainerHider = styled.div<{ $isHidden: boolean }>`
  display: ${(props) => (props.$isHidden ? 'flex' : 'none')};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: var(--light-background);
  opacity: 0.35;
  cursor: not-allowed;
`;

export const Line = styled.div`
  width: 100%;
  height: 0;
  margin-top: 15px;
  margin-bottom: 10px;
  border-bottom: solid 1px var(--secondary-light-gray);
`;
