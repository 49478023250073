function roundDecimal(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

function PerDiemCalculatorResult(
  weekday: number,
  weekend: number,
  holiday: number,
  weekly: number,
  monthly: number,
  yearly: number,
  shifts?: number,
) {
  let perDiem = 0;

  if (holiday > 0 && weekday > 0 && weekend > 0) {
    perDiem +=
      weekday * 0.6927592955 + holiday * 0.0301369863 + weekend * 0.2771037182;
  } else if (holiday > 0 && weekday > 0) {
    perDiem += weekday * 0.9698630137 + holiday * 0.0301369863;
  } else if (weekday > 0 && weekend > 0) {
    perDiem += weekday * 0.7142857142 + weekend * 0.2857142857;
  } else if (holiday > 0 || weekend > 0) {
    perDiem += holiday * 0.0956521739 + weekend * 0.9043478261;
  } else {
    perDiem = holiday + weekday + weekend;
  }
  perDiem = roundDecimal(perDiem);

  if (yearly > 0) {
    return { perDiem, annualCall: yearly };
  }
  if (weekly > 0) {
    return { perDiem, annualCall: weekly * 52 };
  }
  if (monthly > 0) {
    return { perDiem, annualCall: monthly * 12 };
  }

  let annualCall = perDiem * (shifts ?? 0) * 12;
  annualCall = roundDecimal(annualCall);

  return { perDiem, annualCall };
}

export default PerDiemCalculatorResult;
