import styled from 'styled-components';

export const VideoContainer = styled.div`
  width: 100%;
`;

export const VideoElement = styled.video`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
`;
