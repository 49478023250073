import React, { useEffect, useState } from 'react';
import GeneralServices from '../../services/generalServices';
import RateComparison from '../RateComparison';
import RateComparisonDignity from '../RateComparisonDignity';

function RateComparisonLoader() {
  const [loadingDignity, setLoadingDignity] = useState<boolean>(false);
  const [selfDignity, setSelfDignity] = useState<boolean>(false);

  const loadSelf = async () => {
    const response = await GeneralServices.self();
    if (!response.failed) {
      setSelfDignity(response.data.dignity);
    }
    setLoadingDignity(false);
  };

  useEffect(() => {
    setLoadingDignity(true);
    loadSelf().then(null);
  }, []);

  if (!loadingDignity) {
    return selfDignity ? <RateComparisonDignity /> : <RateComparison />;
  }

  return null;
}

export default RateComparisonLoader;
