import styled from 'styled-components';

interface ChartContainerInterface {
  marginTop?: number;
}

export const ChartContainer = styled.div<ChartContainerInterface>`
  display: flex;
  position: relative;
  max-height: 500px;
  width: 350px;
  min-height: 150px;
  height: min-content;
  overflow: visible;
  margin-top: ${(props) => props.marginTop}px;
`;
