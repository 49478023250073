import styled from 'styled-components';
import Select from '@mui/material/Select';
import { AiOutlineClose } from 'react-icons/ai';

export const CancelIcon = styled(AiOutlineClose)`
  width: 12px;
  color: var(--footer-light-gray);
`;

export const SelectContainer = styled(Select)`
  & .MuiOutlinedInput-input {
    border: none;
    font-size: 18px;
    font-family: inherit;
    outline: 0;
  }

  .MuiSelect-select {
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 2px;
  }

  .MuiChip-root {
    border-radius: 2px;
  }
`;
