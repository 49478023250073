/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Section, Line, Row, SubmitButton } from './style';
import FacilityPageContextProvider, {
  FacilityPageContext,
} from '../../contexts/FacilityPage/FacilityPageContext';
import Title from '../../components/atoms/Title';
import Loader from '../../components/atoms/DotLoader';
import SubTitle from '../../components/atoms/Subtitle';
import Input from '../../components/molecules/Input';
import DoubleCheckBoxInput from '../../components/molecules/DoubleCheckBoxInput';
import SelectDropDown from '../../components/atoms/SelectDropDown';
import FacilityService from '../../services/facilityPage';
import Facilities from '../../contracts/models/Facilities.model';
import Footer from '../../components/organisms/Footer';

interface VolumeMeasuresRowProps {
  facility?: string;
  label: string;
  setState: Function;
  value?: string;
  min?: number;
  max?: number;
  exceptionTitle?: string;
  exceptionMessage?: string;
}

function VolumeMeasuresRow({
  facility,
  label,
  setState,
  value,
  min,
  max,
  exceptionTitle,
  exceptionMessage,
}: VolumeMeasuresRowProps) {
  return (
    <Row>
      <Input
        min={min}
        max={max}
        exceptionTitle={exceptionTitle}
        exceptionMessage={exceptionMessage}
        title={label}
        titleSide="right"
        width={100}
        value={value}
        callback={(val: number) => setState(String(val))}
        type="number"
        inputColor="#EDEEF2"
        titleSideColor="#2451CB"
        disabled={facility === undefined}
      />
    </Row>
  );
}

function FacilityPage() {
  const { t } = useTranslation('facility-page');

  const {
    facilitiesList,
    setFacilitiesList,
    facility,
    setFacility,
    totalBeds,
    setTotalBeds,
    dailyCensus,
    setDailyCensus,
    emergencyVisits,
    setEmergencyVisits,
    surgeriesAnnual,
    setSurgeriesAnnual,
    icuAdc,
    setIcuAdc,
    icuBeds,
    setIcuBeds,
    nicuBeds,
    setNicuBeds,
    birthsAnnual,
    setBirthsAnnual,
    medicarePercentage,
    setMedicarePercentage,
    medicaidPercentage,
    setMedicaidPercentage,
    traumaClassification,
    setTraumaClassification,
    isTeaching,
    setIsTeaching,
    isStroke,
    setIsStroke,
    loading,
    setLoading,
    levelDropDownOptions,
  } = useContext(FacilityPageContext);

  const getFacilityData = async (mdrId?: string) => {
    const response = await FacilityService.getFacilityData(mdrId);
    if (!response.failed) {
      setFacilitiesList(response.data.facilities as Facilities);

      setTotalBeds(response.data.beds ? String(response.data.beds) : '');
      setDailyCensus(response.data.adc ? String(response.data.adc) : '');
      setEmergencyVisits(
        response.data.emsVisTotl ? String(response.data.emsVisTotl) : '',
      );
      setSurgeriesAnnual(
        response.data.surgeries ? String(response.data.surgeries) : '',
      );
      setIcuAdc(response.data.icuAdc ? String(response.data.icuAdc) : '');
      setIcuBeds(response.data.icuBeds ? String(response.data.icuBeds) : '');
      setNicuBeds(response.data.nicuAdc ? String(response.data.nicuAdc) : '');
      setBirthsAnnual(response.data.births ? String(response.data.births) : '');
      setMedicarePercentage(
        response.data.mcrPct ? String(response.data.mcrPct) : '',
      );
      setMedicaidPercentage(
        response.data.mcdPct ? String(response.data.mcdPct) : '',
      );
      setTraumaClassification(response.data.traumaCtr ?? undefined);
      setIsTeaching(response.data.teaching ?? undefined);
      setIsStroke(response.data.strokeCenter ?? undefined);
    }
  };

  const handleChangeDoubleButton = (setState: Function, toggle?: boolean) => {
    switch (toggle) {
      case true:
        setState('1');
        break;
      case false:
        setState('0');
        break;
      default:
        setState(undefined);
        break;
    }
  };

  const handleChangeFacility = (callReturn: any) => {
    setFacility(callReturn.id);
    getFacilityData(callReturn.id).then(null);
  };

  const appendFormData = (form: FormData, key: string, value?: string) => {
    if (value !== undefined) {
      form.append(key, value);
    }
  };

  const makeUpdateBody = () => {
    const formData = new FormData();

    appendFormData(formData, 'beds', totalBeds);
    appendFormData(formData, 'adc', dailyCensus);
    appendFormData(formData, 'ems_vis_totl', emergencyVisits);
    appendFormData(formData, 'surgeries', surgeriesAnnual);
    appendFormData(formData, 'icu_adc', icuAdc);
    appendFormData(formData, 'icu_beds', icuBeds);
    appendFormData(formData, 'nicu_adc', nicuBeds);
    appendFormData(formData, 'births', birthsAnnual);
    appendFormData(formData, 'trauma_ctr', traumaClassification);
    appendFormData(formData, 'stroke_center', isStroke);
    appendFormData(formData, 'teaching', isTeaching);
    appendFormData(formData, 'mcr_pct', medicarePercentage);
    appendFormData(formData, 'mcd_pct', medicaidPercentage);
    return formData;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const body = makeUpdateBody();

    const response = await FacilityService.updateFacilityData(
      facility as string,
      body,
    );

    if (response.failed) {
      setLoading(false);
    }
    setLoading(false);
  };

  const firstReq = async () => {
    const response = await FacilityService.getFacilityData();
    if (!response.failed) {
      const { facilities } = response.data;
      return setFacilitiesList(facilities);
    }
    return setFacilitiesList({});
  };

  useEffect(() => {
    firstReq().then(null);
  }, []);

  return (
    <Page>
      <Section>
        <Title text={t('facility_maintaince')} />
        <SubTitle className="blueText" text={t('public_sources')} />
        <SubTitle className="blueText" text={t('please_correct')} />
        <SelectDropDown
          dataItems={facilitiesList}
          callBack={(value: any) => handleChangeFacility(value)}
          startValueKey={Object.keys(facilitiesList ?? {})?.[0]}
        />
        <SubTitle text={t('volume_measures')} className="SmallerTitle" />
        <Line />
        <VolumeMeasuresRow
          label={t('beds_total')}
          value={totalBeds}
          setState={setTotalBeds}
          facility={facility}
        />
        <VolumeMeasuresRow
          label={t('daily_census')}
          value={dailyCensus}
          setState={setDailyCensus}
          facility={facility}
        />
        <VolumeMeasuresRow
          label={t('emergency_visits')}
          value={emergencyVisits}
          setState={setEmergencyVisits}
          facility={facility}
        />
        <VolumeMeasuresRow
          label={t('surgeries_annual')}
          value={surgeriesAnnual}
          setState={setSurgeriesAnnual}
          facility={facility}
        />
        <VolumeMeasuresRow
          label={t('icu_adc')}
          value={icuAdc}
          setState={setIcuAdc}
          facility={facility}
        />
        <VolumeMeasuresRow
          label={t('icu_beds')}
          value={icuBeds}
          setState={setIcuBeds}
          facility={facility}
        />
        <VolumeMeasuresRow
          label={t('nicu_adc')}
          value={nicuBeds}
          setState={setNicuBeds}
          facility={facility}
        />
        <VolumeMeasuresRow
          label={t('births_annual')}
          value={birthsAnnual}
          setState={setBirthsAnnual}
          facility={facility}
        />
        <VolumeMeasuresRow
          label={t('medical_care_percentage')}
          value={medicarePercentage}
          setState={setMedicarePercentage}
          min={0}
          max={1}
          exceptionTitle={t('medical_care_percentage')}
          exceptionMessage={t('decimal_exception')}
          facility={facility}
        />
        <VolumeMeasuresRow
          label={t('medical_aid_percentage')}
          value={medicaidPercentage}
          setState={setMedicaidPercentage}
          min={0}
          max={1}
          exceptionTitle={t('medical_aid_percentage')}
          exceptionMessage={t('decimal_exception')}
          facility={facility}
        />
        <SelectDropDown
          title={t('trauma_classification')}
          dataItems={levelDropDownOptions}
          callBack={(res: { id: string; value: string }) => {
            setTraumaClassification(levelDropDownOptions[res.value]);
          }}
          startValueKey={traumaClassification ?? levelDropDownOptions.None}
        />
        <DoubleCheckBoxInput
          title={t('teaching_hospital')}
          firstLabel={t('yes')}
          secondLabel={t('no')}
          onChange={(value: boolean | undefined) =>
            handleChangeDoubleButton(setIsTeaching, value)
          }
          direction="column"
          startValue={isTeaching ?? false}
        />
        <DoubleCheckBoxInput
          title={t('stroke_center')}
          firstLabel={t('yes')}
          secondLabel={t('no')}
          onChange={(value: boolean | undefined) =>
            handleChangeDoubleButton(setIsStroke, value)
          }
          direction="column"
          startValue={isStroke ?? false}
        />
        <SubTitle text={t('must_submit')} />
        <SubmitButton onClick={() => handleSubmit()}>
          {loading ? <Loader color="#FFFFFF" marginTop={-20} /> : t('submit')}
        </SubmitButton>
      </Section>
      <Footer />
    </Page>
  );
}

// eslint-disable-next-line react/function-component-definition
export default () => (
  <FacilityPageContextProvider>
    <FacilityPage />
  </FacilityPageContextProvider>
);
