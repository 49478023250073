import React, { useState, useEffect } from 'react';
import TrendAnalysisTemplate from '../../components/templates/Reports/TrendAnalysisReport';
import TrendAnalysis from '../trendAnalysis';

function TrendAnalysisPDF() {
  const [pdfData, setPdfData] = useState<any>();

  const getPdfData = async () => {
    const searchUrl = window.location.search;

    const queryString = searchUrl.split('&').splice(1).join('&');
    const response = await TrendAnalysis.pdf(queryString);

    if (!response.failed) {
      setPdfData({ ...response.data });
    }
  };

  useEffect(() => {
    getPdfData().then(null);
  }, []);

  return pdfData ? <TrendAnalysisTemplate data={{ ...pdfData }} /> : null;
}

export default TrendAnalysisPDF;
