import React from 'react';

import {
  Page,
  AverageTextHolder,
  AverageText,
  ItemRow,
  SupplementalFirstTile,
} from '../style';

import ReportFooter from '../../../../organisms/Reports/Footer';
import ReportHeader from '../../../../organisms/Reports/Header';

function SupplementalBenchmark(
  reportType: string,
  nowDate: string,
  percent: any,
  count: any,
) {
  const countsKeys = count ? Object.keys(count) : [];
  const percentLenght = Object.keys(percent).length;
  const countLength = countsKeys.length;

  const supplementalPercent = (
    service: string,
    stipend: string,
    admin: string,
    coverage: string,
    weight?: 'bold' | 'light',
  ) => (
    <ItemRow width="90%">
      <SupplementalFirstTile
        width="60%"
        marginLeft={0}
        fontWeight={weight}
        alignItens="flex-start"
      >
        {service}
      </SupplementalFirstTile>
      {reportType === 'hbr' ? (
        <SupplementalFirstTile width="10%" fontWeight={weight}>
          {stipend ?? '0%'}
        </SupplementalFirstTile>
      ) : null}
      <SupplementalFirstTile width="10%" fontWeight={weight} marginLeft={5}>
        {admin ?? '0%'}
      </SupplementalFirstTile>
      <SupplementalFirstTile width="10%" fontWeight={weight} marginLeft={15}>
        {coverage ?? '0%'}
      </SupplementalFirstTile>
    </ItemRow>
  );

  const supplementalCounts = (
    service: string,
    q1: string,
    q2: string,
    q3: string,
    q4: string,
    q5: string,
    weight?: 'bold' | 'light',
  ) => (
    <ItemRow width="90%">
      <SupplementalFirstTile
        width="50%"
        marginLeft={0}
        fontWeight={weight}
        alignItens="flex-start"
      >
        {service}
      </SupplementalFirstTile>
      <SupplementalFirstTile width="10%" fontWeight={weight}>
        {q1}
      </SupplementalFirstTile>
      <SupplementalFirstTile width="10%" fontWeight={weight}>
        {q2}
      </SupplementalFirstTile>
      <SupplementalFirstTile width="10%" fontWeight={weight}>
        {q3}
      </SupplementalFirstTile>
      <SupplementalFirstTile width="10%" fontWeight={weight}>
        {q4}
      </SupplementalFirstTile>
      <SupplementalFirstTile width="10%" fontWeight={weight}>
        {q5}
      </SupplementalFirstTile>
    </ItemRow>
  );

  return (percent && percentLenght) || (count && countLength) ? (
    <Page id="suplementalBenchmark">
      <ReportHeader title="" />
      <AverageTextHolder height={50} direction="row">
        <AverageText color="#2451CB" fontSize={16} fontWeight="bold">
          Supplemental Benchmark
        </AverageText>
      </AverageTextHolder>
      {percentLenght
        ? supplementalPercent(
            'Percent of subscriber hospitals paying for',
            'Stipend',
            'Admin',
            'Coverage',
            'bold',
          )
        : null}
      {Object.keys(percent)?.map((percentKey: string) =>
        supplementalPercent(
          percentKey,
          percent[percentKey]?.HB,
          percent[percentKey]?.AD ?? percent[percentKey]?.HD,
          percent[percentKey]?.CC ?? percent[percentKey]?.HC,
        ),
      )}
      <div style={{ height: '30px' }} />
      {countLength
        ? supplementalCounts(
            'Paid Administrative Position Count',
            '1',
            '2',
            '3',
            '4',
            '5+',
            'bold',
          )
        : null}
      {countsKeys.map((countKey: string) =>
        supplementalCounts(
          countKey,
          String(count[countKey]['1']),
          String(count[countKey]['2']),
          String(count[countKey]['3']),
          String(count[countKey]['4']),
          String(count[countKey]['5+']),
        ),
      )}
      <ReportFooter nowDate={nowDate} />
    </Page>
  ) : null;
}

export default SupplementalBenchmark;
