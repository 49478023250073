import React from 'react';

import { PercentageSign, PercentileIcons } from 'src/global/PngIcons';

import {
  Page,
  IconContainer,
  AverageTextHolder,
  AverageText,
  SpecialAbsoluteTextHolder,
} from '../style';

import ReportHeader from '../../../../organisms/Reports/Header';
import ReportFooter from '../../../../organisms/Reports/Footer';

export interface FourthPageTextsInterface {
  howUse: string;
  howUseText: string;
  percentStatistic: string;
  percentText: string;
  paymentRanges: string;
  paymentRangesFirstText: string;
  paymentRangesSecondText: string;
  nowDate: string;
}

function FourthPage(fourthPages: FourthPageTextsInterface) {
  const BOLD_TEXT_SIZE = 11;
  const AVERAGE_TEXT_SIZE = 9.5;
  const {
    howUse,
    howUseText,
    percentStatistic,
    percentText,
    paymentRanges,
    paymentRangesFirstText,
    paymentRangesSecondText,
    nowDate,
  } = fourthPages;

  const PRIMARY_LIGHT_BLUE = '#2451CB';

  return (
    <Page>
      <ReportHeader title="" />
      <SpecialAbsoluteTextHolder>
        <AverageTextHolder alignContent="flex-start" height={55}>
          <AverageText
            color={PRIMARY_LIGHT_BLUE}
            fontWeight="bold"
            fontSize={10}
          >
            {howUse}
          </AverageText>
          <AverageText fontSize={9}>{howUseText}</AverageText>
        </AverageTextHolder>
      </SpecialAbsoluteTextHolder>
      <AverageTextHolder
        className="percentageSide"
        direction="row"
        alignContent="flex-start"
        height={70}
        marginTop={20}
      >
        <IconContainer className="percentageIcon">
          <img src={PercentageSign} alt="percentage" style={{ width: 66 }} />
        </IconContainer>
        <AverageTextHolder>
          <AverageTextHolder
            className="percentText"
            alignContent="flex-start"
            height={76}
          >
            <AverageText
              fontSize={BOLD_TEXT_SIZE}
              fontWeight="bold"
              color={PRIMARY_LIGHT_BLUE}
            >
              {percentStatistic}
            </AverageText>
            <AverageText fontSize={AVERAGE_TEXT_SIZE}>
              {percentText}
            </AverageText>
          </AverageTextHolder>
        </AverageTextHolder>
      </AverageTextHolder>
      <AverageTextHolder alignContent="flex-start" height={90} marginTop={30}>
        <AverageText
          color={PRIMARY_LIGHT_BLUE}
          fontWeight="bold"
          fontSize={BOLD_TEXT_SIZE}
        >
          {paymentRanges}
        </AverageText>
        <AverageText className="paymentText" fontSize={AVERAGE_TEXT_SIZE}>
          {paymentRangesFirstText}
        </AverageText>
      </AverageTextHolder>
      <AverageTextHolder
        className="percentageSide"
        direction="row"
        alignContent="flex-start"
        height={70}
        marginTop={25}
      >
        <AverageTextHolder className="percentileText">
          <AverageTextHolder alignContent="flex-start" height={66}>
            <AverageText fontSize={AVERAGE_TEXT_SIZE}>
              {paymentRangesSecondText}
            </AverageText>
          </AverageTextHolder>
        </AverageTextHolder>
        <IconContainer className="percentileRanges">
          <img src={PercentileIcons} alt="percentage" width={220} />
        </IconContainer>
      </AverageTextHolder>
      <ReportFooter nowDate={nowDate} />
    </Page>
  );
}

export default FourthPage;
