import React from 'react';
import Title from '../../atoms/Title';
import { Container, TitleContainer } from './style';
import SubTitle from '../../atoms/Subtitle';
import HorizontalBarChart from '../../atoms/VerticalBarChart';
import TitledVerticalBarChartInterface from './interface';

function TitledLineChart({
  title,
  subtitle,
  sliceName,
  chartYear,
  xValues,
  yValues,
  id,
}: TitledVerticalBarChartInterface) {
  return (
    <Container id={id}>
      <TitleContainer>
        <Title
          text={`${chartYear ?? ''} ${title ?? ''} Benchmarks: ${
            subtitle ?? ''
          }`}
        />
        <SubTitle text={sliceName ?? ''} />
      </TitleContainer>
      <HorizontalBarChart id={id} xValues={xValues} yValues={yValues} isPdf />
    </Container>
  );
}

export default TitledLineChart;
