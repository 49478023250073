import styled from 'styled-components';

interface ContainerInterface {
  width?: number;
}

interface LegendInterface {
  even?: boolean;
}

export const TableTopBorder = styled.div`
  background-color: #052049;
  width: 100%;
  height: 8px;
`;

export const TableTitle = styled.div`
  color: #222222;
  font-size: 25px;
  letter-spacing: -0.3px;
  line-height: 30px;
  text-align: left;
  font-weight: bold;
  margin: 33px 34px 10px;
`;

export const Container = styled.div<ContainerInterface>`
  height: fit-content;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.16);
  margin: 34px 0;
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
`;

export const TableContainer = styled.div`
  margin-left: 34px;
  margin-right: 34px;
  margin-bottom: 34px;
  height: fit-content;

  .rdt_TableHead {
    font-size: 16px;
  }

  .rdt_TableCell {
    font-size: 15px;
  }
`;

export const StyledTableHeader = styled.div`
  white-space: normal;
`;

export const TableInfo = styled.div`
  color: var(--primary-dark-gray);
  font-size: 15px;
  letter-spacing: -0.3px;
  text-align: left;
  margin-left: 34px;
  margin-top: 5px;
  margin-bottom: 20px;
`;

export const TableSubtitle = styled.div`
  color: #000000;
  font-size: 15px;
  letter-spacing: -0.3px;
  text-align: left;
  margin-left: 34px;
`;

export const TableLegend = styled.div<LegendInterface>`
  width: 301px;
  height: 30px;
  background-color: ${(props) =>
    props.even ? 'var(--ecg-royal-blue)' : 'var(--primary-dark-blue)'};
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const LegendsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 34px;
  margin-bottom: 10px;
  align-items: center;
`;

export const LegendText = styled.span`
  padding-right: 10px;
`;
