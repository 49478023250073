import Api from './api';
import keysToCamel from '../utils/keysToCamelCase';
import singleServiceReportSectionsDivider from '../utils/singleServiceReportSectionsDivider';
import GetCustomReportParams from '../contracts/models/GetCustomReportParams';

export default class CustomReportPdf {
  static async getSSR(token: string, params: GetCustomReportParams) {
    return Promise.resolve(
      Api.get('api/ssr-pdf', {
        params: {
          ...params,
        },
      })
        .then((response) => ({
          failed: false,
          data: singleServiceReportSectionsDivider(keysToCamel(response.data)),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getHBR(token: string, params: GetCustomReportParams) {
    return Promise.resolve(
      Api.get('api/ssr-pdf', {
        params: {
          ...params,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
}
