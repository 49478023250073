import React, { useEffect, useState } from 'react';
import SingleServicePdfTemplate from '../../components/templates/Reports/SingleService';
import GetCustomReportParams from '../../contracts/models/GetCustomReportParams';
import CustomReportPdf from '../customReportPdfs';

function SingleServicePDF() {
  const [pdfData, setPdfData] = useState<any>(undefined);
  const [appendixType, setAppendixType] = useState<string>('');
  const [reportType, setReportType] = useState<undefined | string>('');

  function GeneratePdfAfterRenderContent() {
    const content = document.getElementById('pdf');
    if (!content) {
      return null;
    }
    return null;
  }

  const getSSRPdfData = async (
    token: string,
    params: GetCustomReportParams,
  ) => {
    const response = await CustomReportPdf.getSSR(token, params);

    if (!response.failed) {
      setPdfData(response.data);
    }
  };

  const getHBRPdfData = async (
    token: string,
    params: GetCustomReportParams,
  ) => {
    const response = await CustomReportPdf.getHBR(token, params);

    if (!response.failed) {
      setPdfData(response.data);
    }
  };

  const getAppendixType = (flags: string) => {
    switch (true) {
      case flags.includes('ad'):
        setAppendixType('ad');
        break;
      case flags.includes('cc'):
        setAppendixType('cc');
        break;
      default:
        setAppendixType('hb');
        break;
    }
  };

  const templateRender = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const token = urlParams.get('token') ?? undefined;
    const hsid = urlParams.get('hsid') ?? undefined;
    const title = urlParams.get('title') ?? undefined;
    const custname = urlParams.get('custname') ?? undefined;
    const hflags = urlParams.get('hflags') ?? undefined;
    const svcs = urlParams.get('svcs') ?? undefined;
    const dbident = urlParams.get('dbident') ?? undefined;
    const regions = urlParams.get('regions') ?? undefined;

    const reqParams: GetCustomReportParams = {
      hsid,
      hflags,
      svcs,
      title,
      custname,
      dbident,
      regions,
    };

    setReportType(hsid);
    getAppendixType(hflags ?? '');

    if (hsid === 'ssr') return getSSRPdfData(token ?? '', reqParams).then(null);
    return getHBRPdfData(token ?? '', reqParams).then(null);
  };

  useEffect(() => {
    templateRender();
  }, []);

  // @ts-ignore
  useEffect(() => {
    setTimeout(() => GeneratePdfAfterRenderContent(), 2000);
  }, []);

  return pdfData ? (
    <SingleServicePdfTemplate
      id="pdf"
      data={pdfData}
      appendixType={appendixType}
      reportType={reportType}
    />
  ) : null;
}
export default SingleServicePDF;
