import styled from 'styled-components';

interface ContainerProps {
  color: string;
}

export const Container = styled.div`
  display: flex;
  height: 40px;
  width: max-content;
  align-self: end;
  align-items: center;

  > img {
    height: 35px;
    width: 35px;
    margin-right: 10px;
  }
`;

export const MessageContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  height: 100%;
  width: max-content;
  min-width: 30px;
  padding: 0 24px;
  border: solid 4px ${(props) => props.color};
  color: ${(props) => props.color};
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;

  > sup {
    align-self: flex-start;
    margin-top: 7px;
    margin-right: 6px;
    color: ${(props) => props.color};
  }
`;
