import React, { useEffect } from 'react';
import { format } from 'date-fns';
import {
  Document,
  PageParent,
  Page,
  Row,
  TitleContainer,
  BenchmarkTitle,
  PercentText,
  HeaderGeneralTile,
  HeaderFirstTile,
  RowGeneraltile,
  RowFirstTile,
  CharacteristcTile,
  CharacteristicHolder,
  Line,
} from './style';
import ReportHeader from '../../../organisms/Reports/Header';
import ReportFooter from '../../../organisms/Reports/Footer';
import addChildtoParent from '../../../../utils/addChildToParent';

function formatTopSectionKeys(key: string) {
  switch (key) {
    case 'slice':
      return 'Characteristc';
    case 'systems':
      return 'Providers';
    case 'hospitals':
      return 'Facilities';
    case 'contracts':
      return 'Contracts';
    case 'mean':
      return 'Mean';
    case 'q25':
      return '25%';
    case 'q50':
      return '50%';
    case 'q75':
      return '75%';
    case 'q90':
      return '90%';
    default:
      return key;
  }
}

function TopSectionRow({ id, dataBody }: any) {
  const filteredDataBody = dataBody.filter((key: any) => key !== 'showP');
  const [firstKey, ...otherKeys] = Object.keys(filteredDataBody);
  const totalColumns = Object.keys(filteredDataBody).length;

  return (
    <Row id={id}>
      <HeaderFirstTile>
        {formatTopSectionKeys(filteredDataBody[firstKey])}
      </HeaderFirstTile>
      {otherKeys.map((key: string) => (
        <HeaderGeneralTile key={key} totalColumns={totalColumns}>
          {formatTopSectionKeys(filteredDataBody[key])}
        </HeaderGeneralTile>
      ))}
    </Row>
  );
}

function CharacteristcRow({ text, id }: any) {
  return (
    <Row id={id} margin="8px 0px 8px">
      <CharacteristcTile>{text}</CharacteristcTile>
    </Row>
  );
}

const verifySize = (text: string) => text.length > 9;

function DataRow({ dataBody, divId }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { showP, ...filteredDataBody } = dataBody;

  const [firstKey, ...otherKeys] = Object.keys(filteredDataBody);
  const totalColumns = Object.keys(filteredDataBody).length;

  return (
    <Row id={divId} margin="3px 0px">
      <RowFirstTile>{dataBody[firstKey]}</RowFirstTile>
      {otherKeys.map((key: any) => (
        <RowGeneraltile
          totalColumns={totalColumns}
          fontSize={verifySize(dataBody[key]) ? 6 : undefined}
        >
          {dataBody[key]}
        </RowGeneraltile>
      ))}
    </Row>
  );
}

function CharacteristicSection({
  charName,
  charRows,
  sectionId,
  marginBottom,
}: any) {
  return (
    <CharacteristicHolder id={sectionId} marginBottom={marginBottom}>
      <CharacteristcRow text={charName} />
      {charRows.map((row: any) => (
        <DataRow dataBody={row} />
      ))}
      <Line />
    </CharacteristicHolder>
  );
}

function SearchNonSalaryTemplate({ data }: any) {
  const states = data;

  const percentPaying = states?.percentpaying;

  const currentDate = format(new Date(), 'MM/dd/yyyy');

  const SPECIAL_STRUCTE_HANDLE_SECTIONS = ['Administration'];

  const buildPage = (pageIndex: number) => (
    <PageParent id={`search-nonsalary-parent-page-${pageIndex}`}>
      <ReportHeader title={states.service} />
      <Page id={`search-nonsalary-page-${pageIndex}`} />
      <ReportFooter nowDate={currentDate} />
    </PageParent>
  );

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  let page = 0;

  function buildTableBodyInformation(
    usedBenchData: any,
    benchName?: any,
    isAdmin?: string,
  ) {
    const characteristics = Object.keys(usedBenchData);
    const containerId = buildId();

    const tableContent = characteristics.map(
      (charName: string, index: number) => {
        const isLast = index === characteristics.length - 1;
        const charRows = usedBenchData[charName];

        if (!charRows.length) return null;

        const charSectionId = buildId();
        const characteristicSection = (
          <CharacteristicSection
            charName={charName}
            charRows={charRows}
            sectionId={charSectionId}
            marginBottom={isLast ? 20 : 0}
          />
        );

        return characteristicSection;
      },
    );

    const benchmarkTitle = isAdmin?.length ? (
      <TitleContainer className="adminSectionTitle" id={buildId()}>
        <BenchmarkTitle fontSize={10}>{isAdmin}</BenchmarkTitle>
      </TitleContainer>
    ) : (
      <TitleContainer className="regionTitle">
        <BenchmarkTitle id={buildId()}>{benchName}</BenchmarkTitle>
        {percentPaying[benchName] ? (
          <PercentText>
            {`Percent Paying: ${percentPaying[benchName]}`}
          </PercentText>
        ) : null}
      </TitleContainer>
    );

    const headerKeys: any = [];
    Object.keys(usedBenchData).forEach((key: any) => {
      const benchmarks = usedBenchData[key];
      benchmarks.forEach((benchmark: any) => {
        const Benchkeys = Object.keys(benchmark);
        Benchkeys.forEach((headerKey: any) => {
          if (!headerKeys.includes(headerKey)) {
            headerKeys.push(headerKey);
          }
        });
      });
    });

    const topSectionId = buildId();
    const container = (
      <div id={containerId}>
        {benchmarkTitle}
        <TopSectionRow id={topSectionId} dataBody={headerKeys} />
        {tableContent}
      </div>
    );

    page = addChildtoParent(
      containerId,
      container,
      'search-nonsalary-page',
      page,
    );
  }

  const adminSectionHandle = (
    adminSection: any,
    percentPayingAdmin?: string,
  ) => {
    page += 1;
    const adminTitleId = buildId();
    const adminSectionTitle = (
      <TitleContainer className="regionTitle" id={adminTitleId}>
        <BenchmarkTitle>Administration</BenchmarkTitle>
        {percentPayingAdmin ? (
          <PercentText>{`Percent Paying: ${percentPayingAdmin}`}</PercentText>
        ) : null}
      </TitleContainer>
    );

    page = addChildtoParent(
      adminTitleId,
      adminSectionTitle,
      'search-nonsalary-page',
      page,
    );

    Object.keys(adminSection).map((key: string) => {
      const section = adminSection[key];
      if (section.length === 0) return null;
      return buildTableBodyInformation(section, key);
    });
    page += 1;
  };

  const removeNotUsedPage = () => {
    for (let i = 0; i < 50; i += 1) {
      const currentPage = document!.getElementById(
        `search-nonsalary-page-${i}`,
      );
      if (!currentPage?.firstChild) {
        document?.getElementById(`search-nonsalary-parent-page-${i}`)?.remove();
      }
    }
  };

  const benchmarkPages = new Set<string>();

  const buildContent = async () => {
    states.benchmarks.forEach((benchmark: any) => {
      benchmark.forEach((benchmarksObject: any) => {
        if (!benchmarksObject) return null;
        const benchNames = Object.keys(benchmarksObject);
        benchNames.forEach((benchName) => {
          const usingBenchmark = benchmarksObject[benchName];
          if (usingBenchmark.length === 0 || benchmarkPages.has(benchName)) {
            return null;
          }
          benchmarkPages.add(benchName);
          if (SPECIAL_STRUCTE_HANDLE_SECTIONS.includes(benchName)) {
            return adminSectionHandle(usingBenchmark, percentPaying[benchName]);
          }
          buildTableBodyInformation(usingBenchmark, benchName);
          return null;
        });
        return null;
      });
    });
  };

  useEffect(() => {
    buildContent().then(() => removeNotUsedPage());
  }, []);

  return (
    <Document id="documentBody">
      {Array(20)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default SearchNonSalaryTemplate;
