import styled from 'styled-components';

interface ContainerProps {
  color: string;
}

export const Container = styled.div`
  display: flex;
  width: max-content;
  align-self: flex-start;
  flex-direction: column;
  gap: 8px;
  margin-right: 30px;

  > img {
    height: 35px;
    width: 35px;
    margin-right: 10px;
  }
`;

export const MessageContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  height: 100%;
  width: max-content;
  min-width: 30px;
  padding: 6px 24px;
  border: solid 4px ${(props) => props.color};
  color: ${(props) => props.color};
  border-radius: 20px;
  font-weight: bolder;
  font-size: 16px;

  > sup {
    margin-right: 6px;
    color: ${(props) => props.color};
  }
`;

export const SlicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
`;
