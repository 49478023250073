import React from 'react';
import { FooterItem as Body, FooterItemText } from './style';
import FooterItemInterface from './interface';

function FooterItem({
  text,
  Icon,
  iconColor,
  textColor,
  link,
}: FooterItemInterface) {
  const openLink = () => window.open(link);

  return (
    <Body
      hasIcon={!!Icon}
      color={textColor}
      onClick={() => (link ? openLink() : null)}
      clickable={!!link}
    >
      {Icon && <Icon color={iconColor} />}
      <FooterItemText>{text}</FooterItemText>
    </Body>
  );
}

export default FooterItem;
