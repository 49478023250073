import styled from 'styled-components';

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 700px;
  padding: 0;
  top: 0;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 25px;
  margin: 20px 0;

  > :not(:first-child).benchTitle {
    margin-top: 50px;
  }
`;

export const AppendixContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  margin-bottom: 5px;

  > .glossary {
    margin: 10px 0 20px 10px;
    color: var(--almost-black);
    font-weight: bold;
    font-size: 14px;
  }
`;

export const AppendixTerm = styled.span`
  font-family: inherit;
  font-size: 11px;
  font-weight: bold;
  color: var(--ecg-royal-blue);
`;

export const AppendixInfo = styled.span`
  font-family: inherit;
  font-size: 9px;
  color: var(--almost-black);
  padding-left: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  margin-top: 80px;
  width: max-content;
  height: max-content;
  align-self: center;
  margin-bottom: 25px;
`;

export const BenchMarkTitle = styled.h1`
  color: var(--ecg-royal-blue);
  font-family: inherit;
  font-weight: bolder;
  letter-spacing: -1px;
  font-size: 18px;
  margin: 20px 0 0 0;
  align-self: center;
`;
