import { renderToString } from 'react-dom/server';

function isVisible(parentId: string, childId: string, fittingError?: number) {
  const child = document.getElementById(childId);
  const parent = document.getElementById(parentId);

  return !(
    child!.offsetTop + child!.offsetHeight + (fittingError ?? 0) >
    parent!.offsetHeight
  );
}

function addChildtoParent(
  childId: string,
  content: any,
  parentPrefix: string,
  pageNumber: number,
  fittingError?: number,
  headerElement?: any,
) {
  let page = pageNumber;
  const parentId = `${parentPrefix}-${page}`;
  document!.getElementById(parentId)!.innerHTML += renderToString(content);
  const visible = isVisible(parentId, childId, fittingError);
  if (!visible) {
    document!.getElementById(childId)!.remove();
    page += 1;
    if (content.props?.className !== 'lineDivider') {
      document!.getElementById(`${parentPrefix}-${page}`)!.innerHTML +=
        renderToString(headerElement);
      document!.getElementById(`${parentPrefix}-${page}`)!.innerHTML +=
        renderToString(content);
    }
  }
  return page;
}

export default addChildtoParent;
