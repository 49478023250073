import styled from 'styled-components';

interface FooterItemProps {
  color?: string;
  clickable?: boolean;
  hasIcon: boolean;
}

export const FooterItem = styled.div<FooterItemProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props.color ?? '#4A494A'};
  height: 35px;

  p {
    margin-left: ${(props) => (!props.hasIcon ? '25px' : '6px')};
    text-decoration: ${(props) => (props.clickable ? 'underline' : 'none')};
    cursor: ${(props) => (props.clickable ? 'pointer' : 'normal')};
  }
`;

export const FooterItemText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  word-wrap: break-word;
`;
