import styled from 'styled-components';

interface ContainerProps {
  $withouButton?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > :nth-child(n + 2) {
    margin-left: ${(props) => (props.$withouButton ? '0px' : '35px')};
    padding: ${(props) => (props.$withouButton ? '0px' : 'auto')};
    margin-top: ${(props) => (props.$withouButton ? '17px' : 'auto')};
  }
  > span {
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    color: #ed7a23;
    margin-top: 20px;
    max-width: 350px;
    text-align: left;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  > :nth-child(2) {
    margin-left: 20px;
  }
`;

export const RowSlices = styled.div<{ $marginBottom?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.$marginBottom ? '20px' : '0px')};

  > :nth-child(2) {
    margin-left: 20px;
  }
`;

export const Helper = styled.p`
  font-style: italic;
  padding-left: 6px;
  font-size: 12px;
`;
