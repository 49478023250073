import React from 'react';
import SearchHeaderInterface from './interface';
import Title from '../../atoms/Title';
import { Container } from './style';
import SearchInput from '../../atoms/SearchInput';

function SearchHeader({
  placeholder,
  title,
  callback,
  options,
}: SearchHeaderInterface) {
  return (
    <Container>
      <Title text={title} />
      <SearchInput
        placeholder={placeholder}
        callback={callback}
        options={options}
      />
    </Container>
  );
}

export default SearchHeader;
