enum LightColors {
  green = 'green',
  none = 'none',
  notfound = 'notfound',
  red = 'red',
  white = 'white',
  yellow = 'yellow',
  over90 = 'over90',
}

export default LightColors;
