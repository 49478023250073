import React from 'react';
import { format, parse } from 'date-fns';
import {
  GeneralTextContainer,
  GeneralBlueText,
  SubtitleContentText,
} from '../style';

export interface FirstPageProps {
  executiveSummary: string;
  estimated: string;
  physician: string;
  spending: string;
  customer: string;
  reportPrepared: string;
  reportExpires: string;
  confidentialInfo: string;
  facility?: string;
  id: string;
  cuttDate: string;
}

function FirstPage(pageProps: FirstPageProps) {
  const {
    executiveSummary,
    estimated,
    physician,
    spending,
    customer,
    facility,
    reportPrepared,
    reportExpires,
    confidentialInfo,
    id,
    cuttDate,
  } = pageProps;
  const creationDate = new Date();
  const formatCreationDate = format(creationDate, 'EEEE, MMMM dd,  yyyy');
  const expirationDate = parse(cuttDate, 'yyyy-MM-dd', new Date());
  const formatExpirationDate = format(expirationDate, 'MMMM dd, yyyy');

  return (
    <div id={id}>
      <GeneralTextContainer marginTop={100}>
        <GeneralBlueText fontWeight="bolder" fontSize={22}>
          {executiveSummary}
        </GeneralBlueText>
        <GeneralBlueText fontSize={22}>{estimated}</GeneralBlueText>
        <GeneralBlueText fontSize={22}>{physician}</GeneralBlueText>
        <GeneralBlueText fontSize={22}>{spending}</GeneralBlueText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={80}>
        <GeneralBlueText fontColor="#0073b0" fontWeight="bolder" fontSize={22}>
          {customer}
        </GeneralBlueText>
        <GeneralBlueText>{facility}</GeneralBlueText>
        <GeneralTextContainer marginTop={40}>
          <SubtitleContentText fontWeight="bold">{`${reportPrepared} ${formatCreationDate}`}</SubtitleContentText>
          <SubtitleContentText fontWeight="bold">{`${reportExpires} ${formatExpirationDate}`}</SubtitleContentText>
          <SubtitleContentText>{confidentialInfo}</SubtitleContentText>
        </GeneralTextContainer>
      </GeneralTextContainer>
    </div>
  );
}

export default FirstPage;
