/* eslint-disable react/function-component-definition,react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';
import UserGlobalContextInterface from './interface';

const GlobalContext = createContext<UserGlobalContextInterface>(null as any);

export const useGlobal = () => useContext(GlobalContext);

const GlobalContextProvider: React.FC = ({ children }) => {
  const [userToken, setUserToken] = useState<string>('');

  return (
    <GlobalContext.Provider
      value={{
        userToken,
        setUserToken,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
