import Api from './api';
import keysToCamel from '../utils/keysToCamelCase';

export default class RateComparison {
  static async get(
    service: string,
    param: string,
    value: string,
    trauma: 0 | 1,
    slices?: string,
  ) {
    return Promise.resolve(
      Api.get('/api/ratecomparison', {
        params: {
          [param]: value,
          service,
          trauma,
          slices,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getHiddenBenchmarks(service: string, trauma: 0 | 1) {
    return Promise.resolve(
      Api.get('/api/ratecomparison', {
        params: {
          precheck: 1,
          service,
          trauma,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async pdf(queryString: string) {
    return Promise.resolve(
      Api.get(`/api/ratecomparison-pdf?${queryString}`)
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getComparisons(service: string, precheck: 0 | 1) {
    return Promise.resolve(
      Api.get('/api/ratecomparison', {
        params: {
          service,
          precheck,
        },
      })
        .then((response) => ({
          failed: false,
          data: response.data,
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async sendSlices(HC: string, service: string, slices: string[]) {
    return Promise.resolve(
      Api.post('/api/ratecomparison', {
        params: {
          HC,
          service,
          slices,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
}
