import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > :nth-child(2) {
    width: 400px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > :first-child {
    color: var(--ecg-royal-blue);
    font-size: 12px;
  }

  h2 {
    font-size: 10px;
    margin: 0;
  }

  margin-bottom: 15px;
`;
