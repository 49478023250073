import styled from 'styled-components';

interface AverageTextInterface {
  fontSize?: number;
  color?: string;
  fontWeight?: string;
}

export const Page = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffff;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 90%;

  &.firstSection {
    padding-top: 40px;
  }

  &.lastSection {
    padding-bottom: 40px;
  }
`;

export const Text = styled.span<AverageTextInterface>`
  font-family: inherit;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize ?? 12}px;
  color: ${(props) => props.color ?? '#242424'};
  margin-bottom: 10px;
  text-align: justify;
`;
