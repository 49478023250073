import React, { useEffect, useState } from 'react';
import store from 'src/hooks/redux/store';
import { Box, BoxHider, Page } from './style';
import Footer from '../../components/organisms/Footer';
import ReportGenerator from '../../components/templates/ReportsGenerator';
import DignityReportGenerator from '../../components/templates/DignityReportsGenerator';
import MDRangerService from '../../services/mdrangerServices';
import GeneralServices from '../../services/generalServices';
import Loader from '../../components/atoms/DotLoader';
import RateComparisonServiceBox from '../../components/organisms/RateComparisonServiceBox';
import { buildPdf } from '../../utils/buildPdf';

function RateComparison() {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [services, setServices] = useState<string[]>([]);
  const [loadingDignity, setLoadingDignity] = useState<boolean>(false);

  const [service, setService] = useState<string[]>(['', '', '']);
  const [selfDignity, setSelfDignity] = useState<boolean>(false);
  const [trauma] = useState<boolean[]>([false, false, false]);
  const [reportTitle, setReportTitle] = useState<string>('');
  const [counterParty, setCounterParty] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [facility, setFacility] = useState<string>('');
  const [hospitalName, setHospitalName] = useState<string>('');
  const [fmvApproval, setFmvApproval] = useState<boolean>(false);
  const [previousApproval, setPreviousApproval] = useState<string>('');
  const [additionalText, setAdditionalText] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [signature, setSignature] = useState<boolean>(false);

  const [serviceData, setServiceData] = useState<any>([{}, {}, {}]);
  const [showAdditional, setShowAdditional] = useState<boolean[]>([
    false,
    false,
  ]);

  const handleReportGeneratorInputsChange = (inputValues: any) => {
    setReportTitle(inputValues.reportTitle);
    setCounterParty(inputValues.counterParty);
    setStartDate(inputValues.startDate);
    setEndDate(inputValues.endDate);
    setFacility(inputValues.facility);
    setNotes(inputValues.notes);
    setSignature(inputValues.signature);
  };

  const handleDignityReportInputChange = (inputValues: any) => {
    setReportTitle(inputValues.reportTitle);
    setHospitalName(inputValues.hospitalName);
    setFmvApproval(inputValues.fmvApproval);
    setPreviousApproval(inputValues.previousApproval);
    setAdditionalText(inputValues.additionalText);
  };

  const loadServices = async () => {
    const response: any = await MDRangerService.getAll();
    if (!response.failed) {
      setServices(response.data.services);
    }
  };

  const loadSelf = async () => {
    const response = await GeneralServices.self();
    if (!response.failed) {
      setSelfDignity(response.data.dignity);
    }
    setLoadingDignity(false);
  };

  useEffect(() => {
    setLoadingDignity(true);
    loadSelf().then(null);
  }, []);

  const encodeService = (items: (string | undefined)[]) => {
    const andURLEncoder = /&/g;
    return items.map((s) =>
      s ? encodeURIComponent(s).replace(andURLEncoder, '%26') : '',
    );
  };

  const getAtributeData = (attribute: string) => [
    serviceData[0][attribute] ?? '',
    serviceData[1][attribute] ?? '',
    serviceData[2][attribute] ?? '',
  ];

  const buildPDFAttributes = () => {
    // make sure url encondement wont be bugged in the micro pdf page

    const checkServiceEncoded = encodeService(getAtributeData('service'));

    return {
      checkservice: checkServiceEncoded,
      checkht: [trauma[0] ?? '', trauma[1] ?? '', trauma[2] ?? ''],
      checkHC: getAtributeData('HC'),
      checkHD: getAtributeData('HD'),
      checkHH: getAtributeData('HH'),
      checkHS: getAtributeData('HS'),
      checkHR: getAtributeData('HR'),
      checkLH: getAtributeData('LH'),
      checkHP: getAtributeData('HP'),
      checkHQ: getAtributeData('HQ'),
      checkHT: getAtributeData('HT'),
      checkEP: getAtributeData('EP'),
      checkEV: getAtributeData('EV'),
      checkEC: getAtributeData('EC'),
      checkUS: getAtributeData('US'),
      checkUG: getAtributeData('UG'),
      checkDS: getAtributeData('DS'),
      slices: [
        serviceData[0].selectedSlices ?? [],
        serviceData[1].selectedSlices ?? [],
        serviceData[2].selectedSlices ?? [],
      ],
      'report-title': reportTitle,
      'report-counterparty': counterParty,
      'report-start': startDate,
      'report-end': endDate,
      'report-facility': facility,
      'report-notes': notes,
      'report-approval': signature,
      hospname: hospitalName, // if dignity true
      prevappy: fmvApproval,
      prevappid: previousApproval,
      checkrm: additionalText,
    };
  };

  function buildQueryEncoded(params: any): string {
    return Object.keys(params)
      .map((key) => {
        const value = params[key];

        if (Array.isArray(value)) {
          return value
            .map(
              (item, index) => `${key}[${index}]=${encodeURIComponent(item)}`,
            )
            .join('&');
        }
        if (value !== '') {
          return `${key}=${encodeURIComponent(value)}`;
        }
        return '';
      })
      .filter((part) => part !== '')
      .join('&');
  }

  const handleSubmit = async () => {
    setSubmitting(true);
    const body: any = buildPDFAttributes();
    const paramsArray = buildQueryEncoded(body);
    const accessToken = store.getState().auth.token;
    const microUrl = `/rcr-pdf?token=${accessToken}&dignity=${selfDignity}&${paramsArray}`;
    const prefix = window.location.href.split('/rate-comparison')[0];

    const pdfTitle = `${service}RateComparisonReport2024.pdf`;

    await buildPdf(`${prefix}${microUrl}`, pdfTitle, setSubmitting);
  };

  const handleBoxSelectService = async (callReturn: any, arrayId: number) => {
    const serviceArray = [...service];
    serviceArray[arrayId] = callReturn;

    setService(serviceArray);
    const additionalArray = [...showAdditional];
    additionalArray[arrayId] = true;
    setShowAdditional(additionalArray);
  };

  const getBoxData = async (callReturn: any, arrayId: number) => {
    const serviceDataArray = [...serviceData];
    serviceDataArray[arrayId] = callReturn;
    setServiceData(serviceDataArray);
  };

  useEffect(() => {
    loadServices().then(null);
  }, []);

  return (
    <Page>
      <RateComparisonServiceBox
        id="serviceBox"
        key="serviceBox"
        services={services}
        type="main"
        onServiceSelect={(value: string) => handleBoxSelectService(value, 0)}
        callBack={(value: any) => getBoxData(value, 0)}
      />
      {showAdditional[0] && (
        <RateComparisonServiceBox
          id="additionalFirstServiceBox"
          key="additionalFirstServiceBox"
          services={services}
          type="additional"
          onServiceSelect={(value: string) => handleBoxSelectService(value, 1)}
          callBack={(value: any) => getBoxData(value, 1)}
        />
      )}
      {showAdditional[1] && (
        <RateComparisonServiceBox
          id="additionalSecondServiceBox"
          key="additionalSecondServiceBox"
          type="additional"
          services={services}
          onServiceSelect={(value: string) => handleBoxSelectService(value, 2)}
          callBack={(value: any) => getBoxData(value, 2)}
        />
      )}
      <Box>
        {(loadingDignity || !service) && (
          <BoxHider blur={loadingDignity.toString()}>
            {loadingDignity && <Loader />}
          </BoxHider>
        )}
        {selfDignity ? (
          <DignityReportGenerator
            loading={submitting}
            handleInputsChange={handleDignityReportInputChange}
            handleSubmit={handleSubmit}
          />
        ) : (
          <ReportGenerator
            loading={submitting}
            handleInputsChange={handleReportGeneratorInputsChange}
            handleSubmit={handleSubmit}
          />
        )}
      </Box>
      <Footer />
    </Page>
  );
}

export default RateComparison;
