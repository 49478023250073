import styled from 'styled-components';

interface ItemInterface {
  size?: number;
}

export const Item = styled.img<ItemInterface>`
  height: auto;
  ${(props) => (props.size ? `width: ${props.size}px` : '')}
`;
