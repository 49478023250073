import React, { useEffect, useState } from 'react';
import Title from 'src/components/atoms/Title';
import Checkbox from 'src/components/atoms/Checkbox';
import DoubleCheckBoxInputInterface from './interface';
import { Container, Row, Notes } from './style';

function DoubleCheckBoxInput({
  className,
  direction,
  firstLabel,
  onChange,
  startValue,
  secondLabel,
  title,
  height,
  width,
  secondLabelNote,
}: DoubleCheckBoxInputInterface) {
  const [sharedState, setSharedState] = useState<boolean | undefined>();

  useEffect(() => {
    onChange(sharedState);
  }, [sharedState]);

  useEffect(() => {
    if (startValue === null) {
      return setSharedState(undefined);
    }
    return Number(startValue) === 0
      ? setSharedState(false)
      : setSharedState(true);
  }, [startValue]);

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  return (
    <Container className={className}>
      {title && <Title text={title} />}
      <Row direction={direction!}>
        <Checkbox
          id={buildId()}
          label={firstLabel}
          onChange={(event: boolean) => event && setSharedState(event)}
          control={sharedState === true}
          format="circular"
          height={height}
          width={width}
        />
        <Checkbox
          id={buildId()}
          label={secondLabel}
          onChange={(event: boolean) => event && setSharedState(!event)}
          control={sharedState === false}
          format="circular"
          height={height}
          width={width}
        />
        {secondLabelNote ? <Notes>{secondLabelNote}</Notes> : null}
      </Row>
    </Container>
  );
}

export default DoubleCheckBoxInput;
