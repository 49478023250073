import styled from 'styled-components';

interface RowInterface {
  height?: number;
  marginTop?: number;
  marginBottom?: number;
  width?: string;
}

interface SupplermentalTile {
  width?: string;
  marginLeft?: number;
  fontWeight?: 'bold' | 'light';
  alignItens?: 'center' | 'flex-start' | 'flex-end';
}

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-pdf-height);
  padding: 0;
  top: 0;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 25px;
  margin: 20px 0;

  > :not(:first-child).benchTitle {
    margin-top: 50px;
  }
  > .adminBenchTitle {
    font-size: 11px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: var(--default-pdf-width);
  height: max-content;
  margin-left: -25px;
`;

export const IconContainer = styled.div`
  display: flex;
  margin-top: 80px;
  width: max-content;
  height: max-content;
  align-self: center;
  margin-bottom: 25px;
`;

export const BenchMarkTitle = styled.h1`
  color: var(--ecg-royal-blue);
  font-family: inherit;
  font-size: 20px;
  font-weight: bolder;
  margin: 0 0 12px;
  letter-spacing: -1px;
`;

export const BetweenSectionText = styled(BenchMarkTitle)`
  font-size: 18px;
  margin: 20px 0 0 0;
  align-self: center;
`;

export const ItemRow = styled.div<RowInterface>`
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  min-height: ${(props) => `${props.height}px`};
  align-items: center;
  height: max-content;
  padding: 3px 0;
  margin-top: ${(props) => `${props.marginTop ?? 0}px`};
  margin-bottom: ${(props) => `${props.marginBottom ?? 0}px`};
`;

const BaseTile = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

export const RowFirstTile = styled(BaseTile)`
  width: 22%;
  height: max-content;
  background-color: transparent;
  color: var(--almost-black);
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 0.2px;
`;

export const RowGeneraltile = styled(RowFirstTile)`
  width: 11%;
  height: max-content;
  justify-content: flex-end;
`;

export const HeaderFirstTile = styled(RowFirstTile)`
  height: 20px;
  border-bottom: solid 1px #dbdbdb;
  background-color: var(--primary-light-gray);
  font-weight: bold;
`;

export const HeaderGeneralTile = styled(RowGeneraltile)`
  width: 11.15%;
  height: 20px;
  border-bottom: solid 1px #dbdbdb;
  border-left: solid 1px #dbdbdb;
  background-color: var(--primary-light-gray);
  font-weight: bold;
`;

export const CharacteristcTile = styled(RowFirstTile)`
  height: max-content;
  padding-top: 2px;
  font-weight: bold;
  font-size: 9px;
  color: var(--ecg-royal-blue);
  white-space: nowrap;
`;

export const CharacteristicHolder = styled.div<{ marginBotton?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  margin-bottom: ${(props) => props.marginBotton}px;
`;

export const CharacteristcSection = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  flex-direction: column;
  border-bottom: solid 1px #dbdbdb;
`;

export const Line = styled.div`
  width: 100%;
  height: 0;
  border-top: solid 1px #dbdbdb;
  margin: 2px 0;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: max-content;
  margin-bottom: 20px;
`;

export const SupplementalFirstTile = styled.div<SupplermentalTile>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.alignItens ? props.alignItens : 'flex-end')};
  justify-content: start;
  height: 15px;
  width: ${(props) => props.width};
  font-weight: ${(props) => props.fontWeight ?? 'light'};
  font-size: 9px;
  font-family: inherit;
  margin-left: ${(props) => props.marginLeft ?? 0}px;
`;
