import React from 'react';
import ReportHeader from 'src/components/organisms/Reports/Header';
import ReportFooter from 'src/components/organisms/Reports/Footer';
import { Page, TextsWrappers } from '../style';

type IntroductionPageTexts = {
  title: string;
  firstParagraph: string;
  assumption: string;
  assumptionsListItems: string[];
  reportingTitle: string;
  reportingText: string;
  complianceTitle: string;
  complianceText: string;
  reportingListItems: string[];
  footerText: string;
};
function IntroductionPage({
  title,
  firstParagraph,
  assumption,
  assumptionsListItems,
  reportingTitle,
  reportingText,
  complianceTitle,
  complianceText,
  reportingListItems,
  footerText,
}: IntroductionPageTexts) {
  return (
    <Page>
      <div className="reportHeader">
        <ReportHeader title="" />
      </div>
      <TextsWrappers>
        <h2 className="title">{title}</h2>
        <p className="averageParagraph">{firstParagraph}</p>
        <h2 className="title">{assumption}</h2>
        <p className="averageParagraph">
          <ul className="paragraphList">
            {assumptionsListItems.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </p>
        <h2 className="title">{reportingTitle}</h2>
        <p className="averageParagraph">{reportingText}</p>
        <h2 className="title">{complianceTitle}</h2>
        <p className="averageParagraph">{complianceText}</p>
        <p className="averageParagraph">
          <ul className="paragraphList">
            {reportingListItems.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </p>
      </TextsWrappers>
      <div className="reportFooter">
        <ReportFooter customText={footerText} />
      </div>
    </Page>
  );
}

export default IntroductionPage;
