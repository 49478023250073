import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;

  > :first-child {
    margin-right: 10px;
    width: max-content;
  }
`;

export const FakeInput = styled.div<{ showInput: boolean }>`
  position: relative;
  display: flex;
`;

export const InvisibleContent = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;
  background-color: #ffffff;
  border: 1px solid #b6b7bd;
  width: 225px;
  height: 40px;
  padding-left: 20px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`;

export const CalendarGroup = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: #2451cb;
  position: relative;

  > :nth-child(2) {
    background-color: #fff;
    position: absolute;
    top: 24px;
    left: -16px;
    border: 0.5px solid #b6b7bd;
    z-index: 10;
  }
`;
