import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { MDRanger } from 'src/global/PngIcons';
import {
  Document,
  PageParent,
  Page,
  AppendixContainer,
  AppendixTerm,
  AppendixInfo,
  IconContainer,
  BenchMarkTitle,
} from './style';

import AppendixGlossaryInterface from './interface';
import addChildtoParent from '../../../utils/addChildToParent';

import ReportHeader from '../../organisms/Reports/Header';
import ReportFooter from '../../organisms/Reports/Footer';

function AppendixGlossary({
  appendixAInfo,
  nowDate,
}: AppendixGlossaryInterface) {
  let page = 0;

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  const addSectionFront = (sectionName: string, title: string) => {
    const parentId = `glossary-page-${page}`;

    document!.getElementById(parentId)!.innerHTML += renderToString(
      <IconContainer>
        <img src={MDRanger} style={{ width: 180 }} alt="mdlogo" />
      </IconContainer>,
    );

    document!.getElementById(parentId)!.innerHTML += renderToString(
      <BenchMarkTitle>{title}</BenchMarkTitle>,
    );

    if (sectionName !== 'RefRates') {
      document!.getElementById(parentId)!.innerHTML += renderToString(
        <BenchMarkTitle>{sectionName}</BenchMarkTitle>,
      );
    }

    page += 1;
  };

  const buildPage = (pageIndex: number) => (
    <PageParent id={`glossary-parent-page-${pageIndex}`}>
      <ReportHeader title="" />
      <Page id={`glossary-page-${pageIndex}`} />
      <ReportFooter nowDate={nowDate} />
    </PageParent>
  );

  const removeNotUsedPage = () => {
    for (let i = 0; i < 20; i += 1) {
      const currentPage = document!.getElementById(`glossary-page-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`glossary-parent-page-${i}`)?.remove();
      }
    }
  };

  const appendixGlossary = (id: string) => (
    <AppendixContainer id={id}>
      <span className="glossary">Appendix A: Glossary</span>
    </AppendixContainer>
  );

  const appendixInfoSection = (appendix: any, infoId: string) => (
    <AppendixContainer id={infoId}>
      <AppendixTerm>{appendix.term}</AppendixTerm>
      <AppendixInfo>{appendix.definition}</AppendixInfo>
    </AppendixContainer>
  );

  const buildAppendixA = async (appendixArray: any) => {
    addSectionFront('Appendices', '');
    if (!appendixArray.length) return null;
    const topGlossaryId = buildId();
    addChildtoParent(
      topGlossaryId,
      appendixGlossary(topGlossaryId),
      'glossary-page',
      page,
    );
    await appendixArray?.map((appendix: any) => {
      const definitionId = buildId();
      page = addChildtoParent(
        definitionId,
        appendixInfoSection(appendix, definitionId),
        'glossary-page',
        page,
        120,
      );
      return null;
    });
    return null;
  };

  useEffect(() => {
    buildAppendixA(appendixAInfo).then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <Document id="glossaryBody">
      {Array(20)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default AppendixGlossary;
