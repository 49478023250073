import styled from 'styled-components';

interface ContainerInterface {
  justifyChart?: 'center' | 'left' | 'right';
}

export const Container = styled.div<ContainerInterface>`
  display: flex;
  margin-bottom: 35px;
  flex-direction: column;
  > :nth-child(1) {
    margin: 0px 0px 0px 0px;
    font-size: 10px;
  }
  > :nth-child(2) {
    margin-top: 5px;
    font-size: 8px;
    font-weight: 400;
  }
  > :nth-child(3) {
    justify-content: ${(props) => props.justifyChart ?? 'center'};
    > :nth-child(1) {
      width: ${(props) => (props.justifyChart ? 'max-content' : '0px')};
    }
  }
  > :nth-child(4) {
    align-self: center;
    margin: 0px;
    width: max-content;
    font-size: 8px;
  }
`;
