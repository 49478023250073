import styled from 'styled-components';
import { FaEdit, FaTrash } from 'react-icons/fa';

interface loadingProps {
  loading?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  > :nth-child(2) {
    margin-left: 35px;
  }

  > :nth-child(3) {
    margin-top: 10px;
    margin-left: 30px;
  }
`;

export const PaymentFieldsContainer = styled.div`
  display: flex;
  width: max-content;
  max-width: 550px;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--light-background);
  padding: 20px 10px 20px 20px;
`;

export const Row = styled.div<loadingProps>`
  display: flex;
  width: max-content;
  align-items: center;

  > :nth-child(n + 2) {
    margin-left: 5px;
    align-self: ${(props) => (props.loading ? 'center' : 'flex-end')};
  }
`;

export const IconsHolder = styled.div`
  margin-left: auto;
`;

export const EditIcon = styled(FaEdit)`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  cursor: pointer;
  color: var(--secondary-light-gray);
`;

export const DeleteIcon = styled(FaTrash)`
  height: 22px;
  width: 22px;
  cursor: pointer;
  color: var(--secondary-light-gray);
`;
