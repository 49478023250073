import React from 'react';
import { Page, AverageTextHolder, AverageText } from '../style';

import ReportHeader from '../../../../organisms/Reports/Header';
import ReportFooter from '../../../../organisms/Reports/Footer';

export interface ThirdPageTextsInterface {
  thanks: string;
  firstParagraph: string;
  secondPage: string;
  edCall: string;
  thirdParagraph: string;
  medicaldirection: string;
  fourthParagraph: string;
  providers: string;
  fifthParagraph: string;
  best: string;
  mdTeam: string;
  appendixType: string;
  aboutUs: string;
  traditionalWay: string;
  nowDate: string;
}

function ThirdPage(thirdPageTexts: ThirdPageTextsInterface) {
  const BOLD_TEXT_SIZE = 11;
  const AVERAGE_TEXT_SIZE = 9.5;
  const {
    thanks,
    firstParagraph,
    secondPage,
    edCall,
    thirdParagraph,
    medicaldirection,
    fourthParagraph,
    providers,
    fifthParagraph,
    appendixType,
    aboutUs,
    traditionalWay,
    nowDate,
  } = thirdPageTexts;

  const email = 'info@mdranger.com';
  return (
    <Page>
      <ReportHeader title="" />
      <AverageTextHolder alignContent="flex-start" marginTop={30}>
        <AverageText
          fontSize={BOLD_TEXT_SIZE}
          fontWeight="bold"
          color="#2451CB"
        >
          {thanks}
        </AverageText>
      </AverageTextHolder>
      <AverageTextHolder alignContent="flex-start" marginTop={10} height={115}>
        <AverageText fontSize={AVERAGE_TEXT_SIZE}>{firstParagraph}</AverageText>
      </AverageTextHolder>
      <AverageTextHolder alignContent="flex-start" marginTop={23} height={80}>
        <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
          {edCall}
        </AverageText>
        <AverageText fontSize={AVERAGE_TEXT_SIZE}>{thirdParagraph}</AverageText>
      </AverageTextHolder>
      {appendixType !== 'cc' ? (
        <AverageTextHolder alignContent="flex-start" marginTop={10} height={80}>
          <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
            {medicaldirection}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>
            {fourthParagraph}
          </AverageText>
        </AverageTextHolder>
      ) : null}
      <AverageTextHolder alignContent="flex-start" marginTop={15} height={65}>
        <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
          {providers}
        </AverageText>
        <AverageText fontSize={AVERAGE_TEXT_SIZE}>{fifthParagraph}</AverageText>
      </AverageTextHolder>
      {appendixType === 'cc' ? (
        <AverageTextHolder alignContent="flex-start" marginTop={15} height={60}>
          <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
            {aboutUs}
          </AverageText>
          <AverageText fontSize={AVERAGE_TEXT_SIZE}>
            {traditionalWay}
          </AverageText>
        </AverageTextHolder>
      ) : null}
      <AverageTextHolder marginTop={10}>
        <AverageText fontSize={AVERAGE_TEXT_SIZE}>
          {secondPage}
          <span style={{ color: '#2451CB' }}>{email}</span>
        </AverageText>
      </AverageTextHolder>
      <ReportFooter nowDate={nowDate} />
    </Page>
  );
}

export default ThirdPage;
