/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS } from 'chart.js';
import {
  Document,
  PageParent,
  Page,
  GeneralTextContainer,
  GeneralContentText,
  OuterTotalSpending,
  TotalSpendingRow,
  GeneralBlueText,
  Row,
  Tile,
  LegendContainer,
  LegendContentText,
  HeaderContainer,
  VerticalTile,
} from './style';
import TitledBarChart from '../../../molecules/TitledBarChart';
import TitledDoughnutChart from '../../../molecules/TitledDoughnutChart';
import ReportHeader from '../../../organisms/Reports/Header';
import ReportFooter from '../../../organisms/Reports/Footer';
import addChildtoParent from '../../../../utils/addChildToParent';
import appendChildToElement from '../../../../utils/appendChildToElement';
import FirstPage, { FirstPageProps } from './Pages/FirstPage';
import SecondPage, { SecondPageProps } from './Pages/SecondPage';
import ThirdPage, { ThirdPageProps } from './Pages/ThirdPage';

import {
  calculateDoughnutPercentage,
  removeZeroValues,
  buildLabel,
  formatValue,
} from '../../../../utils/executiveSummary';

function buildTicksWidth(ticks: string[]) {
  let value = '';

  ticks.forEach((name: string) => {
    if (name.length > value.length) {
      value = name;
    }
  });

  return value.length;
}

interface BarChartProps {
  id: string;
  dataObject: { [key: string]: number };
  title?: string;
  subtitle?: string;
  bottomText?: string;
  barWidth?: number;
  justifyChart?: 'center' | 'left' | 'right';
  tickNumber?: number;
  minHeight?: number;
  rowWidth?: string;
  buildValue: number;
}

function BuildBarChart(props: BarChartProps) {
  const {
    id,
    dataObject,
    title,
    subtitle,
    bottomText,
    barWidth,
    justifyChart,
    tickNumber,
    minHeight,
    buildValue,
    rowWidth,
  } = props;

  const removeZeros = removeZeroValues(dataObject ?? {});
  const labels = buildLabel(removeZeros);

  const xvalues = Object.values(labels) as string[];

  const ticksWidth = `${buildTicksWidth(xvalues) * buildValue}px`;
  const yvalues: number[] = Object.values(removeZeros);

  return xvalues.length ? (
    <TitledBarChart
      title={title ?? ''}
      subtitle={subtitle ?? ''}
      bottomText={bottomText ?? ''}
      xValues={xvalues}
      yValues={yvalues}
      id={id}
      width={barWidth ?? 200}
      tickNumber={tickNumber ?? 6}
      justifyChart={justifyChart}
      minHeight={minHeight}
      rowWidth={rowWidth || ticksWidth}
    />
  ) : null;
}

interface DoughnutChartProps {
  id: string;
  dataObject: { [key: string]: number };
  title?: string;
  subtitle?: string;
}

function BuildDoughnutChart(props: DoughnutChartProps) {
  const { id, dataObject, title, subtitle } = props;
  const percentageData = calculateDoughnutPercentage(dataObject);
  const doughnutX = Object.keys(percentageData);
  const doughnutY = Object.values(percentageData);
  return doughnutX.length ? (
    <TitledDoughnutChart
      title={title ?? ''}
      subtitle={subtitle ?? ''}
      id={id}
      xValues={doughnutX}
      yValues={doughnutY as number[]}
      marginTop={10}
    />
  ) : null;
}

function DottedLine(text: string) {
  return (
    <GeneralTextContainer direction="row" className="dottedLine">
      <div className="leftDot" />
      <GeneralContentText>{text}</GeneralContentText>
    </GeneralTextContainer>
  );
}

function TotalSpendingTable({ totalSpendingObject, totalSpending }: any) {
  return (
    <OuterTotalSpending id="totalSpendingTable">
      <GeneralContentText fontSize={10} fontWeight="bold">
        Total Contract Spending, by Facility
      </GeneralContentText>
      {Object.keys(totalSpendingObject).map((hospital: string) => (
        <TotalSpendingRow key={hospital}>
          <GeneralContentText>{hospital}</GeneralContentText>
          <GeneralContentText>
            {formatValue(Number(totalSpendingObject[hospital]), true)}
          </GeneralContentText>
        </TotalSpendingRow>
      ))}
      <TotalSpendingRow>
        <GeneralContentText fontWeight="bold">
          Total Contract Spending
        </GeneralContentText>
        <GeneralContentText fontWeight="bold">
          {formatValue(Number(totalSpending), true)}
        </GeneralContentText>
      </TotalSpendingRow>
    </OuterTotalSpending>
  );
}

interface TablePageProps {
  facName: string;
  typeName: string;
  expirationMessage: string;
  id: string;
  keys: any;
}

function TablePageHeaderSection({
  facName,
  typeName,
  expirationMessage,
  keys,
  id,
}: TablePageProps) {
  const LegendsLabels = ['Trauma', 'Expired', 'Multifacility'];
  const showLegendContainer = LegendsLabels.some((label: string) =>
    keys.includes(label),
  );
  return (
    <HeaderContainer id={id}>
      <GeneralTextContainer marginBottom={15}>
        <GeneralBlueText fontSize={10} fontWeight="bold">
          {`${facName}: ${typeName}`}
        </GeneralBlueText>
        <GeneralContentText>{expirationMessage}</GeneralContentText>
      </GeneralTextContainer>
      {showLegendContainer && (
        <LegendContainer>
          <GeneralBlueText fontSize={8} fontWeight="bold">
            Legend
          </GeneralBlueText>
          <LegendContentText fontSize={8}>†Trauma</LegendContentText>
          <LegendContentText fontSize={8}>*Multi-Facility</LegendContentText>
          <LegendContentText isExpired fontSize={8}>
            Expired
          </LegendContentText>
        </LegendContainer>
      )}
    </HeaderContainer>
  );
}

function ExecutiveSummary(data: any) {
  const { t } = useTranslation('executive-pdf');

  const PARENTPAGEPREFIX = 'analytics-parent-page';
  const PAGEPREFIX = 'analytics-page';

  const states: any = { ...data }.data;

  const docType: string = states?.docType;
  const facility: string = Object.keys(states)[0];
  const cuttDate: string = states?.cutoffdate;
  // constant measures
  const FACILITY_BAR_CHART_WIDTH = 200;
  const BAR_CHART_WIDTH = 200;

  // constant values for ticks
  const BAR_BUILD_VALUE = 4.5;
  const BAR_BUILD_SMALL_VALUE = 3.8;

  const FACILITY_DATA_SPENDING = states.ft;
  const TOTAL_SPENDING_PERCENTAGE = states?.totals_percent;

  // couple of graphs per benchmark
  const CALL_COVERAGE_PERCENTS = states?.cc ?? {};
  const CALL_COVERAGE_TOTALS = states?.totcc ?? {};

  const MEDICAL_DIRECTION_PERCENTS = states?.md ?? {};
  const MEDICAL_DIRECTION_TOTALS = states?.totmd ?? {};

  const OTHER_ADMIN_PERCENTAGE = states?.ad ?? {};
  const OTHER_ADMIN_TOTALS = states?.totad ?? {};

  const HOSPITAL_BASED_PERCENTAGES = states?.hb ?? {};
  const HOSPITAL_BASED_TOTALS = states?.tothb ?? {};

  const XX_PERCENTAGE = states?.xx ?? {};
  const XX_TOTALS = states?.totxx ?? {};

  const TABLE_DATA = states?.tables ?? [];

  const currentDate = format(new Date(), 'MM/dd/yyyy');

  const creationDate = new Date();
  const expirationDate = new Date(creationDate);
  expirationDate.setDate(expirationDate.getDate() + 1);

  const buildPage = (pageIndex: number) => (
    <PageParent id={`${PARENTPAGEPREFIX}-${pageIndex}`}>
      <ReportHeader title="" />
      <Page id={`${PAGEPREFIX}-${pageIndex}`} />
      <ReportFooter nowDate={currentDate} />
    </PageParent>
  );

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  let page = 0;

  const firstPageProps: FirstPageProps = {
    executiveSummary: t('executive_summary'),
    estimated: t('estimated'),
    physician: t('physician'),
    spending: t('spending'),
    customer: states?.customer,
    reportPrepared: t('report_prepared'),
    reportExpires: t('report_expires'),
    confidentialInfo: t('confidential_info'),
    id: buildId(),
    cuttDate,
  };

  if (docType !== 'SELECTALL') {
    firstPageProps.facility = facility;
  }

  const addFirstPage = async () => {
    page = await addChildtoParent(
      firstPageProps.id,
      FirstPage(firstPageProps),
      PAGEPREFIX,
      page,
      10,
    );
  };

  const secondPageProps: SecondPageProps = {
    dearSubscriber: t('dear_subscriber'),
    letterFirstParagraph: t('letter_first_paragraph'),
    letterSecondParagraph: t('letter_second_paragraph'),
    letterThirdParagraph: t('letter_third_paragraph'),
    letterFirstPoint: t('letter_first_point'),
    letterSecondPoint: t('letter_second_point'),
    letterThirdPoint: t('letter_third_point'),
    letterFourthPoint: t('letter_fourth_point'),
    letterFourthParagraph: t('letter_fourth_paragraph'),
    letterFifthParagraph: t('letter_fifth_paragraph'),
    sincerely: t('sincerely'),
    mdTeam: t('md_team'),
    id: buildId(),
  };

  const addSecondPage = async () => {
    page = await addChildtoParent(
      secondPageProps.id,
      SecondPage(secondPageProps),
      PAGEPREFIX,
      page,
    );
  };

  const thirdPageProps: ThirdPageProps = {
    explanation: t('explanation'),
    explanationFirst: t('explanation_first_paragraph'),
    explanationSecond: t('explanation_second_paragraph'),
    explanationThird: t('explanation_third_paragraph'),
    explanationFourth: t('explanation_fourth_paragraph'),
    explanationFifth: t('explanation_fifth_paragraph'),
    explanationSixth: t('explanation_sixth_paragraph'),
    explanationSeventh: t('explanation_seventh_paragraph'),
    notesFacility: t('notes_facility'),
    notesParagraph: t('notes_paragraph'),
    id: buildId(),
  };

  const addThirdPage = async () => {
    page = await addChildtoParent(
      thirdPageProps.id,
      ThirdPage(thirdPageProps),
      PAGEPREFIX,
      page,
    );
    page += 1;
  };

  const useAppendChildToElement = async (element: any) => {
    if (element !== null) {
      page = appendChildToElement(element, PAGEPREFIX, page);
    }
  };

  const addEstimadedTotalPage = async () => {
    if (docType !== 'SELECTALL') return null;

    const topTextId = buildId();
    page = addChildtoParent(
      topTextId,
      <GeneralTextContainer id={topTextId}>
        <GeneralContentText fontWeight="bold" fontSize={10}>
          Estimated Total Spending by Type of Contract
        </GeneralContentText>
        <GeneralContentText>
          This chart breaks contract spending into four broad categories:
        </GeneralContentText>
        {DottedLine('Emergency Call coverage')}
        {DottedLine('Medical Direction')}
        {DottedLine(
          'Administration (other than Medical Direction, e.g. Chief of Staff)',
        )}
        {DottedLine('Stipends for hospital-based services')}
        <GeneralContentText textAlign="justify">
          This division offers broad insight into the nature of contract
          payments. These totals do not include payments for clinical
          professional services or diagnostic testing. Collection or income
          guarantees also are not included; only actual amounts paid net of any
          professional fees are used in these estimates.
        </GeneralContentText>
      </GeneralTextContainer>,
      PAGEPREFIX,
      page,
    );

    const doughnutTotals = document.getElementById('totalsPercentage');
    if (doughnutTotals !== null) useAppendChildToElement(doughnutTotals);

    const totalBarChart = document.getElementById('totalsBarChart');
    if (totalBarChart !== null) useAppendChildToElement(totalBarChart);

    page += 1;
    return null;
  };

  const benchmarkName: { [id: string]: string } = {
    cc: 'Coverage',
    md: 'Medical Direction',
    ad: 'Other Administration',
    hb: 'Hospital-Based Stipends and Incentives',
  };

  const addTotalByFacility = async () => {
    if (docType !== 'SELECTALL') return null;

    page = addChildtoParent(
      'totalSpendingTable',
      <TotalSpendingTable
        totalSpendingObject={FACILITY_DATA_SPENDING.tot ?? {}}
        totalSpending={states?.totalspending ?? '0'}
      />,
      PAGEPREFIX,
      page,
    );
    Object.keys(FACILITY_DATA_SPENDING).map((hat: string) => {
      if (!Object.keys(benchmarkName).includes(hat)) return null;
      const chartId = `${hat}TotalsBarChart`;
      const chart = document.getElementById(chartId);
      useAppendChildToElement(chart ?? null);
      const chartCanva = ChartJS.getChart(`chart${chartId}`);

      chartCanva?.render();
      return null;
    });
    page += 1;
    return null;
  };

  const addGeneralCoupleGraphPage = (doughnutId: string, barId: string) => {
    const doughnutChart = document.getElementById(doughnutId);
    useAppendChildToElement(doughnutChart);

    const totalBarChart = document.getElementById(barId);
    useAppendChildToElement(totalBarChart);

    page += 1;
  };

  const formatRowValue = (
    value: string,
    key?: string,
    trauma?: number,
    _facility?: number,
  ) => {
    if (typeof value !== 'string') {
      return value;
    }

    let formattedValue = value;

    switch (key) {
      case 'Expires':
        if (value.includes('/') && value.split('/').length >= 3) {
          const [month, day, year] = value.split('/');
          formattedValue = `${month}/${day}/ ${year}`;
        }
        break;

      case 'Facility':
        switch (true) {
          case trauma === 1 && _facility === 1:
            return `${formattedValue} † *`;
          case _facility === 1:
            return `${formattedValue} *`;
          case trauma === 1:
            return `${formattedValue} †`;
          default:
            break;
        }
        break;
      default:
        return formattedValue;
    }

    if (formattedValue.length > 11 && formattedValue.includes('/')) {
      formattedValue = formattedValue.split('/').join('/ ');
    }

    return formattedValue;
  };

  const addTablePages = () =>
    TABLE_DATA.map((table: any) => {
      if (!Array.isArray(table?.rows)) return null;
      const headerKeys = Object.keys(
        table?.rows.reduce((a: any, b: any) =>
          Object.keys(a ?? {}).length > Object.keys(b ?? {}).length ? a : b,
        ),
      );

      const headerSectionId = buildId();
      const headerContent = (
        <TablePageHeaderSection
          facName={table?.facName}
          typeName={table?.typeName}
          expirationMessage={table?.expirationMessage}
          id={headerSectionId}
          keys={headerKeys}
        />
      );

      page = addChildtoParent(
        headerSectionId,
        headerContent,
        PAGEPREFIX,
        page,
        80,
      );

      const widerTileLabels = ['Service', 'Comparison Service'];

      const isTileWider = (key: string) => {
        switch (key) {
          case 'Facility':
            return 90;
          case 'Expires':
            return 40;
          default:
            return widerTileLabels.includes(key) ? 90 : 70;
        }
      };

      const excludedKeys = [
        'Trauma',
        'Multifacility',
        'Expired',
        'Comparison Service',
      ];
      const filteredHeaderKeys = headerKeys.filter(
        (key) => !excludedKeys.includes(key),
      );

      const headerRowId = buildId();
      const tableHeaders = (
        <Row id={headerRowId}>
          {filteredHeaderKeys.map((name: string) =>
            !name.includes('Range') ? (
              <Tile fontWeight={700} width={isTileWider(name)}>
                {name}
              </Tile>
            ) : null,
          )}
        </Row>
      );

      page = addChildtoParent(headerRowId, tableHeaders, PAGEPREFIX, page);

      table.rows.map((row: any) => {
        if (!row) return null;

        const rowId = buildId();
        const rowContent = (
          <Row id={rowId} borderBottom="solid">
            {filteredHeaderKeys.map((key: string) =>
              !key.includes('Range') ? (
                <VerticalTile width={isTileWider(key)}>
                  <Tile isExpired={row?.Expired === 1}>
                    {formatRowValue(
                      row?.[key],
                      key,
                      row.Trauma,
                      row.Multifacility,
                    ) ?? ''}
                  </Tile>
                  <Tile isRed={row?.[`${key} Range`] === '>90%'}>
                    <span>{row?.[`${key} Range`] ?? ''}</span>
                  </Tile>
                </VerticalTile>
              ) : null,
            )}
          </Row>
        );

        page = addChildtoParent(
          rowId,
          rowContent,
          PAGEPREFIX,
          page,
          0,
          tableHeaders,
        );
        return null;
      });

      page += 1;
      return null;
    });

  const buildContent = async () => {
    await addFirstPage();
    await addSecondPage();
    await addThirdPage();
    await addEstimadedTotalPage();
    await addTotalByFacility();
    await addGeneralCoupleGraphPage('ccPercentage', 'totccBarchart');
    await addGeneralCoupleGraphPage('mdPercentage', 'totmdBarChart');
    await addGeneralCoupleGraphPage('adPercentage', 'totadBarChart');
    await addGeneralCoupleGraphPage('hbPercentage', 'tothbBarChart');
    await addGeneralCoupleGraphPage('xxPercentage', 'totxxBarChart');
    await addTablePages();
  };

  const RANDOM_ID_DIV = 'teste';

  const removeNotUsedPage = () => {
    for (let i = 0; i < 200; i += 1) {
      const currentPage = document!.getElementById(`${PAGEPREFIX}-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`${PARENTPAGEPREFIX}-${i}`)?.remove();
      }
    }
    const element = document.getElementById(RANDOM_ID_DIV);
    // @ts-ignore
    element?.setAttribute('hidden', true);
  };

  useEffect(() => {
    buildContent().then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <>
      <Document>
        {/* create chart nodes in DOM before inserting into the document */}
        <div id={RANDOM_ID_DIV} />
        <div
          style={{
            height: '5000px',
            width: '100%',
            display: 'none',
            flexDirection: 'column',
          }}
        >
          {/* total spending charts */}
          <BuildDoughnutChart
            id="totalsPercentage"
            dataObject={TOTAL_SPENDING_PERCENTAGE}
          />
          {FACILITY_DATA_SPENDING?.tot ? (
            <BuildBarChart
              id="totalsBarChart"
              dataObject={FACILITY_DATA_SPENDING?.tot} // total spending in percentage
              title="Total Estimated Physician Contract Spending"
              barWidth={FACILITY_BAR_CHART_WIDTH}
              rowWidth="100%"
              justifyChart="left"
              minHeight={80}
              buildValue={BAR_BUILD_SMALL_VALUE}
            />
          ) : null}

          {/* Bar charts for each facility spensings */}
          {Object.keys(FACILITY_DATA_SPENDING).map(
            (hat: string) =>
              hat !== 'tot' && (
                <BuildBarChart
                  id={`${hat}TotalsBarChart`}
                  dataObject={FACILITY_DATA_SPENDING[hat]}
                  title={`Contract Spending: ${benchmarkName[hat] ?? '"le"'}`}
                  barWidth={FACILITY_BAR_CHART_WIDTH}
                  rowWidth="100%"
                  justifyChart="left"
                  minHeight={50}
                  buildValue={BAR_BUILD_SMALL_VALUE}
                />
              ),
          )}

          <BuildDoughnutChart
            id="ccPercentage"
            dataObject={CALL_COVERAGE_PERCENTS}
            title="Call Coverage"
          />
          <BuildBarChart
            id="totccBarchart"
            dataObject={CALL_COVERAGE_TOTALS}
            justifyChart="center"
            barWidth={BAR_CHART_WIDTH}
            buildValue={BAR_BUILD_VALUE}
          />

          <BuildDoughnutChart
            id="mdPercentage"
            dataObject={MEDICAL_DIRECTION_PERCENTS}
            title="Medical Direction"
          />
          <BuildBarChart
            id="totmdBarChart"
            dataObject={MEDICAL_DIRECTION_TOTALS}
            justifyChart="left"
            barWidth={BAR_CHART_WIDTH}
            buildValue={BAR_BUILD_VALUE}
          />

          <BuildDoughnutChart
            id="adPercentage"
            dataObject={OTHER_ADMIN_PERCENTAGE}
            title="Administrative Services"
          />
          <BuildBarChart
            id="totadBarChart"
            dataObject={OTHER_ADMIN_TOTALS}
            justifyChart="left"
            barWidth={BAR_CHART_WIDTH}
            buildValue={BAR_BUILD_VALUE}
          />

          <BuildDoughnutChart
            id="hbPercentage"
            dataObject={HOSPITAL_BASED_PERCENTAGES}
            title="Hospital-Based Services"
          />
          <BuildBarChart
            id="tothbBarChart"
            dataObject={HOSPITAL_BASED_TOTALS}
            justifyChart="center"
            barWidth={BAR_CHART_WIDTH}
            buildValue={BAR_BUILD_VALUE}
          />
          <BuildDoughnutChart
            id="xxPercentage"
            dataObject={XX_PERCENTAGE}
            title="Spending by Medical Specialty"
          />
          <BuildBarChart
            id="totxxBarChart"
            dataObject={XX_TOTALS}
            justifyChart="left"
            barWidth={BAR_CHART_WIDTH}
            buildValue={BAR_BUILD_VALUE}
          />
        </div>
      </Document>
      <Document id="documentBody">
        {Array(200)
          .fill('')
          .map((_, index) => buildPage(index))}
      </Document>
    </>
  );
}

export default ExecutiveSummary;
