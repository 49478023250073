import React, { useState, useEffect } from 'react';
import Analytics from '../analytics';

import SingleContractTemplate from '../../components/templates/Reports/SingleContract';

function SingleContractPDF() {
  const [pdfData, setPdfData] = useState<any>();

  const getPdfData = async () => {
    const searchUrl = window.location.search;
    const urlParams = new URLSearchParams(searchUrl);
    const lookback = urlParams.get('lookback');
    const hsid = urlParams.get('hsid') ?? '';
    const mdrId = urlParams.get('mdr_id') ?? 'SELECTALL';

    const response = await Analytics.getSingleContract(
      lookback ?? 12,
      hsid ?? '',
      mdrId,
    );

    if (!response.failed) {
      setPdfData(response.data);
    }
  };
  useEffect(() => {
    getPdfData().then(null);
  }, []);

  return pdfData ? <SingleContractTemplate data={pdfData} /> : null;
}

export default SingleContractPDF;
