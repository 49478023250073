/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TotalCompensation from 'src/services/totalCompensation';
import {
  Container,
  PaymentFieldsContainer,
  Row,
  IconsHolder,
  EditIcon,
  DeleteIcon,
} from './style';
import PlusButton from '../../atoms/PlusButton';
import Input from '../Input';
import Loader from '../../atoms/DotLoader';
import ComparisonIncrementalInputInterface from './interface';
import TotalCompensationCoverageModal from '../../organisms/TotalCompensationCoverageModal';
import coveragePerDiem from '../../../contracts/models/coveragePerDiem.model';
import ConditionalPercentileIcon from '../ConditionalPercentileIcon';

function CompensationCoveragePerDiemInputs({
  buttonPlaceholder,
  inputType,
  width,
  sumCallback,
  setCallDays,
  hcArrayCallback,
  serviceArrayCallback,
  traumaArrayCallback,
  pageService,
  pagePhysicianType,
  pageRegion,
}: ComparisonIncrementalInputInterface) {
  const { t } = useTranslation('total-compensation');

  interface Response {
    loading: boolean;
    color?: string | undefined;
    message?: string;
    errorMessage?: string;
    light?: string | undefined;
  }

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [inputResults, setInputResults] = useState<coveragePerDiem[]>([]);
  const [inputEdit, setInputEdit] = useState<coveragePerDiem | undefined>();
  const [editingIndex, setEditingIndex] = useState<number | undefined>();
  const [responseSignals, setResponseSignals] = useState<Response[]>([]);

  useEffect(() => {
    let annualSum = 0;
    let callDaysSum = 0;
    const tempHCArray: number[] = [];
    const tempServiceArray: string[] = [];
    const tempTraumaArray: Array<'1' | '0'> = [];
    inputResults.map(
      (
        item: coveragePerDiem,
        // eslint-disable-next-line array-callback-return
      ) => {
        const { coverageResult, unpaidCallDays, callDays } = item;

        annualSum += Number(coverageResult);
        callDaysSum += unpaidCallDays
          ? Number(unpaidCallDays)
          : Number(callDays);
        if (item?.perDiemRate) {
          tempHCArray.push(item?.perDiemRate!);
          tempServiceArray.push(item?.service!);
          tempTraumaArray.push(item?.trauma!);
        }
      },
    );
    sumCallback(annualSum);
    setCallDays(callDaysSum);
    hcArrayCallback(tempHCArray);
    serviceArrayCallback(tempServiceArray);
    traumaArrayCallback(tempTraumaArray);
  }, [inputResults]);

  useEffect(() => {
    setInputResults([]);
  }, [pageService, pageRegion, pagePhysicianType]);

  const editResponseItem = (newItem: Response, index: number) => {
    const aux = responseSignals;
    aux.splice(index, 1, newItem);
    setResponseSignals([...aux]);
  };
  const getBaseCompensation = async (
    serviceCall: string[],
    value: number[],
    index: number,
    trauma: '1' | '0',
  ) => {
    const response = await TotalCompensation.getBaseCompensation(
      pageService!,
      { HC: value },
      pageRegion,
      pagePhysicianType,
      serviceCall,
      trauma ?? '0',
    );

    if (response.failed) {
      return editResponseItem({ loading: false }, index);
    }
    const hcLight = response.data?.HC?.[0];

    const signalBody: Response = { loading: false, light: hcLight };

    return editResponseItem(signalBody, index);
  };

  const handlePlusButton = (status: boolean) => {
    setModalOpen(status);
  };

  const addArrayElement = (element: coveragePerDiem) => {
    const currentLen = inputResults.length;
    setInputResults((prev) => [...prev, element]);
    setResponseSignals((prev) => [...prev, { loading: true }]);
    getBaseCompensation(
      [element.service!],
      [element.perDiemRate!],
      currentLen,
      element.trauma!,
    ).then(null);
  };

  const editArrayElement = (editBody: coveragePerDiem, index: number) => {
    const editingArray = inputResults;
    editingArray.splice(index, 1, editBody);
    setInputResults([...editingArray]);
    editResponseItem({ loading: true }, index);
    getBaseCompensation(
      [editBody.service!],
      [editBody.perDiemRate!],
      index,
      editBody.trauma!,
    ).then(null);
  };

  const handleModalClose = (modalReturn: coveragePerDiem) => {
    // If the editing button was pressed, so editing and already result
    if (editingIndex !== undefined) {
      editArrayElement(modalReturn, editingIndex);
      setInputEdit(undefined);
      return setEditingIndex(undefined);
    }
    // adding a new element
    if (modalReturn.service) {
      return addArrayElement(modalReturn);
    }
    return null;
  };

  const deleteFields = (index: number) => {
    const deletingInputResult = inputResults;
    deletingInputResult.splice(index, 1);
    setInputResults([...deletingInputResult]);

    const deletingResponseResult = responseSignals;
    deletingResponseResult.splice(index, 1);
    setResponseSignals([...deletingResponseResult]);
  };

  const handleInputValue = (value: number, index: number) => {
    const coverageAux = inputResults[index];

    Object.assign(coverageAux, {
      weekday: 0,
      weekend: 0,
      holiday: 0,
      callDays: 0,
      weeklyRate: 0,
      monthlyRate: 0,
      perDiemRate: 0,
      annualRate: value,
      coverageResult: value,
    });

    editArrayElement(coverageAux, index);
  };

  function ShowResponseIcon(index: number) {
    return (
      <>
        {responseSignals[index]?.loading && <Loader />}
        {responseSignals[index]?.light && !responseSignals[index]?.loading ? (
          <ConditionalPercentileIcon
            percentileIndicator={responseSignals[index].light}
          />
        ) : null}
      </>
    );
  }

  function ShowUnpaidCallDays(unpaidValue?: number, service?: string) {
    return unpaidValue ? (
      <Row>
        <Input
          title={t('unpaid_days')}
          subtitle={service}
          value={unpaidValue}
          type="number"
          callback={() => null}
          width={width}
          disabled
        />
      </Row>
    ) : null;
  }

  function RenderUpperFields(modalFields: coveragePerDiem, index: number) {
    const {
      service,
      callDays,
      weeklyRate,
      monthlyRate,
      perDiemRate,
      unpaidCallDays,
    } = modalFields;
    return perDiemRate ? (
      <>
        <Row loading={responseSignals[index]?.loading}>
          <Input
            title={t('add_ed_coverage_per_diem_subtitle')}
            subtitle={service}
            value={perDiemRate}
            type="currency"
            callback={() => null}
            width={width}
            disabled
          />
          {ShowResponseIcon(index)}
        </Row>
        {callDays ? (
          <Row>
            <Input
              title={t('call_days')}
              subtitle={service}
              value={callDays}
              type="number"
              callback={() => null}
              width={width}
              disabled
            />
          </Row>
        ) : null}
        {weeklyRate! > 0 && monthlyRate === 0 ? (
          <Row>
            <Input
              title={t('weekly_ed_call_coverage')}
              subtitle={service}
              value={weeklyRate}
              type="currency"
              callback={() => null}
              width={width}
              disabled
            />
          </Row>
        ) : null}
        {monthlyRate! > 0 && weeklyRate === 0 ? (
          <Row>
            <Input
              title={t('monthly_ed_call_coverage')}
              subtitle={service}
              value={monthlyRate}
              type="currency"
              callback={() => null}
              width={width}
              disabled
            />
          </Row>
        ) : null}
      </>
    ) : (
      ShowUnpaidCallDays(unpaidCallDays, service)
    );
  }

  return (
    <Container>
      <TotalCompensationCoverageModal
        isOpen={isModalOpen}
        onHandle={setModalOpen}
        onClose={handleModalClose}
        customCoveragePerDiemFields={inputEdit}
        pageService={pageService!}
      />
      <PlusButton
        placeholder={buttonPlaceholder}
        callback={handlePlusButton}
        toggleable={false}
        bind={false}
      />
      <div>
        {inputResults.map((item: any, index: number) => (
          <PaymentFieldsContainer>
            <IconsHolder>
              <EditIcon
                onClick={() => {
                  setInputEdit(item);
                  setEditingIndex(index);
                  setModalOpen(true);
                }}
              />
              <DeleteIcon onClick={() => deleteFields(index)} />
            </IconsHolder>
            {RenderUpperFields(item, index)}
            {item.coverageResult ? (
              <Row
                loading={responseSignals[index]?.loading}
                key={`callCoverageResult-${item.service}`}
              >
                <Input
                  title={t('annual_ed_call_coverage')}
                  subtitle={item.service}
                  value={item.coverageResult}
                  callback={(value: number) => {
                    if (Number(value) !== item.coverageResult) {
                      handleInputValue(Number(value), index);
                    }
                  }}
                  type={inputType}
                  width={width}
                />
              </Row>
            ) : null}
          </PaymentFieldsContainer>
        ))}
      </div>
    </Container>
  );
}

export default CompensationCoveragePerDiemInputs;
