/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import TotalCompensation from 'src/services/totalCompensation';
import Title from '../../atoms/Title';
import { Container } from './style';
import Loader from '../../atoms/DotLoader';
import CompensationSingleReportFieldInterface from './interface';
import ConditionalPercentileIcon from '../ConditionalPercentileIcon';
import LightColors from '../../../constants/lightColors';
import HelpTooltip from '../../atoms/HelpTooltip';

function CompensationSingleReportField({
  result,
  text,
  hasCustomResult,
  customResult,
  service,
  param,
  responseValue,
  region,
  physicianType,
  fontSize,
  fontStyle,
  tooltip,
  extraFields,
  notDollar,
}: CompensationSingleReportFieldInterface) {
  const [loading, setLoading] = useState<boolean>(false);
  const [shownLight, setShownLight] = useState<string | undefined>();
  const NONELIGHT = LightColors.none;

  const getTotalCompensation = async (
    value: string,
    signal: AbortController,
  ) => {
    setLoading(true);

    const response = await TotalCompensation.getBaseCompensation(
      service ?? '',
      {
        [param!]: value,
        ...extraFields,
      },
      region,
      physicianType,
      undefined,
      undefined,
      signal,
    );

    if (response.failed) {
      setLoading(false);
      return setShownLight(NONELIGHT);
    }
    const responseColor: LightColors =
      response.data?.[responseValue!] ?? NONELIGHT;

    setShownLight(responseColor);

    return setLoading(false);
  };

  useEffect(() => {
    if (result === undefined || !service || !hasCustomResult) {
      return setLoading(false);
    }
    if (!Number(result)) return setLoading(false);
    const controller = new AbortController();
    getTotalCompensation(result, controller).then(null);

    return () => {
      controller.abort();
    };
  }, [result, region, physicianType]);

  const formatValue = (value: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const formated = formatter.format(value);

    if (notDollar) return formated;

    return `$${formated}`;
  };

  return (
    <Container
      fontSize={fontSize}
      fontStyle={fontStyle}
      tooltip={Boolean(tooltip && tooltip !== '')}
    >
      <Title text={text} />
      {result && <span>{formatValue(Number(result))}</span>}
      {tooltip && tooltip !== '' && <HelpTooltip text={tooltip} />}
      {loading && hasCustomResult && result && <Loader />}
      {shownLight && hasCustomResult && !customResult && result && !loading ? (
        <ConditionalPercentileIcon percentileIndicator={shownLight} />
      ) : (
        <span>{customResult}</span>
      )}
    </Container>
  );
}

export default CompensationSingleReportField;
