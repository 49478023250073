import React from 'react';
import {
  GeneralTextContainer,
  GeneralContentText,
  GeneralBlueText,
} from '../style';

export interface ThirdPageProps {
  explanation: string;
  explanationFirst: string;
  explanationSecond: string;
  explanationThird: string;
  explanationFourth: string;
  explanationFifth: string;
  explanationSixth: string;
  explanationSeventh: string;
  notesFacility: string;
  notesParagraph: string;
  id: string;
}

function ThirdPage(pageProps: ThirdPageProps) {
  const {
    explanation,
    explanationFirst,
    explanationSecond,
    explanationThird,
    explanationFourth,
    explanationFifth,
    explanationSixth,
    explanationSeventh,
    notesFacility,
    notesParagraph,
    id,
  } = pageProps;

  return (
    <div id={id} className="explanationPage">
      <GeneralBlueText fontSize={16} fontWeight="bold">
        {explanation}
      </GeneralBlueText>
      <GeneralTextContainer>
        <GeneralContentText>{explanationFirst}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <GeneralContentText>{explanationSecond}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <GeneralContentText>{explanationThird}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <GeneralContentText>{explanationFourth}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <GeneralContentText>{explanationFifth}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <GeneralContentText>{explanationSixth}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <GeneralContentText>{explanationSeventh}</GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <GeneralContentText fontWeight="bold" fontSize={9}>
          {notesFacility}
        </GeneralContentText>
      </GeneralTextContainer>
      <GeneralTextContainer marginTop={12}>
        <GeneralContentText>{notesParagraph}</GeneralContentText>
      </GeneralTextContainer>
    </div>
  );
}

export default ThirdPage;
