import styled from 'styled-components';

export const ContainerNon = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
`;

export const NonBenchTitle = styled.span`
  font-size: 10px;
  margin-bottom: 3px;
  color: var(--ecg-royal-blue);
  font-weight: 400;
  letter-spacing: 0.2px;
`;

export const ComparisonService = styled.em`
  font-size: 7px;
  margin-bottom: 3px;
`;

export const BenchmarkService = styled.span`
  font-size: 8px;
  margin-bottom: 3px;
  color: var(--ecg-royal-blue);
`;
export const Information = styled.span`
  font-size: 8px;
  text-align: start;
  margin-bottom: 3px;
  white-space: nowrap;
`;

export const Line = styled.div`
  display: flex;
  height: 0.5px !important;
  margin-left: 30px;
  background-color: var(--secondary-light-gray);
`;
