import React from 'react';
import ThoroughTextInterface from './interface';
import { StaticText, LinkedText } from './style';

function ThoroughText({ link, text }: ThoroughTextInterface) {
  const openLink = () => window.open(link);

  return link ? (
    <LinkedText onClick={openLink}>{text}</LinkedText>
  ) : (
    <StaticText>{text}</StaticText>
  );
}

export default ThoroughText;
