import React from 'react';
import { Circle, Label, Legends, Subtitle } from './style';
import CircleTicksInterface from './interface';

function CircleTicks({
  id,
  colors,
  labels,
  fontSize,
  size,
}: CircleTicksInterface) {
  return (
    <Legends id={id}>
      {labels.map((label, index) => (
        <Subtitle key={label}>
          <Circle color={colors[index]} size={size} />
          <Label fontSize={fontSize}>{label}</Label>
        </Subtitle>
      ))}
    </Legends>
  );
}

export default CircleTicks;
