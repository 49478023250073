import Api from './api';

export default class FacTotals {
  static async getFacTotals() {
    return Promise.resolve(
      Api.get('/api/factotals')
        .then((response) => ({
          failed: false,
          data: response.data,
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getFacTotalsPdf() {
    return Promise.resolve(
      Api.get('/api/factotals-pdf')
        .then((response) => ({
          failed: false,
          data: response.data,
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
}
