import styled from 'styled-components';

interface ChartContainerInterface {
  width?: number;
  marginRight?: string | 'auto';
  numLabels: number;
  minHeight?: number;
}

interface TicksRowInterface {
  rowWidth?: string;
}

export const OuterContainer = styled.div<ChartContainerInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  width: 100%;
  height: calc(
    ${(props) => props.minHeight ?? 100}px +
      ${(props) => props.numLabels * 12}px
  );
  position: relative;
`;

export const TicksContainer = styled.div`
  flex: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  min-height: 90px;
  height: 100%;
  padding-bottom: 20px;
  overflow: visible;
`;

export const TicksRow = styled.div<TicksRowInterface>`
  display: flex;
  width: ${(props) => props.rowWidth};
`;

export const TickLabel = styled.span`
  font-size: 7px;
  line-height: 7px;
  overflow: visible;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: center;

  &:first-child {
    text-wrap: balance;
    height: 7px;
    text-align: start;
  }

  &:nth-child(1) {
    flex: 1;
  }

  &:nth-child(2) {
    flex-grow: 0;
    justify-content: flex-end;
    max-width: 60px;
  }

  &:last-child {
    width: 30px;
    flex-grow: 0;
    justify-content: flex-end;
  }
`;

export const ChartContainer = styled.div<ChartContainerInterface>`
  flex: 70%;
  display: flex;
  align-items: center;
  margin-right: ${(props) => props.marginRight};
  justify-self: center;
  width: ${((props) => `${props.width}px`) ?? '100%'};
  min-height: ${((props) => props.minHeight) ?? 100}px;
  height: calc(
    ${(props) => props.minHeight ?? 100}px +
      ${(props) => props.numLabels * 12}px
  );
  overflow: visible;
  position: relative;
  flex-direction: column;

  > .bottomText {
    text-align: center;
  }
`;
