import styled from 'styled-components';

export const LinkContainer = styled.a`
  color: #2451cb;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const Image = styled.img`
  border: 1px solid black;
  height: 243px;
  background-color: gray;
`;
