const MDparams = {
  DIGNITY: 'dignity',
  EXECSUM: 'execsum',
  HSID: 'hsid',
  LOOKBACK: 'lookback',
  MDR_ID: 'mdr_id',
  REGIONS: 'regions',
  ROUTE: 'route',
  SELECTALL: 'SELECTALL',
  SVCS: 'svcs',
  TOKEN: 'token',
  CALLDAYS: 'callDays',
  TITLE: 'title',
  CUSTNAME: 'custname',
  HFLAGS: 'hflags',
  DBIDENT: 'dbident',
};

export default MDparams;
