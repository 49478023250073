import React, { useState } from 'react';
import {
  TableContainer,
  BenchMarkTitle,
  TableWrapper,
  Table,
  TableHeader,
  Characteristic,
  TableRow,
  TableCell,
  AngleIcon,
} from './style';
import FacilityTotalTableInterface from './interface';

function FacilityTotalTable({
  benchName,
  benchData,
}: FacilityTotalTableInterface) {
  const [isTableOpen, setIsTableOpen] = useState<boolean>(true);

  return (
    <TableContainer>
      <BenchMarkTitle onClick={() => setIsTableOpen((prev) => !prev)}>
        <AngleIcon isOpen={isTableOpen} />
        {benchName}
      </BenchMarkTitle>
      <TableWrapper isOpen={isTableOpen}>
        <Table>
          <thead>
            <tr>
              <TableHeader textAlign="left">Characteristc</TableHeader>
              <TableHeader textAlign="center">Providers</TableHeader>
              <TableHeader textAlign="center">Facilities</TableHeader>
              <TableHeader textAlign="right">Mean</TableHeader>
              <TableHeader textAlign="right">25%</TableHeader>
              <TableHeader textAlign="right">50%</TableHeader>
              <TableHeader textAlign="right">75%</TableHeader>
              <TableHeader textAlign="right" style={{ borderRight: 'none' }}>
                90%
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {Object.entries(benchData).map(([characteristic, rows]) => (
              <>
                <Characteristic>
                  <TableCell textAlign="left">{characteristic}</TableCell>
                </Characteristic>
                {(rows as any[]).map((row: any, index: number) => (
                  <TableRow isLast={index === (rows as any[]).length - 1}>
                    <TableCell textAlign="left">{row.slice}</TableCell>
                    <TableCell textAlign="center">{row.providers}</TableCell>
                    <TableCell textAlign="center">{row.facilities}</TableCell>
                    <TableCell textAlign="right">{row.mean}</TableCell>
                    <TableCell textAlign="right">{row.q25}</TableCell>
                    <TableCell textAlign="right">{row.q50}</TableCell>
                    <TableCell textAlign="right">{row.q75}</TableCell>
                    <TableCell textAlign="right">{row.q90}</TableCell>
                  </TableRow>
                ))}
              </>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </TableContainer>
  );
}

export default FacilityTotalTable;
