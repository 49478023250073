import styled from 'styled-components';

interface CircleInterface {
  color: string;
  size?: string;
}

interface CommonInterface {
  fontSize?: string;
  width?: string;
  height?: string;
  $paddingTop?: string;
  paddingLeft?: string;
}

export const Table = styled.div<CommonInterface>`
  background-color: #f7f7f9;
  border-radius: 4px;
  border: 1px solid #e3e4e6;
  width: ${(props) => props.width}; !important;
  color: var(--border-black);
`;

export const TableHeaderInfo = styled.div<CommonInterface>`
  width: ${(props) => props.width}; !important;
  height: ${(props) => props.height};
`;

export const TableHeaderColumn = styled.div<CommonInterface>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.fontSize || '16px'};
`;

export const TableHeader = styled.div`
  width: 100%;
`;

export const Circle = styled.div<CircleInterface>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;

export const TableInfo = styled.div<CommonInterface>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${(props) => props.paddingLeft};
  gap: 4px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-top: 1px solid #e3e4e6;
  border-right: 1px solid #e3e4e6;

  color: #151624;
  font-size: ${(props) => props.fontSize};
  font-weight: 400;
  line-height: 20px;
`;

export const TableData = styled.div<CommonInterface>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-top: 1px solid #e3e4e6;

  background-color: #ffffff;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${(props) => props.$paddingTop};
  justify-content: flex-start;
  font-size: ${(props) => props.fontSize};
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DataComparison = styled.span<CommonInterface>`
  font-size: ${(props) => props.fontSize};
  font-weight: 400;
  letter-spacing: -0.1px;
  color: #005887;
  font-style: italic;
`;
