import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  > :first-child {
    color: var(--hoover-blue);
    font-size: 10px;
    padding-top: 25px;
    padding-bottom: 5px;
  }
`;

export const Footnote = styled.span`
  font-size: 6px;
  color: var(--hoover-blue);
  font-style: italic;
`;
