/* eslint-disable camelcase */
import Api from './api';
import keysToCamel from '../utils/keysToCamelCase';

export default class FacilityPage {
  static async getFacilityData(mdr_id?: string) {
    return Promise.resolve(
      Api.get('/api/facility', {
        params: {
          mdr_id,
        },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async updateFacilityData(mdr_id: string, paramsBody: FormData) {
    return Promise.resolve(
      Api.post(`/api/facility?mdr_id=${mdr_id}`, paramsBody, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
}
