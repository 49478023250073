import React from 'react';
import LinkInterface from './interface';
import { LinkContainer, Image } from './style';

function Link({ href, text, imageSrc, imageWidth, altText }: LinkInterface) {
  return (
    <LinkContainer href={href} target="_blank" rel="noopener noreferrer">
      {imageSrc ? (
        <Image src={imageSrc} width={imageWidth} alt={altText} />
      ) : (
        text
      )}
    </LinkContainer>
  );
}

export default Link;
