import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, MessageContainer } from './style';
import ConditionalPercentileIconInterface from './interface';
import getComparisonConditionalMessage from '../../../../utils/getComparisonConditionalMessage';

function ConditionalPercentileIconDignity({
  percentileIndicator,
}: ConditionalPercentileIconInterface) {
  const { t } = useTranslation('input-results');

  const [message, setMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [color, setColor] = useState<string | undefined>('default');

  const iconColors: { [key: string]: string } = {
    90: '#ff4644',
    default: '#242424',
  };

  const getLightMessage = (percentileResponse: string) => {
    if (!percentileResponse) return setErrorMessage(t('not_found_benchmark'));

    const signal = percentileResponse.charAt(0);
    const percentileSplit = percentileResponse.split(signal)[1];
    const conditionalMessage = getComparisonConditionalMessage(signal!);

    if (!conditionalMessage) {
      setMessage('');
      setColor(undefined);
      return setErrorMessage(t('not_found_benchmark'));
    }

    setErrorMessage('');
    setMessage(t(conditionalMessage, { percentile: percentileSplit }));
    return setColor(percentileSplit === '90' ? '90' : 'default');
  };

  useEffect(() => {
    getLightMessage(percentileIndicator ?? '');
  }, [percentileIndicator]);

  return (
    <Container>
      {message && !errorMessage ? (
        <MessageContainer color={iconColors[color ?? 'default']}>
          {message}
          <sup>TH</sup>
          PERCENTILE
        </MessageContainer>
      ) : (
        <span>{errorMessage}</span>
      )}
    </Container>
  );
}

export default ConditionalPercentileIconDignity;
