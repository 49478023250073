/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { format } from 'date-fns';
import PdfCharts from 'src/components/molecules/PdfCharts';
import addChildtoParent from 'src/utils/addChildToParent';
import ReportFooter from 'src/components/organisms/Reports/Footer';
import ReportHeader from 'src/components/organisms/Reports/Header';
import CompensationNonBenchmark from 'src/components/molecules/CompensationNonBenchmark';
import {
  Information,
  InformationColumn,
  InformationText,
  BlueInformationText,
  InformationItalicText,
  JustifyInformationRow,
  Line,
  ApprovedByContainer,
  BottomTextContainer,
  ApprovedByLine,
  ApprovedByText,
  Document,
  PageParent,
  Page,
  TCRTitle,
  DetailsInformationColumn,
} from './style';

function TotalCompensationTemplate(data: any) {
  const states: any = { ...data }.data;

  const currentDate = format(new Date(), 'MM/dd/yyyy');

  const benchmarksToShow = states?.benchmarks?.filter(
    (benchmark: any) =>
      benchmark?.shortname in states.benchmarkValues ||
      benchmark?.hat in states.benchmarkValues,
  );

  const nonBenchmarksToShow = states?.benchmarks?.filter(
    (benchmark: any) =>
      benchmark?.hat in states.benchmarkValues && !benchmark?.mean,
  );

  const separateBenchsToShow = () => {
    const totalToShow = [...benchmarksToShow];
    const totalToNotShow = [...nonBenchmarksToShow];

    const profIndex = totalToShow
      .map((benchs: any) => benchs.shortname)
      .indexOf('profCollections');
    const workRVUIndex = totalToShow
      .map((benchs: any) => benchs.shortname)
      .indexOf('workRVUs');
    const totalRVUIndex = totalToShow
      .map((benchs: any) => benchs.shortname)
      .indexOf('totalRVUs');

    const indexes = [profIndex, workRVUIndex, totalRVUIndex];
    const finalIndex = indexes.find((value: number) => value !== -1);

    const firstSection = totalToShow.slice(0, finalIndex);
    const secondSection = finalIndex ? totalToShow.slice(finalIndex) : [];
    return { firstSection, secondSection, totalToNotShow };
  };

  const { firstSection, secondSection } = separateBenchsToShow();

  // making the graph show the adjusted value when present
  const filterBenchValues = () => {
    const benchValuesToUse = { ...states?.benchmarkValues } ?? {};

    // Index the key's value using the adjusted value, and ensure its accuracy whenever it changes
    if (benchValuesToUse.totalCompAdjusted) {
      benchValuesToUse.totalComp = benchValuesToUse.totalCompAdjusted;
    }

    if (benchValuesToUse.workRVUsAdjusted) {
      benchValuesToUse.workRVUs = benchValuesToUse.workRVUsAdjusted;
    }

    if (benchValuesToUse.totalRVUsAdjusted) {
      benchValuesToUse.totalRVUs = benchValuesToUse.totalRVUsAdjusted;
    }

    if (benchValuesToUse.profCollectionsAdjusted) {
      benchValuesToUse.profCollections =
        benchValuesToUse.profCollectionsAdjusted;
    }

    if (benchValuesToUse.totalClinicalComp) {
      benchValuesToUse.clinicalComp = benchValuesToUse.totalClinicalComp;
    }

    if (benchValuesToUse.totalClinicalCompAdjusted) {
      benchValuesToUse.clinicalComp =
        benchValuesToUse.totalClinicalCompAdjusted;
    }

    return benchValuesToUse;
  };

  const percentPayingArray = ['HC'];
  const administrativeHats = ['HH', 'HR', 'HD'];

  states?.benchmarks?.map((benchmark: any) => {
    if (
      administrativeHats.includes(benchmark.hat) &&
      percentPayingArray.length < 2
    ) {
      percentPayingArray.push(benchmark.hat);
    }
    return null;
  });

  const buildPage = (pageIndex: number) => (
    <PageParent id={`tcr-parent-page-${pageIndex}`}>
      <ReportHeader title={states.service} />
      <Page id={`tcr-page-${pageIndex}`} />
      <ReportFooter nowDate={currentDate} datayear={states.datayear} />
    </PageParent>
  );

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  const getMarginTop = () => 30;

  function ChartPicker(benchmark: any, chartId: string) {
    const { hat, searchService, shortname } = benchmark;

    if (hat) {
      return states?.benchmarkValues[hat].map(
        (benchValue: any) =>
          benchValue.service === searchService && (
            <PdfCharts
              benchmarkObject={benchmark}
              service={states?.service}
              benchValue={benchValue.value}
              marginTop={getMarginTop()}
              chartId={chartId}
              percentPayingHats={percentPayingArray}
            />
          ),
      );
    }
    return (
      <PdfCharts
        benchmarkObject={benchmark}
        service={states?.service}
        benchValue={filterBenchValues()[shortname]}
        marginTop={getMarginTop()}
        chartId={chartId}
        percentPayingHats={percentPayingArray}
      />
    );
  }

  const nonBenchPicker = (benchmark: any, benchId: string) => {
    const compNonBench = (benchValue: any) =>
      benchValue.service === benchmark.searchService && (
        <CompensationNonBenchmark
          benchmarkId={benchId}
          benchmarkObject={benchmark}
          service={states?.service}
          benchmarkTitle={benchmark.hat}
          benchValue={benchValue.value}
        />
      );
    return states?.benchmarkValues[benchmark.hat].map(compNonBench);
  };

  function NotDignityInformation() {
    return (
      <Information padding="10px 30px 0">
        <InformationColumn>
          <BlueInformationText fontSize={10}>
            {states?.title ?? states?.service}
          </BlueInformationText>
          {states?.counterparty && (
            <BlueInformationText>
              {`Counterparty: ${states?.counterparty ?? ''}`}
            </BlueInformationText>
          )}
          {(states?.start || states?.end) && (
            <BlueInformationText>
              {`Effective dates: ${format(
                new Date(states.start),
                'MMMM, dd, yyyy',
              )} – ${format(new Date(states.end), 'MMMM, dd, yyyy')}`}
            </BlueInformationText>
          )}
          {states?.facility && (
            <BlueInformationText>
              {`Facility: ${states?.facility ?? ''}`}
            </BlueInformationText>
          )}
          {states?.experienceYears && (
            <BlueInformationText>
              {`Years of experience: ${states?.experienceYears ?? ''}`}
            </BlueInformationText>
          )}
        </InformationColumn>
      </Information>
    );
  }

  function DignityInformation() {
    return (
      <Information padding="10px 30px">
        <InformationColumn>
          {states?.title && (
            <BlueInformationText>
              {`Title: ${states?.title ?? ''}`}
            </BlueInformationText>
          )}
          {states?.region && (
            <BlueInformationText>
              {`Region: ${states?.region ?? ''}`}
            </BlueInformationText>
          )}
          {states?.hospname && (
            <BlueInformationText>
              {`Hospital: ${states?.hospname}`}
            </BlueInformationText>
          )}
          <BlueInformationText>
            {`Previously approved: ${
              states?.prevappy === 'true' ? 'Yes' : 'No'
            }`}
          </BlueInformationText>
          {states?.prevappy === 'true' && (
            <BlueInformationText>
              {`Related Matter ID: ${states?.prevappid ?? ''}`}
            </BlueInformationText>
          )}
        </InformationColumn>
      </Information>
    );
  }

  const renderInformationRow = (
    label: string,
    value: string,
    c?: string,
    italic?: boolean,
  ) =>
    value && value !== '0' ? (
      <JustifyInformationRow>
        {
          <InformationText textstyle={italic ? 'italic' : 'normal'}>
            {value !== '' ? label : ' '}
          </InformationText> /* make Number cast be a blank line when api returns empty string */
        }
        <InformationText className={c}>{value}</InformationText>
      </JustifyInformationRow>
    ) : null;

  function NotBenchmarkInformation() {
    const {
      physiciantype,
      FTEVal,
      totalAdminVal,
      clinicalFTEVal,
      bonusVal,
      adjustedBonusVal,
      otherVal,
      totalCallVal,
      otherReimbursementsVal,
      callDays,
      paymentPerWorkRVUVal,
    } = states ?? {};

    const CALLDAYS_LABEL = totalCallVal
      ? 'Call days per month'
      : 'Unpaid call days/month';
    const { clinicalComp } = states.benchmarkValues;
    const {
      totalClinicalComp,
      totalClinicalCompAdjusted,
      clinicalCompPerWorkRVU,
      totalComp,
    } = states.benchmarkValues;

    const adjustedClinical = states.benchmarkValues.clinicalCompAdjusted;
    const adjustedTotal = states.benchmarkValues.totalCompAdjusted;
    return (
      <Information padding="0px 30px 10px 30px">
        <InformationColumn
          align="start"
          padding="10px 30px 0px 0px"
          minHeight={0}
          maxHeight={240}
        >
          <InformationText fontSize={9} fontWeight={600} className="contract">
            Contract Terms
          </InformationText>
          {renderInformationRow('Physician Type', physiciantype)}
          {renderInformationRow('Physician FTE', FTEVal)}
          {renderInformationRow('Clinical FTE', clinicalFTEVal)}
          {renderInformationRow('Clinical Compensation*', clinicalComp)}
          {renderInformationRow(
            'Annual ED Call Coverage Payment',
            totalCallVal,
          )}
          {renderInformationRow(CALLDAYS_LABEL, callDays)}
          {renderInformationRow('Annual Administrative Payment', totalAdminVal)}
          {
            renderInformationRow(
              'Bonus and Incentives',
              bonusVal,
            ) /* value may return as null from the api } */
          }
          {renderInformationRow('Other', otherVal)}
          {renderInformationRow(
            'Other reimbursements',
            otherReimbursementsVal,
            '',
            true,
          )}
          {renderInformationRow(
            'Defined Payment per Work RVU',
            paymentPerWorkRVUVal,
          )}
          {renderInformationRow(
            'Calculated Clinical Compensation per Work RVU',
            clinicalCompPerWorkRVU,
          )}
          {
            renderInformationRow(
              'Total Clinical Compensation',
              totalClinicalComp,
            ) /* value may return as null from the api */
          }
          {renderInformationRow('Total Compensation', totalComp)}

          {clinicalComp ? (
            <Information marginTop={6}>
              <InformationText fontWeight={600}>
                *excluding call pay and clinical/bonus incentive payments
              </InformationText>
            </Information>
          ) : null}
        </InformationColumn>
        <InformationColumn
          align="start"
          width="35%"
          padding="10px 10px 0px 10px"
          minHeight={0}
          maxHeight={240}
        >
          <InformationText fontSize={9} fontWeight={600} className="contract">
            FTE-Adjusted Terms
          </InformationText>
          {physiciantype
            ? renderInformationRow(' ', ' ', 'physician-type-blank')
            : null}
          {FTEVal ? renderInformationRow(' ', ' ', 'fte-blank') : null}
          {clinicalFTEVal
            ? renderInformationRow(' ', ' ', 'clinical-fte-blank')
            : null}
          {adjustedClinical
            ? renderInformationRow(
                adjustedClinical ?? ' ',
                ' ',
                'adjustedclinical',
              )
            : null}
          {totalCallVal
            ? renderInformationRow(' ', ' ', 'total-call-blank')
            : null}
          {callDays && callDays !== '0'
            ? renderInformationRow(' ', ' ', 'calldays-blank')
            : null}
          {totalAdminVal
            ? renderInformationRow(' ', ' ', 'total-admin-blank')
            : null}
          {adjustedBonusVal !== null
            ? renderInformationRow(
                adjustedBonusVal ?? ' ',
                ' ',
                'adjusted-bonus',
              )
            : null}
          {otherVal ? renderInformationRow(' ', ' ', 'other-blank') : null}
          {otherReimbursementsVal
            ? renderInformationRow(' ', ' ', 'other-reimbursement-blank')
            : null}
          {paymentPerWorkRVUVal
            ? renderInformationRow(' ', ' ', 'defined-payment-blank')
            : null}
          {clinicalCompPerWorkRVU
            ? renderInformationRow(' ', ' ', 'clinical-payment-blank')
            : null}
          {totalClinicalCompAdjusted
            ? renderInformationRow(
                totalClinicalCompAdjusted ?? ' ',
                ' ',
                'totalclinical-ad',
              )
            : null}
          {adjustedTotal
            ? renderInformationRow(adjustedTotal ?? ' ', ' ', 'adjusted-total')
            : null}
          {adjustedTotal ? (
            <InformationItalicText>
              (Used for benchmark comparisons below)
            </InformationItalicText>
          ) : null}
        </InformationColumn>
      </Information>
    );
  }

  function DetailsInformation() {
    const { detailsReimbursementsVal, detailsVal } = states ?? {};
    const italic = true;
    const showNotes = detailsVal || detailsReimbursementsVal;

    return showNotes ? (
      <Information padding="0px 30px 0px 30px">
        <InformationColumn
          align="start"
          padding="10px 0px"
          minHeight={0}
          maxHeight={200}
        >
          <InformationText fontSize={9} fontWeight={600} className="contract">
            Notes:
          </InformationText>
          {detailsVal && (
            <DetailsInformationColumn>
              <InformationText textstyle={italic ? 'italic' : 'normal'}>
                {'Details of Other Compensation: '}
              </InformationText>
              {detailsVal.split('\n').map((row: any) => (
                <InformationText className="detailsNote" key={row}>
                  {row}
                </InformationText>
              ))}
            </DetailsInformationColumn>
          )}
          {detailsReimbursementsVal && (
            <DetailsInformationColumn>
              <InformationText textstyle={italic ? 'italic' : 'normal'}>
                {
                  'Details of Other reimbursements not included as personal income: '
                }
              </InformationText>
              {detailsReimbursementsVal.split('\n').map((row: any) => (
                <InformationText className="detailsNote" key={row}>
                  {row}
                </InformationText>
              ))}
            </DetailsInformationColumn>
          )}
        </InformationColumn>
      </Information>
    ) : null;
  }

  const breakPageTopSection = (sectionId: string) => (
    <>
      <TCRTitle padding={10}>Productivity and Calculated Values</TCRTitle>
      <Information padding="10px 10px" marginTop={0} id={sectionId}>
        <InformationColumn
          align="end"
          width="55%"
          padding="10px 5px 10px 0px"
          maxHeight={200}
        >
          {renderInformationRow('Work RVUs', states?.benchmarkValues?.workRVUs)}
          {renderInformationRow(
            'Net Professional Collections',
            states?.benchmarkValues?.profCollections,
          )}
          {renderInformationRow(
            'Payment per Work RVU',
            states?.benchmarkValues?.ClinicalCompPerWorkRVU,
          )}
          {renderInformationRow(
            'Net Professional Collections per Work RVU',
            states?.benchmarkValues?.percentCollectionsPayments,
          )}
          {renderInformationRow(
            'Calculated Clinical Compensation per Work RVU',
            states?.benchmarkValues?.clinicalCompPerWorkRVU,
          )}
        </InformationColumn>
        <InformationColumn
          align="start"
          width="45%"
          padding="10px 0px 10px 5px"
          maxHeight={200}
        >
          {renderInformationRow(
            'Work RVUs Adjusted',
            states?.benchmarkValues?.workRVUsAdjusted,
          )}
          {renderInformationRow(
            'Net Professional Collections Adjusted',
            states?.benchmarkValues?.profCollectionsAdjusted,
          )}
        </InformationColumn>
      </Information>
      <Line />
    </>
  );

  const bottomContainerSection = (sectionId: string) =>
    (states?.approval === 'true' || states?.notes || states?.checkrm) && (
      <ApprovedByContainer margin={30} id={sectionId}>
        <BottomTextContainer>
          {states?.notes && (
            <DetailsInformationColumn>
              <InformationText>{'Notes: '}</InformationText>
              {states?.notes.split('\n').map((row: any) => (
                <InformationText key={row} className="detailsNote">
                  {row}
                </InformationText>
              ))}
            </DetailsInformationColumn>
          )}
        </BottomTextContainer>
        {states?.approval === 'true' && (
          <>
            <ApprovedByLine />
            <ApprovedByText>APPROVED BY:</ApprovedByText>
            <div style={{ height: '30px' }} />
            <ApprovedByLine />
            <ApprovedByText>Date:</ApprovedByText>
          </>
        )}
      </ApprovedByContainer>
    );

  let page = 0;

  const addFirstTopSection = async () => {
    const parentId = `tcr-page-${page}`;

    const information = states.dignity ? (
      <DignityInformation />
    ) : (
      <NotDignityInformation />
    );
    document!.getElementById(parentId)!.innerHTML += await renderToString(
      information,
    );

    const notBenchInformation = <NotBenchmarkInformation />;
    const detailsInformation = <DetailsInformation />;
    document!.getElementById(parentId)!.innerHTML += await renderToString(
      notBenchInformation,
    );
    document!.getElementById(parentId)!.innerHTML += await renderToString(
      detailsInformation,
    );
    document!.getElementById(parentId)!.innerHTML += await renderToString(
      <Line />,
    );
  };

  const addFirstChartsSection = async () => {
    await firstSection.map(async (benchmark) => {
      if (benchmark?.service) {
        const chartId = buildId();
        page = addChildtoParent(
          chartId,
          ChartPicker(benchmark, chartId),
          'tcr-page',
          page,
        );
      } else {
        const nonBenchId = buildId();
        page = addChildtoParent(
          nonBenchId,
          nonBenchPicker(benchmark, nonBenchId),
          'tcr-page',
          page,
        );
      }
    });
  };

  const addSecondTopSection = async () => {
    if (!secondSection.length) return null;

    page += 1;
    const sectionId = buildId();
    const content = breakPageTopSection(sectionId);
    page = addChildtoParent(sectionId, content, 'tcr-page', page);
    return page;
  };

  const addSecondChartsSection = async () => {
    if (!secondSection.length) return null;

    await secondSection.map(async (benchmark) => {
      if (benchmark?.service) {
        const chartId = buildId();
        page = addChildtoParent(
          chartId,
          ChartPicker(benchmark, chartId),
          'tcr-page',
          page,
        );
      } else {
        const nonBenchId = buildId();
        page = addChildtoParent(
          nonBenchId,
          nonBenchPicker(benchmark, nonBenchId),
          'tcr-page',
          page,
        );
      }
    });

    return null;
  };

  const addBottomContainerSection = async () => {
    const sectionId = buildId();
    const content = bottomContainerSection(sectionId);
    page = addChildtoParent(sectionId, content, 'tcr-page', page);
  };

  const buildContent = async () => {
    await addFirstTopSection();
    await addFirstChartsSection();
    await addSecondTopSection();
    await addSecondChartsSection();
    await addBottomContainerSection();
  };

  const removeNotUsedPage = () => {
    for (let i = 0; i < 50; i += 1) {
      const currentPage = document!.getElementById(`tcr-page-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`tcr-parent-page-${i}`)?.remove();
      }
    }
  };

  useEffect(() => {
    buildContent().then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <Document id="documentBody">
      {Array(10)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default TotalCompensationTemplate;
