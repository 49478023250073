import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubTitle from 'src/components/atoms/Subtitle';
import Link from 'src/components/atoms/Link';
import Footer from 'src/components/organisms/Footer';
import {
  CommercialToolkit,
  EmergencyCallCoverage,
  FMVDocumentation,
  GuideMedical,
} from 'src/global/PngImages';
import SearchBenchmark from 'src/components/molecules/SearchBenchmark';
import MDRangerService from 'src/services/mdrangerServices';
import Title from '../../components/atoms/Title';

import {
  ImageCard,
  Page,
  Section,
  Row,
  Text,
  BulletPoint,
  Points,
  Column,
} from './style';
import Video from '../../components/atoms/VideoArea';
import { KeyFindingsVideo } from '../../global/Videos';

function UsingBenchmarkPage() {
  const { t } = useTranslation('using-benchmark');

  const TEXT_FONT_SIZE = 16;

  const [services, setServices] = useState<string[]>([]);

  const handleSearchSubmit = async (service: string) => {
    if (service) {
      window.open(
        `${document.referrer}search?b=${service}&t=non-salary`,
        '_top',
      );
    }
  };

  const loadServices = async () => {
    const response: any = await MDRangerService.getAll();
    if (!response.failed) {
      setServices(response.data.services);
    }
  };

  const callback = async (value: string) => {
    await handleSearchSubmit(value);
  };

  useEffect(() => {
    loadServices().then(null);
  }, []);

  return (
    <Page>
      <SearchBenchmark
        placeholder="Search benchmarks"
        callback={(data: string) => callback(data)}
        options={services}
      />

      <Section className="usingBenchmarks">
        <Title text={t('using_benchmarks')} />
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('first_paragraph')}
        </Text>
      </Section>

      <Section className="general">
        <Column>
          <Title text={t('key_video')} />
          <Video src={KeyFindingsVideo} width="600" />
        </Column>
      </Section>

      <Section className="emphasisSection">
        <Row className="guides">
          <ImageCard>
            <SubTitle fontSize={18} text={t('guide_coverage')} />
            <Link
              href={t('guide_coverage_pdf')}
              imageSrc={EmergencyCallCoverage}
              imageWidth="200"
            />
          </ImageCard>
          <ImageCard className="image">
            <SubTitle fontSize={18} text={t('guide_direction')} />
            <Link
              href={t('guide_direction_pdf')}
              imageSrc={GuideMedical}
              imageWidth="200"
            />
          </ImageCard>

          <ImageCard>
            <SubTitle fontSize={18} text={t('commercial')} />
            <Link
              href={t('commercial_pdf')}
              imageSrc={CommercialToolkit}
              imageWidth="200"
            />
          </ImageCard>

          <ImageCard>
            <SubTitle fontSize={18} text={t('fmv_guide')} />
            <Link
              href={t('fmv_pdf')}
              imageSrc={FMVDocumentation}
              imageWidth="200"
            />
          </ImageCard>
        </Row>
      </Section>
      <Section className="general">
        <Title text={t('methodology')} />
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('methodology_paragraph')}
        </Text>
      </Section>
      <Section className="general">
        <Title text={t('antitrust')} />
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('antitrust_paragraph')}
        </Text>
        <Points>
          <BulletPoint>{t('antitrust_first_bullet')}</BulletPoint>
          <BulletPoint>{t('antitrust_second_bullet')}</BulletPoint>
          <BulletPoint>{t('antitrust_third_bullet')}</BulletPoint>
        </Points>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('antitrust_second_paragraph')}
        </Text>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('antitrust_third_paragraph')}
          <Link
            href="https://www.justice.gov/atr/statements-antitrust-enforcement-policyin-health-care"
            text="Statements Of Antitrust Enforcement Policy In Health Care."
          />
        </Text>
      </Section>
      <Section className="general emphasisSection">
        <Title text={t('data_collection')} />
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('data_first_paragraph')}
        </Text>
        <Points>
          <BulletPoint>{t('data_first_bullet')}</BulletPoint>
          <BulletPoint>{t('data_second_bullet')}</BulletPoint>
          <BulletPoint>{t('data_third_bullet')}</BulletPoint>
          <BulletPoint>{t('data_fourth_bullet')}</BulletPoint>
          <BulletPoint>{t('data_fifth_bullet')}</BulletPoint>
          <BulletPoint>{t('data_sixth_bullet')}</BulletPoint>
          <BulletPoint>{t('data_seventh_bullet')}</BulletPoint>
        </Points>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('data_second_paragraph')}
        </Text>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('data_third_paragraph')}
        </Text>
        <Points>
          <BulletPoint>{t('data_eighth_bullet')}</BulletPoint>
          <BulletPoint>{t('data_ninth_bullet')}</BulletPoint>
          <BulletPoint>{t('data_tenth_bullet')}</BulletPoint>
          <BulletPoint>{t('data_eleventh_bullet')}</BulletPoint>
          <BulletPoint>{t('data_twelfth_bullet')}</BulletPoint>
        </Points>
      </Section>
      <Section className="general lastSection">
        <Title text={t('rate_calculations')} />
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('rate_first_paragraph')}
        </Text>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('rate_second_paragraph')}
        </Text>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('rate_third_paragraph')}
        </Text>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('rate_fourth_paragraph')}
        </Text>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('rate_fifth_paragraph')}
        </Text>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('rate_sixth_paragraph')}
        </Text>
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('rate_seventh_paragraph')}
        </Text>
      </Section>
      <Footer />
    </Page>
  );
}

export default UsingBenchmarkPage;
