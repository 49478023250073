import styled from 'styled-components';

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-pdf-height);
  padding: 0;
  top: 0;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 30px;
  margin: 20px 0;

  > :not(:first-child).regionTitle {
    margin-top: 50px;
  }
`;
