import styled from 'styled-components';

interface SignatureInterface {
  $margin: number;
}

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-pdf-height);
  padding: 0;
  top: 0;

  > :first-child {
    > span {
      font-size: 12px !important;
    }
  }
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 25px;
  margin: 20px 0;
`;

export const Line = styled.div`
  display: flex;
  margin-left: 30px;
  width: calc(var(--default-pdf-width) - 80px);
  height: 0.5px !important;
  background-color: var(--secondary-light-gray);
`;

export const Information = styled.div`
  display: flex;
  flex-direction: row;
  text-align: justify;
  width: 100%;
  padding: 10px 30px;
`;

export const InformationColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  max-height: 66px;
  overflow: hidden;
`;

export const HospitalBasedDignityColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const InformationText = styled.span`
  font-size: 9px;
  text-align: justify;
  margin-bottom: 3px;
`;

export const ApprovedByContainer = styled.div<SignatureInterface>`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 50px 30px;
  width: 100%;

  margin-top: ${(props) => `${props.$margin}px`};
`;

export const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  white-space: normal;
  word-wrap: break-word;
  margin-bottom: 40px;
`;

export const ApprovedByLine = styled.div`
  width: 100px;
  height: 0.5px;
  background-color: #000;
`;

export const ApprovedByText = styled.span`
  font-size: 8px;
`;

export const Charts = styled.div``;
