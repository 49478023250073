import React from 'react';
import { Body } from './style';
import HomeTemplateInterface from './interface';
import SvgIcon from '../../atoms/SvgIcon';
import Title from '../../atoms/Title';
import Images from '../../../assets/images';

function HomeTemplate({ title }: HomeTemplateInterface) {
  const image = Images.MDRangerECGWhite;

  return (
    <Body>
      <SvgIcon Svg={image} size={200} />
      <Title text={title} />
    </Body>
  );
}

export default HomeTemplate;
