/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'src/hooks/redux/store';
import { Page, Box, Section, Row, BoxHider, Container } from './style';
import SearchHeader from '../../components/molecules/SearchHeader';
import Checkbox from '../../components/atoms/Dignity/Checkbox';
import RateConversionModal from '../../components/organisms/RateConversionModal';
import Footer from '../../components/organisms/Footer';
import ReportGenerator from '../../components/templates/ReportsGenerator';
import DignityReportGenerator from '../../components/templates/DignityReportsGenerator';
import MDRangerService from '../../services/mdrangerServices';
import GeneralServices from '../../services/generalServices';
import ComparisonConditionalMultipleInput from '../../components/molecules/Dignity/ComparisonConditionalMultipleInput';
import RateComparisonService from '../../services/rateComparison';
import Loader from '../../components/atoms/DotLoader';
import PdfMicroService from '../../services/pdfMicroService';
import PlusButton from '../../components/atoms/PlusButton';
import Subtitle from '../../components/atoms/Subtitle';
import SelectDropDown from '../../components/atoms/SelectDropDown';
import MultiSelectPillbox from '../../components/atoms/PillboxSelect';
import ComparisonConditionalInputDignity from '../../components/molecules/Dignity/ComparisonConditionalInput';
import ComparisonConditionalInput from '../../components/molecules/ComparisonConditionalInput';

function RateComparison() {
  const { t } = useTranslation('rate-comparison');

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [service, setService] = useState<string>('');
  const [services, setServices] = useState<string[]>([]);
  const [loadingDignity, setLoadingDignity] = useState<boolean>(false);
  const [selfDignity, setSelfDignity] = useState<boolean>(false);
  const [isNewDignity, setIsNewDignity] = useState<boolean>(false);
  const [precheck] = useState<boolean>(false);
  const [trauma, setTrauma] = useState<boolean>(false);
  const [reportTitle, setReportTitle] = useState<string>('');
  const [counterParty, setCounterParty] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [facility, setFacility] = useState<string>('');
  const [hospitalName, setHospitalName] = useState<string>('');
  const [fmvApproval, setFmvApproval] = useState<boolean>(false);
  const [previousApproval, setPreviousApproval] = useState<string>('');
  const [additionalText, setAdditionalText] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [signature, setSignature] = useState<boolean>(false);
  const [HC, setHC] = useState<string>('');
  const [HD, setHD] = useState<string>('');
  const [HH, setHH] = useState<string>('');
  const [HR, setHR] = useState<string>('');
  const [HS, setHS] = useState<string>('');
  const [LH, setLH] = useState<string>('');
  const [HP, setHP] = useState<string>('');
  const [HQ, setHQ] = useState<string>('');
  const [HT, setHT] = useState<string>('');
  const [EP, setEP] = useState<string>('');
  const [EV, setEV] = useState<string>('');
  const [EC, setEC] = useState<string>('');
  const [DS, setDS] = useState<string>('');
  const [US, setUS] = useState<string>('');
  const [UG, setUG] = useState<string>('');
  const [CG, setCG] = useState<string>('');
  const [PC, setPC] = useState<string>('');
  const [hiddenBenchmarks, setHiddenBenchmarks] = useState<string[]>([]);
  const [loadingHiddenBenchmarks, setLoadingHiddenBenchmarks] =
    useState<boolean>(false);
  const [isTelemedicine, setIsTelemedicine] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [hasMultiple, setHasMultiple] = useState<boolean>(false);
  const HSTypeList = ['Collection Guarantee', 'Overall Stipend'];
  const [dignitySlices, setDignitySlices] = useState<string[]>([]);
  const [selectedSlices, setSelectedSlices] = useState<string[]>([]);

  const handleReportGeneratorInputsChange = (inputValues: any) => {
    setReportTitle(inputValues.reportTitle);
    setCounterParty(inputValues.counterParty);
    setStartDate(inputValues.startDate);
    setEndDate(inputValues.endDate);
    setFacility(inputValues.facility);
    setNotes(inputValues.notes);
    setSignature(inputValues.signature);
  };

  const handleDignityReportInputChange = (inputValues: any) => {
    setReportTitle(inputValues.reportTitle);
    setHospitalName(inputValues.hospitalName);
    setFmvApproval(inputValues.fmvApproval);
    setPreviousApproval(inputValues.previousApproval);
    setAdditionalText(inputValues.additionalText);
  };

  const loadServices = async () => {
    const response: any = await MDRangerService.getAll();
    if (!response.failed) {
      setServices(response.data.services);
    }
  };

  const loadSelf = async () => {
    const response = await GeneralServices.self();
    console.log(response, 'response');
    if (!response.failed) {
      const newDignity = response.data.isNewDignity;
      setSelfDignity(response.data.dignity);
      console.log(newDignity, 'is new dignity');
      setIsNewDignity(!!newDignity);
    }
    setLoadingDignity(false);
  };

  const buttonHandle = (clicked: boolean) => {
    setShow(clicked);
  };

  useEffect(() => {
    setLoadingDignity(true);
    loadSelf().then(null);
  }, []);

  const buildPDFAttributes = () => {
    // make sure url encondement wont be bugged in the micro pdf page

    const andURLEncoder = '%26';
    const checkServiceEncoded = service.replace('&', andURLEncoder);
    return {
      checkservice: checkServiceEncoded,
      checkht: trauma,
      checkHC: HC,
      checkHD: HD,
      checkHH: HH,
      checkHS: HS,
      checkHR: HR,
      checkLH: LH,
      checkHP: HP,
      checkHQ: HQ,
      checkHT: HT,
      checkEP: EP,
      checkEV: EV,
      checkEC: EC,
      checkUS: US,
      checkUG: UG,
      checkDS: DS,
      checkCG: CG,
      checkPC: PC,
      'report-title': reportTitle,
      'report-counterparty': counterParty,
      'report-start': startDate,
      'report-end': endDate,
      'report-facility': facility,
      'report-notes': notes,
      'report-approval': signature,
      hospname: hospitalName, // if dignity true
      prevappy: fmvApproval,
      prevappid: previousApproval,
      checkrm: additionalText,
      dignitySlices: selectedSlices,
    };
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const body: any = buildPDFAttributes();
    const paramsArray: string[] = [];

    const nonArrayParams = ['report-approval', 'prevappy', 'checkrm'];

    Object.keys(body).map((key) => {
      if (body[key] === '') return null;
      if (Array.isArray(body[key]) && !body[key].length) return null;

      if (nonArrayParams.includes(key)) {
        return paramsArray.push(`${key}=${body[key]}`);
      }
      return paramsArray.push(`${key}[0]=${body[key]}`);
    });
    const accessToken = store.getState().auth.token;
    const microUrl = `/rcr-pdf?token=${accessToken}&dignity=${selfDignity}&${paramsArray.join(
      '&',
    )}`;
    const prefix = window.location.href.split('/rate-comparison')[0];

    const pdfTitle = `${service}RateComparisonReport.pdf`;
    const buildResponse = await PdfMicroService.buildPdf(
      `${prefix}${microUrl}`,
      pdfTitle,
    );

    if (!buildResponse.failed) {
      window.open(buildResponse.data.url, '_blank');
    }

    setSubmitting(false);
  };

  const handleCheckValidInputs = async (selectedService: string) => {
    setLoadingHiddenBenchmarks(true);
    setService(selectedService);

    if (!selectedService || !selectedService.length) {
      setLoadingHiddenBenchmarks(false);
      return setHiddenBenchmarks([]);
    }

    const response = await RateComparisonService.getHiddenBenchmarks(
      selectedService,
      trauma ? 1 : 0,
    );

    if (response.failed) {
      setLoadingHiddenBenchmarks(false);
      return setHiddenBenchmarks([]);
    }

    const comparisonResponse = await RateComparisonService.getComparisons(
      selectedService,
      precheck ? 1 : 0,
    );

    if (comparisonResponse.failed) {
      return setDignitySlices([]);
    }

    setDignitySlices(comparisonResponse.data?.dignitySlices ?? []);

    const toHideArray = response.data.hideAnnualPayment
      ? [...response.data.hiddenBenchmarks, 'HD']
      : response.data.hiddenBenchmarks;
    setLoadingHiddenBenchmarks(false);
    setIsTelemedicine(Boolean(response.data?.telemedicine));
    return setHiddenBenchmarks(toHideArray);
  };

  const handleSelectSlices = (callReturn: any) => {
    setSelectedSlices(callReturn);
  };

  const checkAllBenchmarksAreUnavailable = (benchMarksArray: string[]) =>
    benchMarksArray.every((element) => hiddenBenchmarks.includes(element));

  const handleSelectChange = (selectedValue: string) => {
    setHasMultiple(selectedValue === HSTypeList[0]);
  };

  useEffect(() => {
    loadServices().then(null);
  }, []);

  useEffect(() => {}, [hasMultiple]);

  return (
    <Page>
      <Section>
        <SearchHeader
          placeholder={t('search')}
          title={t('title')}
          callback={(data: string) => handleCheckValidInputs(data)}
          options={services}
        />
      </Section>
      <Box>
        {(!service?.length || loadingHiddenBenchmarks) && (
          <BoxHider blur={loadingHiddenBenchmarks}>
            {loadingHiddenBenchmarks && <Loader />}
          </BoxHider>
        )}
        <Row>
          <Checkbox
            label={t('trauma-center')}
            onChange={(value: boolean) => setTrauma(value)}
          />
          <span>{t('trauma_only_comparison')}</span>
        </Row>
        {!hiddenBenchmarks.includes('HC') && (
          <ComparisonConditionalInputDignity
            buttonPlaceholder={t('add_ed_coverage_title')}
            inputPlaceholder={t('add_ed_coverage_subtitle')}
            inputCallback={(value: string) => setHC(value)}
            selectedService={service}
            width={520}
            value={HC}
            trauma={trauma}
            identifier="HC"
            inputType="currency"
            inputFooter={
              <RateConversionModal
                result={(calcResult: number) =>
                  setHC(String(calcResult.toFixed(2)))
                }
              />
            }
          />
        )}
        {!checkAllBenchmarksAreUnavailable(['HH', 'HR']) && (
          <ComparisonConditionalMultipleInput
            slices={[]}
            buttonPlaceholder={t('add_medical_direction_rate_title')}
            width={520}
            inputsPlaceholder={[
              t('add_medical_direction_hours_annual_hours'),
              t('add_medical_direction_hours_hourly'),
              t('add_medical_direction_hours_annual_payment'),
            ]}
            inputsCallback={(inputsValues: {
              firstInput: string;
              secondInput: string;
              thirdInput: string;
            }) => {
              setHH(inputsValues.firstInput);
              setHR(inputsValues.secondInput);
              setHD(inputsValues.thirdInput);
            }}
            selectedService={service}
            trauma={trauma}
            values={[HH, HR, HD]}
            identifiers={['HH', 'HR', 'HD']}
            hiddenInputs={hiddenBenchmarks}
            hiddenMessage={
              checkAllBenchmarksAreUnavailable(['HD'])
                ? t('hide_annual_payment')
                : undefined
            }
          />
        )}
        {!hiddenBenchmarks.includes('HS') && (
          <Container>
            <PlusButton
              placeholder={t('add_hospital_based_title')}
              callback={buttonHandle}
              toggleable
              bind={show}
            />
            {show ? (
              isNewDignity ? (
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                >
                  <div style={{ marginTop: '15px', width: '60%' }}>
                    <MultiSelectPillbox
                      pillBoxId="rcr-dignity-pillbox"
                      callBack={handleSelectSlices}
                      dataItems={dignitySlices}
                    />
                  </div>
                  <Subtitle text="Type" />
                  <SelectDropDown
                    callBack={(value: any) => handleSelectChange(value.value)}
                    dataItems={HSTypeList}
                    startValueKey={Object.keys(HSTypeList)[0]}
                  />
                  <Row>
                    {hasMultiple ? (
                      <ComparisonConditionalMultipleInput
                        slices={selectedSlices}
                        withoutButton
                        buttonPlaceholder={t('add_hospital_based_title')}
                        width={520}
                        inputsPlaceholder={[
                          t('add_hospital_dignity_collection'),
                          t('add_hospital_dignity_professional_collection'),
                          t('add_hospital_dignity_net_payment'),
                        ]}
                        hasIndicator={['HS']}
                        inputsCallback={(inputsValues: {
                          firstInput: string;
                          secondInput: string;
                          thirdInput: string;
                        }) => {
                          setCG(inputsValues.firstInput);
                          setPC(inputsValues.secondInput);
                          setHS(inputsValues.thirdInput);
                        }}
                        selectedService={service}
                        trauma={trauma}
                        values={[CG, PC, HS]}
                        identifiers={['CQ', 'PC', 'HS']}
                        hiddenInputs={hiddenBenchmarks}
                        helper={[t('add_hospital_dignity_collection_helper')]}
                      />
                    ) : (
                      <ComparisonConditionalInput
                        withoutButton
                        hasCustomResult
                        buttonPlaceholder={t('add_hospital_based_title')}
                        inputPlaceholder={t('add_hospital_based_subtitle')}
                        inputCallback={(value: string) => setHS(value)}
                        selectedService={service}
                        width={520}
                        value={HS}
                        trauma={trauma}
                        identifier="HS"
                        inputType="currency"
                        slices={selectedSlices}
                        helper={t('add_hospital_based_helper')}
                      />
                    )}
                  </Row>
                </div>
              ) : (
                <ComparisonConditionalInput
                  withoutButton
                  hasCustomResult
                  buttonPlaceholder={t('add_hospital_based_title')}
                  inputPlaceholder={t('add_hospital_based_subtitle')}
                  inputCallback={(value: string) => setHS(value)}
                  selectedService={service}
                  width={520}
                  value={HS}
                  trauma={trauma}
                  identifier="HS"
                  inputType="currency"
                  slices={selectedSlices}
                  helper={t('add_hospital_based_helper')}
                />
              )
            ) : null}
          </Container>
        )}
        {!hiddenBenchmarks.includes('LH') && (
          <ComparisonConditionalInputDignity
            buttonPlaceholder={t('add_clinical_service_title')}
            inputPlaceholder={t('add_clinical_service_subtitle')}
            width={520}
            inputCallback={(value: string) => setLH(value)}
            selectedService={service}
            trauma={trauma}
            inputType="currency"
            identifier="LH"
            value={LH}
          />
        )}
        {!checkAllBenchmarksAreUnavailable(
          !isTelemedicine ? ['HP', 'HQ', 'HT'] : ['HP', 'HQ', 'HT', 'EP'],
        ) && (
          <ComparisonConditionalMultipleInput
            slices={[]}
            buttonPlaceholder={t('add_telemedicine_title')}
            width={520}
            inputsPlaceholder={[
              t('add_telemedicine_per_payment'),
              t('add_telemedicine_non_per_payment'),
              t('add_telemedicine_non_per_episode'),
              t('add_telemedicine_per_episode'),
            ]}
            inputsCallback={(inputsValues: {
              firstInput: string;
              secondInput: string;
              thirdInput: string;
              fourthInput: string;
            }) => {
              setHP(inputsValues.firstInput);
              setHQ(inputsValues.secondInput);
              setHT(inputsValues.thirdInput);
              setEP(inputsValues.fourthInput);
            }}
            selectedService={service}
            trauma={trauma}
            values={[HP, HQ, HT, EP]}
            identifiers={['HP', 'HQ', 'HT', 'EP']}
            hiddenInputs={[...hiddenBenchmarks, !isTelemedicine ? 'EP' : '']}
          />
        )}
        {!checkAllBenchmarksAreUnavailable(
          isTelemedicine ? ['EV', 'EC'] : ['EV', 'EC', 'EP'],
        ) && (
          <ComparisonConditionalMultipleInput
            slices={[]}
            buttonPlaceholder={t('add_per_activation')}
            width={520}
            inputsPlaceholder={[
              t('add_per_activation_ac'),
              t('add_per_activation_ep'),
              t('add_per_activation_pro'),
            ]}
            inputsCallback={(inputsValues: {
              firstInput: string;
              secondInput: string;
              thirdInput: string;
            }) => {
              setEV(inputsValues.firstInput);
              setEP(inputsValues.secondInput);
              setEC(inputsValues.thirdInput);
            }}
            selectedService={service}
            trauma={trauma}
            values={[EV, EP, EC]}
            identifiers={['EV', 'EP', 'EC']}
            hiddenInputs={[...hiddenBenchmarks, isTelemedicine ? 'EP' : '']}
          />
        )}
        {!hiddenBenchmarks.includes('DS') && (
          <ComparisonConditionalInputDignity
            buttonPlaceholder={t('add_diagnostic_testing_title')}
            inputPlaceholder={t('add_diagnostic_testing_subtitle')}
            width={520}
            inputCallback={(value: string) => setDS(value)}
            selectedService={service}
            trauma={trauma}
            inputType="currency"
            identifier="DS"
            value={DS}
          />
        )}
        {!hiddenBenchmarks.includes('US') && (
          <ComparisonConditionalInputDignity
            buttonPlaceholder={t('add_unsponsored_percent_title')}
            inputPlaceholder={t('add_unsponsored_percent_subtitle')}
            width={520}
            inputCallback={(value: string) => setUS(value)}
            selectedService={service}
            trauma={trauma}
            inputType="number"
            identifier="US"
            value={US}
          />
        )}
        {!hiddenBenchmarks.includes('UG') && (
          <ComparisonConditionalInputDignity
            buttonPlaceholder={t('add_unit_guarantee_title')}
            inputPlaceholder={t('add_unit_guarantee_subtitle')}
            width={520}
            inputCallback={(value: string) => setUG(value)}
            selectedService={service}
            trauma={trauma}
            inputType="number"
            identifier="UG"
            value={UG}
          />
        )}
      </Box>
      <Box>
        {(loadingDignity || !service) && (
          <BoxHider blur={loadingDignity}>
            {loadingDignity && <Loader />}
          </BoxHider>
        )}
        {selfDignity ? (
          <DignityReportGenerator
            loading={submitting}
            handleInputsChange={handleDignityReportInputChange}
            handleSubmit={handleSubmit}
          />
        ) : (
          <ReportGenerator
            loading={submitting}
            handleInputsChange={handleReportGeneratorInputsChange}
            handleSubmit={handleSubmit}
          />
        )}
      </Box>
      <Footer />
    </Page>
  );
}

export default RateComparison;
