/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { TableContainer } from '@mui/material';
import addChildtoParent from 'src/utils/addChildToParent';
import {
  Document,
  Page,
  ItemRow,
  BetweenSectionText,
  HeaderFirstTile,
  HeaderGeneralTile,
  RowFirstTile,
  RowGeneraltile,
  CharacteristcTile,
  CharacteristicHolder,
  PageParent,
} from './style';

import ReportHeader from '../../organisms/Reports/Header';
import ReportFooter from '../../organisms/Reports/Footer';

function HBRServicesTablePages(data: any, nowDate: string) {
  const [serviceNames, setServiceNames] = useState<string[]>(
    Array(50).fill(''),
  );
  const docData = data;
  const NOT_ITERABLE_SERVICES_KEYS = [
    'RefRates',
    'appendixA',
    'appendixB',
    'appendixC',
    'custname',
    'percentpaying',
    'title',
  ];
  const REFRATES_KEY = 'RefRates';

  const PARENT_PAGE_PREFIX = 'table-parent-page';
  const CHILD_PAGE_PREFIX = 'table-page';

  const buildPage = (pageIndex: number) => (
    <PageParent id={`${PARENT_PAGE_PREFIX}-${pageIndex}`}>
      <ReportHeader title={serviceNames[pageIndex]} />
      <Page id={`${CHILD_PAGE_PREFIX}-${pageIndex}`} />
      <ReportFooter nowDate={nowDate} />
    </PageParent>
  );

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  const TopSectionRow = () => (
    <ItemRow height={20}>
      <HeaderFirstTile>Characteristc</HeaderFirstTile>
      <HeaderGeneralTile>Providers</HeaderGeneralTile>
      <HeaderGeneralTile>Facilities</HeaderGeneralTile>
      <HeaderGeneralTile>Mean</HeaderGeneralTile>
      <HeaderGeneralTile>25%</HeaderGeneralTile>
      <HeaderGeneralTile>50 %</HeaderGeneralTile>
      <HeaderGeneralTile>75%</HeaderGeneralTile>
      <HeaderGeneralTile>90%</HeaderGeneralTile>
    </ItemRow>
  );

  const CharacteristcRow = (text: string) => {
    const { length } = text;
    return (
      <ItemRow marginBottom={length && length > 20 ? 5 : 0}>
        <CharacteristcTile>{text}</CharacteristcTile>
      </ItemRow>
    );
  };

  const dataRow = (dataBody: any, divId: string) => {
    const { slice, systems, hospitals, mean, q25, q50, q75, q90 } = dataBody;

    return (
      <ItemRow id={divId}>
        <RowFirstTile>{slice}</RowFirstTile>
        <RowGeneraltile>{systems}</RowGeneraltile>
        <RowGeneraltile>{hospitals}</RowGeneraltile>
        <RowGeneraltile>{mean}</RowGeneraltile>
        <RowGeneraltile>{q25}</RowGeneraltile>
        <RowGeneraltile>{q50}</RowGeneraltile>
        <RowGeneraltile>{q75}</RowGeneraltile>
        <RowGeneraltile>{q90}</RowGeneraltile>
      </ItemRow>
    );
  };

  let page = 0;

  const updateServices = (serviceName: string) => {
    const aux = serviceNames;
    if (!aux[page]) {
      aux.splice(page, 1, serviceName);
    }
    return aux;
  };

  const buildRefRateTable = (
    benchName: string,
    benchObj: any,
    tableId: string,
  ) => (
    <TableContainer id={tableId}>
      <BetweenSectionText>{benchName}</BetweenSectionText>
      {TopSectionRow()}
      {Object.entries(benchObj as any).map(([characteristic, rows]) => (
        <CharacteristicHolder>
          {CharacteristcRow(characteristic ?? '')}
          {(rows as any[]).map((row: any) => dataRow(row, ''))}
        </CharacteristicHolder>
      ))}
    </TableContainer>
  );
  const handleRefRates = (refData: any) => {
    Object.entries(refData).forEach(([serviceName, benchmarks]) => {
      (benchmarks as any[]).forEach((bench: any) => {
        Object.entries(bench).forEach(([benchName, benchObj]) => {
          const tableId = buildId();
          const tableContent = buildRefRateTable(
            benchName as string,
            benchObj as any,
            tableId,
          );
          page = addChildtoParent(
            tableId,
            tableContent,
            CHILD_PAGE_PREFIX,
            page,
            60,
          );
          setServiceNames([...updateServices(serviceName)]);
        });
      });
    });
  };

  const buildBenchmarkTable = (benchmark: any, tableId: string) => {
    const { slices } = benchmark;
    const tableElement = (
      <TableContainer id={tableId}>
        <BetweenSectionText>{benchmark?.name}</BetweenSectionText>
        {TopSectionRow()}
        {slices?.map((slice: any) => {
          const { sliceName, rows } = slice;
          return (
            <CharacteristicHolder>
              {CharacteristcRow(sliceName ?? '')}
              {rows?.map((row: any) => dataRow(row, ''))}
            </CharacteristicHolder>
          );
        })}
      </TableContainer>
    );
    return tableElement;
  };

  const insertServiceTables = (benchmarks: any[], serviceName: string) =>
    benchmarks.map((benchmark: any) => {
      const tableId = buildId();
      if (Array.isArray(benchmark)) return null;
      const { slices } = benchmark;
      if (!slices || !slices.length) return null;
      const haveRows = !slices.every(
        (slice: { sliceName: string; rows: any }) => !slice.rows.length,
      );
      if (!haveRows) return null;
      const tableContent = buildBenchmarkTable(benchmark, tableId);
      page = addChildtoParent(
        tableId,
        tableContent,
        CHILD_PAGE_PREFIX,
        page,
        60,
      );
      if (tableContent !== null) {
        setServiceNames([...updateServices(serviceName)]);
      }
      return page;
    });

  const buildHBRTablesContent = async () => {
    if (!docData) return null;
    Object.entries(docData).map(([serviceName, value]) => {
      if (NOT_ITERABLE_SERVICES_KEYS.includes(serviceName)) return null;
      const insertedService = insertServiceTables(
        (value as any).benchmarks,
        serviceName,
      );
      if (insertedService) page += 1;
      return page;
    });

    // eslint-disable-next-line react/destructuring-assignment
    handleRefRates(data[REFRATES_KEY]);

    return null;
  };

  const removeNotUsedPage = () => {
    for (let i = 0; i < 50; i += 1) {
      const currentPage = document!.getElementById(`${CHILD_PAGE_PREFIX}-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`${PARENT_PAGE_PREFIX}-${i}`)?.remove();
      }
    }
  };

  useEffect(() => {
    buildHBRTablesContent().then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <Document id="hbrTable">
      {Array(50)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default HBRServicesTablePages;
