import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  width: 100%;
  height: 50px;
  background-color: var(--ecg-navy-blue);

  > img {
    width: 80px;
    height: auto;
  }
`;

export const HeaderTitle = styled.span`
  font-size: 14px;
  padding-right: 10px;
  color: white;
`;
