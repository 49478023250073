import styled from 'styled-components';

interface InputContainerProps {
  titleSide?: 'left' | 'upper' | 'right' | 'bottom';
  justifyContent?: string;
  inputColor?: string;
  IsModalOpen?: boolean;
}

interface RowProps {
  textColor?: string;
  titleSide?: 'left' | 'upper' | 'right' | 'bottom';
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  > .subtitleAtom {
    color: ${(props) => props.textColor ?? '#242424'};
    width: ${(props) => (props.titleSide === 'right' ? '100%' : 'max-content')};
  }
`;

export const Container = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: ${(props) =>
    props.titleSide === 'left' || props.titleSide === 'right'
      ? 'row'
      : 'column'};
  width: max-content;
  margin-right: 15px;
  justify-content: ${(props) => props.justifyContent || 'start'};

  > .currencyInput {
    ${(props) => props.titleSide && `margin-${props.titleSide}: 10px;`}
  }

  > .inputAtom {
    ${(props) => props.titleSide && `margin-${props.titleSide}: 10px;`}

    >:nth-child(1) {
      background-color: ${(props) => props.inputColor ?? '#FFFFFF'};
    }
  }
`;
