function CoveragePerDiemCalculation(
  weekday: number,
  weekend: number,
  holiday: number,
  weekly: number,
  monthly: number,
  yearly: number,
) {
  let perdiem = 0;

  if (yearly > 0) {
    perdiem = yearly / 365;
  }

  if (weekly > 0) {
    perdiem += weekly / 7;
  }
  if (monthly > 0) {
    perdiem += monthly / 30;
  }
  if (holiday > 0 && weekday > 0 && weekend > 0) {
    perdiem +=
      weekday * 0.6927592955 + holiday * 0.0301369863 + weekend * 0.2771037182;
  } else if (holiday > 0 && weekday > 0) {
    perdiem += weekday * 0.9698630137 + holiday * 0.0301369863;
  } else if (weekday > 0 && weekend > 0) {
    perdiem += weekday * 0.7142857142 + weekend * 0.2857142857;
  } else if (holiday > 0 && weekend > 0) {
    perdiem += holiday * 0.0956521739 + weekend * 0.9043478261;
  } else if (weekday > 0) {
    perdiem += weekday;
  } else if (weekend > 0) {
    perdiem += weekend;
  } else if (holiday > 0) {
    perdiem += holiday;
  }
  return perdiem;
}

export default CoveragePerDiemCalculation;
