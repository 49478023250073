import styled from 'styled-components';

interface RowProps {
  marginBottom?: number | string;
  borderBottom?: 'none' | 'solid';
}

interface TextProps {
  fontSize?: number;
  fontWeight?: string;
  fontColor?: string;
  textAlign?: 'left' | 'justify' | 'right';
}

interface TextContainerProps {
  marginTop?: number;
  marginBottom?: number;
  direction?: 'row' | 'column';
}

interface TileProps {
  width?: number;
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  justifyContent?: 'center' | 'flex-start' | 'flex-end';
  fontWeight?: number;
  isRed?: boolean;
  textAlign?: 'right' | 'left';
  isExpired?: boolean;
}

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-pdf-height);
  padding: 0;
  top: 0;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 25px;
  margin: 20px 0;
`;

export const HeaderTextContent = styled.span`
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  font-weight: 600;
`;

export const Row = styled.div<RowProps>`
  display: flex;
  margin-right: 40px;
  width: 100%;
  height: max-content;
  justify-content: space-between;
  margin-bottom: ${(props) => props.marginBottom}px;
  border-bottom: ${(props) => props.borderBottom ?? 'none'} 0.2px
    rgba(237, 238, 242, 0.7);
`;

export const Tile = styled.div<TileProps>`
  display: flex;
  font-size: 8px;
  line-height: 10px;
  width: ${(props) => (props.width ? `${props.width}px` : '40px')};
  padding: 1px;
  font-weight: ${(props) => props.fontWeight};
  justify-content: ${(props) => props.justifyContent};
  text-align: ${(props) => props.textAlign ?? 'left'};
  align-items: flex-start;
  font-style: ${(props) => (props.isExpired ? 'italic' : 'normal')};

  > span {
    line-height: 12px;
    ${(props) =>
      props.isRed &&
      `
      color: #fff;
      background-color: #b13524;
  `}
  }
`;

export const Value = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VerticalTile = styled(Tile)`
  flex-direction: column;
`;

export const GeneralTextContainer = styled.div<TextContainerProps>`
  display: flex;
  height: max-content;
  width: 100%;
  flex-direction: ${(props) => props.direction ?? 'column'};
  margin-top: ${(props) => `${props.marginTop ?? 0}px`};
  margin-bottom: ${(props) => `${props.marginBottom ?? 0}px`};\
  align-items: flex-start;
  >.dottedLine{
    flex-direction: row;
    >:nth-child(1){
      width: 2px;
      height: 2px;
      border-radius: 100%;
      margin-left: 15px;
      margin-right: 3px;
      background-color: #040404;
      align-self: center;
    }

    :nth-child(2){
      margin: 0;
    }
  }

  >:first-child{
    margin-bottom: 8px;
  }

  >:nth-child(n+2){
    color: var(--ecg-royal-blue);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  > :last-child {
    margin-bottom: 20px;
  }
`;

export const GeneralContentText = styled.span<TextProps>`
  font-family: inherit;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  font-size: ${(props) => `${props.fontSize ? props.fontSize : 7}px`};
  color: var(--almost-black);
  margin-top: 5px;
  text-align: ${(props) => props.textAlign ?? 'left'};
`;

const LEGEND_CONTAINER_MEASURE = 50;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  right: 20px;
  height: ${LEGEND_CONTAINER_MEASURE}px;
  width: 80px;
  border: solid 1.5px var(--ecg-royal-blue);
  padding: 5px;
`;

export const GeneralBlueText = styled.span<TextProps>`
  font-family: inherit;
  font-size: ${(props) => `${props.fontSize ? props.fontSize : 16}px`};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  color: ${(props) => props.fontColor ?? 'var(--ecg-royal-blue)'};
  text-align: left;
`;

interface LegendProps {
  isExpired?: boolean;
}

export const LegendContentText = styled(GeneralContentText)<LegendProps>`
  line-height: normal;
  font-size: 7px;
  margin-top: 0;
  font-style: ${(props) => (props.isExpired ? 'italic' : 'normal')};
`;
