import React from 'react';
import { HeaderContainer, HeaderTitle } from './style';
import Images from '../../../../assets/images';

interface ReportHeaderInterface {
  title: string;
}

function ReportHeader({ title }: ReportHeaderInterface) {
  const image = Images.MDRangerECGWhite;

  return (
    <HeaderContainer>
      <HeaderTitle>{title}</HeaderTitle>
      <img src={image} alt="" />
    </HeaderContainer>
  );
}

export default ReportHeader;
