import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'src/components/atoms/Title';
import Input from '../../molecules/Input';
import { Box } from './style';
import DoubleCheckBoxInput from '../../molecules/DoubleCheckBoxInput';
import ReportTextArea from '../../molecules/ReportTextArea';
import ConfirmButton from '../../atoms/ConfirmButton';
import DignityReportsGeneratorInterface from './interface';

function DignityReportGenerator({
  handleSubmit,
  handleInputsChange,
  loading,
}: DignityReportsGeneratorInterface) {
  const { t } = useTranslation('rate-comparison');

  const [reportTitle, setReportTitle] = useState<string>('');
  const [hospitalName, setHospitalName] = useState<string>('');
  const [fmvApproval, setFmvApproval] = useState<boolean>(false);
  const [previousApproval, setPreviousApproval] = useState<string>('');
  const [additionalText, setAdditionalText] = useState<string>('');

  useEffect(() => {
    handleInputsChange({
      reportTitle,
      hospitalName,
      fmvApproval,
      previousApproval,
      additionalText,
    });
  }, [
    reportTitle,
    hospitalName,
    fmvApproval,
    previousApproval,
    additionalText,
  ]);

  return (
    <Box>
      <Input
        title={t('report_title')}
        callback={(value: any) => setReportTitle(value)}
        type={t('add_telemedicine_title')}
      />
      <Input
        title={t('name_of_hospital')}
        callback={(value: any) => setHospitalName(value)}
        type={t('add_telemedicine_title')}
      />
      <Title text={t('fmv_approvals')} />
      <DoubleCheckBoxInput
        title={t('compensation_above_75')}
        firstLabel={t('yes')}
        secondLabel={t('no')}
        onChange={setFmvApproval}
      />
      <Input
        title={t('provide_number_of_previous')}
        callback={(value: any) => setPreviousApproval(value)}
        type="text"
        disabled={!fmvApproval}
      />
      <ReportTextArea
        title={t('additional_info')}
        callback={(value: string) => setAdditionalText(value)}
        minHeight={250}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <ConfirmButton
          loading={loading}
          label={t('generate-pdf-report')}
          onClick={() => handleSubmit()}
          color="#fff"
        />
        {loading ? (
          <span
            style={{
              height: 'fit-content',
            }}
          >
            If you encounter issues with PDF generation, please ensure that
            pop-ups are enabled in your browser.
          </span>
        ) : null}
      </div>
    </Box>
  );
}

export default DignityReportGenerator;
