/* eslint-disable react/no-unstable-nested-components,react/function-component-definition */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import HBRServicesTablePages from '../../../molecules/HBRServicesTablePages';
import { Document } from './style';

import FrontPage, { FrontPageTextsInterface } from './Pages/FrontPage';
import SecondPage, { SecondPageTextsInterface } from './Pages/SecondPage';
import ThirdPage, { ThirdPageTextsInterface } from './Pages/ThirdPage';
import HbrSpecialPages, { HbrSpecialProps } from './Pages/HbrSpecialPages';
import FourthPage, { FourthPageTextsInterface } from './Pages/FourthPage';
import SupplementalBenchmark from './Pages/SupplementalBenchmark';
import AppendixB, { AppendixBPropsInterface } from './Pages/AppendixB';
import AppendixC from './Pages/AppendixC/AppendixC';
import TextPage from './Pages/TextPage';

import SingleServiceTable from '../../../molecules/SingleServiceTable';
import AppendixGlossary from '../../../molecules/AppendixGlossary';

function SingleServicePdfTemplate({ id, data, reportType, appendixType }: any) {
  const { t } = useTranslation('custom-report-pdf');

  const sectioning = data;
  const now = new Date();

  const currentDate = format(now, 'MM/dd/yyyy');
  const daynameDate = format(now, 'EEEE, MMMM dd, yyyy');

  const { textPage } = data;

  const frontPageTexts: FrontPageTextsInterface = {
    title: sectioning.title,
    subtitle: t('premier_source'),
    year: t('report_year', {
      previousYear: now.getFullYear() - 1,
      currentYear: now.getFullYear(),
    }),
    reportPrepared: `${t('report_prepared')} ${sectioning.custname}`,
    confidential: t('confidential'),
    daynameDate,
    nowDate: currentDate,
  };

  const secondPageTexts: SecondPageTextsInterface = {
    custname: sectioning.custname,
    confidentiality: t('confidentiality_notice'),
    firstParagraph: t('second_page_first_paragraph'),
    legalDisclaimer: t('legal_disclaimer'),
    secondParagraph: t('second_page_second_paragraph'),
    notGive: t('does_not_give'),
    thirdParagraph: t('second_page_third_paragraph'),
    nowDate: currentDate,
  };

  const thirdPageTexts: ThirdPageTextsInterface = {
    thanks: t('thank_you'),
    firstParagraph: t('third_page_first_paragraph'),
    secondPage: t('any_questions_email'),
    edCall: t('ed_call_coverage'),
    thirdParagraph: t('third_page_third_paragraph'),
    medicaldirection: t('medical_direction'),
    fourthParagraph: t('third_page_fourth_paragraph'),
    providers: t('providers_and_facilities'),
    fifthParagraph: t('third_page_fifth_paragraph'),
    best: t('best'),
    mdTeam: t('md_team'),
    appendixType,
    aboutUs: t('about_us'),
    traditionalWay: t('traditional_way'),
    nowDate: currentDate,
  };

  const hbrPageTexts: HbrSpecialProps = {
    aboutMd: t('about_md'),
    since2009: t('since_2009'),
    ourBenchmarkds: t('our_benchmarks'),
    thanksGrowing: t('thanks_to_growing'),
    benchmarksCalculated: t('md_benchmarks_calculated'),
    calculatedExclude: t('calculated_exclude'),
    makeSeparateDetermination: t('make_separate_determination'),
    allBenchShow: t('all_benchmarks_show'),
    fullyComplies: t('fully_complies_federal'),
    hospitalBased: t('hospital_based'),
    generallyContainVariables: t('generally_contain_variables'),
    generallySpecifyClinical: t('generally_specify_clinical'),
    hbReportsContain: t('hb_reports_contains'),
    totalAnnualPayments: t('total_annual_payments'),
    mdUsesTotal: t('md_uses_the_total'),
    excludingMedicalDirection: t('excluding_medical_direction'),
    someHospitalBased: t('some_hospital_based'),
    netAnnualPayments: t('net_annual_payments'),
    forSeveralServices: t('for_several_services'),
    perDiemEquivalent: t('per_diem_equivalent'),
    dividingTheAnnual: t('dividing_the_annual'),
    annualRanges: t('annual_ranges'),
    containPaymentsServices: t('contain_payments_services'),
    perDiemRanges: t('per_diem_ranges'),
    onlyIncludeSpecifically: t('only_include_specifically'),
    subscribingHospitalsReport: t('subscribing_hospitals_report'),
    anyQuestions: t('any_questions_email'),
    best: t('best'),
    mdTeam: t('md_team'),
    nowDate: currentDate,
  };

  const fourthPageTexts: FourthPageTextsInterface = {
    howUse: t('how_to_use'),
    howUseText: t('how_to_use_text'),
    percentStatistic: t('percent_statistic'),
    percentText: t('percent_text'),
    paymentRanges: t('payment_ranges'),
    paymentRangesFirstText: t('payment_ranges_first_paragraph'),
    paymentRangesSecondText: t('payment_ranges_second_paragraph'),
    nowDate: currentDate,
  };

  const AppendixBProps: AppendixBPropsInterface = {
    textData: data.appendixB,
    nowDate: currentDate,
  };

  const AppendixCProps: any = {
    appendixType,
    nowDate: currentDate,
  };
  return (
    <Document id={id ?? ''}>
      {FrontPage(frontPageTexts)}
      {SecondPage(secondPageTexts)}
      {ThirdPage(thirdPageTexts)}
      {reportType === 'hbr' ? HbrSpecialPages(hbrPageTexts) : null}
      {FourthPage(fourthPageTexts)}
      {reportType === 'ssr'
        ? SingleServiceTable(sectioning, currentDate)
        : HBRServicesTablePages(sectioning, currentDate)}
      {textPage ? <TextPage textPage={textPage} /> : null}
      {sectioning &&
        SupplementalBenchmark(
          reportType,
          currentDate,
          sectioning.percentpaying,
          sectioning.counts,
        )}
      <AppendixGlossary
        appendixAInfo={sectioning.appendixA}
        nowDate={currentDate}
      />
      {AppendixB(AppendixBProps)}
      {AppendixC(AppendixCProps)}
    </Document>
  );
}

export default SingleServicePdfTemplate;
