import React, { MouseEvent } from 'react';
import { Button, Label } from './style';
import ConfirmButtonInterface from './interface';
import DotLoader from '../DotLoader';

function ConfirmButton({
  label,
  onClick,
  loading,
  propRef,
  color,
}: ConfirmButtonInterface) {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (loading) {
      return null;
    }

    return onClick(event);
  };

  return (
    <Button
      ref={propRef}
      onClick={(event: MouseEvent<HTMLButtonElement>) => handleClick(event)}
    >
      {loading ? (
        <DotLoader color={color} marginTop={-20} />
      ) : (
        <Label>{label}</Label>
      )}
    </Button>
  );
}

export default ConfirmButton;
