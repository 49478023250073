import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from './style';
import TextareaInterface from './interface';

function TextArea({
  value,
  callback,
  placeholder,
  width,
  maxLen,
  minHeight,
}: TextareaInterface) {
  const { t } = useTranslation('input');
  const [eventValue, setEventValue] = useState<string>('');
  const inputRef = useRef(null);

  const handleMaxLenException = (max: number) => {
    const text = t('max_length', { max_len: max });

    window.alert(text);

    // @ts-ignore
    inputRef.current.value = '';
    setEventValue('');
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (maxLen && eventValue.length && eventValue.length > maxLen) {
        return handleMaxLenException(maxLen);
      }

      return callback(eventValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [eventValue]);

  return (
    <Element
      value={value}
      onChange={(event) => setEventValue(event.target.value)}
      placeholder={placeholder}
      width={width}
      minHeight={minHeight}
    />
  );
}

export default TextArea;
