import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 600px;
  min-height: 200px;
  position: fixed;
  left: 50%;
  top: 50px;
  margin-left: -300px;
  opacity: 1;
  padding-top: 50px;
  border: 2px solid #eeeeee;
  border-radius: 8px;
  z-index: 9999;
  background-color: #fff;

  > :nth-child(1) {
    margin-top: 0px;
    font-size: 16px;
    font-weight: 500;
  }

  > :nth-child(2) {
    width: max-content;
    text-align: justify;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: max-content;
  width: 100%;
  padding: 20px;
  z-index: 9999;
  border-top: 0.25px solid var(--primary-light-gray);
`;

export const CloseButton = styled.button`
  min-height: 33px;
  min-width: 60px;
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
  border-radius: 4px;
  margin-left: auto;
  z-index: 9999;

  cursor: pointer;

  > :nth-child(1) {
    font-family: inherit;
    color: #dddddd;
    font-size: 11px;
  }

  :focus {
    border: 1px solid black;
  }
`;
