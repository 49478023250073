import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/atoms/Table';
import Footer from 'src/components/organisms/Footer';

import Title from 'src/components/atoms/Title';
import { Page, Section, Text } from './style';
import {
  EDColumns,
  EDRows,
  MDColumns,
  MDRows,
  exampleColumns,
  exampleRows,
} from '../UsingBenchmark/data';

function MultiCampusPage() {
  const { t } = useTranslation('multi-campus');

  const TEXT_FONT_SIZE = 16;

  const legends = [
    'ED Call Coverage: Interventional Radiology',
    'Per Campus Equivalent',
  ];

  return (
    <Page>
      <Section className="firstSection">
        <Title text={t('title')} />
        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('first_paragraph')}
        </Text>

        <Text fontWeight="normal" fontSize={TEXT_FONT_SIZE}>
          {t('second_paragraph')}
        </Text>
      </Section>

      <Section>
        <Table
          title="ED Contracts"
          subtitle="Call coverage increment factor 44% of single campus rate added for each additional campus"
          dataInfo="3 year rolling average discount (inclusive of 2023 data, 2024 release)"
          columns={EDColumns}
          data={EDRows}
        />

        <Table
          title="Medical Direction Contracts"
          subtitle="Hours for each additional campus cost 32% of single campus rate, and hourly rate 13% higher, regardless of number of campuses"
          dataInfo="3 year rolling average discount (inclusive of 2023 data, 2024 release)"
          columns={MDColumns}
          data={MDRows}
        />

        <Table
          width={776}
          columns={exampleColumns}
          legendText="Example for: "
          data={exampleRows}
          legends={legends}
        />
      </Section>
      <Section className="lastSection" />
      <Footer />
    </Page>
  );
}

export default MultiCampusPage;
