import styled from 'styled-components';

export const StaticText = styled.span`
  margin: 0 auto;
  text-align: center;
  color: var(--secondary-dark-gray);
  font-size: 12px;
  font-weight: 400;
`;

export const LinkedText = styled(StaticText)`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;
