import React from 'react';
import { MDRangerECGLogo } from 'src/global/PngImages';
import { Page, IconContainer, AverageTextHolder, AverageText } from '../style';

import ReportFooter from '../../../../organisms/Reports/Footer';

export interface FrontPageTextsInterface {
  title: string;
  subtitle: string;
  year: string;
  reportPrepared: string;
  confidential: string;
  daynameDate: string;
  nowDate: string;
}

function FrontPage(frontProps: FrontPageTextsInterface) {
  const {
    title,
    subtitle,
    year,
    reportPrepared,
    confidential,
    daynameDate,
    nowDate,
  } = frontProps;

  return (
    <Page className="frontPage">
      <div />
      <IconContainer>
        <img src={MDRangerECGLogo} style={{ width: 190 }} alt="mdranger" />
      </IconContainer>
      <AverageText className="logoSubtitle" fontSize={9} color="#2451CB">
        {subtitle}
      </AverageText>
      <AverageTextHolder height={50} marginTop={40}>
        <AverageText fontSize={18} fontWeight="bold">
          {title}
        </AverageText>
        <AverageText fontSize={18} fontWeight="bold">
          {year}
        </AverageText>
      </AverageTextHolder>
      <AverageTextHolder height={45} marginTop={80}>
        <AverageText fontSize={11} fontWeight="bold">
          {reportPrepared}
        </AverageText>
        <AverageText fontSize={11} fontWeight="bold">
          {daynameDate}
        </AverageText>
      </AverageTextHolder>
      <AverageTextHolder marginTop={30}>
        <AverageText fontSize={11}>{confidential}</AverageText>
      </AverageTextHolder>
      <ReportFooter nowDate={nowDate} />
    </Page>
  );
}

export default FrontPage;
