import styled from 'styled-components';
import { FaTimesCircle } from 'react-icons/fa';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 650px;
  height: 400px;
  position: fixed;
  left: 50%;
  top: 100px;
  margin-left: -300px;
  opacity: 1;
  padding: 25px;
  border: 2px solid #333;
  z-index: 9999;
  background-color: #fff;

  > :nth-child(1) {
    position: absolute;
    right: 2px;
  }
`;

export const Column = styled.div`
  display: flex;
  width: 13%;
  flex-direction: column;
  align-items: end;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: start;
`;

export const CloseButton = styled(FaTimesCircle)`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  cursor: pointer;
  color: var(--secondary-light-gray);
`;
