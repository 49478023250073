import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa6';
import CheckboxStyles from '../../../contracts/models/CheckboxStyle.model';

interface CircularInputProps {
  $minHeight?: string;
  $minWidth?: string;
  $inputStyle?: CheckboxStyles;
}

interface LabelInterface {
  $labelStyle?: CheckboxStyles;
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  cursor: pointer;
  flex-direction: row;

  > :nth-child(1) {
    margin-right: 10px;
  }
`;

export const Input = styled.input.attrs({
  type: 'checkbox',
})`
  cursor: pointer;
  transform: scale(1.3);
`;

export const CustomInputContainer = styled.label`
  display: inline-block;
  cursor: pointer;
  user-select: none;
`;

export const CustomInputCheckbox = styled.input`
  display: none;
`;

export const CustomInputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  border-radius: 2.4px;
  border: 1.2px solid var(--light-border);
  background-color: #ffffff;

  ${CustomInputCheckbox}:checked + & {
    background-color: var(--ecg-royal-blue);
    border: none;
  }
`;

export const CheckIcon = styled(FaCheck)`
  color: white;
  font-size: 16px;
`;

export const CircularInput = styled.input.attrs<CircularInputProps>(
  ({ $minHeight, $minWidth }) => ({
    type: 'radio',
    style: {
      minWidth: $minWidth || '19px',
      minHeight: $minHeight || '19px',
    },
  }),
)<CircularInputProps>`
  cursor: pointer;
  transform: scale(1.2);
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: ${(props) =>
    props.$inputStyle?.default?.border
      ? props.$inputStyle.default.border
      : '1px solid var(--almost-black)'};
  appearance: none;
  -webkit-appearance: none;
  margin: ${(props) =>
    props.$inputStyle?.default?.margin ? props.$inputStyle.default.margin : ''};

  &:checked {
    background-color: ${(props) =>
      props.$inputStyle?.selected?.backgroundColor
        ? props.$inputStyle.selected.backgroundColor
        : 'gray'};

    border: ${(props) =>
      props.$inputStyle?.selected?.border
        ? props.$inputStyle.selected.border
        : '3px solid white'};
    outline: ${(props) =>
      props.$inputStyle?.selected?.outline
        ? props.$inputStyle.selected.outline
        : '2px solid var(--almost-black)'};
  }
`;

export const Label = styled.label<LabelInterface>`
  width: 100%;
  color: ${(props) =>
    props.disabled
      ? props.$labelStyle?.label?.disabled?.color || 'gray'
      : props.$labelStyle?.label?.color || 'var(--almost-black)'};
  font-size: ${(props) =>
    props.$labelStyle?.label?.fontSize
      ? props.$labelStyle.label.fontSize
      : '16px'};
  line-height: 19px;
  text-align: left;
  cursor: pointer;
  font-weight: ${(props) =>
    props.$labelStyle?.label?.fontWeight
      ? props.$labelStyle.label.fontWeight
      : 'bold'};
`;
