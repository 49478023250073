import React, { useState, useEffect } from 'react';
import SearchSalaryTemplate from '../../components/templates/Reports/SearchSalary';
import SearchBenchmarks from '../searchBenchmarks';

function SearchSalaryPdf() {
  const [pdfData, setPdfData] = useState<any>();

  const getSearchPdfData = async () => {
    const queryParams = window.location.href
      .split('/search-pdf?')[1]
      .split('&')
      .splice(1)
      .join('&');

    const response = await SearchBenchmarks.pdf(queryParams);

    if (!response.failed) {
      setPdfData(response.data);
    }
  };

  useEffect(() => {
    getSearchPdfData().then(null);
  }, []);

  return pdfData ? <SearchSalaryTemplate data={pdfData} /> : null;
}

export default SearchSalaryPdf;
