import React, { useEffect, useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { createPopper } from '@popperjs/core';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow.js';
import flip from '@popperjs/core/lib/modifiers/flip.js';
import { Container } from './style';
import HelpToolTipInterface from './interface';
import makeId from '../../../utils/idGenerator';

function HelpTooltip({ size, text }: HelpToolTipInterface) {
  const iconId: string = makeId(10);
  const tooltipId: string = makeId(10);

  const [popperInstance, setPopperInstance] = useState<any>(null);
  const [tooltip, setTooltip] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    const iconElement = document.querySelector<HTMLInputElement>(`#${iconId}`);
    const tooltipElement = document.querySelector<HTMLInputElement>(
      `#${tooltipId}`,
    );

    setTooltip(tooltipElement);

    const instance = createPopper(iconElement!, tooltipElement!, {
      placement: 'bottom-start',
      modifiers: [
        {
          modifiers: [preventOverflow, flip],
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });

    setPopperInstance(instance);
  }, []);

  const show = () => {
    tooltip?.setAttribute('data-show', '');

    popperInstance?.setOptions((options: any) => ({
      ...options,
      modifiers: [
        ...options!.modifiers!,
        { name: 'eventListeners', enabled: true },
      ],
    }));

    popperInstance?.update();
  };

  const hide = () => {
    tooltip?.removeAttribute('data-show');

    popperInstance.setOptions((options: any) => ({
      ...options,
      modifiers: [
        ...options!.modifiers!,
        { name: 'eventListeners', enabled: false },
      ],
    }));
  };
  return (
    <Container tooltipId={tooltipId}>
      <FaRegQuestionCircle
        onMouseOutCapture={show}
        onMouseLeave={hide}
        id={iconId}
        size={size ?? 20}
        color="gray"
      />
      <div id={tooltipId} role="tooltip">
        {text}
        <div id="arrow" data-popper-arrow />
      </div>
    </Container>
  );
}

export default HelpTooltip;
