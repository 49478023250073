import React, { useEffect } from 'react';
import Loader from '../../components/atoms/DotLoader';
import PdfMicroService from '../../services/pdfMicroService';

function PDFBuilder() {
  const buildPdf = async (url: string) => {
    const response = await PdfMicroService.buildPdf(
      url,
      'SingleServiceReport.pdf',
    );

    if (!response.failed) {
      window.open(response.data.url, '_self');
    }
  };

  useEffect(() => {
    const url = window.location.href;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const route = urlParams.get('route');

    const actualUrl = url.replace(window.location.pathname, `/${route}`);
    buildPdf(actualUrl).then(null);
  }, []);

  return (
    <div className="DefaultPdfLoading">
      <Loader color="#2451CB" />
    </div>
  );
}

export default PDFBuilder;
