import React from 'react';
import format from 'date-fns/format';
import { FooterContainer, FooterTitle } from './style';
import FooterInterface from './interface';

function ReportFooter({
  nowDate,
  datayear,
  isSalary,
  customText,
}: FooterInterface) {
  const YEAR_TO_USE = datayear ?? 2024;
  const generateDate = nowDate ?? format(new Date(), 'MM/dd/yyyy');

  const defaultFooter = (id: string) => (
    <FooterTitle id={id}>
      {customText ||
        `Report generated ${generateDate}. MD Ranger ${
          YEAR_TO_USE - 1
        }-${YEAR_TO_USE} Benchmarks, published May 8, 2024. Copyright © ${YEAR_TO_USE} MD Ranger, Inc.`}
    </FooterTitle>
  );

  const salaryFooter = (id: string) => (
    <FooterTitle id={id}>
      {`Report generated ${generateDate}. Copyright ©${YEAR_TO_USE} MD Ranger Inc. Non-MD Ranger benchmarks licensed from ECG’s ${
        YEAR_TO_USE - 1
      } and ${YEAR_TO_USE} National Physician and APP Compensation & Production Survey. All rights reserved.`}
    </FooterTitle>
  );

  const selectFooter = (id: string, styleCheck: string) => {
    switch (styleCheck) {
      case 'salary':
        return salaryFooter(id);

      default:
        return defaultFooter(id);
    }
  };

  return (
    <FooterContainer>
      {selectFooter('footer', isSalary ? 'salary' : '')}
    </FooterContainer>
  );
}

export default ReportFooter;
