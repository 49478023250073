import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  display: block;
  clear: left;
  background-color: var(--ecg-royal-blue);
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 0 4px 7px 0 rgb(0 0 0 / 9%);
  width: 257px;
  height: 50px;
  color: #ffffff;
`;

export const Label = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;
