import React, { useEffect, useRef, useState } from 'react';
import { Element } from './style';
import Checkbox from '../../atoms/Checkbox';
import CheckboxGroupInterface from './interface';

function CheckboxGroup({
  options,
  onChange,
  initialChecked,
}: CheckboxGroupInterface) {
  const itemsRef = useRef<Array<HTMLInputElement>>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const checkboxStyle = {
    label: {
      color: 'var(--border-black)',
      fontSize: '16px',
      fontWeight: 400,
    },
  };

  const handleOptionChange = () => {
    const checkedOptions = itemsRef.current
      .filter((item) => item?.checked)
      .map((item) => item?.name ?? '');

    setSelectedOptions(checkedOptions);
  };

  useEffect(() => {
    if (initialChecked) {
      setSelectedOptions(initialChecked);
    }
  }, [initialChecked]);

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  return (
    <Element>
      {options.map((option, index: number) => (
        <Checkbox
          id={buildId()}
          key={option}
          label={option}
          onChange={() => handleOptionChange()}
          // @ts-ignore
          propRef={(element: HTMLInputElement) => {
            itemsRef.current[index] = element;
          }}
          initialValue={selectedOptions.includes(option)}
          propName={option}
          style={checkboxStyle}
          format="custom"
        />
      ))}
    </Element>
  );
}

export default CheckboxGroup;
