import React, { useState, useEffect } from 'react';
import {
  Document,
  Page,
  AverageTextHolder,
  AverageText,
  PointItem,
} from '../style';

import ReportHeader from '../../../../organisms/Reports/Header';
import ReportFooter from '../../../../organisms/Reports/Footer';

import GeneralServices from '../../../../../services/generalServices';

function TextContainer({ text, weight, size }: any) {
  return (
    <AverageTextHolder marginTop={10} alignContent="flex-start">
      <AverageText fontSize={size ?? 9.5} fontWeight={weight}>
        {text}
      </AverageText>
    </AverageTextHolder>
  );
}

function TextPage({ textPage, nowDate }: any) {
  const [pageData, setPageData] = useState<any>();

  const textElement = (texts: string[]) => {
    if (!texts.length) return null;
    const tag = texts[0];
    switch (tag) {
      case 'h2':
        return (
          <AverageTextHolder marginTop={20}>
            <AverageText fontSize={16} fontWeight="bold">
              {texts[1]}
            </AverageText>
            <AverageText className="textPageTitle">{texts[1]}</AverageText>
          </AverageTextHolder>
        );
      case 'p':
        return <TextContainer text={texts[1]} />;
      case 'h3':
        return <TextContainer text={texts[1]} size={10} weight="bold" />;
      case 'li':
        return <PointItem className="test">{texts[1]}</PointItem>;
      default:
        break;
    }
    return null;
  };

  const getPageText = async () => {
    const response = await GeneralServices.getHBRCustomTexts(textPage);

    const text = await response.text();
    const splitByColoun = text.split(':');
    setPageData(splitByColoun);
  };

  // @ts-ignore
  useEffect(() => {
    getPageText().then(null);
  }, []);

  return (
    <Document>
      <Page>
        <ReportHeader title="" />
        {pageData
          ? pageData.map((element: string) => {
              const eachElement = element.split('\n\n');
              return textElement(eachElement);
            })
          : null}
        <ReportFooter nowDate={nowDate} />
      </Page>
    </Document>
  );
}

export default TextPage;
