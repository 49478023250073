import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Container, Input, SearchIcon } from './style';
import SearchInputInterface from './interface';

function SearchInput({
  placeholder,
  callback,
  width,
  height,
  paddingTop,
  options,
  searchRef,
  isBenchmark,
}: SearchInputInterface) {
  const [open, setOpen] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');

  const startWithSearch = (search: string, text: string) =>
    text.slice(0, search.length) === search;

  const hasSearchInAnyDistinctWordStart = (search: string, text: string) =>
    text.includes(` ${search}`);

  const filterOptions = (services: any, state: any) => {
    const newOptions: any = [];
    const inputAsLowerCase: string = state.inputValue.toLowerCase();

    services.forEach((element: string) => {
      const elementLowerCase = element.toLowerCase();
      if (
        startWithSearch(inputAsLowerCase, elementLowerCase) ||
        hasSearchInAnyDistinctWordStart(inputAsLowerCase, elementLowerCase)
      ) {
        newOptions.push(element);
      }
    });

    return newOptions;
  };

  const handleChange = (_: any, value: any) => {
    setInputText(value ?? '');
    callback(value ?? '');
    setOpen(false);
  };

  return (
    <Container width={width} isBenchmark={isBenchmark}>
      <SearchIcon isBenchmark={isBenchmark} />
      <Input
        id="search-container"
        value={inputText}
        open={open}
        onInputChange={(_, value) => {
          if (value.length === 0) {
            if (open) setOpen(false);
          } else if (!open) setOpen(true);
        }}
        onChange={handleChange}
        disablePortal
        options={options ?? []}
        filterOptions={filterOptions as any}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            onClick={() => handleChange(true, '')}
          />
        )}
        ref={searchRef ?? undefined}
        height={height}
        paddingTop={paddingTop}
      />
    </Container>
  );
}

export default SearchInput;
