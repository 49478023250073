import React, { useEffect, useState } from 'react';
import { Container, Helper, Row } from './style';
import PlusButton from '../../../atoms/PlusButton';
import Input from '../../Input';
import ComparisonConditionalInputInterface from './interface';
import Loader from '../../../atoms/DotLoader';
import RateComparison from '../../../../services/rateComparison';
import ConditionalPercentileIcon from '../ConditionalPercentileIcon';
import LightColors from '../../../../constants/lightColors';

function ComparisonConditionalInputDignity({
  inputPlaceholder,
  buttonPlaceholder,
  inputType,
  inputFooter,
  width,
  selectedService,
  identifier,
  trauma,
  inputCallback,
  value,
  withoutButton,
  helper,
}: ComparisonConditionalInputInterface) {
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');

  const [shownLight, setShownLight] = useState<string | undefined>();

  const NONELIGHT = LightColors.none;

  const buttonHandle = (clicked: boolean) => {
    if (!clicked) {
      setInputText('');
    }
    setShow(clicked);
  };

  const findResult = async () => {
    const response = await RateComparison.get(
      selectedService,
      identifier,
      inputText ?? '',
      trauma ? 1 : 0,
    );

    if (response.failed) {
      setLoading(false);
      return setShownLight(NONELIGHT);
    }

    const responseColor: LightColors =
      response.data?.[0]?.[identifier] ?? NONELIGHT;
    setShownLight(responseColor);

    return setLoading(false);
  };

  useEffect(() => {
    inputCallback(inputText);
    if (!inputText) {
      setShownLight(undefined);
      return setLoading(false);
    }

    findResult().then(null);
    return setLoading(true);
  }, [inputText]);

  useEffect(() => {
    if (value || value === '') {
      setInputText(value);
    }
  }, [value]);

  useEffect(() => {
    if (inputText) {
      setLoading(true);
      findResult().then(() => null);
    }
  }, [trauma]);

  useEffect(() => {
    buttonHandle(false);
  }, [selectedService]);

  useEffect(() => {
    if (withoutButton) {
      setShow(true);
    }
  }, [withoutButton]);

  return (
    <Container $withouButton={withoutButton}>
      {!withoutButton && (
        <PlusButton
          placeholder={buttonPlaceholder ?? ''}
          callback={buttonHandle}
          toggleable
          bind={show}
        />
      )}
      {show && (
        <Row>
          <Input
            focus
            title={inputPlaceholder}
            callback={(event: any) => setInputText(event)}
            type={inputType}
            width={width}
            value={value}
          />
          {loading && inputText && <Loader />}
          {shownLight && inputText && !loading ? (
            <ConditionalPercentileIcon percentileIndicator={shownLight} />
          ) : null}
        </Row>
      )}
      {inputFooter && show ? inputFooter : null}
      {helper && show ? <Helper>{helper}</Helper> : null}
    </Container>
  );
}
export default ComparisonConditionalInputDignity;
