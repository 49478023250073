/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { Container, Row } from './style';
import PlusButton from '../../atoms/PlusButton';
import Input from '../Input';
import ComparisonConditionalMultipleInputInterface from './interface';
import Loader from '../../atoms/DotLoader';
import RateComparison from '../../../services/rateComparison';
import ConditionalPercentileIcon from '../ConditionalPercentileIcon';
import LightColors from '../../../constants/lightColors';
import Subtitle from '../../atoms/Subtitle';

function ComparisonConditionalMultipleInput({
  inputsPlaceholder,
  buttonPlaceholder,
  width,
  selectedService,
  identifiers,
  trauma,
  inputsCallback,
  values,
  hiddenInputs,
  hiddenMessage,
  slices,
}: ComparisonConditionalMultipleInputInterface) {
  const [show, setShow] = useState<boolean>(false);

  const [firstInput, setFirstInput] = useState<string>('');
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  const [firstLight, setFirstLight] = useState<string | undefined>();
  const [firstLightSlices, setFirstLightSlices] = useState<string[]>([]);

  const [secondInput, setSecondInput] = useState<string>('');
  const [secondLoading, setSecondLoading] = useState<boolean>(false);
  const [secondLight, setSecondLight] = useState<string | undefined>();
  const [secondLightSlices, setSecondLightSlices] = useState<string[]>([]);

  const [thirdInput, setThirdInput] = useState<string>('');
  const [thirdLoading, setThirdLoading] = useState<boolean>(false);
  const [thirdLight, setThirdLight] = useState<string | undefined>();
  const [thirdLightSlices, setThirdLightSlices] = useState<string[]>([]);

  const [fourthInput, setFourthInput] = useState<string>('');
  const [fourthLoading, setFourthLoading] = useState<boolean>(false);
  const [fourthLight, setFourthLight] = useState<string | undefined>();
  const [fourthLightSlices, setFourthLightSlices] = useState<string[]>([]);

  const NONELIGHT = LightColors.none;

  const notCurrency = ['HH'];

  const isCurrency = (identifier: string) =>
    notCurrency.includes(identifier) ? 'number' : 'currency';

  const buttonHandle = (clicked: boolean) => {
    if (!clicked) {
      setSecondInput('');
      setThirdInput('');
      setFirstInput('');
      setFourthInput('');
    }

    setShow(clicked);
  };

  const findResult = async (identifier: string, value: string) =>
    RateComparison.get(
      selectedService,
      identifier,
      value,
      trauma ? 1 : 0,
      slices.length > 0 ? slices.join(',') : undefined,
    );

  const handleChangeInput = async (
    index: number,
    value: string,
    setLoading: Function,
    setValue: Function,
    setLight: Function,
    setLightSlices: Function,
  ) => {
    if (!value || !value.length) {
      setValue('');
      return false;
    }
    setValue(value);
    setLoading(true);

    const response = await findResult(identifiers[index], value ?? '');

    if (response.failed) {
      setLoading(false);
      return setLight(NONELIGHT);
    }

    const responseColor: LightColors =
      response.data?.[0]?.[identifiers[index]] ?? NONELIGHT;

    setLight(responseColor);

    if (slices.length > 0 && responseColor !== NONELIGHT) {
      const indicators = Object.keys(responseColor);
      const indicatorSlices = Object.values(responseColor) ?? [];

      setLight(indicators);
      setLightSlices(indicatorSlices);
    }

    setLoading(false);
    return true;
  };

  useEffect(() => {
    inputsCallback({
      firstInput,
      secondInput,
      thirdInput,
      fourthInput,
    });
  }, [firstInput, secondInput, thirdInput, fourthInput]);

  useEffect(() => {
    if (values && values?.length >= 1 && firstInput) {
      handleChangeInput(
        0,
        firstInput,
        setFirstLoading,
        setFirstInput,
        setFirstLight,
        setFirstLightSlices,
      );
    }
    if (values && values?.length >= 2 && secondInput) {
      handleChangeInput(
        1,
        secondInput,
        setSecondLoading,
        setSecondInput,
        setSecondLight,
        setSecondLightSlices,
      );
    }

    if (values && values?.length >= 3 && thirdInput) {
      handleChangeInput(
        2,
        thirdInput,
        setThirdLoading,
        setThirdInput,
        setThirdLight,
        setThirdLightSlices,
      );
    }

    if (values && values?.length >= 4 && fourthInput) {
      handleChangeInput(
        3,
        fourthInput,
        setFourthLoading,
        setFourthInput,
        setFourthLight,
        setFourthLightSlices,
      );
    }
  }, [trauma, slices]);

  function ShowResponseIcon(
    loading: boolean,
    light: any,
    input: string,
    conditionalSlices: string[],
  ) {
    return (
      <>
        <div style={{ marginTop: -15 }}>{loading && input && <Loader />}</div>
        {light &&
          input &&
          !loading &&
          (Array.isArray(light) ? (
            // eslint-disable-next-line react/destructuring-assignment
            light.map((item, index) => (
              <ConditionalPercentileIcon
                key={item}
                percentileIndicator={item}
                // @ts-ignore
                slicesIndicator={conditionalSlices[index]}
              />
            ))
          ) : (
            <ConditionalPercentileIcon percentileIndicator={light} />
          ))}
      </>
    );
  }

  useEffect(() => {
    buttonHandle(false);
  }, [selectedService]);

  return (
    <Container>
      <PlusButton
        placeholder={buttonPlaceholder}
        callback={buttonHandle}
        toggleable
        bind={show}
      />
      {show && (
        <>
          {values &&
            values?.length >= 1 &&
            !hiddenInputs?.includes(identifiers[0]) && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <Subtitle text={inputsPlaceholder[0]} />
                <Row $marginBottom={(slices.length > 0).toString()}>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                  >
                    <Input
                      focus
                      callback={(event: any) =>
                        handleChangeInput(
                          0,
                          event,
                          setFirstLoading,
                          setFirstInput,
                          setFirstLight,
                          setFirstLightSlices,
                        )
                      }
                      type={isCurrency(identifiers[0])}
                      width={width}
                      value={values![0]!}
                    />
                  </div>
                  {ShowResponseIcon(
                    firstLoading,
                    firstLight,
                    firstInput,
                    firstLightSlices,
                  )}
                </Row>
              </div>
            )}
          {values &&
            values?.length >= 2 &&
            !hiddenInputs?.includes(identifiers[1]) && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <Subtitle text={inputsPlaceholder[1]} />
                <Row $marginBottom={(slices.length > 0).toString()}>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                  >
                    <Input
                      focus={
                        !hiddenInputs?.includes(identifiers[1]) &&
                        hiddenInputs?.includes(identifiers[0])
                      }
                      callback={(event: any) =>
                        handleChangeInput(
                          1,
                          event,
                          setSecondLoading,
                          setSecondInput,
                          setSecondLight,
                          setSecondLightSlices,
                        )
                      }
                      type={isCurrency(identifiers[1])}
                      width={width}
                      value={values![1]!}
                    />
                  </div>
                  {ShowResponseIcon(
                    secondLoading,
                    secondLight,
                    secondInput,
                    secondLightSlices,
                  )}
                </Row>
              </div>
            )}
          {values &&
            values?.length >= 3 &&
            !hiddenInputs?.includes(identifiers[2]) && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <Subtitle text={inputsPlaceholder[2]} />
                <Row $marginBottom={(slices.length > 0).toString()}>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                  >
                    <Input
                      focus={
                        !hiddenInputs?.includes(identifiers[2]) &&
                        hiddenInputs?.includes(identifiers[1])
                      }
                      callback={(event: any) =>
                        handleChangeInput(
                          2,
                          event,
                          setThirdLoading,
                          setThirdInput,
                          setThirdLight,
                          setThirdLightSlices,
                        )
                      }
                      type={isCurrency(identifiers[2])}
                      width={width}
                      value={values![2]!}
                    />
                  </div>
                  {ShowResponseIcon(
                    thirdLoading,
                    thirdLight,
                    thirdInput,
                    thirdLightSlices,
                  )}
                </Row>
              </div>
            )}
          {values &&
            values?.length >= 4 &&
            !hiddenInputs?.includes(identifiers[3]) && (
              <Row $marginBottom={(slices.length > 0).toString()}>
                <Input
                  focus={
                    !hiddenInputs?.includes(identifiers[3]) &&
                    hiddenInputs?.includes(identifiers[2])
                  }
                  title={inputsPlaceholder[3]}
                  callback={(event: any) =>
                    handleChangeInput(
                      3,
                      event,
                      setFourthLoading,
                      setFourthInput,
                      setFourthLight,
                      setFourthLightSlices,
                    )
                  }
                  type={isCurrency(identifiers[3])}
                  width={width}
                  value={values![3]!}
                />
                {ShowResponseIcon(
                  fourthLoading,
                  fourthLight,
                  fourthInput,
                  fourthLightSlices,
                )}
              </Row>
            )}
          {hiddenMessage && <span>{hiddenMessage}</span>}
        </>
      )}
    </Container>
  );
}

export default ComparisonConditionalMultipleInput;
