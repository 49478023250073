import Axios from 'axios';
import Environment from 'src/config/environment';

const pdfMicro = Axios.create({
  baseURL: Environment.microPdf,
});

pdfMicro.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export default pdfMicro;
