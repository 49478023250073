import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import format from 'date-fns/format';
import {
  Container,
  FakeInput,
  InvisibleContent,
  Text,
  CalendarGroup,
} from './style';
import SubTitle from '../Subtitle';
import DatePickerInterface from './interface';

function DatePicker({
  label,
  onChange,
  changeFocus,
  pickerRef,
}: DatePickerInterface) {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [show, setShow] = useState<boolean>(false);

  const handleChange = (date: Date) => {
    setShow(false);
    setSelectedDate(date);
    onChange(date);
  };

  const blurChange = () => {
    if (typeof changeFocus !== 'undefined') {
      return pickerRef && changeFocus(pickerRef);
    }
    return null;
  };

  return (
    <Container>
      <SubTitle text={label} />
      <CalendarGroup
        id="calendarGroup"
        tabIndex={0}
        ref={pickerRef as any}
        onFocus={() => {
          setShow(true);
        }}
        onKeyDownCapture={(event: any) => {
          if (event.keyCode === 9) {
            blurChange();
            setShow(false);
          }
        }}
      >
        <FakeInput showInput={show} tabIndex={0}>
          <Text onClick={() => setShow(true)}>
            {selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
          </Text>
        </FakeInput>
        {show && (
          <DayPicker
            selected={selectedDate}
            onDayClick={handleChange}
            fromYear={new Date().getFullYear() - 5}
            toYear={new Date().getFullYear() + 5}
            captionLayout="dropdown"
          />
        )}
        {show && <InvisibleContent onClick={() => setShow(false)} />}
      </CalendarGroup>
    </Container>
  );
}

export default DatePicker;
