import React from 'react';
import { Title as TitleStyle } from './styles';
import TitleInterface from './interface';

function Title({ text, onClick, cursor }: TitleInterface) {
  return (
    <TitleStyle onClick={() => onClick?.()} cursor={cursor}>
      {text}
    </TitleStyle>
  );
}

export default Title;
