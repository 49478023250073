import styled from 'styled-components';

interface ChartsInterface {
  margin: number;
}

interface ColorBlocInterface {
  color?: string;
  width?: number;
}

interface PercentInterface {
  position: number;
}

export const BenchmarkInformation = styled.div<ChartsInterface>`
  display: flex;
  align-items: start;
  height: 105px;
  padding: 10px 30px;
  width: 100%;
  margin-top: ${(props) => props.margin}px;
`;

export const Left = styled.div`
  display: flex;
  width: 50%;
  padding-right: 20px;
  flex-direction: column;
`;

export const Right = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
`;

export const BenchmarkService = styled.span`
  font-size: 8px;
  margin-bottom: 3px;
  color: var(--ecg-royal-blue);
`;

export const Field = styled.span`
  font-size: 10px;
  margin-bottom: 3px;
  color: var(--ecg-royal-blue);
  font-weight: 400;
  letter-spacing: 0.2px;
`;

export const ComparisonService = styled.em`
  font-size: 7px;
  margin-bottom: 3px;
`;
export const CoverageTitle = styled.span`
  font-size: 7px;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const CoverageDetails = styled.span`
  font-size: 7px;
  margin-bottom: 3px;
`;

export const ColorBloc = styled.div<ColorBlocInterface>`
  height: 7px;
  width: 7px;
  background-color: ${(props) => props.color};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  > :first-child {
    margin-right: 3px;
  }
  > :nth-child(2) {
    margin: 0;
  }
`;
export const PercentileGroups = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-self: start;
  height: 25px;
  width: 340px;
  margin-left: 30px;
  background-color: #242424;
  margin-bottom: 20px;
`;

export const Color = styled.div<ColorBlocInterface>`
  height: 100%;
  width: ${(props) => props.width}px;
  background-color: ${(props) => props.color};
`;

export const Average = styled.div<ColorBlocInterface>`
  position: absolute;
  display: flex;
  align-items: start;
  justify-content: end;
  align-self: center;
  padding: 0 5px;
  background-color: #11427d;
  width: ${(props) => props.width}px;
  min-width: max-content;
  height: 10px;

  > span {
    color: white;
    font-size: 6px;
  }
`;

export const Percent = styled.span<PercentInterface>`
  position: absolute;
  color: var(--secondary-light-gray);
  margin-left: ${(props) => props.position}px;
  font-size: 11px;
  bottom: -14px;
`;

export const Line = styled.div`
  display: flex;
  width: 100%;
  height: 0.5px !important;
  background-color: var(--secondary-light-gray);
`;
