import styled from 'styled-components';

interface RowInterface {
  $margin?: string;
}

interface GeneralTileInterface {
  fontSize?: number;
}

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-pdf-height);
  padding: 0;
  top: 0;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 30px;
  margin: 20px 0;

  > :not(:first-child).regionTitle {
    margin-top: 50px;
  }
`;

export const RegionTitle = styled.h1`
  color: var(--ecg-royal-blue);
  font-family: inherit;
  font-size: 14px;
  font-weight: 900;
  margin: 0 0 12px;
  letter-spacing: -0.2px;
`;

export const Row = styled.div<RowInterface>`
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  margin: ${(props) => props.$margin};
  padding: 3px 0px;
`;

const BaseTile = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

export const RowFirstTile = styled(BaseTile)`
  width: 22%;
  height: max-content;
  background-color: transparent;
  color: var(--almost-black);
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 0.2px;
`;

export const RowGeneraltile = styled(RowFirstTile)<GeneralTileInterface>`
  width: 11%;
  height: max-content;
  justify-content: center;
  padding: 0;
  font-size: ${(props) => `${props.fontSize}px`};
`;

export const HeaderFirstTile = styled(RowFirstTile)`
  height: 20px;
  border-bottom: solid 1px #dbdbdb;
  background-color: var(--primary-light-gray);
  font-weight: bold;
`;

export const HeaderGeneralTile = styled(RowGeneraltile)`
  width: 11.15%;
  height: 20px;
  border-bottom: solid 1px #dbdbdb;
  border-left: solid 1px #dbdbdb;
  background-color: var(--primary-light-gray);
  font-weight: bold;
  padding: 0 8px;
`;

export const CharacteristcTile = styled(RowFirstTile)`
  height: max-content;
  padding-top: 2px;
  font-weight: bold;
  font-size: 9px;
  color: var(--ecg-royal-blue);
  white-space: nowrap;
`;

export const Line = styled.div`
  width: 100%;
  height: 0;
  border-top: solid 0.3px #dbdbdb;
  margin: 2px 0 8px;
`;
