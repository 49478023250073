import styled from 'styled-components';

interface buttonProps {
  clicked: boolean;
}

export const Button = styled.button<buttonProps>`
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: transparent;
  width: max-content;
  border: none;
  outline: none;
  color: var(--ecg-royal-blue);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  :hover {
    font-size: ${(props) => (!props.clicked ? '17px;' : '16px;')};
  }

  *:first-child {
    margin-right: 10px;
  }
`;
