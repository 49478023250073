import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Body,
  Chart,
  ChartContainer,
  ChartInfo,
  ChartTitleContainer,
  Container,
  Header,
  Line,
  Option,
  OptionDetails,
  OptionH1,
  OptionHeader,
  OptionsContainer,
  OptionTitle,
  Page,
  Placeholder,
  TableContainer,
} from './style';
import Title from '../../components/atoms/Title';
import LineChart from '../../components/atoms/LineChart';
import RadioButton from '../../components/molecules/RadioButton';
import CheckboxGroup from '../../components/molecules/CheckboxGroup';
import MDRangerService from '../../services/mdrangerServices';
import SearchService from '../../components/molecules/SearchService';
import SelectDropdown from '../../components/atoms/SelectDropDown';
import ConfirmButton from '../../components/atoms/ConfirmButton';
import store from '../../hooks/redux/store';
import TrendTable from '../../components/atoms/TrendTable';
import TrendAnalysis from '../../services/trendAnalysis';
import SubTitle from '../../components/atoms/Subtitle';
import { buildPdf } from '../../utils/buildPdf';
import VerticalBarChart from '../../components/atoms/VerticalBarChart';
import Footer from '../../components/organisms/Footer';

function TrendAnalysisPage() {
  const { t } = useTranslation('trend-analysis');

  interface BenchObject {
    benchmark: string;
    service: string;
    slicename: string;
    years: { [key: string]: any };
  }

  const CURRENT_YEAR = new Date().getFullYear();

  const previousCheckboxTypes = useRef<string[]>([]);

  const [services, setServices] = useState<string[]>([]);
  const [slices, setSlices] = useState<string[]>([]);
  const [type, setType] = useState<string>('');
  const [checkboxTypes, setCheckboxTypes] = useState<string[]>([]);
  const [service, setService] = useState<string>('');
  const [characteristic, setCharacteristic] = useState<string>('');
  const [defaultSelectedYears, setDefaultSelectedYears] = useState<string[]>(
    [],
  );
  const [years, setYears] = useState<string[]>([]);
  const [pdfSubmitted, setPdfSubmitted] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [charError, setCharError] = useState<boolean>(false);
  const [benchObject, setBenchObject] = useState<BenchObject[]>([]);
  const [seed, setSeed] = useState(1);

  const NO_SERVICE_HIDDEN = [
    'HC',
    'AD',
    'HD',
    'HH',
    'HR',
    'HB',
    'HA',
    'HS',
    'HY',
    'LH',
    'TM',
    'HP',
    'HQ',
    'HT',
    'PER',
    'EV',
    'EP',
    'EC',
    'ED',
  ];
  const [hiddenTypes, setHiddenTypes] = useState<string[]>(NO_SERVICE_HIDDEN);
  const [defaultFirstTypeSelected, setDefaultFirstTypeSelected] = useState<
    string | undefined
  >();

  const types: any = {
    HC: { label: t('hc_label'), options: {}, gap: '' },
    AD: {
      label: t('ad_label'),
      options: {
        HD: t('ad_hd_option'),
        HH: t('ad_hh_option'),
        HR: t('ad_hr_option'),
      },
      gap: '',
    },
    HB: {
      label: t('hb_label'),
      options: {
        HA: t('hb_ha_option'),
        HS: t('hb_hs_option'),
        HY: t('hb_hy_option'),
      },
      gap: '15px',
    },
    LH: {
      label: t('lh_label'),
      options: {},
      gap: '',
    },
    TM: {
      label: t('tm_label'),
      options: {
        HP: t('tm_hp_option'),
        HQ: t('tm_hq_option'),
        HT: t('tm_ht_option'),
      },
      gap: '',
    },
    PER: {
      label: t('per_label'),
      options: {
        EV: t('per_ev_option'),
        EP: t('per_ep_option'),
        EC: t('per_ec_option'),
        ED: t('per_ed_option'),
      },
      gap: '',
    },
  };

  const OPTION_YEARS = ['2020', '2021', '2022', '2023', '2024'];

  const loadServices = async () => {
    const response: any = await MDRangerService.getAll({ trends: 1 });

    if (!response.failed) {
      setServices(response.data.services);
    }
  };

  const isPERHidden = (hidden: string[]) => {
    const perOptions = Object.keys(types.PER.options);
    return perOptions.every((option: string) => hidden.includes(option));
  };

  const getHidden = async () => {
    const response: any = await TrendAnalysis.getHiddenTypes(service, 1);
    if (response.failed) return NO_SERVICE_HIDDEN;
    const hidden = response.data.hiddenTypes;
    return isPERHidden(hidden) ? [...hidden, 'PER'] : hidden;
  };

  const getSlices = async () => {
    const response: any = await TrendAnalysis.getSlices(
      service,
      checkboxTypes?.length > 0 ? `${type},${checkboxTypes.join(',')}` : type,
      1,
    );

    if (!response.failed) {
      const returnSlices = response.data.slices;
      setSlices(returnSlices);
      setCharacteristic(Object.keys(returnSlices)[0]);
    }
  };

  const buildChartTitle = (obj: any, year?: string) => {
    const characteristicTitle =
      (slices[characteristic as keyof typeof slices] as string) || '';
    return (
      <ChartInfo>
        <ChartTitleContainer>
          {year ? <OptionH1>{`${year} `}</OptionH1> : null}
          <OptionH1>{`${obj.service}`}</OptionH1>
          <OptionH1>{`Benchmarks: ${obj.benchmark}`}</OptionH1>
        </ChartTitleContainer>
        <SubTitle text={characteristicTitle} />
      </ChartInfo>
    );
  };

  const buildChartTable = (obj: any) => (
    <TableContainer>
      <OptionTitle>{t('benchmarks')}</OptionTitle>
      <TrendTable
        id="page-trend-table"
        values={obj}
        years={Object.keys(obj.years)}
      />
    </TableContainer>
  );

  const renderBenchObject = (item: any, index: number) => {
    const dataYears = Object.keys(item.years);
    const margin = index !== 0 ? 40 : 0;

    if (dataYears.length === 1) {
      const yearsKeys = Object.keys(item.years[dataYears[0]]);
      const yearsValues = Object.values(item.years[dataYears[0]]) as number[];

      return (
        <Chart $marginTop={margin} key={`${item.slicename}-bar-chart`}>
          {buildChartTitle(item, dataYears[0])}
          <VerticalBarChart
            id={`${item.slicename}-bar-chart`}
            xValues={yearsKeys}
            yValues={yearsValues}
          />
          {buildChartTable(item)}
        </Chart>
      );
    }

    return (
      <Chart $marginTop={margin} key={`${item.slicename}-line-chart`}>
        {buildChartTitle(item)}
        <LineChart benchObject={item} />
        {buildChartTable(item)}
      </Chart>
    );
  };

  const buildPDFAttributes = () => {
    const andURLEncoder = '%26';
    const encondedService = service.replace('&', andURLEncoder);
    const attributesObject = {
      service: encondedService,
      type:
        checkboxTypes.length > 0 ? `${type},${checkboxTypes.join(',')}` : type,
      slice: characteristic,
      years,
    };
    return attributesObject;
  };

  const handleBuildChart = async () => {
    let hasError = false;

    setFormError(false);
    setCharError(false);

    if (!characteristic) {
      hasError = true && !!service;
      setCharError(true && !!service);
    }

    if (!type) {
      hasError = true;
    }

    if (years.length < 2) {
      hasError = true && !!service;
      setFormError(true && !!service);
    }

    if (!service) {
      hasError = true;
    }
    if (hasError) return true;

    setFormError(false);
    setCharError(false);

    const response = await TrendAnalysis.getTrends(
      service,
      checkboxTypes?.length > 0 ? `${type},${checkboxTypes.join(',')}` : type,
      characteristic,
      years.join(','),
    );

    if (response.failed) {
      return setBenchObject([]);
    }

    return setBenchObject(response.data?.benchmarks);
  };

  const handleSubmit = async () => {
    setPdfSubmitted(true);
    const body: any = buildPDFAttributes();
    const paramsArray: string[] = [];
    Object.keys(body).map((key) => {
      if (body[key] === '') return null;
      if (Array.isArray(body[key]) && !body[key].length) return null;
      return paramsArray.push(`${key}=${body[key]}`);
    });
    const accessToken = store.getState().auth.token;
    const microUrl = `/trends-pdf?token=${accessToken}&${paramsArray.join(
      '&',
    )}`;
    const prefix = window.location.href.split('/trends')[0];
    const pdfTitle = `Trends${CURRENT_YEAR}.pdf`;

    await buildPdf(`${prefix}${microUrl}`, pdfTitle, setPdfSubmitted);
    setPdfSubmitted(false);
  };

  const handleChangeService = async (serviceSelected: any) => {
    if (!serviceSelected) {
      setHiddenTypes(NO_SERVICE_HIDDEN);
      setSlices([]);
      setCharacteristic('');
      setDefaultSelectedYears([]);
      setDefaultFirstTypeSelected(undefined);
      setBenchObject([]);
      setSeed(Math.random());
      return;
    }

    const hidden = await getHidden();

    setHiddenTypes(hidden);
    setHiddenTypes(hidden);
    const defaultFirstType = Object.keys(types).find((typeKey: string) => {
      const { options } = types[typeKey];
      const isCheckTypeHidden = Object.keys(options).every((key: string) =>
        hidden.includes(key),
      );
      return !isCheckTypeHidden || !hidden.includes(typeKey);
    });
    // const defaultFirstType = Object.keys(types).find(
    //   (item: string) => !hidden.includes(item),
    // );
    setDefaultFirstTypeSelected(defaultFirstType);
    setDefaultSelectedYears(OPTION_YEARS);
  };

  useEffect(() => {
    loadServices().then(null);
  }, []);

  useEffect(() => {
    handleChangeService(service).then(() => null);
    handleBuildChart().then(() => null);
  }, [service]);

  useEffect(() => {
    if (service && type) {
      getSlices().then(null);
    }
  }, [type, checkboxTypes]);

  useEffect(() => {
    if (
      previousCheckboxTypes?.current !== checkboxTypes &&
      !previousCheckboxTypes?.current?.some((element) =>
        checkboxTypes?.includes(element),
      )
    ) {
      setCharacteristic('');
    }

    handleBuildChart().then(() => null);
    previousCheckboxTypes.current = checkboxTypes;
  }, [checkboxTypes]);

  const validateTypeError = () => {
    if (service && !type) {
      return true;
    }

    return (type === 'AD' || type === 'HB') && checkboxTypes?.length === 0;
  };

  useEffect(() => {
    setCharError(false);
    handleBuildChart().then(() => null);
  }, [characteristic]);

  useEffect(() => {
    setFormError(false);

    if (years.length < 2) {
      setFormError(!!service && true);
    } else {
      handleBuildChart().then(() => null);
    }
  }, [years]);

  return (
    <Page>
      <Header>
        <Title text={t('title')} />
      </Header>
      <Line />
      <Body>
        <OptionsContainer>
          <Option>
            <OptionTitle>{t('service_title')}</OptionTitle>
            <SearchService
              placeholder={t('service_placeholder')}
              callback={setService}
              options={services}
            />
          </Option>
          <Option>
            <OptionTitle>{t('type_title')}</OptionTitle>
            <RadioButton
              key={seed}
              types={types}
              hiddenOptions={hiddenTypes}
              onSelectType={setType}
              onCheckboxChange={setCheckboxTypes}
              initialValue={defaultFirstTypeSelected}
            />
            {validateTypeError() && (
              <OptionDetails $error={validateTypeError()}>
                {t('type_details')}
              </OptionDetails>
            )}
          </Option>
          <Option>
            <OptionTitle>{t('characteristics_title')}</OptionTitle>
            <SelectDropdown
              key={seed}
              callBack={(result: any) => setCharacteristic(result?.id)}
              dataItems={slices}
              styleCheck="checkbox"
              placeholder="Select a characteristic"
              startValueKey={characteristic}
            />
            {charError && (
              <OptionDetails $error={charError}>
                {t('char_details')}
              </OptionDetails>
            )}
          </Option>
          <Option className="selectYears">
            <OptionHeader>
              <OptionTitle>{t('years_title')}</OptionTitle>
              <OptionDetails $error={formError}>
                {t('years_details')}
              </OptionDetails>
            </OptionHeader>
            <CheckboxGroup
              key={seed}
              options={OPTION_YEARS}
              initialChecked={defaultSelectedYears}
              onChange={setYears}
            />
          </Option>
          <Container className="confirmButton">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                alignItems: 'start',
              }}
            >
              <ConfirmButton
                loading={pdfSubmitted}
                label={t('generate_pdf')}
                onClick={() => handleSubmit()}
                color="var(--ecg-royal-blue)"
              />
              {pdfSubmitted ? (
                <span
                  style={{
                    height: 'fit-content',
                  }}
                >
                  If you encounter issues with PDF generation, please ensure
                  that pop-ups are enabled in your browser.
                </span>
              ) : null}
            </div>
          </Container>
        </OptionsContainer>
        <ChartContainer className="generatePDF">
          {benchObject &&
          Array.isArray(benchObject) &&
          benchObject.length > 0 ? (
            <>
              {benchObject.map((item: any, index: number) => (
                <>
                  {renderBenchObject(item, index)}
                  {benchObject.length > 1 &&
                  index !== benchObject.length - 1 ? (
                    <Line />
                  ) : null}
                </>
              ))}
            </>
          ) : (
            <Placeholder>{t('chart_placeholder')}</Placeholder>
          )}
        </ChartContainer>
      </Body>
      <Footer />
    </Page>
  );
}

export default TrendAnalysisPage;
