import React from 'react';
import { useTranslation } from 'react-i18next';
import { MDRangerLogo } from '../../global/SvgIcons';
import HomeTemplate from '../../components/templates/Home';
import Footer from '../../components/organisms/Footer';
import { Page } from './style';

function Home() {
  const { t } = useTranslation('home');

  return (
    <Page>
      <HomeTemplate
        title={t('title')}
        subtitle={t('subtitle')}
        Icon={MDRangerLogo}
      />
      <Footer />
    </Page>
  );
}

export default Home;
