/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportTextArea from 'src/components/molecules/ReportTextArea';
import Input from '../../molecules/Input';
import { Box } from './style';
import PeriodPicker from '../../molecules/PeriodPicker';
import Checkbox from '../../atoms/Checkbox';
import ReportsGeneratorInterface from './interface';
import ConfirmButton from '../../atoms/ConfirmButton';

function ReportGenerator({
  handleSubmit,
  handleInputsChange,
  loading,
  hasExperience,
  editPage,
}: ReportsGeneratorInterface) {
  const { t } = useTranslation('rate-comparison');

  const [reportTitle, setReportTitle] = useState<string>('');
  const [counterParty, setCounterParty] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [facility, setFacility] = useState<string>('');
  const [experienceYears, setExperienceYears] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [signature, setSignature] = useState<boolean>(true);

  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleSelectChange = (value: string) => {
    if (value === 'Generate a report') {
      handleSubmit();
    }
    setSelectedOption(value);
  };

  useEffect(() => {
    handleInputsChange({
      reportTitle,
      counterParty,
      startDate,
      endDate,
      facility,
      experienceYears,
      notes,
      signature,
    });
  }, [
    reportTitle,
    counterParty,
    startDate,
    endDate,
    facility,
    experienceYears,
    notes,
    signature,
  ]);

  return (
    <Box>
      <Input
        title={t('report_title')}
        callback={(value: any) => setReportTitle(value)}
        type={t('add_telemedicine_title')}
      />
      <Input
        title={t('counter_party')}
        callback={(value: any) => setCounterParty(value)}
        type={t('add_telemedicine_title')}
      />
      <PeriodPicker
        onChange={(data: any) => {
          setStartDate(data.start);
          setEndDate(data.end);
        }}
        title={t('effective-dates')}
      />
      <Input
        title={t('facility')}
        callback={(value: any) => setFacility(value)}
        type={t('add_telemedicine_title')}
      />
      {hasExperience ? (
        <Input
          title={t('experience_years')}
          tooltip={t('experience_tooltip')}
          callback={(value: any) => setExperienceYears(value)}
          type="number"
        />
      ) : null}
      <ReportTextArea
        title={t('notes')}
        callback={(value: string) => setNotes(value)}
      />
      <Checkbox
        id="signature"
        label={t('add-approval-signature-line')}
        onChange={(value: boolean) => setSignature(value)}
        initialValue
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <ConfirmButton
          loading={loading}
          label={t('generate-pdf-report')}
          onClick={() => handleSubmit()}
          color="#fff"
        />
        {loading ? (
          <span
            style={{
              height: 'fit-content',
            }}
          >
            If you encounter issues with PDF generation, please ensure that
            pop-ups are enabled in your browser.
          </span>
        ) : null}
      </div>
      {/* <Row>
        {!editPage ? (
          <>
            <SelectActionDropDown
              actionType="save"
              label="Save"
              userType="user"
              width="small"
              callBack={() => {}}
            />
            <SelectActionDropDown
              actionType="moreActions"
              label="More Actions"
              userType="user"
              width="medium"
              callBack={(selectedValue: string) => {
                handleSelectChange(selectedValue);
              }}
            />
          </>
        ) : (
          <SelectActionDropDown
            actionType="sendCreator"
            label="More Actions"
            userType="user"
            width="medium"
            callBack={() => {}}
          />
        )}
      </Row>
      */}
    </Box>
  );
}

export default ReportGenerator;
