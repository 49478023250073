/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TotalCompensation from 'src/services/totalCompensation';
import Loader from '../../atoms/DotLoader';
import Input from '../Input';
import CompensationSingleInputsInterface from './interface';
import ConditionalPercentileIcon from '../ConditionalPercentileIcon';
import { Row } from './style';
import LightColors from '../../../constants/lightColors';
import MessageModal from '../MessageModal';

function CompensationSingleInputs({
  min,
  max,
  title,
  callback,
  type,
  value,
  hasCustomResult,
  customResult,
  param,
  service,
  responseValue,
  region,
  physicianType,
  exceptionTitle,
  exceptionMessage,
  tooltip,
}: CompensationSingleInputsInterface) {
  const { t } = useTranslation('input');

  const [inputText, setInputText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [shownLight, setShownLight] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const NONELIGHT = LightColors.none;

  const getTotalCompensation = async () => {
    setLoading(true);
    const response = await TotalCompensation.getBaseCompensation(
      service ?? '',
      { [param!]: inputText },
      region,
      physicianType,
    );

    if (response.failed) {
      setLoading(false);
      return setShownLight(NONELIGHT);
    }
    const responseColor: LightColors =
      response.data?.[responseValue!] ?? NONELIGHT;
    setShownLight(responseColor);

    return setLoading(false);
  };

  useEffect(() => {
    if ((!inputText || !hasCustomResult) && !customResult) {
      return setLoading(false);
    }
    const controller = new AbortController();
    getTotalCompensation().then(null);
    return () => controller.abort();
  }, [inputText, region, physicianType]);

  useEffect(() => {
    setLoading(false);
  }, [customResult]);

  useEffect(() => {
    if (!value) setInputText('');
  }, [value]);

  useEffect(() => {
    callback?.(inputText);

    if (inputText && hasCustomResult) {
      setLoading(true);
    }
  }, [inputText]);

  return (
    <Row>
      {isModalOpen && (
        <MessageModal
          title={exceptionTitle}
          fisrtMessage={exceptionMessage}
          buttonLabel={t('close_label')}
          handleClose={setIsModalOpen}
        />
      )}
      <Input
        tooltip={tooltip}
        value={value}
        title={title}
        type={type}
        callback={(event: any) => setInputText(event)}
        min={min}
        max={max}
        exceptionTitle={exceptionTitle}
        exceptionMessage={exceptionMessage}
      />
      {loading && inputText && hasCustomResult && <Loader />}
      {shownLight && !customResult && inputText && !loading
        ? hasCustomResult && (
            <ConditionalPercentileIcon percentileIndicator={shownLight} />
          )
        : hasCustomResult && <span>{customResult}</span>}
    </Row>
  );
}

export default CompensationSingleInputs;
