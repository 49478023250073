import styled from 'styled-components';
import { FaPlus, FaMinus } from 'react-icons/fa';

interface BoxInterface {
  direction?: string;
  padding?: string;
  edCallPosition?: number;
}

export const Section = styled.section`
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  height: max-content;
  width: 90%;

  &.details {
    margin-left: 35px;
  }
`;

export const FieldsWithAdjustedContainer = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  margin-bottom: 35px;

  > :nth-child(2) {
    margin: 25px 0 0 0;
  }
`;

export const Box = styled.section<BoxInterface>`
  position: relative;
  padding: ${(props) => props.padding || '20px 20px'};
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 90%;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 23%);
  overflow: auto;

  ${(props) =>
    props.direction === 'row' &&
    `
      flex-direction: row;
      align-items: center;
  `}

  > :nth-child(${(props) => props.edCallPosition}) {
    margin-top: 20px;
  }
`;

export const BoxHider = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  cursor: not-allowed;
`;

export const InsideBox = styled(Box)`
  padding: 0 0;
  margin: 0 0;
  background-color: transparent;
  box-shadow: none;

  > :nth-child(n + 3) {
    margin-bottom: 20px;
  }

  > :nth-child(5) {
    margin-top: 10px;
  }
`;

export const TotalCompBox = styled.div`
  display: flex;
  max-width: 565px;
  height: max-content;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-background);
  width: 100vw;
`;

export const PlusButton = styled(FaPlus)`
  cursor: pointer;
`;

export const MinusButton = styled(FaMinus)`
  cursor: pointer;
`;

export const InputCalc = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 10px !important;
  margin-bottom: 20px;

  > span {
    width: max-content;
    color: var(--almost-black);
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    cursor: pointer;
    font-weight: bold;
  }
`;
