// eslint-disable-next-line max-len
/* eslint-disable react/function-component-definition,react/no-unstable-nested-components,react/destructuring-assignment */
import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { renderToString } from 'react-dom/server';
import PdfCharts from 'src/components/molecules/PdfCharts';
import {
  Information,
  InformationColumn,
  InformationText,
  Line,
  ApprovedByContainer,
  BottomTextContainer,
  ApprovedByLine,
  ApprovedByText,
  Document,
  PageParent,
  Page,
} from './style';
import ReportFooter from '../../../organisms/Reports/Footer';
import ReportHeader from '../../../organisms/Reports/Header';
import addChildtoParent from '../../../../utils/addChildToParent';

function RateComparisonTemplate(data: any) {
  const states: any = { ...data }.data;
  const services: string[] =
    states?.service?.map((service: any) => Object.values(service)[0]) ?? [];
  const servicesIds: string[] =
    states?.service?.map((service: any) => Object.keys(service)[0]) ?? [];

  const currentDate = format(new Date(), 'MM/dd/yyyy');
  const benchmarksToShow = states?.benchmarks?.filter((benchmark: any) => {
    const { hat, origServiceId } = benchmark;
    return states.benchmarkValues[hat]?.[origServiceId];
  });

  const dignity = Boolean(Number(states?.dignity));

  const isDignity = () => dignity !== null && dignity;

  const getMarginTop = (index: number) => {
    if (index < 2) {
      return 20;
    }

    return 20;
  };

  const buildPage = (pageIndex: number) => (
    <PageParent
      key={`rcr-parent-${pageIndex}`}
      id={`rcr-parent-page-${pageIndex}`}
    >
      <ReportHeader title={services.join(' & ')} />
      <Page id={`rcr-page-${pageIndex}`} />
      <ReportFooter nowDate={currentDate} />
    </PageParent>
  );

  const removeNotUsedPage = () => {
    for (let i = 0; i < 50; i += 1) {
      const currentPage = document!.getElementById(`rcr-page-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`rcr-parent-page-${i}`)?.remove();
      }
    }
  };

  let page = 0;

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  const NotDignityInformation = () => (
    <Information>
      <InformationColumn>
        {states?.title && (
          <InformationText>{`Title: ${states?.title ?? ''}`}</InformationText>
        )}
        {states?.counterparty && (
          <InformationText>
            {`Counterparty: ${states?.counterparty ?? ''}`}
          </InformationText>
        )}
        {(states?.start || states?.end) && (
          <InformationText>
            {`Effective dates: ${states?.start ?? ''} – ${states?.end ?? ''}`}
          </InformationText>
        )}
        {states?.facility && (
          <InformationText>
            {`Facility: ${states?.facility ?? ''}`}
          </InformationText>
        )}
      </InformationColumn>
    </Information>
  );

  const DignityInformation = () => (
    <Information>
      <InformationColumn>
        {states?.title && (
          <InformationText>{`Title: ${states?.title ?? ''}`}</InformationText>
        )}
        {states?.hospname && (
          <InformationText>{`Hospital: ${states?.hospname}`}</InformationText>
        )}
        <InformationText>
          {`Previously approved: ${states?.prevappy === 'true' ? 'Yes' : 'No'}`}
        </InformationText>
        {states?.prevappy === 'true' && (
          <InformationText>
            {`Related Matter ID: ${states?.prevappid ?? ''}`}
          </InformationText>
        )}
      </InformationColumn>
    </Information>
  );

  const DignityInformationHospitalBased = (
    id: string,
    service: string,
    index: string,
  ) => (
    <Information id={id}>
      <InformationColumn>
        <InformationText>{`Service: ${service}`}</InformationText>
        {states?.collectionGuarantee && (
          <InformationText>{`Collection Guarantee: ${states?.collectionGuarantee}`}</InformationText>
        )}
        {states?.professionalCollection && (
          <InformationText>{`Professional Collections: ${states?.professionalCollection}`}</InformationText>
        )}
        <InformationText>{`Net Payment: ${states?.benchmarkValues?.HS[index]}`}</InformationText>
      </InformationColumn>
    </Information>
  );

  const bottomContainer = (id: string) =>
    (states?.approval === 'true' || states?.notes || states?.checkrm) && (
      <ApprovedByContainer $margin={0} id={id}>
        <BottomTextContainer>
          {states?.notes &&
            states?.notes
              ?.split('\n')
              .map((row: any, index: number) => (
                <InformationText>
                  {`${index === 0 ? 'Notes :' : ''} ${row ?? ''}`}
                </InformationText>
              ))}
          {states?.checkrm &&
            states?.checkrm
              ?.split('\n')
              .map((row: any) => <InformationText>{row}</InformationText>)}
        </BottomTextContainer>
        {states?.approval === 'true' && (
          <>
            <ApprovedByLine />
            <ApprovedByText>Approved by:</ApprovedByText>
          </>
        )}
      </ApprovedByContainer>
    );

  const addInformation = () => {
    const parentId = `rcr-page-${page}`;
    const content = isDignity() ? (
      <DignityInformation />
    ) : (
      <NotDignityInformation />
    );
    document!.getElementById(parentId)!.innerHTML += renderToString(content);
    document!.getElementById(parentId)!.innerHTML += renderToString(<Line />);
  };

  const addHospitalBasedContainer = () => {
    const parentId = `rcr-page-${page}`;
    if (isDignity()) {
      services?.map((service: string, index: number) => {
        const hospitalId = buildId();
        const content = DignityInformationHospitalBased(
          hospitalId,
          service,
          servicesIds[index],
        );
        page = addChildtoParent(hospitalId, content, 'rcr-page', page, 0);
        document!.getElementById(parentId)!.innerHTML += renderToString(
          <Line />,
        );
        return null;
      });
    }
  };

  const addBottomContainer = async () => {
    const bottomID = buildId();
    const parentId = `rcr-page-${page}`;
    const content = bottomContainer(bottomID);

    document!.getElementById(parentId)!.innerHTML += renderToString(content);
  };

  const parseFormattedValue = (value: string): number =>
    parseInt(value.replace(/[$,]/g, ''), 10);

  const buildContent = async () => {
    addInformation();
    if (
      (isDignity() && states?.benchmarkValues?.HS) ||
      states?.professionalCollections ||
      states?.collectionGuarantee
    ) {
      addHospitalBasedContainer();
    }

    benchmarksToShow?.map((benchmark: any, index: number) => {
      const serviceId = benchmark?.origServiceId;
      const benchHat = benchmark?.hat;
      if (benchHat === 'HS' && isDignity()) {
        const hsValue = states?.benchmarkValues?.HS[serviceId];
        const parsedValue = parseFormattedValue(hsValue);
        if (parsedValue <= 0) {
          return null;
        }
      }
      const chartId = buildId();
      page = addChildtoParent(
        chartId,
        <PdfCharts
          key={chartId}
          benchmarkObject={benchmark}
          service={benchmark?.service}
          benchValue={states?.benchmarkValues?.[benchHat][serviceId]}
          marginTop={getMarginTop(index)}
          chartId={chartId}
        />,
        'rcr-page',
        page,
      );
      return page;
    });
    await addBottomContainer();
  };

  useEffect(() => {
    buildContent().then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <Document id="documentBody">
      {Array(10)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default RateComparisonTemplate;
