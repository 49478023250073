import styled from 'styled-components';
import { FaTimesCircle } from 'react-icons/fa';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  width: 630px;
  height: 550px;
  position: fixed;
  left: 50%;
  top: 100px;
  margin-left: -300px;
  opacity: 1;
  padding: 15px;
  border: 2px solid #333;
  z-index: 9999;
  background-color: #fff;

  > :nth-child(1) {
    align-items: center !important;

    > :nth-child(1) {
      position: absolute;
      right: 2px;
    }

    > :nth-child(2) {
      font-size: 18px;
      width: max-content;
      line-height: 0;
    }

    > :nth-child(3) {
      font-size: 18px;
      width: max-content;
      line-height: 0;
    }
  }

  > :nth-child(n) {
    margin: 10px 0px;
  }

  > .traumaRow {
    height: 24px;
    width: 91%;
    align-self: flex-end;
    padding-right: 15px;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px 0px;
    > :nth-child(1) {
      margin: 0px;
      > h2 {
        margin: 0px;
      }
    }
  }

  > :nth-child(3) {
    margin-top: 15px;
  }

  .bottomException {
    align-self: center;
    width: max-content;
  }
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: end;

  > :nth-child(n) {
    > .currencyInput {
      > :nth-child(1) {
        background-color: #fff;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  flex-direction: row;
  align-items: start;
`;

export const CloseButton = styled(FaTimesCircle)`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  cursor: pointer;
  color: var(--secondary-light-gray);
`;
