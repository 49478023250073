import styled from 'styled-components';

interface RowInputProps {
  direction?: 'row' | 'column';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;

  > :nth-child(1) {
    font-size: 18px;
    margin-top: 10px;
  }
`;

export const Notes = styled.span`
  font-size: 15px;
  font-style: italic;
  margin-left: 23px;
  margin-bottom: 10px;
  color: #808080;
`;

export const Row = styled.div<RowInputProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  width: 100%;

  > :nth-child(1) {
    margin-right: ${(props) => (props.direction === 'column' ? '0px' : '20px')};
    margin-bottom: ${(props) =>
      props.direction === 'column' ? '15px' : '0px'};
  }
`;
