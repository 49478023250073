import styled from 'styled-components';

interface TextareaInterface {
  width?: number;
  minHeight?: number;
}

export const Element = styled.textarea<TextareaInterface>`
  width: ${(props) => (props.width ? `${props.width}px` : '550px')};
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : '80px')};
  background-color: #ffffff;
  border: 1px solid #b6b7bd;
  padding: 15px;
  float: left;

  font-size: inherit;
  line-height: inherit;
`;
