import styled from 'styled-components';

interface HeelToolTipContainerInterface {
  tooltipId: string;
}

export const Container = styled.div<HeelToolTipContainerInterface>`
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 9;
  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  #arrow {
    visibility: hidden;
  }

  #arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  > :nth-child(1) {
    margin-left: 7px;
  }

  #${(props) => props.tooltipId} {
    background: gray;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
    display: none;
  }

  #${(props) => props.tooltipId}[data-show] {
    display: block;
  }

  #${(props) => props.tooltipId}[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
  }

  #${(props) => props.tooltipId}[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
  }

  #${(props) => props.tooltipId}[data-popper-placement^='left'] > #arrow {
    right: -4px;
  }

  #${(props) => props.tooltipId}[data-popper-placement^='right'] > #arrow {
    left: -4px;
  }
`;
