import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  FormControl,
  Box,
  Chip,
  InputLabel,
  Checkbox,
} from '@mui/material';
import { CancelIcon, SelectContainer } from './style';
import MultiSelectDropDownAtomInterface from './interface';
import { SelectedItem } from '../SelectDropDown/style';

function MultiSelectDropDown({
  dropDownId,
  label,
  callBack,
  styleCheck,
  dataItems,
  initialValues,
}: MultiSelectDropDownAtomInterface) {
  const [selectedValues, setSelectedValues] = useState<string[] | undefined>(
    undefined,
  );

  const handleChange = (event: any) => {
    callBack(event.target.value);
    return setSelectedValues(event.target.value);
  };

  useEffect(() => {
    if (initialValues?.length! > 0 && selectedValues === undefined) {
      setSelectedValues(initialValues);
    }
  }, [initialValues]);

  const checkboxSelect = (selectId: string) => (
    <SelectContainer
      id={selectId}
      multiple
      value={selectedValues ?? []}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'Without label' }}
      renderValue={(selected: any) => {
        if (selected.length === 1) {
          const singleSelectedValue = dataItems[selected[0]];
          return (
            <MenuItem
              sx={{ padding: '0' }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Checkbox
                key={`${selected[0]}-checkbox`}
                checked={selectedValues?.includes(selected[0])}
                sx={{
                  '&.Mui-checked': {
                    color: 'var(--success)',
                  },
                }}
              />
              {singleSelectedValue}
            </MenuItem>
          );
        }
        return (
          <SelectedItem key="multi-selected-item">
            {selectedValues?.map((key: any) => dataItems[key]).join(', ')}
          </SelectedItem>
        );
      }}
    >
      {Object.entries(dataItems).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          <Checkbox
            key={`${key}-checkbox`}
            checked={selectedValues?.includes(key)}
            sx={{
              '&.Mui-checked': {
                color: 'var(--success)',
              },
            }}
          />
          {value}
        </MenuItem>
      ))}
    </SelectContainer>
  );

  const chipSelect = (selectId: string) => (
    <SelectContainer
      id={selectId}
      multiple
      value={selectedValues ?? []}
      onChange={handleChange}
      renderValue={(selected: any) => (
        <Box
          sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, borderRadius: 2 }}
        >
          {selected.map((key: any) => (
            <Chip
              variant="outlined"
              key={key}
              label={dataItems[key]}
              onDelete={() => {
                const newSelected = selectedValues
                  ? selectedValues.filter((item) => item !== key)
                  : [];
                callBack(newSelected);
                setSelectedValues(newSelected);
              }}
              deleteIcon={
                <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
              }
            />
          ))}
        </Box>
      )}
    >
      {Object.entries(dataItems).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      ))}
    </SelectContainer>
  );

  const selectType = () => {
    switch (styleCheck) {
      case 'checkbox':
        return checkboxSelect(dropDownId);
      default:
        return chipSelect(dropDownId);
    }
  };

  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 250,
        justifyContent: 'center',
        margin: 0,
      }}
    >
      {label ? (
        <InputLabel
          htmlFor={dropDownId}
          sx={{
            fontSize: 14,
            fontWeight: 700,
            color: 'var(--almost-black)',
          }}
        >
          {label}
        </InputLabel>
      ) : null}
      {selectType()}
    </FormControl>
  );
}

export default MultiSelectDropDown;
