/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { format } from 'date-fns';
import {
  Document,
  PageParent,
  Page,
  HeaderContainer,
  HeaderTextContent,
  Row,
  Tile,
  VerticalTile,
  GeneralBlueText,
  LegendContainer,
  LegendContentText,
  GeneralTextContainer,
  Value,
} from './style';
import addChildtoParent from '../../../../utils/addChildToParent';

import ReportHeader from '../../../organisms/Reports/Header';
import ReportFooter from '../../../organisms/Reports/Footer';

type HeaderSectionProps = {
  facName: string;
  typeName: string;
  expirationMessage: string;
  keys: any;
  id: string;
};

function HeaderSection({
  facName,
  typeName,
  expirationMessage,
  keys,
  id,
}: HeaderSectionProps) {
  const LegendsLabels = ['Trauma', 'Expired', 'Multifacility'];
  const showLegendContainer = LegendsLabels.some((label: string) =>
    keys.includes(label),
  );
  return (
    <HeaderContainer id={id}>
      <GeneralTextContainer marginBottom={15}>
        <HeaderTextContent>{facName}</HeaderTextContent>
        <HeaderTextContent>{typeName}</HeaderTextContent>
        <HeaderTextContent>{expirationMessage}</HeaderTextContent>
      </GeneralTextContainer>
      {showLegendContainer && (
        <LegendContainer>
          <GeneralBlueText fontSize={8} fontWeight="bold">
            Legend
          </GeneralBlueText>
          <LegendContentText fontSize={8}>†Trauma</LegendContentText>
          <LegendContentText fontSize={8}>*Multi-Facility</LegendContentText>
          <LegendContentText isExpired fontSize={8}>
            Expired
          </LegendContentText>
        </LegendContainer>
      )}
    </HeaderContainer>
  );
}

function AnalyticsAnnualSummaries({ data, type }: any) {
  const PARENTPAGEPREFIX = 'annual-summaries-parentpage';
  const PAGEPREFIX = 'annual-summaries-page';

  const currentDate = format(new Date(), 'MM/dd/yyyy');

  let page = 0;

  const states: any = type === 'all' ? data : [data];

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  const formatRowValue = (
    value: string,
    key?: string,
    trauma?: number,
    _facility?: number,
  ) => {
    if (typeof value !== 'string') {
      return value;
    }

    let formattedValue = value;

    switch (key) {
      case 'Expires':
        if (value.includes('/') && value.split('/').length >= 3) {
          const [month, day, year] = value.split('/');
          formattedValue = `${month}/${day}/ ${year}`;
        }
        break;

      case 'Facility':
        switch (true) {
          case trauma === 1 && _facility === 1:
            return `${formattedValue} † *`;
          case _facility === 1:
            return `${formattedValue} *`;
          case trauma === 1:
            return `${formattedValue} †`;
          default:
            break;
        }
        break;
      default:
        return formattedValue;
    }

    if (formattedValue.length > 11 && formattedValue.includes('/')) {
      formattedValue = formattedValue.split('/').join('/ ');
    }

    return formattedValue;
  };

  const buildTableContent = (table: any, docType: string) => {
    const rowsKey = docType === 'all' ? 'rows' : 'table';
    const headerKeys = Object.keys(
      table?.[rowsKey].reduce((a: any, b: any) =>
        Object.keys(a ?? {}).length > Object.keys(b ?? {}).length ? a : b,
      ),
    );

    const excludedKeys = [
      'Trauma',
      'Multifacility',
      'Expired',
      'Comparison Service',
    ];
    const filteredHeaderKeys = headerKeys.filter(
      (key) => !excludedKeys.includes(key),
    );
    const widerTileLabels = ['Service', 'Comparison Service', 'Facility'];
    const SMALLER_WIDTH_LABELS = type === 'svcs' ? 50 : 60;
    const isTileWider = (key: string) => {
      switch (key) {
        case 'Label':
          return 60;
        case 'Facility':
          return 90;
        case 'Expires':
          return 40;
        default:
          return widerTileLabels.includes(key) ? 80 : SMALLER_WIDTH_LABELS;
      }
    };

    const headerRowId = buildId();
    const tableHeaders = (
      <Row id={headerRowId}>
        {filteredHeaderKeys
          .filter((name) => !name.includes('Range'))
          .map((name: string) => (
            <Tile fontWeight={700} width={isTileWider(name)}>
              {name}
            </Tile>
          ))}
      </Row>
    );

    page = addChildtoParent(headerRowId, tableHeaders, PAGEPREFIX, page);

    table?.[rowsKey].map((row: any) => {
      if (!row) return null;

      const rowId = buildId();
      const rowContent = (
        <Row id={rowId} borderBottom="solid">
          {filteredHeaderKeys.map((key: string) =>
            !key.includes('Range') ? (
              <VerticalTile width={isTileWider(key)}>
                <Tile
                  textAlign={
                    type === 'svcs' && key !== 'Service' ? 'right' : 'left'
                  }
                  width={isTileWider(key)}
                  isExpired={row?.Expired === 1}
                >
                  <Value>
                    {formatRowValue(
                      row?.[key],
                      key,
                      row.Trauma,
                      row.Multifacility,
                    ) ?? ''}
                  </Value>
                </Tile>
                <Tile
                  isRed={row?.[`${key} Range`] === '>90%'}
                  textAlign={type === 'svcs' ? 'right' : 'left'}
                  width={isTileWider(key)}
                >
                  <span>{row?.[`${key} Range`] ?? ''}</span>
                </Tile>
              </VerticalTile>
            ) : null,
          )}
        </Row>
      );
      page = addChildtoParent(
        rowId,
        rowContent,
        PAGEPREFIX,
        page,
        0,
        tableHeaders,
      );
      return null;
    });
  };

  const buildTablePages = async () => {
    await states.map((table: any) => {
      const { facName, typeName, expirationMessage } = table;

      const { facility, expired } = table;

      const TOT_ANNUAL_TITLE = 'Total Annual Spending Report';
      const rowsKey = type === 'all' ? 'rows' : 'table';
      const headerKeys = Object.keys(
        table?.[rowsKey].reduce((a: any, b: any) =>
          Object.keys(a ?? {}).length > Object.keys(b ?? {}).length ? a : b,
        ),
      );

      const pageHeaderId = buildId();
      const pageHeader = (
        <HeaderSection
          facName={type !== 'svcs' ? facName : facility}
          typeName={type !== 'svcs' ? typeName : TOT_ANNUAL_TITLE}
          expirationMessage={type !== 'svcs' ? expirationMessage : expired}
          keys={headerKeys}
          id={pageHeaderId}
        />
      );

      page = addChildtoParent(pageHeaderId, pageHeader, PAGEPREFIX, page);
      buildTableContent(table, type);
      page += 1;
      return null;
    });
  };

  const removeNotUsedPage = () => {
    for (let i = 0; i < 50; i += 1) {
      const currentPage = document!.getElementById(`${PAGEPREFIX}-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`${PARENTPAGEPREFIX}-${i}`)?.remove();
      }
    }
  };

  const buildPage = (pageIndex: number) => (
    <PageParent id={`${PARENTPAGEPREFIX}-${pageIndex}`}>
      <ReportHeader title="" />
      <Page id={`${PAGEPREFIX}-${pageIndex}`} />
      <ReportFooter nowDate={currentDate} />
    </PageParent>
  );

  useEffect(() => {
    buildTablePages().then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <Document id="documentBody">
      {Array(40)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default AnalyticsAnnualSummaries;
