const roundDecimals = (num: number) =>
  Math.round((num + Number.EPSILON) * 1) / 1;

export function calcPercentClinicalResult(
  percentClinical: string,
  physician: string,
) {
  if (!Number(percentClinical) || !Number(physician)) {
    return '';
  }
  const percent = Number(percentClinical) * Number(physician);
  return percent.toString();
}

export function calcProfCollectionsperWorkRVUs(
  collctions: string,
  workRVUs: string,
) {
  if (!Number(collctions) || !Number(workRVUs)) {
    return '';
  }

  const a = Number(collctions);
  const b = Number(workRVUs);

  if (!(b > 0)) {
    return '';
  }
  const result = a / b;
  return result.toString();
}

export function calcClinicalCompPerWorkRVU(
  clinicalComp: string,
  clinicalFTE: string,
  workRVU: string,
) {
  if (!Number(clinicalComp) || !Number(clinicalFTE) || !Number(workRVU)) {
    return '';
  }
  const a = Number(clinicalComp);
  const b = Number(clinicalFTE);
  const c = Number(workRVU);

  if (!(b > 0) || !(c > 0)) {
    return '';
  }
  const result = a / b / c;
  return result.toString();
}

export function calcProfCollectionsperTotalRVUs(
  collections: string,
  totalRVU: string,
) {
  if (!Number(collections) || !Number(totalRVU)) {
    return '';
  }

  const a = Number(collections);
  const b = Number(totalRVU);

  if (!(b > 0)) {
    return '';
  }

  const result = a / b;
  return result.toString();
}

export function calcClinicalCompPerTotalRVU(
  clinicalComp: string,
  clinicalFTE: string,
  totalRVU: string,
) {
  if (!Number(clinicalComp) || !Number(clinicalFTE) || !Number(totalRVU)) {
    return '';
  }
  const a = Number(clinicalComp);
  const b = Number(clinicalFTE);
  const c = Number(totalRVU);

  if (!(b > 0) || !(c > 0)) {
    return '';
  }
  const result = a / b / c;
  return result.toString();
}

export function calcAdjustedValue(toAdjust: string, percentClinical: string) {
  if (
    !Number(toAdjust) ||
    !Number(percentClinical) ||
    !(Number(percentClinical) < 1)
  ) {
    return '';
  }

  const adjusting = Number(toAdjust);
  const bottom = Number(percentClinical);

  if (adjusting === 0) return toAdjust;

  const result = roundDecimals(adjusting / bottom);
  return result.toString();
}

// previous adjusted total comp calculation
export function calcAdjustedTotalComp(
  physician: number,
  clinicalComp: number,
  totalProductivity: number,
  annualCall: number,
  adminPayment: number,
  bonus: number,
  other: number,
  clinicalFTE: number,
) {
  let totalAdjusted = annualCall + adminPayment + other;
  totalAdjusted += Number(
    calcAdjustedValue(String(clinicalComp), String(clinicalFTE)),
  );
  totalAdjusted += Number(
    calcAdjustedValue(String(totalProductivity), String(clinicalFTE)),
  );
  totalAdjusted += Number(
    calcAdjustedValue(String(bonus), String(clinicalFTE)),
  );
  return totalAdjusted;
}

export function calcTotalCompensation(
  physician: number,
  clinicalComp: number,
  annualCall: number,
  annualAdmin: number,
  bonus: number,
  other: number,
) {
  const totalComp = clinicalComp + annualCall + annualAdmin + bonus + other;
  return totalComp;
}

export function calcTotalClinicalComp(
  clinicalComp: number,
  nonProductivityIncentives: number,
  annualCallPayment: number,
) {
  return clinicalComp + nonProductivityIncentives + annualCallPayment;
}

export function calcAdjustedTotalClinicalComp(
  adjustedClinicalComp: number,
  adjustedNonProductivityIncentives: number,
  annualCallPayment: number,
) {
  return (
    adjustedClinicalComp + adjustedNonProductivityIncentives + annualCallPayment
  );
}
