import React, { useEffect, useState } from 'react';
import { Container, Helper, Row } from './style';
import PlusButton from '../../atoms/PlusButton';
import Input from '../Input';
import ComparisonConditionalInputInterface from './interface';
import Loader from '../../atoms/DotLoader';
import RateComparison from '../../../services/rateComparison';
import ConditionalPercentileIcon from '../ConditionalPercentileIcon';
import LightColors from '../../../constants/lightColors';
import Subtitle from '../../atoms/Subtitle';

function ComparisonConditionalInput({
  inputPlaceholder,
  buttonPlaceholder,
  inputType,
  inputFooter,
  width,
  selectedService,
  identifier,
  trauma,
  inputCallback,
  value,
  helper,
  slices,
  withoutButton,
  hasCustomResult,
}: ComparisonConditionalInputInterface) {
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');

  const [shownLight, setShownLight] = useState<string | string[] | undefined>();
  const [lightSlices, setLightSlices] = useState<string[]>([]);

  const NONELIGHT = LightColors.none;

  const buttonHandle = (clicked: boolean) => {
    if (!clicked) {
      setInputText('');
    }
    setShow(clicked);
  };

  const findResult = async () => {
    const response = await RateComparison.get(
      selectedService,
      identifier,
      inputText ?? '',
      trauma ? 1 : 0,
      slices && slices.length > 0 ? slices.join(',') : undefined,
    );

    if (response.failed) {
      setLoading(false);
      return setShownLight(NONELIGHT);
    }

    const responseColor: LightColors =
      response.data?.[0]?.[identifier] ?? NONELIGHT;

    setShownLight(responseColor);

    if (slices && slices.length > 0 && responseColor !== NONELIGHT) {
      const indicators = Object.keys(responseColor) ?? NONELIGHT;
      const indicatorSlices = Object.values(responseColor) ?? [];

      setShownLight(indicators);
      setLightSlices(indicatorSlices);
    }
    return setLoading(false);
  };

  useEffect(() => {
    inputCallback(inputText);
    if (!inputText) {
      setShownLight(undefined);
      return setLoading(false);
    }

    findResult().then(null);
    return setLoading(true);
  }, [inputText]);

  useEffect(() => {
    if (value || value === '') {
      setInputText(value);
    }
  }, [value]);

  useEffect(() => {
    if (inputText) {
      setLoading(true);
      findResult().then(() => null);
    }
  }, [trauma, slices]);

  useEffect(() => {
    buttonHandle(false);
  }, [selectedService]);

  useEffect(() => {
    if (withoutButton) {
      setShow(true);
    }
  }, []);

  return (
    <Container $withouButton={withoutButton}>
      {!withoutButton && (
        <PlusButton
          placeholder={buttonPlaceholder ?? ''}
          callback={buttonHandle}
          toggleable
          bind={show}
        />
      )}
      {show && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Subtitle text={inputPlaceholder} />
          <Row>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                focus
                callback={(event: any) => setInputText(event)}
                type={inputType}
                width={width}
                value={value}
              />
              {inputFooter && show ? inputFooter : null}
            </div>
            <div style={{ marginTop: -15 }}>
              {loading && inputText && <Loader />}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 15,
              }}
            >
              {shownLight &&
                inputText &&
                !loading &&
                hasCustomResult &&
                (Array.isArray(shownLight) ? (
                  shownLight.map((item, index) => (
                    <ConditionalPercentileIcon
                      key={item}
                      percentileIndicator={item}
                      // @ts-ignore
                      slicesIndicator={lightSlices[index]}
                    />
                  ))
                ) : (
                  <ConditionalPercentileIcon percentileIndicator={shownLight} />
                ))}
            </div>
          </Row>
        </div>
      )}
      {helper && show ? <Helper>{helper}</Helper> : null}
    </Container>
  );
}
export default ComparisonConditionalInput;
