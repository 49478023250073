import React, { useState, useEffect, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Container, Subtitle } from './style';
import CurrencyInputAtomInterface from './interface';

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 10,
  allowNegative: false,
  allowLeadingZeroes: true,
};

function CurrencyInputAtom({
  callback,
  value,
  width,
  propRef,
  disabled,
  subtitle,
  focus,
}: CurrencyInputAtomInterface) {
  const [eventValue, setEventValue] = useState<string>('');
  const inputRef = propRef ?? useRef<any>(null);

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  const hasChanged = (checkValue: any) =>
    (checkValue || checkValue === '') &&
    String(checkValue) !== eventValue &&
    inputRef != null;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => callback(eventValue), 500);
    return () => clearTimeout(delayDebounceFn);
  }, [eventValue]);

  useEffect(() => {
    if (hasChanged(value)) {
      const castValue = value !== 0 ? String(value) : '';
      inputRef!.current!.value = castValue;
      setEventValue(castValue as string);
    }
  }, [value]);

  return (
    <Container width={width} className="currencyInput" disabled={disabled}>
      <MaskedInput
        ref={inputRef}
        autoFocus={focus}
        mask={currencyMask}
        disabled={disabled}
        name="input-name"
        id="input-example"
        defaultValue=""
        value={Number(eventValue) !== 0 ? Number(eventValue) : undefined}
        onChange={(val: any) => {
          const cleanValue = val?.target?.value.replace(/[^\d]/g, '');
          setEventValue(cleanValue);
        }}
      />
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    </Container>
  );
}

export default CurrencyInputAtom;
