import React, { useState, useEffect } from 'react';
import store from 'src/hooks/redux/store';
import { useTranslation } from 'react-i18next';
import FacTotals from 'src/services/totalfacs';
import FacilityTotalTable from 'src/components/molecules/FacilityTotalTable';
import Loader from 'src/components/atoms/DotLoader';
import {
  Page,
  PageTitle,
  PrintBenchmarks,
  PdfIcon,
  TextWrapper,
  CommonPageText,
  List,
  Item,
} from './style';
import { buildPdf } from '../../utils/buildPdf';

function FacTotalsPage() {
  const { t } = useTranslation('facility-totals-page');
  const [facTotalsData, setFacTotalsData] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getFacTotalsData = async () => {
    const response = await FacTotals.getFacTotals();
    if (!response.failed) {
      setFacTotalsData(response.data);
    }
  };

  const handleBuildPdf = async () => {
    setIsSubmitting(true);
    const accessToken = store.getState().auth.token;
    const microUrl = `/factotals-pdf?token=${accessToken}`;
    const prefix = window.location.href.split('/factotals')[0];

    const pdfTitle = 'FacilityTotals.pdf';
    await buildPdf(`${prefix}${microUrl}`, pdfTitle, setIsSubmitting);
  };

  useEffect(() => {
    getFacTotalsData().then(() => {});
  }, []);

  return (
    <Page>
      <TextWrapper>
        <PageTitle>{t('facility_totals')}</PageTitle>
        <CommonPageText>{t('introduction_paragraph')}</CommonPageText>
        <PageTitle>{t('key_assumptions.title')}</PageTitle>
        <List>
          <Item>{t('key_assumptions.first')}</Item>
          <Item>{t('key_assumptions.second')}</Item>
          <Item>{t('key_assumptions.third')}</Item>
          <Item>{t('key_assumptions.fourth')}</Item>
          <Item>{t('key_assumptions.fifth')}</Item>
        </List>
        <PageTitle>{t('reporting_methodology')}</PageTitle>
        <CommonPageText>{t('reporting_methodology_text')}</CommonPageText>
        <CommonPageText>
          {t('antitrust.bold')}
          {t('antitrust.text')}
        </CommonPageText>
        <List>
          <Item>{t('antitrust.first')}</Item>
          <Item>{t('antitrust.second')}</Item>
          <Item>{t('antitrust.third')}</Item>
        </List>
        <PageTitle>{t('data_collections.bold')}</PageTitle>
        <CommonPageText>{t('data_collections.first')}</CommonPageText>
        <CommonPageText>{t('data_collections.second')}</CommonPageText>
        <CommonPageText>{t('data_collections.third')}</CommonPageText>
        <CommonPageText>{t('data_collections.fourth')}</CommonPageText>
      </TextWrapper>
      <PrintBenchmarks
        isSubmitting={isSubmitting}
        onClick={() => handleBuildPdf()}
      >
        <PdfIcon />
        {isSubmitting ? (
          <Loader color="var(--ecg-royal-blue)" marginTop={-18} />
        ) : (
          t('print_benchmarks')
        )}
      </PrintBenchmarks>
      {facTotalsData
        ? facTotalsData?.map((serviceSection: any) =>
            Object.entries(serviceSection).map(([benchName, benchData]) => (
              <FacilityTotalTable benchName={benchName} benchData={benchData} />
            )),
          )
        : null}
    </Page>
  );
}

export default FacTotalsPage;
