import React from 'react';
import Title from 'src/components/atoms/Title';
import TitledLineChartInterface from './interface';
import LineChart from '../../atoms/LineChart';
import { Container, TitleContainer } from './style';
import SubTitle from '../../atoms/Subtitle';

function TitledLineChart({
  title,
  subtitle,
  sliceName,
  values,
  id,
}: TitledLineChartInterface) {
  return (
    <Container id={id}>
      <TitleContainer>
        <Title text={`${title ?? ''} Benchmarks: ${subtitle ?? ''}`} />
        <SubTitle text={sliceName ?? ''} />
      </TitleContainer>
      <LineChart benchObject={values} isPdf />
    </Container>
  );
}

export default TitledLineChart;
