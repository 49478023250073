import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  > :nth-child(1) {
    margin: 0;
    font-size: 14px;
  }
  > :nth-child(2) {
    margin-top: 5px;
    font-size: 8px;
    font-weight: 400;
  }
`;
