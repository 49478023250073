import styled from 'styled-components';

interface RowProps {
  width?: string;
}

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  width: 100vw;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  height: max-content;
  min-width: 500px;
  width: 30%;
  padding: 20px 10px 100px;
  background-color: #ffffff;

  .blueText.blueText {
    width: max-content;
    color: var(--ecg-royal-blue);
    margin: 0;
  }

  .SmallerTitle.SmallerTitle {
    margin: 15px 0;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.1rem;
  }

  > :nth-child(1) {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 30px;
    letter-spacing: 0.1rem;
  }

  > :nth-child(5) {
    margin: 5px 0 15px;

    > :nth-child(2) {
      margin-left: 15px;
      color: var(--almost-black);
      width: max-content;
    }
  }

  > :nth-last-child(2) {
    margin: 20px 30px 0;
    color: #ff3b3a;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 0;
  margin-top: 15px;
  margin-bottom: 10px;
  border-bottom: solid 1px var(--secondary-light-gray);
`;

export const Row = styled.div<RowProps>`
  display: flex;
  align-items: center;
  min-height: 40px;
  margin: 2px 0;
  width: ${(props) => props.width || '100%'};

  > :nth-child(1) {
    width: 100%;
    margin-bottom: 10px;
    align-items: flex-start;
    > :last-child {
      > h2 {
        margin: 0;
      }
    }
  }
  > :nth-child(2) {
    margin: 0;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  height: 65px;
  width: 100px;
  align-items: center;
  justify-content: center;
`;

export const SubmitButton = styled.button`
  display: flex;
  min-height: 50px;
  min-width: 90px;
  width: max-content;
  padding: 10px 20px;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  background-color: var(--ecg-navy-blue);
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;

  cursor: pointer;
  outline: none;
  border: none;
`;
