import React from 'react';
import { Subtitle as SubtitleStyle } from './styles';
import TitleInterface from './interface';

function SubTitle({ text, onClick, className, fontSize }: TitleInterface) {
  return (
    <SubtitleStyle
      onClick={() => (onClick ? onClick() : () => null)}
      className={className ?? 'subtitleAtom'}
      fontSize={fontSize}
    >
      {text}
    </SubtitleStyle>
  );
}

export default SubTitle;
