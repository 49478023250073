/* eslint-disable array-callback-return */
/* eslint-disable max-len */
export default function singleServiceReportSectionsDivider(dataStructure: any) {
  type responseObj = {
    [key: string]: string;
  };

  const sections: any = [];
  const ignore = [
    'percentpaying',
    'counts',
    'title',
    'custname',
    'appendixA',
    'appendixB',
  ];

  let textPage;
  const destructureArrayLayer = (destructuringArray: any) => {
    let sectionContent = {};
    destructuringArray.map((benchmarktest: any) => {
      sectionContent = { ...sectionContent, ...benchmarktest };
    });
    return sectionContent;
  };

  const destructurePercentLayer = (percentObject: any) => {
    const percentValues: any = {};
    Object.keys(percentObject).map((key: string) => {
      if (percentObject[key] !== null) percentValues[key] = percentObject[key];
    });
    return percentValues;
  };
  const destructureCountsLayer = (countsArray: any) => {
    const countsValues: any = {};
    countsArray?.map((count: any) => {
      Object.keys(count)?.map((key: string) => {
        countsValues[key] = count[key];
      });
    });
    return countsValues;
  };

  const characteristicDestructure = (
    characteristicObject: any,
    characteristicArray: any[],
  ) => {
    Object.keys(characteristicObject).map((sliceName: string) => {
      const row: any = {
        name: sliceName,
        rows: Object.values(characteristicObject[sliceName]),
      };
      return row.rows.length ? characteristicArray.push(row) : null;
    });
  };

  const handleAdminSection = (arrayToPush: any, section: responseObj) => {
    Object.keys(section ?? {}).map((adminBenchmarkName: string) => {
      const adminBenchMarks = {
        name: adminBenchmarkName,
        adminCharacteristics: [],
      };
      const { [adminBenchmarkName]: characteristicAdmin } = section;

      Object.keys(characteristicAdmin).map((characteristicName: string) => {
        const characteristic: any = {
          name: characteristicName,
          rows: Object.values(characteristicAdmin[characteristicName as any]),
        };
        // @ts-ignore
        adminBenchMarks.adminCharacteristics.push(characteristic);
      });
      arrayToPush.push(adminBenchMarks);
    });
  };

  Object.keys(dataStructure).map((sectionName: string) => {
    if (ignore.includes(sectionName)) return;
    const section: any = {
      name: sectionName,
      services: [],
    };
    // @ts-ignore
    const { [sectionName]: service } = dataStructure;
    Object.keys(service).map((serviceName: string) => {
      if (serviceName === 'textpage') {
        textPage = service[serviceName];
        return null;
      }
      let { [serviceName]: benchmark } = service;
      const services: any = {
        name: serviceName,
        benchmarks: [],
      };

      if (!benchmark) return null;

      if (sectionName === 'RefRates') {
        benchmark = destructureArrayLayer(benchmark);
      }
      const maping = Object.keys(benchmark);
      maping.map((benchmarkName: string) => {
        const characteristics: any = {
          name: benchmarkName,
          characteristics: [],
        };

        const { [benchmarkName]: slices } = benchmark;
        switch (benchmarkName) {
          case 'Administration':
            handleAdminSection(characteristics.characteristics, slices);
            break;
          default:
            characteristicDestructure(slices, characteristics.characteristics);
            break;
        }

        if (characteristics.characteristics.length) {
          return services.benchmarks.push(characteristics);
        }
        return null;
      });
      if (services.benchmarks.length) return section.services.push(services);
      return null;
    });
    sections.push(section);
  });
  ignore.map((ignoring: string) => {
    // @ts-ignore
    sections[ignoring] = dataStructure[ignoring];
  });
  sections.textPage = textPage;
  sections.counts = destructureCountsLayer(dataStructure.counts);
  sections.percentpaying = destructurePercentLayer(sections.percentpaying);

  return sections;
}
