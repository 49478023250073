import styled from 'styled-components';

interface CircleInterface {
  color: string;
  size: string;
}

interface LabelInterface {
  fontSize: string;
}

export const Legends = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  margin-left: 24px;
  justify-content: flex-end;
  gap: 16px;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Circle = styled.div<CircleInterface>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;

export const Label = styled.span<LabelInterface>`
  color: var(--border-black);
  font-size: ${(props) => props.fontSize};
  font-weight: 400;
`;
