import React, { useEffect, useState } from 'react';
import MDparams from 'src/constants/MDparams';
import RateComparisonTemplate from '../../components/templates/Reports/RateComparison';
import RateComparison from '../rateComparison';

function RateComparisonPDF() {
  const [pdfData, setPdfData] = useState<any>();

  const getRcrData = async () => {
    const searchUrl = window.location.search;
    const urlParams = new URLSearchParams(searchUrl);
    const dignity = urlParams.get(MDparams.DIGNITY);

    const queryString = searchUrl.split('&').splice(2).join('&');

    const response = await RateComparison.pdf(queryString);

    if (!response.failed) {
      setPdfData({ ...response.data, dignity });
    }
  };

  useEffect(() => {
    getRcrData().then(null);
  }, []);

  return pdfData ? <RateComparisonTemplate data={pdfData} /> : null;
}

export default RateComparisonPDF;
