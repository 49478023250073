import Api from './api';
import keysToCamel from '../utils/keysToCamelCase';

export default class GeneralServices {
  static async self() {
    return Promise.resolve(
      Api.get('/api/self')
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getHBRCustomTexts(textPage: string) {
    return fetch(`/hbr_texts/${textPage}.txt`);
  }
}
