import styled from 'styled-components';

interface InputInterface {
  width?: number;
  disabled?: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const Subtitle = styled.div`
  position: absolute;
  right: 10px;
  top: 25%;
  font-weight: 300;
  color: var(--primary-dark-gray);
`;

export const Element = styled.input<InputInterface>`
  margin-left: 0;
  background-color: ${(props) => (props.disabled ? '#F0F0F0' : '#FFFFFF')};
  border: 1px solid #b6b7bd;
  width: ${(props) => (props.width ? `${props.width}px` : '550px')};
  height: 40px;
  padding-left: 20px;
  float: left;

  font-size: inherit;
  line-height: inherit;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: none;
  }
`;
