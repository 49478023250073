import styled from 'styled-components';

interface AverageTextInterface {
  fontSize?: number;
  color?: string;
  fontWeight?: string;
}

interface AverageTextHolderInterface {
  height?: number;
  direction?: 'row' | 'column';
  marginTop?: number;
  alignContent?: 'flex-start' | 'center' | 'flex-end';
  gap?: number;
}
export const TableContainer = styled.div`
  width: 100%;
  max-width: 445px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 2px;
`;

export const BenchMarkTitle = styled.h2`
  color: var(--ecg-royal-blue);
  max-width: 445px;
  font-family: inherit;
  font-size: 20px;
  font-weight: bolder;
  margin: 0 0 12px;
  letter-spacing: -1px;
`;

export const TableWrapper = styled.div`
  margin-top: 16px;
  max-width: 445px;
  overflow: hidden;
`;

export const Table = styled.table`
  width: 100%;
  max-width: 445px;
  border-collapse: collapse;
  margin-top: 10px;
  overflow: hidden;
`;

export const TableHeader = styled.th`
  height: 20px;
  border-bottom: solid 1px #dbdbdb;
  background-color: var(--primary-light-gray);
  font-size: 8px;
  padding: 4px 3px;
  font-weight: bold;
  text-align: left;
`;

export const TableHead = styled.thead`
  max-width: 445px;
`;

export const TableBody = styled.tbody`
  max-width: 445px;
`;

export const Characteristic = styled.tr`
  max-width: 445px;
  background-color: transparent;
  margin-left: 10px;
  font-weight: bold;
  font-size: 9px;
  color: var(--ecg-royal-blue);
  /* white-space: nowrap; */
  margin-top: 10px;
`;

export const TableCell = styled.td`
  padding: 4px 3px;
  text-align: left;
  max-width: 42px;
  white-space: nowrap;
  overflow: visible;
  font-size: 8px;
`;

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);

  > .frontPage {
    > :first-child {
      position: absolute;
    }
    > :last-child {
      background-color: transparent;
      position: absolute;
      bottom: 0;
    }
  }

  > .appendixBPage {
    flex-direction: column;
  }
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 700px;
  padding: 50px 25px;
  align-items: center;
  position: relative;

  > .frontSection {
    margin: 70px 0 0;
  }

  > .logoSubtitle {
    margin-left: auto;
    margin-right: 45px;
    text-align: right;
  }

  > .reportHeader {
    width: 100%;
    position: absolute;
    top: 0;
    height: max-content;
  }
  > .reportFooter {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: max-content;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  margin-top: 60px;
  width: max-content;
  height: max-content;
  margin-right: 8%;
`;

export const AverageTextHolder = styled.div<AverageTextHolderInterface>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  width: 100%;
  align-items: ${(props) => props.alignContent ?? 'center'};
  height: ${(props) => props.height}px;
  margin-top: ${(props) => props.marginTop}px;
  justify-content: space-between;
  gap: ${(props) => `${props.gap ?? 0}px`};

  > .percentageIcon {
    width: max-content;
    margin: 0 20px 0 0;
  }

  > .percentileRanges {
    width: 51%;
    margin: 0;
    height: 82%;
  }

  > .percentText {
    padding-right: 40px;
  }

  > .percentileText {
    width: 50%;
  }

  > .paymentText {
    padding-right: 40px;
  }

  > .paddingLeftText {
    padding-left: 8px;
  }

  > .textPageTitle {
    align-self: flex-start;
    margin-top: 30px;
    font-size: 12px;
    font-weight: bold;
  }
`;

export const AverageText = styled.span<AverageTextInterface>`
  font-family: inherit;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize ?? 12}px;
  color: ${(props) => props.color ?? '#242424'};
`;

export const List = styled.ul`
  list-style-type: disc;
`;

export const Item = styled.li`
  font-size: 14px;
  color: var(--almost-black);
  margin-bottom: 8px;
  line-height: 1.5;
`;

export const TextsWrappers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  > .title {
    font-size: 14px;
    margin-bottom: 5px;
  }
  > .averageParagraph {
    font-size: 9px;
    text-align: justify;
    padding-right: 10px;

    > .paragraphList {
      padding-inline-start: 20px;
      margin: 0px;
    }
  }
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-pdf-height);
  padding: 0;
  top: 0;
`;

export const PageChild = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 25px;
  margin: 20px 0;

  > :not(:first-child).benchTitle {
    margin-top: 50px;
  }
  > .adminBenchTitle {
    font-size: 11px;
  }
`;
