import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import { FiSearch } from 'react-icons/fi';
import CleanInputStyles from '../../../contracts/models/CleanInputStyle.model';

interface InputProps {
  $inputStyle?: CleanInputStyles;
}

export const SearchIcon = styled(FiSearch)<InputProps>`
  color: #151624;
  height: ${(props) =>
    props.$inputStyle?.icon?.size
      ? props.$inputStyle.icon.size
      : '24px'} !important;
  width: ${(props) =>
    props.$inputStyle?.icon?.size
      ? props.$inputStyle.icon.size
      : '16px'} !important;
  position: absolute;
  margin-left: ${(props) =>
    props.$inputStyle?.icon?.marginLeft
      ? props.$inputStyle.icon.marginLeft
      : '24px'};
  line-height: 24px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Input = styled(Autocomplete)<InputProps>`
  background-color: white;
  width: ${(props) =>
    props.$inputStyle?.width ? props.$inputStyle.width : '100%'} !important;

  .MuiSvgIcon-root {
    display: none;
  }

  .MuiOutlinedInput-root {
    font-family: 'Open Sans', sans-serif;
    padding: 0 !important;
    border-radius: 2px !important;
    height: ${(props) =>
      props.$inputStyle?.height ? props.$inputStyle.height : '48px'} !important;
  }

  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding-left: ${(props) =>
      props.$inputStyle?.paddingLeft
        ? props.$inputStyle.paddingLeft
        : '50px'} !important;
  }

  input::placeholder {
    color: ${(props) =>
      props.$inputStyle?.placeholder?.color
        ? props.$inputStyle.placeholder.color
        : '#838a8e'} !important;
    opacity: 1;
    font-size: ${(props) =>
      props.$inputStyle?.placeholder?.fontSize
        ? props.$inputStyle.placeholder.fontSize
        : '16px'};
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
  }
  > * input {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.1px;
  }
`;
