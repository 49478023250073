import React, { useEffect } from 'react';
import { format } from 'date-fns';
import {
  Document,
  PageParent,
  Page,
  RegionTitle,
  Row,
  RowFirstTile,
  RowGeneraltile,
  HeaderFirstTile,
  HeaderGeneralTile,
  CharacteristcTile,
  Line,
} from './style';

import ReportHeader from '../../../organisms/Reports/Header';
import ReportFooter from '../../../organisms/Reports/Footer';

import addChildtoParent from '../../../../utils/addChildToParent';

function formatTopSectionKeys(key: string) {
  switch (key) {
    case 'benchmark':
      return 'Benchmark';
    case 'practices':
      return 'Practices';
    case 'individuals':
      return 'Individuals';
    case 'physicians':
      return 'Physicians';
    case 'mean':
      return 'Mean';
    case 'q25':
      return '25%';
    case 'q50':
      return '50%';
    case 'q75':
      return '75%';
    case 'q90':
      return '90%';
    default:
      return key;
  }
}

function TopSectionRow({ id, dataBody }: any) {
  const [firstKey, ...otherKeys] = dataBody;

  return (
    <Row id={id}>
      <HeaderFirstTile>{formatTopSectionKeys(firstKey)}</HeaderFirstTile>
      {otherKeys.map((key: string) => (
        <HeaderGeneralTile key={key}>
          {formatTopSectionKeys(key)}
        </HeaderGeneralTile>
      ))}
    </Row>
  );
}

function CharacteristcRow({ text, id }: any) {
  return (
    <Row id={id} $margin="8px 0px 8px">
      <CharacteristcTile>{text}</CharacteristcTile>
    </Row>
  );
}

function verifySize(text: string) {
  return text.length > 9;
}

function getPhysiciansOrIndividuals(dataBody: any) {
  return 'individuals' in dataBody ? dataBody.individuals : dataBody.physicians;
}

function DataRow({ dataBody, divId }: any) {
  const { benchmark, practices, mean, q25, q50, q75, q90 } = dataBody;

  const thirdColumn = getPhysiciansOrIndividuals(dataBody);

  return (
    <Row id={divId}>
      <RowFirstTile>{benchmark}</RowFirstTile>
      <RowGeneraltile>{practices}</RowGeneraltile>
      <RowGeneraltile fontSize={verifySize(thirdColumn) ? 7 : undefined}>
        {thirdColumn}
      </RowGeneraltile>
      <RowGeneraltile fontSize={verifySize(mean) ? 7 : undefined}>
        {mean}
      </RowGeneraltile>
      <RowGeneraltile fontSize={verifySize(q25) ? 7 : undefined}>
        {q25}
      </RowGeneraltile>
      <RowGeneraltile fontSize={verifySize(q50) ? 7 : undefined}>
        {q50}
      </RowGeneraltile>
      <RowGeneraltile fontSize={verifySize(q75) ? 7 : undefined}>
        {q75}
      </RowGeneraltile>
      <RowGeneraltile fontSize={verifySize(q90) ? 7 : undefined}>
        {q90}
      </RowGeneraltile>
    </Row>
  );
}

function SearchSalaryTemplate({ data }: any) {
  const states: any = data;

  const currentDate = format(new Date(), 'MM/dd/yyyy');

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  const buildPage = (pageIndex: number) => (
    <PageParent id={`search-salary-parent-page-${pageIndex}`}>
      <ReportHeader title={states.service} />
      <Page id={`search-salary-page-${pageIndex}`} />
      <ReportFooter nowDate={currentDate} isSalary />
    </PageParent>
  );

  let page = 0;

  const reverseYearsSort = (yearsArray: string[]) =>
    yearsArray.sort((a: any, b: any) => b - a);

  const buildContent = async () => {
    Object.keys(states.benchmarks).map((regionName: string) => {
      // @ts-ignore
      const { [regionName]: region } = states.benchmarks;

      const regionYears = Object.keys(region);
      if (!regionYears) return null;

      const yearsToIterate = reverseYearsSort(regionYears);
      yearsToIterate.map((yearName: string) => {
        // @ts-ignore
        const { [yearName]: yearSection } = region;

        const headerKeys: any = [];
        Object.keys(yearSection).forEach((sectionName: any) => {
          const benchmarks = yearSection[sectionName];

          const keys = Object.keys(benchmarks[0]);
          keys.forEach((key: any) => {
            if (!headerKeys.includes(key)) {
              headerKeys.push(key);
            }
          });
        });

        const title = `${regionName} Market Data ${yearName}`;
        const topSectionId = buildId();
        const titleId = buildId();

        const tableHeaders = (
          <TopSectionRow
            key={`${topSectionId}-section`}
            id={topSectionId}
            dataBody={headerKeys}
          />
        );

        page = addChildtoParent(
          titleId,
          <RegionTitle id={titleId} className="regionTitle">
            {title}
          </RegionTitle>,
          'search-salary-page',
          page,
          62,
        );
        page = addChildtoParent(
          topSectionId,
          tableHeaders,
          'search-salary-page',
          page,
          37,
        );
        Object.keys(yearSection).forEach((section: any) => {
          const charRowId = buildId();
          page = addChildtoParent(
            charRowId,
            <CharacteristcRow text={section} id={charRowId} />,
            'search-salary-page',
            page,
            270,
          );
          // @ts-ignore
          yearSection[section].map((row: any) => {
            const rowId = buildId();
            page = addChildtoParent(
              rowId,
              <DataRow dataBody={row} divId={rowId} />,
              'search-salary-page',
              page,
              20,
              tableHeaders,
            );
            return null;
          });
          return null;
        });
        const lineId = buildId();
        page = addChildtoParent(
          lineId,
          <Line id={lineId} />,
          'search-salary-page',
          page,
        );
        return null;
      });
      page += 1;
      return null;
    });
    return null;
  };

  const removeNotUsedPage = () => {
    for (let i = 1; i < 50; i += 1) {
      const currentPage = document!.getElementById(`search-salary-page-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`search-salary-parent-page-${i}`)?.remove();
      }
    }
  };

  useEffect(() => {
    buildContent().then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <Document id="documentBody">
      {Array(20)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default SearchSalaryTemplate;
