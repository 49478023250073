import React from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import { Document } from './style';
import FrontPage from './Pages/FrontPage';
import IntroductionPage from './Pages/IntroductionPage';
import TablePages from './Pages/TablePages';
import DataCollectionPage from './Pages/DataCollectionPage';

type FacilityTotalsTemplateProps = {
  data: any[];
};

function FacilityTotalsTemplate({ data }: FacilityTotalsTemplateProps) {
  const { t } = useTranslation('facility-totals-report');
  const now = new Date();
  const currentDate = format(now, 'MM/dd/yyyy');
  const daynameDate = format(now, 'EEEE, MMMM dd, yyyy');

  const assumptions: string[] = [
    t('introduction.assumptions.first'),
    t('introduction.assumptions.second'),
    t('introduction.assumptions.third'),
    t('introduction.assumptions.fourth'),
    t('introduction.assumptions.fifth'),
  ];

  const antitrustList: string[] = [
    t('reporting.antitrust.first'),
    t('reporting.antitrust.second'),
    t('reporting.antitrust.third'),
  ];

  return (
    <Document>
      <FrontPage
        nowDate={currentDate}
        year={t('report_year', {
          currentYear: now.getFullYear(),
        })}
        daynameDate={daynameDate}
        subtitle={t('premier_source')}
        confidential={t('confidential')}
      />
      <IntroductionPage
        title={t('introduction.title')}
        firstParagraph={t('introduction.first_paragraph')}
        assumption={t('introduction.assumptions.title')}
        assumptionsListItems={assumptions}
        reportingTitle={t('reporting.title')}
        reportingText={t('reporting.first_paragraph')}
        complianceTitle={t('reporting.antitrust.bold')}
        complianceText={t('reporting.antitrust.text')}
        reportingListItems={antitrustList}
        footerText={t('footer_text', {
          currentDate,
          currentYear: now.getFullYear(),
        })}
      />
      <DataCollectionPage
        rateTitle={t('rate_calculations.title')}
        rateFirstParagraph={t('rate_calculations.first_paragraph')}
        rateSecondParagraph={t('rate_calculations.second_paragrapg')}
        rateThirdParagraph={t('rate_calculations.third_paragraph')}
        rateFourthParagraph={t('rate_calculations.fourth_paragraph')}
        footerText={t('footer_text', {
          currentDate,
          currentYear: now.getFullYear(),
        })}
      />
      <TablePages
        reportData={data}
        footerText={t('footer_text', {
          currentDate,
          currentYear: now.getFullYear(),
        })}
      />
    </Document>
  );
}

export default FacilityTotalsTemplate;
