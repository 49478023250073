import React from 'react';
import Modal from 'react-modal';
import ModalInterface from './interface';

Modal.setAppElement('#root');

function CustomModal({ Children, isOpen }: ModalInterface) {
  return (
    <div>
      <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay">
        {Children}
      </Modal>
    </div>
  );
}

export default CustomModal;
