import styled from 'styled-components';

interface InputInterface {
  width?: number;
  disabled?: boolean;
}

export const Container = styled.div<InputInterface>`
  display: flex;
  position: relative;
  height: max-content;
  border: 1px solid #b6b7bd;
  width: ${(props) => (props.width ? `${props.width}px` : '550px')};

  > :nth-child(1) {
    background-color: #fff;
    font-size: inherit;
    line-height: inherit;
    height: 40px;
    width: 100%;
    padding-left: 20px;
    float: left;
    border: none;

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
  }
`;

export const Subtitle = styled.div`
  position: absolute;
  right: 10px;
  top: 25%;
  font-weight: 300;
  color: var(--primary-dark-gray);
`;
