import styled from 'styled-components';

interface CheckboxMenuContainerInterface {
  gap?: string;
}

export const Element = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxContainer = styled.div``;

export const CheckboxMenuContainer = styled.div<CheckboxMenuContainerInterface>`
  padding-left: 15px;
  padding-bottom: 10px;

  > :first-child {
    display: flex;
    gap: ${(props) => props.gap};
  }
`;
