import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../atoms/Modal';
import { Body, Column, Row, CloseButton } from './style';
import Subtitle from '../../atoms/Subtitle';
import Input from '../../molecules/Input';
import ConfirmButton from '../../atoms/ConfirmButton';
import Checkbox from '../../atoms/Checkbox';
import TotalCompensationCoverageModalInterface from './interface';
import SearchInput from '../../atoms/SearchInput';
import MDRangerService from '../../../services/mdrangerServices';
import PerDiemCalculatorResult from '../../../utils/perDiemCalculatorResult';
import coveragePerDiem from '../../../contracts/models/coveragePerDiem.model';

function TotalCompensationCoverageModal({
  isOpen,
  onHandle,
  onClose,
  customCoveragePerDiemFields,
  pageService,
}: TotalCompensationCoverageModalInterface) {
  const { t } = useTranslation('total-compensation');

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [servicesList, setServicesList] = useState<string[]>([]);

  const [bottomSubtitleText, setBottomSubtitleText] = useState<string>('');

  const [disableLeft, setDisableLeft] = useState<boolean>(false);
  const [disableRight, setDisableRight] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const [modalValues, setModalValues] = useState<coveragePerDiem>({
    service: '',
    weekday: 0,
    weekend: 0,
    holiday: 0,
    callDays: 0,
    weeklyRate: 0,
    monthlyRate: 0,
    annualRate: 0,
    coverageResult: 0,
    perDiemRate: 0,
    unpaidCallDays: 0,
    noSeparate: false,
    trauma: '0',
  });

  const searchRef = useRef<HTMLInputElement>(null);

  const weekDayRef = useRef<HTMLInputElement>(null);
  const weekendRef = useRef<HTMLInputElement>(null);
  const holidayRef = useRef<HTMLInputElement>(null);
  const shiftsRef = useRef<HTMLInputElement>(null);

  const monthlyRef = useRef<HTMLInputElement>(null);
  const weeklyRef = useRef<HTMLInputElement>(null);
  const annualRef = useRef<HTMLInputElement>(null);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const loadServices = async () => {
    const response: any = await MDRangerService.getAllWithBenchmarks('HC');
    if (!response.failed) {
      setServicesList(response.data.services);
    }
  };

  const handleInputException = (change: boolean) => {
    let text = '';

    if (change) {
      if (!modalValues.service) {
        text = t('no_service_selected');
        setBottomSubtitleText(text);
        return true;
      }
      if (modalValues.callDays !== 0 && disableRight) {
        text = t('cant_calculate_excpetion');
        setBottomSubtitleText(text);
        return true;
      }
    }
    return false;
  };

  const calculateCoverage = () => {
    const { weekday, weekend, holiday } = modalValues;
    const weeklyVal = modalValues.weeklyRate;
    const monthly = modalValues.monthlyRate;
    const annual = modalValues.annualRate;
    const days = modalValues.callDays;

    const resultTest = PerDiemCalculatorResult(
      weekday ?? 0,
      weekend ?? 0,
      holiday ?? 0,
      weeklyVal ?? 0,
      monthly ?? 0,
      annual ?? 0,
      days ?? 0,
    );
    return resultTest;
  };

  const validateSaveClose = () => {
    if (handleInputException(hasChanged)) return false;
    return true;
  };

  const changeModalValues = (value: number | string | boolean, key: string) =>
    setModalValues({ ...modalValues, [key]: value });

  const handleCallDaysChange = (value: number) => {
    if (modalValues.noSeparate) {
      return setModalValues({ ...modalValues, unpaidCallDays: value });
    }
    return setModalValues({ ...modalValues, callDays: value });
  };

  const handleModalClose = () => {
    const { annualCall, perDiem } = calculateCoverage();

    const returnBody = {
      ...modalValues,
      coverageResult: annualCall,
      perDiemRate: perDiem,
    };

    setHasChanged(false);
    setDisableLeft(false);
    setDisableRight(false);
    setModalOpen(false);
    setBottomSubtitleText('');
    setModalValues({
      service: '',
      weekday: 0,
      weekend: 0,
      holiday: 0,
      callDays: 0,
      weeklyRate: 0,
      monthlyRate: 0,
      annualRate: 0,
      coverageResult: 0,
      perDiemRate: 0,
      unpaidCallDays: 0,
      noSeparate: false,
      trauma: '0',
    });
    return returnBody;
  };

  useEffect(() => {
    onHandle(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    if (isOpen && pageService) {
      loadServices().then(null);
    }
  }, [isOpen]);

  useEffect(() => {
    function handler(event: any) {
      const isEvent = event.keyCode === 9;
      if (searchRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        weekDayRef!.current!.focus();
      }

      if (weekDayRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        weekendRef!.current!.focus();
      }
      if (weekendRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        holidayRef!.current!.focus();
      }
      if (holidayRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        shiftsRef!.current!.focus();
      }
      if (shiftsRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        monthlyRef!.current!.focus();
      }

      if (shiftsRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        monthlyRef!.current!.focus();
      }
      if (monthlyRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        weeklyRef!.current!.focus();
      }
      if (weeklyRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        annualRef!.current!.focus();
      }

      if (annualRef.current?.contains(event.target) && isEvent) {
        // @ts-ignore
        submitButtonRef!.current.focus();
      }
    }
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, []);

  useEffect(() => {
    setModalOpen(isOpen);
    if (customCoveragePerDiemFields) {
      setModalValues(customCoveragePerDiemFields);
    }
  }, [isOpen]);

  useEffect(() => {
    const {
      service,
      weekday,
      weekend,
      holiday,
      unpaidCallDays,
      weeklyRate,
      monthlyRate,
      annualRate,
    } = modalValues;
    setHasChanged(false);
    setDisableLeft(false);
    setDisableRight(false);
    if (!service) {
      setDisableLeft(true);
      setDisableRight(true);
    }
    if (
      weekday! > 0 ||
      weekend !== 0 ||
      holiday !== 0 ||
      unpaidCallDays !== 0
    ) {
      setHasChanged(true);
    }
    if (weeklyRate !== 0 || monthlyRate !== 0 || annualRate !== 0) {
      setHasChanged(true);
      setDisableRight(true);
    }
  }, [
    modalValues.service,
    modalValues.weekday,
    modalValues.weekend,
    modalValues.holiday,
    modalValues.callDays,
    modalValues.weeklyRate,
    modalValues.monthlyRate,
    modalValues.annualRate,
    modalValues.unpaidCallDays,
  ]);

  return (
    <Modal
      isOpen={isModalOpen}
      Children={
        <Body>
          <Column>
            <CloseButton onClick={() => handleModalClose()} />
            <Subtitle text={t('rate-conversion-title')} />
            <Subtitle text={t('rate-conversion-subtitle')} />
          </Column>
          <SearchInput
            placeholder={modalValues.service || t('search_service')}
            callback={(data: string) => changeModalValues(data, 'service')}
            width={300}
            options={servicesList}
            searchRef={searchRef}
          />
          <Row className="traumaRow">
            <Checkbox
              id="traumaCenter"
              initialValue={modalValues.trauma === '1'}
              label={t('trauma_center')}
              disabled={!modalValues.service}
              onChange={(value: boolean) =>
                changeModalValues(value ? '1' : '0', 'trauma')
              }
            />
          </Row>
          <Row className="traumaRow">
            <Checkbox
              id="separatePayment"
              initialValue={modalValues.noSeparate}
              label={t('separate_payment')}
              disabled={!modalValues.service}
              onChange={(value: boolean) => {
                changeModalValues(value, 'noSeparate');
                setDisableLeft(value);
                setDisableRight(value);
              }}
            />
          </Row>
          <Row>
            <Column>
              <Input
                title={t('weekday')}
                titleSide="left"
                width={80}
                type="currency"
                disabled={disableLeft}
                value={modalValues.weekday}
                callback={(value: number) =>
                  changeModalValues(Number(value), 'weekday')
                }
                propRef={weekDayRef}
                delay={0}
              />
              <Input
                title={t('weekend')}
                titleSide="left"
                width={80}
                type="currency"
                value={modalValues.weekend}
                disabled={disableLeft}
                callback={(value: number) =>
                  changeModalValues(Number(value), 'weekend')
                }
                propRef={weekendRef}
                delay={0}
              />
              <Input
                title={t('holiday')}
                titleSide="left"
                width={80}
                type="currency"
                value={modalValues.holiday}
                disabled={disableLeft}
                callback={(value: number) =>
                  changeModalValues(Number(value), 'holiday')
                }
                propRef={holidayRef}
                delay={0}
              />
              <Input
                title={t('shifts')}
                titleSide="left"
                width={80}
                type="number"
                disabled={disableLeft && !modalValues.noSeparate}
                value={modalValues.callDays}
                callback={(value: number) =>
                  handleCallDaysChange(Number(value))
                }
                propRef={shiftsRef}
                delay={0}
              />
            </Column>
            <Column>
              <Input
                title={t('monthly')}
                titleSide="left"
                width={80}
                type="currency"
                disabled={disableRight && modalValues.monthlyRate === 0}
                value={modalValues.monthlyRate}
                callback={(value: number) =>
                  changeModalValues(Number(value), 'monthlyRate')
                }
                propRef={monthlyRef}
                delay={0}
              />
              <Input
                title={t('weekly')}
                titleSide="left"
                width={80}
                type="currency"
                disabled={disableRight && modalValues.weeklyRate === 0}
                value={modalValues.weeklyRate}
                callback={(value: number) =>
                  changeModalValues(Number(value), 'weeklyRate')
                }
                propRef={weeklyRef}
                delay={0}
              />
              <Input
                title={t('annual')}
                titleSide="left"
                width={80}
                type="currency"
                disabled={disableRight && modalValues.annualRate === 0}
                value={modalValues.annualRate}
                callback={(value: number) =>
                  changeModalValues(Number(value), 'annualRate')
                }
                propRef={annualRef}
                delay={0}
              />
            </Column>
          </Row>
          <ConfirmButton
            onClick={() => {
              if (validateSaveClose()) {
                onClose(handleModalClose());
              }
            }}
            label={t('total-compensation-close')}
            propRef={submitButtonRef}
          />
          <Subtitle text={bottomSubtitleText} className="bottomException" />
        </Body>
      }
    />
  );
}

export default TotalCompensationCoverageModal;
