import styled from 'styled-components';
import { FaArrowTrendDown, FaArrowTrendUp } from 'react-icons/fa6';

export const Element = styled.div`
  width: 100%;
`;

export const TrendUp = styled(FaArrowTrendUp)``;
export const TrendDown = styled(FaArrowTrendDown)``;

export const TooltipBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-top: 4px;
`;

export const TooltipContainer = styled.div`
  color: #f4f5f9;
  margin: 5px;
`;

export const TooltipTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const TooltipContent = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
