/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Facilities from '../../contracts/models/Facilities.model';

export const FacilityPageContext = React.createContext<{
  facilitiesList?: Facilities;
  setFacilitiesList: React.Dispatch<React.SetStateAction<Facilities>>;
  facility?: string;
  setFacility: React.Dispatch<React.SetStateAction<string>>;
  totalBeds?: string;
  setTotalBeds: React.Dispatch<React.SetStateAction<string | undefined>>;
  dailyCensus?: string;
  setDailyCensus: React.Dispatch<React.SetStateAction<string | undefined>>;
  emergencyVisits?: string;
  setEmergencyVisits: React.Dispatch<React.SetStateAction<string | undefined>>;
  surgeriesAnnual?: string;
  setSurgeriesAnnual: React.Dispatch<React.SetStateAction<string | undefined>>;
  icuAdc?: string;
  setIcuAdc: React.Dispatch<React.SetStateAction<string | undefined>>;
  icuBeds?: string;
  setIcuBeds: React.Dispatch<React.SetStateAction<string | undefined>>;
  nicuBeds?: string;
  setNicuBeds: React.Dispatch<React.SetStateAction<string | undefined>>;
  birthsAnnual?: string;
  setBirthsAnnual: React.Dispatch<React.SetStateAction<string | undefined>>;
  medicarePercentage?: string;
  setMedicarePercentage: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  medicaidPercentage?: string;
  setMedicaidPercentage: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  traumaClassification?: string;
  setTraumaClassification: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  levelCare?: string;
  setLevelCare: React.Dispatch<React.SetStateAction<string | undefined>>;
  isTeaching?: '0' | '1';
  setIsTeaching: React.Dispatch<React.SetStateAction<'0' | '1' | undefined>>;
  isStroke?: '0' | '1';
  setIsStroke: React.Dispatch<React.SetStateAction<'0' | '1' | undefined>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  requiring: boolean;
  setRequiring: React.Dispatch<React.SetStateAction<boolean>>;
  levelDropDownOptions: any;
}>({
  facilitiesList: {},
  setFacilitiesList: React.useState,
  facility: '',
  setFacility: React.useState,
  totalBeds: undefined,
  setTotalBeds: React.useState,
  dailyCensus: undefined,
  setDailyCensus: React.useState,
  emergencyVisits: undefined,
  setEmergencyVisits: React.useState,
  surgeriesAnnual: undefined,
  setSurgeriesAnnual: React.useState,
  icuAdc: undefined,
  setIcuAdc: React.useState,
  icuBeds: undefined,
  setIcuBeds: React.useState,
  nicuBeds: undefined,
  setNicuBeds: React.useState,
  birthsAnnual: undefined,
  setBirthsAnnual: React.useState,
  medicarePercentage: undefined,
  setMedicarePercentage: React.useState,
  medicaidPercentage: undefined,
  setMedicaidPercentage: React.useState,
  traumaClassification: undefined,
  setTraumaClassification: React.useState,
  levelCare: undefined,
  setLevelCare: React.useState,
  isTeaching: undefined,
  setIsTeaching: React.useState,
  isStroke: undefined,
  setIsStroke: React.useState,
  loading: false,
  setLoading: React.useState,
  requiring: true,
  setRequiring: React.useState,
  levelDropDownOptions: {
    None: 'None',
    'Level I': 'Level I',
    'Level II': 'Level II',
    'Level III': 'Level III',
  },
});

const FacilityPageContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('input');
  const [facilitiesList, setFacilitiesList] = useState<Facilities>({});
  const [facility, setFacility] = useState<string>('');
  const [totalBeds, setTotalBeds] = useState<string | undefined>();
  const [dailyCensus, setDailyCensus] = useState<string | undefined>();
  const [emergencyVisits, setEmergencyVisits] = useState<string | undefined>();
  const [surgeriesAnnual, setSurgeriesAnnual] = useState<string | undefined>();
  const [icuAdc, setIcuAdc] = useState<string | undefined>();
  const [icuBeds, setIcuBeds] = useState<string | undefined>();
  const [nicuBeds, setNicuBeds] = useState<string | undefined>();
  const [birthsAnnual, setBirthsAnnual] = useState<string | undefined>();
  const [medicarePercentage, setMedicarePercentage] = useState<
    string | undefined
  >();
  const [medicaidPercentage, setMedicaidPercentage] = useState<
    string | undefined
  >();
  const [traumaClassification, setTraumaClassification] = useState<
    string | undefined
  >();
  const [levelCare, setLevelCare] = useState<string | undefined>();
  const [isTeaching, setIsTeaching] = useState<'0' | '1' | undefined>();
  const [isStroke, setIsStroke] = useState<'0' | '1' | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [requiring, setRequiring] = useState<boolean>(true);

  const levelDropDownOptions = {
    None: t('none'),
    'Level I': t('level_I'),
    'Level II': t('level_II'),
    'Level III': t('level_III'),
  };

  return (
    <FacilityPageContext.Provider
      value={{
        facilitiesList,
        setFacilitiesList,
        facility,
        setFacility,
        totalBeds,
        setTotalBeds,
        dailyCensus,
        setDailyCensus,
        emergencyVisits,
        setEmergencyVisits,
        surgeriesAnnual,
        setSurgeriesAnnual,
        icuAdc,
        setIcuAdc,
        icuBeds,
        setIcuBeds,
        nicuBeds,
        setNicuBeds,
        birthsAnnual,
        setBirthsAnnual,
        medicarePercentage,
        setMedicarePercentage,
        medicaidPercentage,
        setMedicaidPercentage,
        traumaClassification,
        setTraumaClassification,
        levelCare,
        setLevelCare,
        isTeaching,
        setIsTeaching,
        isStroke,
        setIsStroke,
        loading,
        setLoading,
        requiring,
        setRequiring,
        levelDropDownOptions,
      }}
    >
      {children}
    </FacilityPageContext.Provider>
  );
};

export default FacilityPageContextProvider;
