import React from 'react';
import { Page, AverageTextHolder, AverageText } from '../style';

import ReportHeader from '../../../../organisms/Reports/Header';
import ReportFooter from '../../../../organisms/Reports/Footer';

export interface SecondPageTextsInterface {
  custname: string;
  confidentiality: string;
  firstParagraph: string;
  legalDisclaimer: string;
  secondParagraph: string;
  notGive: string;
  thirdParagraph: string;
  nowDate: string;
}

function SecondPage(secondTexts: SecondPageTextsInterface) {
  const BOLD_TEXT_SIZE = 11;
  const AVERAGE_TEXT_SIZE = 9.5;
  const {
    custname,
    confidentiality,
    firstParagraph,
    legalDisclaimer,
    secondParagraph,
    notGive,
    thirdParagraph,
    nowDate,
  } = secondTexts;
  return (
    <Page>
      <ReportHeader title="" />
      <AverageTextHolder marginTop={25} alignContent="flex-start">
        <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
          {custname}
        </AverageText>
      </AverageTextHolder>
      <AverageTextHolder height={75} marginTop={20} alignContent="flex-start">
        <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
          {confidentiality}
        </AverageText>
        <AverageText fontSize={AVERAGE_TEXT_SIZE}>{firstParagraph}</AverageText>
      </AverageTextHolder>
      <AverageTextHolder height={80} marginTop={20} alignContent="flex-start">
        <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
          {legalDisclaimer}
        </AverageText>
        <AverageText fontSize={AVERAGE_TEXT_SIZE}>
          {secondParagraph}
        </AverageText>
      </AverageTextHolder>
      <AverageTextHolder height={115} marginTop={20} alignContent="flex-start">
        <AverageText fontSize={BOLD_TEXT_SIZE} fontWeight="bold">
          {notGive}
        </AverageText>
        <AverageText fontSize={AVERAGE_TEXT_SIZE}>{thirdParagraph}</AverageText>
      </AverageTextHolder>
      <ReportFooter nowDate={nowDate} />
    </Page>
  );
}

export default SecondPage;
