import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import { AddIcon, CheckIcon, Container } from './style';
import MultiSelectPillboxInterface from './interface';

function MultiSelectPillbox({
  pillBoxId,
  callBack,
  dataItems,
}: MultiSelectPillboxInterface) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleToggleOption = (optionKey: string) => {
    if (selectedOptions.includes(optionKey)) {
      const updatedOptions = selectedOptions.filter(
        (item) => item !== optionKey,
      );
      callBack(updatedOptions);
      return setSelectedOptions(updatedOptions);
    }
    callBack([...selectedOptions, optionKey]);
    return setSelectedOptions([...selectedOptions, optionKey]);
  };

  return (
    <Container id={pillBoxId}>
      {Object.entries(dataItems).map(([key, value]) => (
        <Chip
          key={key}
          label={value}
          color={selectedOptions.includes(key) ? 'primary' : 'default'}
          onClick={() => handleToggleOption(key)}
          style={{
            backgroundColor: selectedOptions.includes(key)
              ? 'var(--ecg-royal-blue)'
              : '#F2F3F5',
            color: selectedOptions.includes(key) ? 'white' : 'black',
          }}
          deleteIcon={
            selectedOptions.includes(key) ? <CheckIcon /> : <AddIcon />
          }
          onDelete={() => handleToggleOption(key)}
        />
      ))}
    </Container>
  );
}

export default MultiSelectPillbox;
