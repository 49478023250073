import Api from './api';
import keysToCamel from '../utils/keysToCamelCase';

export default class AccountPage {
  static async getAccountData() {
    return Promise.resolve(
      Api.get('/api/account')
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async updateAccountData(paramsBody: FormData) {
    return Promise.resolve(
      Api.post('/api/account', paramsBody, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then((response) => ({
          failed: false,
          data: keysToCamel(response.data),
        }))
        .catch((error) => ({ failed: true, data: error.response.data })),
    );
  }
}
