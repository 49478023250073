import React, { useEffect, useState } from 'react';
import { CheckboxContainer, CheckboxMenuContainer, Element } from './style';
import Checkbox from '../../atoms/Checkbox';
import RadioButtonInterface from './interface';
import CheckboxMenu from '../CheckboxMenu';

function RadioButton({
  types,
  onSelectType,
  onCheckboxChange,
  initialValue,
  hiddenOptions,
}: RadioButtonInterface) {
  const [selected, setSelected] = useState<string | undefined>(
    initialValue ?? undefined,
  );
  const [checkboxSelected, setCheckboxSelected] = useState<any | null>();

  useEffect(() => {
    onSelectType(selected);
    onCheckboxChange(checkboxSelected);
  }, [selected, checkboxSelected]);

  const customStyle = {
    selected: {
      backgroundColor: 'var(--ecg-royal-blue)',
      border: '4.5px solid white',
      outline: '1px solid var(--ecg-royal-blue)',
    },
    default: {
      border: '1.25px solid var(--light-border)',
      margin: '7px 0px',
    },
    label: {
      color: 'var(--border-black)',
      fontSize: '16px',
      fontWeight: 400,
      disabled: {
        color: 'var(--footer-light-gray)',
      },
    },
  };

  const isRadioDisabed = (type: string) =>
    hiddenOptions.includes(type) &&
    Object.keys(types[type].options).every((option: string) =>
      hiddenOptions.includes(option),
    );

  const handleOptionChange = (option: string) => {
    setSelected(option);
    setCheckboxSelected(
      Object.keys(types[option].options).filter(
        (typeKey: string) => !hiddenOptions.includes(typeKey),
      ),
    );
  };

  useEffect(() => {
    if (initialValue) {
      handleOptionChange(initialValue);
    }
  }, [initialValue]);

  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  return (
    <Element>
      <CheckboxContainer>
        {Object.keys(types).map((typeKey: string) => (
          <>
            <Checkbox
              id={buildId()}
              key={typeKey}
              label={types[typeKey].label}
              disabled={isRadioDisabed(typeKey)}
              onChange={() => handleOptionChange(typeKey)}
              control={selected === typeKey}
              format="circular"
              height="24px"
              width="24px"
              style={customStyle}
            />
            {Object.keys(types[typeKey].options)?.length > 0 &&
              selected === typeKey && (
                <CheckboxMenuContainer gap={types[typeKey].gap}>
                  <CheckboxMenu
                    initialValues={types[typeKey].options}
                    options={types[typeKey].options}
                    onSelectedChange={setCheckboxSelected}
                    style={customStyle}
                    disabledOptions={hiddenOptions}
                  />
                </CheckboxMenuContainer>
              )}
          </>
        ))}
      </CheckboxContainer>
    </Element>
  );
}

export default RadioButton;
