import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import DonutChartInterface from './interface';
import { ChartContainer } from './style';

function DonutChart({ xValues, yValues, marginTop }: DonutChartInterface) {
  ChartJS.register(ArcElement, Tooltip, Legend);
  return (
    <ChartContainer id="chartHolder" marginTop={marginTop}>
      <Doughnut
        height="min-content"
        width="min-content"
        data={{
          labels: xValues,
          datasets: [
            {
              data: yValues,
              backgroundColor: [
                '#002252',
                '#13477B',
                '#286FA5',
                '#4099CE',
                '#5CC6F6',
                '#8FD4F5',
                '#B7DFF4',
                '#DDEBF3',
                '#afd2e6',
                '#85bbda',
                '#5ea4cd',
              ],
            },
          ],
        }}
        options={{
          animation: false,
          cutout: 55,
          responsive: true,
          maintainAspectRatio: false,
          devicePixelRatio: 10,
          radius: '100%',
          elements: {
            arc: {
              borderWidth: 2,
            },
          },
          plugins: {
            legend: {
              align: 'start',
              position: 'right',
              fullSize: true,
              labels: {
                boxWidth: 15,
                boxHeight: 15,
                padding: 3,
                color: '#040404',
                font: {
                  size: 9,
                },
              },
            },
          },
        }}
      />
    </ChartContainer>
  );
}

export default DonutChart;
