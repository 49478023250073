import styled from 'styled-components';

interface ContainerInterface {
  fontSize?: number;
  fontStyle?: 'normal' | 'oblique' | 'italic';
  tooltip?: boolean;
}

export const Container = styled.div<ContainerInterface>`
  width: 90%;
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;
  flex-grow: 1;
  margin: 10px 0;

  > :nth-child(1) {
    margin-right: 8px;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '18px')};
    font-style: ${(props) => props.fontStyle ?? 'normal'};
  }

  > span {
    color: var(--almost-black);
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '20px')};
    line-height: 19px;
    text-align: left;
    cursor: pointer;
    font-weight: bold;
    font-style: ${(props) => props.fontStyle ?? 'normal'};
  }
  > :nth-child(${(props) => (props.tooltip ? 4 : 3)}) {
    position: absolute;
    left: 565px;
    margin-left: auto;
    align-self: flex-start;
  }
  > .dotloader {
    > div {
      top: 21px;
    }
  }
`;
