import React from 'react';
import ReportHeader from 'src/components/organisms/Reports/Header';
import ReportFooter from 'src/components/organisms/Reports/Footer';
import { Page, TextsWrappers } from '../style';

export interface DataCollectionTexts {
  rateSecondParagraph: string;
  rateThirdParagraph: string;
  rateFourthParagraph: string;
  rateTitle: string;
  rateFirstParagraph: string;
  footerText: string;
}

function DataCollectionPage({
  rateSecondParagraph,
  rateThirdParagraph,
  rateFourthParagraph,
  rateTitle,
  rateFirstParagraph,
  footerText,
}: DataCollectionTexts) {
  return (
    <Page>
      <div className="reportHeader">
        <ReportHeader title="" />
      </div>
      <TextsWrappers>
        <h2 className="title">{rateTitle}</h2>
        <p className="averageParagraph">{rateFirstParagraph}</p>
        <p className="averageParagraph">{rateSecondParagraph}</p>
        <p className="averageParagraph">{rateThirdParagraph}</p>
        <p className="averageParagraph">{rateFourthParagraph}</p>
      </TextsWrappers>
      <div className="reportFooter">
        <ReportFooter customText={footerText} />
      </div>
    </Page>
  );
}

export default DataCollectionPage;
