import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  width: 100%;
  height: 30px;
  background-color: #edeced;
`;

export const FooterTitle = styled.span`
  font-size: 6px;
  font-weight: 200;
  margin-bottom: 3px;
  letter-spacing: 0.5px;
`;

export const FooterText = styled.span`
  font-size: 5px;
  color: var(--primary-dark-gray);
  letter-spacing: -0.2px;
`;
