import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import Autocomplete from '@mui/material/Autocomplete';

interface ContainerInterface {
  width?: number;
  isBenchmark?: boolean;
}

interface BenchmarkInterface {
  isBenchmark?: boolean;
}

interface InputInterface {
  height?: number;
  paddingTop?: number;
}

export const Container = styled.div<ContainerInterface>`
  position: relative;
  width: ${(props) => (props.isBenchmark ? `${props.width}%` : '550px')};
`;

export const SearchIcon = styled(FaSearch)<BenchmarkInterface>`
  position: absolute;
  left: 20px;
  top: ${(props) => (props.isBenchmark ? '20px' : '12px')};
  color: var(--primary-dark-gray);
`;

export const Input = styled(Autocomplete)<InputInterface>`
  background-color: #FFFFFF;
  border: 1px solid #B6B7BD;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%), inset 0 4px 7px 0 rgb(0 0 0 / 9%);
  min-width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : '40px')};

  .MuiOutlinedInput-root{
    padding-left: 39px;
    padding-top: ${(props) =>
      props.paddingTop ? `${props.paddingTop}px` : '0'} !important;
    height: ${(props) =>
      props.height ? `${props.height}px` : '40px'}; !important;
  } 
  
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 0 !important;
  }
  .search-container-label{
    font-size: inherit;
    line-height: inherit;
    color: black;
  }
  
  .MuiSvgIcon-root{
    display: none;
  }
  
  > * label {
    font-size: inherit;
    line-height: inherit;
    color: black;
  }

  > * input {
    ::placeholder {
      color: var(--primary-dark-gray);
      opacity: 1;
    }
    
    font-size: inherit !important;
    line-height: inherit !important;
    height: 40px !important;
    padding-top: 0 !important;
    padding-left: 40px !important;
    margin: 0 !important;
  }
`;
