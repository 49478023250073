import React, { useState, useEffect } from 'react';
import FacilityTotalsTemplate from 'src/components/templates/Reports/FacilityTotals';
import FacTotals from '../totalfacs';

function FacilityTotals() {
  const [pdfData, setPdfData] = useState<any>();

  const getFacilityTotalsData = async () => {
    const response = await FacTotals.getFacTotalsPdf();
    if (!response.failed) {
      setPdfData(response.data);
    }
  };

  useEffect(() => {
    getFacilityTotalsData().then(null);
  }, []);

  return pdfData ? <FacilityTotalsTemplate data={pdfData} /> : null;
}

export default FacilityTotals;
