function isVisible(parentId: string, child: any, fittingError?: number) {
  const parent = document.getElementById(parentId);

  return !(
    child!.offsetTop + child!.offsetHeight + (fittingError ?? 0) >
    parent!.offsetHeight
  );
}

function appendChildToElement(
  content: any,
  parentPrefix: string,
  pageNumber: number,
  fittingError?: number,
) {
  let page = pageNumber;
  const parentId = `${parentPrefix}-${page}`;
  const currentPage = document!.getElementById(parentId);
  currentPage?.appendChild(content as Node);

  const visible = isVisible(parentId, content, fittingError);

  if (!visible) {
    content!.remove();
    page += 1;
    if (content.props?.className !== 'lineDivider') {
      const nextPage = document!.getElementById(`${parentPrefix}-${page}`);
      nextPage?.appendChild(content as Node);
    }
  }
  return page;
}

export default appendChildToElement;
