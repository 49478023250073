import React from 'react';
import TrendTableInterface from './interface';
import {
  Circle,
  DataComparison,
  Table,
  TableBody,
  TableData,
  TableHeader,
  TableHeaderColumn,
  TableHeaderInfo,
  TableInfo,
  TableRow,
} from './style';
import { formatCurrencyToNumber } from '../../../utils/trendAnalysis';

function TrendTable({ id, values, isPdf, years }: TrendTableInterface) {
  const scaleFactor = 20;
  const calculateTableWidth = `${Math.max(60, years.length * scaleFactor)}%`;

  const style = {
    fontSize: isPdf ? '10px' : '14px',
    dataCompSize: isPdf ? '8px' : '12px',
    size: isPdf ? '6px' : '16px',
    infoSize: isPdf ? '35%' : '235px',
    infoPadding: isPdf ? '5px' : '20px',
    headerInfoSize: isPdf ? '160px' : '260px',
    headerHeight: isPdf ? '20px' : '44px',
    rowHeight: isPdf ? '35px' : '64px',
    paddingTop: isPdf ? '9px' : '16px',
    tableWidth: isPdf ? '100%' : calculateTableWidth,
  };

  const calculatePercentageWidth = `${100 / years.length}%`;

  const percentiles = ['q25', 'q50', 'q75', 'q90'];
  const circleTicksLabels = [
    '25th Percentile',
    'Median',
    '75th Percentile',
    '90th Percentile',
  ];
  const percentileColors = ['#5cc6f6', '#0072AF', '#005887', '#00355E'];

  const renderHeader = () => (
    <TableRow>
      <TableHeaderInfo
        height={style.headerHeight}
        width={style.headerInfoSize}
      />
      {years.map((year: string) => (
        <TableHeaderColumn
          fontSize={style.fontSize}
          height={style.headerHeight}
          key={year}
          width={calculatePercentageWidth}
        >
          {year}
        </TableHeaderColumn>
      ))}
    </TableRow>
  );

  const renderRowsInfo = (value: number, year: string, previous: number) => {
    const percentDifference = ((value - previous) / previous) * 100;
    const formattedPercent = Math.abs(percentDifference).toFixed(0);

    if (year === undefined) {
      return null;
    }

    let label = '';

    if (percentDifference > 0) {
      label = `+${formattedPercent}%`;
    } else if (percentDifference < 0) {
      label = `-${formattedPercent}%`;
    } else {
      label = `${formattedPercent}%`;
    }

    return (
      <DataComparison fontSize={style.dataCompSize}>{label}</DataComparison>
    );
  };

  const renderRows = () =>
    circleTicksLabels.map((percentile, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <TableRow key={`percentile-${index}`}>
        <TableInfo
          key={`info-${percentile}`}
          fontSize={style.fontSize}
          width={style.infoSize}
          height={style.rowHeight}
          paddingLeft={style.infoPadding}
        >
          <Circle color={percentileColors[index]} size={style.size} />
          {percentile}
        </TableInfo>
        {years.map((year, yearIndex: number) => {
          const percentileValue = values.years[year][percentiles[index]];

          const previousYear = years[yearIndex - 1];
          const previousValue = previousYear
            ? formatCurrencyToNumber(
                values.years[previousYear][percentiles[index]],
              )
            : 0;
          const valueRender = `${percentileValue}`;
          return (
            <TableData
              height={style.rowHeight}
              fontSize={style.fontSize}
              key={`data-${year}`}
              width={calculatePercentageWidth}
              $paddingTop={style.paddingTop}
            >
              {percentileValue ? (
                <>
                  {valueRender}
                  {renderRowsInfo(
                    formatCurrencyToNumber(percentileValue),
                    previousYear,
                    previousValue,
                  )}
                </>
              ) : null}
            </TableData>
          );
        }, [])}
      </TableRow>
    ));

  return (
    <Table id={id} width={style.tableWidth}>
      <TableHeader>{renderHeader()}</TableHeader>
      <TableBody>{renderRows()}</TableBody>
    </Table>
  );
}

export default TrendTable;
