/* eslint-disable max-len */
/* eslint-disable react/function-component-definition,react/no-unstable-nested-components,react/destructuring-assignment */
import React from 'react';
import {
  BenchmarkInformation,
  Left,
  Right,
  BenchmarkService,
  Field,
  ComparisonService,
  CoverageTitle,
  CoverageDetails,
  ColorBloc,
  Row,
  PercentileGroups,
  Color,
  Average,
  Percent,
  Line,
} from './style';
import PdfChartsInterface from './interface';
import MDRangerServicesConstants from '../../../constants/services';

function PdfCharts({
  benchmarkObject,
  service,
  benchValue,
  marginTop,
  chartId,
  percentPayingHats,
}: PdfChartsInterface) {
  const percentWidths = [112, 173, 228, 280, 337];

  // const percentageBenchmarks = ['US', 'UG']; leave this comment in case of a rollback

  // const noDolarSignBenchmarks = ['HH'];

  // const noDolarSignsShortnames = ['workRVUs', 'totalRVUs'];

  const removeFormatting = (value: string | number) =>
    typeof value !== 'number'
      ? Number(value.replace('$', '').replace(/,/g, ''))
      : value;

  const Chart = (value: string, hat: string, percentsArray: number[]) => {
    let percentBlockIndex = 0;

    let castValue = Number(value);

    if (!castValue && !Number.isNaN(castValue)) return null;

    castValue = removeFormatting(value);

    for (let i = 0; i < percentsArray.length; i += 1) {
      if (castValue > Number(percentsArray[i])) {
        percentBlockIndex = i + 1;
      }
    }

    let averageWidth = 0;

    let diffWidth = 0;
    let diffValue = 0;

    if (percentBlockIndex === 0) {
      diffWidth = percentWidths[percentBlockIndex] - 0;
      diffValue = Number(percentsArray[percentBlockIndex]) - 0;
      averageWidth = (castValue * diffWidth) / diffValue;
    } else if (
      percentBlockIndex === 4 &&
      castValue >= Number(percentsArray[3])
    ) {
      averageWidth = percentWidths[3] + 40;
    } else {
      diffWidth =
        percentWidths[percentBlockIndex] - percentWidths[percentBlockIndex - 1];
      diffValue =
        Number(percentsArray[percentBlockIndex]) -
        Number(percentsArray[percentBlockIndex - 1]);
      const valueMinusBenchmark =
        castValue - Number(percentsArray[percentBlockIndex - 1]);
      const occupationRate =
        diffValue !== 0 ? (valueMinusBenchmark * diffWidth) / diffValue : 0;
      averageWidth = occupationRate + percentWidths[percentBlockIndex - 1];
    }

    return (
      <>
        <PercentileGroups>
          <Color width={120} color="#cde1ef" />
          <Percent position={105}>25%</Percent>
          <Color width={65} color="#5dc4f3" />
          <Percent position={167}>50%</Percent>
          <Color width={60} color="#8bacd3" />
          <Percent position={220}>75%</Percent>
          <Color width={55} color="#3e94c1" />
          <Percent position={274}>90%</Percent>
          <Color width={65} color="#0c74b4" />
          <Average width={averageWidth}>
            <span>{value}</span>
          </Average>
        </PercentileGroups>
        <div style={{ marginLeft: '30px' }}>
          <Line />
        </div>
      </>
    );
  };

  const getSliceMessage = (slice: string, slicedetail: string) => {
    switch (slice) {
      case 'gach':
        return <ComparisonService>All Acute Hospitals</ComparisonService>;
      case 'allfacs':
        return <ComparisonService>All Facility Types</ComparisonService>;
      case 'gach and trauma_flag':
        return (
          <>
            <ComparisonService>All Acute Hospitals</ComparisonService>
            <ComparisonService>Trauma Designation: Trauma</ComparisonService>
          </>
        );
      case 'allfacs and trauma_flag':
        return (
          <>
            <ComparisonService>All Acute Hospitals</ComparisonService>
            <ComparisonService>Trauma Designation: Trauma</ComparisonService>
          </>
        );
      default:
        return (
          slicedetail && <ComparisonService>{slicedetail}</ComparisonService>
        );
    }
  };

  const percentPayingBenchmarks = percentPayingHats;

  const toShowPercentPaying = (hat: string, slice: string) => {
    const show =
      percentPayingBenchmarks?.includes(hat) && !slice.includes('trauma');

    return show;
  };
  return (
    <div id={chartId}>
      <BenchmarkInformation margin={marginTop!}>
        <Left>
          <Field>{MDRangerServicesConstants[benchmarkObject?.hat]}</Field>
          <Field>{benchmarkObject?.benchmark}</Field>
          <BenchmarkService>
            {`Benchmark Service: ${
              benchmarkObject.searchService ?? benchmarkObject.service
            }`}
          </BenchmarkService>
          {benchmarkObject?.region ? (
            <BenchmarkService>
              {`Region: ${benchmarkObject.region}`}
            </BenchmarkService>
          ) : null}
          {service !== benchmarkObject.service ? (
            <ComparisonService>
              {`Comparison Service: ${benchmarkObject.service}`}
            </ComparisonService>
          ) : null}
          {getSliceMessage(
            benchmarkObject?.slice,
            benchmarkObject?.slicedetail,
          )}
        </Left>
        <Right>
          {benchmarkObject.percentPaying &&
            toShowPercentPaying(
              benchmarkObject?.hat,
              benchmarkObject?.slice,
            ) && (
              <CoverageTitle>
                {`Percent of subscriber hospitals paying: ${benchmarkObject.percentPaying}`}
              </CoverageTitle>
            )}
          <Row>
            <ColorBloc color="#cde1ef" />
            <CoverageDetails>
              {`25th Percentile: ${benchmarkObject.q25}`}
            </CoverageDetails>
          </Row>
          <Row>
            <ColorBloc color="#5dc4f3" />
            <CoverageDetails>
              {`50th Percentile: ${benchmarkObject.q50}`}
            </CoverageDetails>
          </Row>
          <Row>
            <ColorBloc color="#8bacd3" />
            <CoverageDetails>
              {`75th Percentile: ${benchmarkObject.q75}`}
            </CoverageDetails>
          </Row>
          <Row>
            <ColorBloc color="#3e94c1" />
            <CoverageDetails>
              {`90th Percentile: ${benchmarkObject.q90}`}
            </CoverageDetails>
          </Row>
        </Right>
      </BenchmarkInformation>
      {Chart(benchValue!, benchmarkObject.hat ?? benchmarkObject.shortname, [
        removeFormatting(benchmarkObject.q25),
        removeFormatting(benchmarkObject.q50),
        removeFormatting(benchmarkObject.q75),
        removeFormatting(benchmarkObject.q90),
      ])}
    </div>
  );
}

export default PdfCharts;
