import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxHider, Section } from '../../../pages/RateComparison/style';
import Loader from '../../atoms/DotLoader';
import DoubleCheckBoxInput from '../../molecules/DoubleCheckBoxInput';
import MultiSelectPillbox from '../../atoms/PillboxSelect';
import ComparisonConditionalInput from '../../molecules/ComparisonConditionalInput';
import ComparisonConditionalMultipleInput from '../../molecules/ComparisonConditionalMultipleInput';
import RateConversionModal from '../RateConversionModal';
import RateComparisonService from '../../../services/rateComparison';
import SearchHeader from '../../molecules/SearchHeader';
import RateComparisonServiceBoxInterface from './interface';

function RateComparisonServiceBox({
  id,
  services,
  onServiceSelect,
  callBack,
  type,
}: RateComparisonServiceBoxInterface) {
  const { t } = useTranslation('rate-comparison');

  const [service, setService] = useState<string>('');
  const [trauma] = useState<boolean>(false);
  const [precheck] = useState<boolean>(false);
  const [HC, setHC] = useState<string>('');
  const [HD, setHD] = useState<string>('');
  const [HH, setHH] = useState<string>('');
  const [HR, setHR] = useState<string>('');
  const [HS, setHS] = useState<string>('');
  const [LH, setLH] = useState<string>('');
  const [HP, setHP] = useState<string>('');
  const [HQ, setHQ] = useState<string>('');
  const [HT, setHT] = useState<string>('');
  const [EP, setEP] = useState<string>('');
  const [EV, setEV] = useState<string>('');
  const [EC, setEC] = useState<string>('');
  const [DS, setDS] = useState<string>('');
  const [US, setUS] = useState<string>('');
  const [UG, setUG] = useState<string>('');
  const [hiddenBenchmarks, setHiddenBenchmarks] = useState<string[]>([]);
  const [slices, setSlices] = useState<string[]>([]);
  const [selectedSlices, setSelectedSlices] = useState<string[]>([]);
  const [loadingHiddenBenchmarks, setLoadingHiddenBenchmarks] =
    useState<boolean>(false);
  const [isTelemedicine, setIsTelemedicine] = useState<boolean>(false);
  const [defaultComparison, setDefaultComparison] = useState<boolean>(true);

  const handleCheckValidInputs = async (selectedService: string) => {
    setLoadingHiddenBenchmarks(true);
    setService(selectedService);
    setDefaultComparison(true);
    onServiceSelect(selectedService);

    if (!selectedService || !selectedService.length) {
      setLoadingHiddenBenchmarks(false);
      return setHiddenBenchmarks([]);
    }

    const response = await RateComparisonService.getHiddenBenchmarks(
      selectedService,
      trauma ? 1 : 0,
    );

    if (response.failed) {
      setLoadingHiddenBenchmarks(false);
      return setHiddenBenchmarks([]);
    }

    const comparisonResponse = await RateComparisonService.getComparisons(
      selectedService,
      precheck ? 1 : 0,
    );

    if (comparisonResponse.failed) {
      return setSlices([]);
    }

    setSlices(comparisonResponse.data?.slices);

    const toHideArray = response.data.hideAnnualPayment
      ? [...response.data.hiddenBenchmarks, 'HD']
      : response.data.hiddenBenchmarks;
    setLoadingHiddenBenchmarks(false);
    setIsTelemedicine(Boolean(response.data?.telemedicine));
    return setHiddenBenchmarks(toHideArray);
  };

  const handleSelectSlices = (callReturn: any) => {
    setSelectedSlices(callReturn);
  };

  const checkAllBenchmarksAreUnavailable = (benchMarksArray: string[]) =>
    benchMarksArray.every((element) => hiddenBenchmarks.includes(element));

  useEffect(() => {
    if (defaultComparison) {
      setSelectedSlices([]);
    }
  }, [defaultComparison]);

  useEffect(() => {
    callBack({
      service,
      HC,
      HD,
      HH,
      HR,
      HS,
      LH,
      HP,
      HQ,
      HT,
      EP,
      EV,
      EC,
      DS,
      US,
      UG,
      selectedSlices,
    });
  }, [
    service,
    HC,
    HD,
    HH,
    HR,
    HS,
    LH,
    HP,
    HQ,
    HT,
    EP,
    EV,
    EC,
    DS,
    US,
    UG,
    selectedSlices,
  ]);

  return (
    <>
      {type === 'main' && (
        <Section>
          <SearchHeader
            placeholder={t('search')}
            title={t('title')}
            callback={(data: string) => handleCheckValidInputs(data)}
            options={services}
          />
        </Section>
      )}
      <Box id={id}>
        {type === 'additional' && (
          <SearchHeader
            placeholder={t('search')}
            title={t('optional')}
            callback={(data: string) => handleCheckValidInputs(data)}
            options={services}
          />
        )}
        {(type === 'main' || service) && (
          <div>
            {(!service?.length || loadingHiddenBenchmarks) && (
              <BoxHider blur={loadingHiddenBenchmarks.toString()}>
                {loadingHiddenBenchmarks && <Loader />}
              </BoxHider>
            )}
            <div style={{ paddingLeft: 6 }}>
              <DoubleCheckBoxInput
                firstLabel="Default Comparison: All Hospitals"
                secondLabel="Custom Comparison"
                secondLabelNote="Note: Some characteristics may not have corresponding benchmarks."
                startValue={defaultComparison}
                direction="column"
                onChange={setDefaultComparison}
                height="14px"
                width="14px"
              />
            </div>
            {!defaultComparison && (
              <Section className="multiplePillBox">
                <MultiSelectPillbox
                  pillBoxId="rcr-pillbox"
                  callBack={handleSelectSlices}
                  dataItems={slices}
                />
              </Section>
            )}
            <div>
              {!hiddenBenchmarks.includes('HC') && (
                <ComparisonConditionalInput
                  hasCustomResult
                  buttonPlaceholder={t('add_ed_coverage_title')}
                  inputPlaceholder={t('add_ed_coverage_subtitle')}
                  inputCallback={(value: string) => setHC(value)}
                  selectedService={service}
                  width={520}
                  value={HC}
                  trauma={trauma}
                  identifier="HC"
                  inputType="currency"
                  slices={selectedSlices}
                  inputFooter={
                    <RateConversionModal
                      result={(calcResult: number) =>
                        setHC(String(calcResult.toFixed(2)))
                      }
                    />
                  }
                />
              )}
              {!checkAllBenchmarksAreUnavailable(['HH', 'HR']) && (
                <ComparisonConditionalMultipleInput
                  slices={selectedSlices}
                  buttonPlaceholder={t('add_medical_direction_rate_title')}
                  width={520}
                  inputsPlaceholder={[
                    t('add_medical_direction_hours_annual_hours'),
                    t('add_medical_direction_hours_hourly'),
                    t('add_medical_direction_hours_annual_payment'),
                  ]}
                  inputsCallback={(inputsValues: {
                    firstInput: string;
                    secondInput: string;
                    thirdInput: string;
                  }) => {
                    setHH(inputsValues.firstInput);
                    setHR(inputsValues.secondInput);
                    setHD(inputsValues.thirdInput);
                  }}
                  selectedService={service}
                  trauma={trauma}
                  values={[HH, HR, HD]}
                  identifiers={['HH', 'HR', 'HD']}
                  hiddenInputs={hiddenBenchmarks}
                  hiddenMessage={
                    checkAllBenchmarksAreUnavailable(['HD'])
                      ? t('hide_annual_payment')
                      : undefined
                  }
                />
              )}
              {!hiddenBenchmarks.includes('HS') && (
                <ComparisonConditionalInput
                  hasCustomResult
                  slices={selectedSlices}
                  buttonPlaceholder={t('add_hospital_based_title')}
                  inputPlaceholder={t('add_hospital_based_subtitle')}
                  inputCallback={(value: string) => setHS(value)}
                  width={520}
                  selectedService={service}
                  trauma={trauma}
                  inputType="currency"
                  identifier="HS"
                  value={HS}
                  helper={t('add_hospital_based_helper')}
                />
              )}
              {!hiddenBenchmarks.includes('LH') && (
                <ComparisonConditionalInput
                  hasCustomResult
                  slices={selectedSlices}
                  buttonPlaceholder={t('add_clinical_service_title')}
                  inputPlaceholder={t('add_clinical_service_subtitle')}
                  width={520}
                  inputCallback={(value: string) => setLH(value)}
                  selectedService={service}
                  trauma={trauma}
                  inputType="currency"
                  identifier="LH"
                  value={LH}
                />
              )}
              {!checkAllBenchmarksAreUnavailable(
                !isTelemedicine ? ['HP', 'HQ', 'HT'] : ['HP', 'HQ', 'HT', 'EP'],
              ) && (
                <ComparisonConditionalMultipleInput
                  buttonPlaceholder={t('add_telemedicine_title')}
                  width={520}
                  inputsPlaceholder={[
                    t('add_telemedicine_per_payment'),
                    t('add_telemedicine_non_per_payment'),
                    t('add_telemedicine_non_per_episode'),
                    t('add_telemedicine_per_episode'),
                  ]}
                  inputsCallback={(inputsValues: {
                    firstInput: string;
                    secondInput: string;
                    thirdInput: string;
                    fourthInput: string;
                  }) => {
                    setHP(inputsValues.firstInput);
                    setHQ(inputsValues.secondInput);
                    setHT(inputsValues.thirdInput);
                    setEP(inputsValues.fourthInput);
                  }}
                  selectedService={service}
                  trauma={trauma}
                  values={[HP, HQ, HT, EP]}
                  identifiers={['HP', 'HQ', 'HT', 'EP']}
                  hiddenInputs={[
                    ...hiddenBenchmarks,
                    !isTelemedicine ? 'EP' : '',
                  ]}
                  slices={selectedSlices}
                />
              )}
              {!checkAllBenchmarksAreUnavailable(
                isTelemedicine ? ['EV', 'EC'] : ['EV', 'EC', 'EP'],
              ) && (
                <ComparisonConditionalMultipleInput
                  buttonPlaceholder={t('add_per_activation')}
                  width={520}
                  inputsPlaceholder={[
                    t('add_per_activation_ac'),
                    t('add_per_activation_ep'),
                    t('add_per_activation_pro'),
                  ]}
                  inputsCallback={(inputsValues: {
                    firstInput: string;
                    secondInput: string;
                    thirdInput: string;
                  }) => {
                    setEV(inputsValues.firstInput);
                    setEP(inputsValues.secondInput);
                    setEC(inputsValues.thirdInput);
                  }}
                  selectedService={service}
                  trauma={trauma}
                  values={[EV, EP, EC]}
                  identifiers={['EV', 'EP', 'EC']}
                  hiddenInputs={[
                    ...hiddenBenchmarks,
                    isTelemedicine ? 'EP' : '',
                  ]}
                  slices={selectedSlices}
                />
              )}
              {!hiddenBenchmarks.includes('DS') && (
                <ComparisonConditionalInput
                  hasCustomResult
                  slices={selectedSlices}
                  buttonPlaceholder={t('add_diagnostic_testing_title')}
                  inputPlaceholder={t('add_diagnostic_testing_subtitle')}
                  width={520}
                  inputCallback={(value: string) => setDS(value)}
                  selectedService={service}
                  trauma={trauma}
                  inputType="currency"
                  identifier="DS"
                  value={DS}
                />
              )}
              {!hiddenBenchmarks.includes('US') && (
                <ComparisonConditionalInput
                  hasCustomResult
                  slices={selectedSlices}
                  buttonPlaceholder={t('add_unsponsored_percent_title')}
                  inputPlaceholder={t('add_unsponsored_percent_subtitle')}
                  width={520}
                  inputCallback={(value: string) => setUS(value)}
                  selectedService={service}
                  trauma={trauma}
                  inputType="number"
                  identifier="US"
                  value={US}
                />
              )}
              {!hiddenBenchmarks.includes('UG') && (
                <ComparisonConditionalInput
                  hasCustomResult
                  slices={selectedSlices}
                  buttonPlaceholder={t('add_unit_guarantee_title')}
                  inputPlaceholder={t('add_unit_guarantee_subtitle')}
                  width={520}
                  inputCallback={(value: string) => setUG(value)}
                  selectedService={service}
                  trauma={trauma}
                  inputType="number"
                  identifier="UG"
                  value={UG}
                />
              )}
            </div>
          </div>
        )}
      </Box>
    </>
  );
}

export default RateComparisonServiceBox;
