const formatPhoneNumber = (input: string) => {
  const maxPhoneNumberLength = 10;
  const phoneNumber = input.slice(0, maxPhoneNumberLength);

  if (phoneNumber.length <= 3) {
    return phoneNumber.replace(/^(\d{3})/, '($1)');
  }

  if (phoneNumber.length <= 6) {
    return phoneNumber.replace(/^(\d{3})(\d{0,3})/, '($1) $2');
  }

  return phoneNumber.replace(/^(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3');
};

const handleMask = (value: string, deleting?: number) => {
  const input = value.replace(/\D/g, '');
  const formatted = formatPhoneNumber(
    input.slice(0, input.length - (deleting ?? 0)),
  );
  return formatted;
};

export default handleMask;
