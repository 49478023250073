import React from 'react';
import { LoaderContainer } from './style';
import LoaderInterface from './interface';

function Loader({ color, marginTop }: LoaderInterface) {
  return (
    <LoaderContainer color={color} marginTop={marginTop} className="dotloader">
      <div />
      <div />
      <div />
    </LoaderContainer>
  );
}

export default Loader;
