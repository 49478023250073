import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Container } from './style';
import DatePicker from '../../atoms/DatePicker';
import PeriodPickerInterface from './interface';
import Subtitle from '../../atoms/Subtitle';

function PeriodPicker({ onChange, title }: PeriodPickerInterface) {
  const { t } = useTranslation('period-picker');

  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>('');

  const secondDayPickerRef = useRef(null);

  useEffect(() => onChange({ start, end }), [start, end]);

  const focusPicker = (ref: any) => {
    ref.current.focus();
  };

  return (
    <div>
      <Subtitle text={title} />
      <Container>
        <DatePicker
          label={t('start')}
          onChange={(date: Date) => setStart(format(date, 'yyyy-MM-dd'))}
          pickerRef={secondDayPickerRef}
          changeFocus={focusPicker}
        />
        <DatePicker
          label={t('end')}
          onChange={(date: Date) => setEnd(format(date, 'yyyy-MM-dd'))}
          pickerRef={secondDayPickerRef}
        />
      </Container>
    </div>
  );
}

export default PeriodPicker;
