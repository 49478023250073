const roundDecimals = (num: number) =>
  Math.round((num + Number.EPSILON) * 10) / 10;

const toPercentage = (current: number, sum: number) => current * (100 / sum);

export const calculateDoughnutPercentage = (sectionBody: any) => {
  const values = Object.values(sectionBody);
  const sum: any = values.reduce((a: any, b: any) => a + b, 0);
  const percentage: any = {};

  Object.keys(sectionBody).map((key: string) => {
    const current = sectionBody[key];

    const currentPercentage = roundDecimals(toPercentage(current, sum));
    percentage[`${key} (${currentPercentage}%)`] = currentPercentage;
    return sum;
  });
  return percentage;
};

export const formatValue = (value: number, currency?: boolean) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
  });

  const result = formatter.format(value ?? 0);

  if (currency) {
    return `$${result}`;
  }
  return `${result}`;
};

export const removeZeroValues = (objectBody: any) => {
  const keys = Object.keys(objectBody);
  const returnable: any = {};
  keys.map((key: string) => {
    if (objectBody[key] > 1) returnable[key] = objectBody[key];
    return null;
  });
  return returnable;
};

export const buildLabel = (labelBody: any) => {
  const entries = Object.entries(labelBody);
  const returnable: any = {};

  const values = Object.values(labelBody);
  const sum: any = values.reduce((a: any, b: any) => a + b, 0);

  entries.forEach((entry: any) => {
    const [key, value] = entry;
    returnable[key] = {};

    const percentage = roundDecimals(toPercentage(value, sum));
    const result = formatValue(value);
    returnable[key] = `${key};$${result};(${percentage}%)`;
  });
  return returnable;
};

export const buildYValues = (dataObject: any) => {
  const values = Object.values(dataObject);
  const returnable: any = [];
  values.forEach((value: any) => {
    const result = formatValue(value, true);
    returnable.push(result);
  });
  return returnable;
};

export const buildTotalCharstData = (dataObject: any) => {
  const ftSection = dataObject ?? {};
  const facilityNames = Object.keys(ftSection);
  const ftCharts: any = {};
  // @ts-ignore
  const benchHats = Object.keys(ftSection[facilityNames[0]]);

  benchHats.forEach((hat: string) => {
    ftCharts[hat] = {};
    facilityNames.forEach((facilityName: string) => {
      // @ts-ignore
      /* swapping order for chart to handle properly */
      ftCharts[hat][facilityName] = ftSection[facilityName][hat];
    });
  });
  return ftCharts;
};
