import styled from 'styled-components';

export const Box = styled.section`
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 90%;
  background-color: #fff;
  border-radius: 2px;
  gap: 15px;

  > :nth-child(6) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 57px;
`;
