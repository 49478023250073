import React from 'react';
import TextArea from 'src/components/atoms/TextArea';
import SubTitle from 'src/components/atoms/Subtitle';
import HelpTooltip from 'src/components/atoms/HelpTooltip';
import { Container, Row } from './style';
import ReportTextAreaInterface from './interface';

function ReportTextArea({
  title,
  callback,
  width,
  value,
  placeholder,
  maxLen,
  helpText,
  minHeight,
}: ReportTextAreaInterface) {
  return (
    <Container>
      <Row>
        {!!title && <SubTitle text={title} />}
        {!!helpText && <HelpTooltip text={helpText} />}
      </Row>
      <TextArea
        placeholder={placeholder}
        value={value}
        maxLen={maxLen}
        callback={callback}
        minHeight={minHeight}
        width={width || 550}
      />
    </Container>
  );
}

export default ReportTextArea;
