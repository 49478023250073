import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CompensationSingleReportField from 'src/components/molecules/CompensationSingleReportField';
import ReportTextArea from 'src/components/molecules/ReportTextArea';
import SearchHeader from '../../components/molecules/SearchHeader';
import ReportsGenerator from '../../components/templates/ReportsGenerator';
import DignityReportGenerator from '../../components/templates/DignityReportsGenerator';
import Input from '../../components/molecules/Input';
import Title from '../../components/atoms/Title';
import Footer from '../../components/organisms/Footer';
import SelectDropDown from '../../components/atoms/SelectDropDown';
import CompensationAdministrativePaymentInputs from '../../components/molecules/CompensationAdministrativePaymentInputs';
import CompensationSingleInputs from '../../components/molecules/CompensationSingleInputs';
import TotalCompensationcontextProvider, {
  TotalCompensationContext,
} from '../../contexts/TotalCompensationContext';
import {
  Page,
  Section,
  FieldsWithAdjustedContainer,
  Box,
  BoxHider,
  InsideBox,
  PlusButton,
  MinusButton,
  TotalCompBox,
} from './style';
import CompensationCoveragePerDiemInputs from '../../components/molecules/CompensationCoveragePerDiemInputs';

function TotalCompensation() {
  const { t } = useTranslation('total-compensation');

  const {
    showProduction,
    setShowProduction,
    dignity,
    service,
    setService,
    services,
    regionsList,
    region,
    setRegion,
    physiciansList,
    physicianType,
    setPhysicianType,
    physicianFTE,
    setPhysicianFTE,
    clinicalFTE,
    setClinicalFTE,
    clinicalCompensation,
    setClinicalCompensation,
    paymentPerWork,
    setPaymentPerWork,
    adjustedClinicalCompensation,
    bonusIncentives,
    setBonusIncentives,
    adjustedBonusIncentives,
    others,
    setOthers,
    othersReimbursements,
    setOthersReimbursements,
    setDetails,
    setDetailsReimbursements,
    submiting,
    setHcValues,
    setTraumaValues,
    setServiceCall,
    setHdValues,
    setHhValues,
    setHrValues,
    setServiceAdmin,
    setProfessionalCollections,
    adjustedProfessionalCollections,
    setWorkRVUs,
    adjustedWorkRVUs,
    profCollectionsperWorkRVUs,
    clinicalCompPerWorkRVU,
    setAnnualCall,
    setCallDays,
    setAnnualAdmin,
    totalCompensation,
    totalCompAdjusted,
    totalClinicalComp,
    totalAdjustedClinicalComp,
    handleDignityReportInputChange,
    handleReportGeneratorInputsChange,
    handleSubmit,
    checkToShowAdjustedFields,
    firstPlusButtonPosition,
  } = useContext(TotalCompensationContext);

  interface showAdjustedInterface {
    result: string;
    fieldTitle: string;
    hasCustom?: boolean;
    param?: string;
    responseValue?: string;
    fontsize?: number;
    notDolar?: boolean;
    extraFields?: { [param: string]: string };
    fontStyle?: 'normal' | 'oblique' | 'italic';
  }
  const showAdjustedValues = (params: showAdjustedInterface) => (
    <TotalCompBox>
      <CompensationSingleReportField
        text={params.fieldTitle}
        result={params.result}
        hasCustomResult={params.hasCustom}
        param={params.param}
        service={service}
        responseValue={params.responseValue}
        fontSize={params.fontsize ?? 16}
        fontStyle={params.fontStyle}
        extraFields={params.extraFields}
        notDollar={params.notDolar}
        region={region}
        physicianType={physicianType}
      />
    </TotalCompBox>
  );

  return (
    <Page>
      <Section>
        <SearchHeader
          placeholder={t('search')}
          title={t('title')}
          callback={(data: string) => setService?.(data)}
          options={services}
        />
      </Section>
      <Box
        padding="20px 18% 20px 20px"
        edCallPosition={firstPlusButtonPosition()}
      >
        {!service && <BoxHider />}
        <SelectDropDown
          title={t('region')}
          callBack={(res: { id: string; value: string }) => {
            setRegion?.(res.id);
          }}
          dataItems={regionsList}
          startValueKey={Object.keys(regionsList)[0]}
        />
        <SelectDropDown
          title={t('physician_dropdown')}
          callBack={(res: { id: string; value: string }) => {
            setPhysicianType?.(res.value);
          }}
          dataItems={physiciansList}
          startValueKey={Object.keys(physiciansList)[0]}
        />
        <CompensationSingleInputs
          max={1}
          min={0}
          title={t('physician')}
          type="number"
          value={physicianFTE}
          callback={(value: any) => setPhysicianFTE?.(value)}
          hasCustomResult={false}
          exceptionTitle={t('physician')}
          exceptionMessage={t('decimal_exception')}
        />
        <CompensationSingleInputs
          hasCustomResult={false}
          max={1}
          min={0}
          title={t('clinical_fte')}
          type="number"
          value={clinicalFTE}
          callback={(value: any) => setClinicalFTE?.(value)}
          exceptionTitle={t('percent_clinical')}
          exceptionMessage={t('decimal_exception')}
        />
        <CompensationSingleInputs
          hasCustomResult={false}
          title={t('clinical_compensation')}
          tooltip={t('clinical_comp_tooltip')}
          type="currency"
          value={clinicalCompensation}
          callback={(value: any) => setClinicalCompensation?.(value)}
        />
        {checkToShowAdjustedFields(clinicalFTE)
          ? showAdjustedValues({
              result: adjustedClinicalCompensation,
              fieldTitle: t('clinical_comp_adjusted'),
              hasCustom: false,
            })
          : null}
        <CompensationSingleInputs
          hasCustomResult
          title={t('payment_per_work')}
          tooltip={t('payment_per_work_tooltip')}
          value={paymentPerWork}
          callback={(value: string) => setPaymentPerWork?.(value)}
          type="currency"
          param="paymentPerWorkRVUVal"
          service={service}
          responseValue="paymentPerWorkRVUVal"
          region={region}
          physicianType={physicianType}
        />
        <Input
          title={t('bonus_incetives')}
          type="currency"
          value={bonusIncentives}
          callback={(value: any) => setBonusIncentives?.(value)}
        />
        {checkToShowAdjustedFields(clinicalFTE)
          ? showAdjustedValues({
              result: adjustedBonusIncentives,
              fieldTitle: t('adjusted_bonus_incetives'),
            })
          : null}
        <CompensationCoveragePerDiemInputs
          buttonPlaceholder={t('add_ed_coverage_per_diem_title')}
          inputPlaceholder={t('add_ed_coverage_per_diem_subtitle')}
          sumCallback={setAnnualCall!}
          setCallDays={setCallDays!}
          hcArrayCallback={setHcValues!}
          serviceArrayCallback={setServiceCall!}
          traumaArrayCallback={setTraumaValues!}
          width={515}
          inputType="currency"
          pageService={service}
          pageRegion={region}
          pagePhysicianType={physicianType}
        />
        <CompensationAdministrativePaymentInputs
          buttonPlaceholder={t('administrative_payment_title')}
          sumCallback={setAnnualAdmin!}
          hdCallback={setHdValues!}
          hhCallback={setHhValues!}
          hrCallback={setHrValues!}
          serviceCallback={setServiceAdmin!}
          width={515}
          pageService={service}
          pageRegion={region}
          pagePhysicianType={physicianType}
        />
        <Input
          title={t('other')}
          value={others}
          type="currency"
          callback={(value: any) => setOthers?.(value)}
          tooltip={t('other_tooltip')}
        />
        <Section className="details">
          <ReportTextArea
            title={t('details')}
            callback={(value: string) => setDetails?.(value)}
            width={515}
          />
        </Section>
        <Input
          title={t('other_reimbursements')}
          value={othersReimbursements}
          type="currency"
          callback={(value: string) => setOthersReimbursements?.(value)}
        />
        <Section className="details">
          <ReportTextArea
            title={t('details_reimbursements')}
            callback={(value: string) => setDetailsReimbursements?.(value)}
            width={515}
          />
        </Section>
      </Box>
      <Box>
        {!service && <BoxHider />}
        <InsideBox direction="row">
          {showProduction ? (
            <MinusButton onClick={() => setShowProduction?.(!showProduction)} />
          ) : (
            <PlusButton onClick={() => setShowProduction?.(!showProduction)} />
          )}
          <div style={{ width: '10px' }} />
          <Title
            text={t('productivity_and_calculated_values')}
            onClick={() => setShowProduction?.(!showProduction)}
            cursor="pointer"
          />
        </InsideBox>
        {showProduction && (
          <InsideBox>
            <FieldsWithAdjustedContainer>
              <CompensationSingleInputs
                title={t('professional_collections')}
                type="currency"
                callback={(value: any) => setProfessionalCollections?.(value)}
                hasCustomResult={!checkToShowAdjustedFields(clinicalFTE)}
                param="profCollectionsVal"
                service={service}
                responseValue="profCollections"
                region={region}
              />
              {checkToShowAdjustedFields(clinicalFTE)
                ? showAdjustedValues({
                    result: adjustedProfessionalCollections,
                    fieldTitle: t('adjusted_professional_collections'),
                    hasCustom: true,
                    param: 'profCollectionsAdjustedVal',
                    responseValue: 'profCollections',
                  })
                : null}
            </FieldsWithAdjustedContainer>
            <FieldsWithAdjustedContainer>
              <CompensationSingleInputs
                title={t('worK_rvus')}
                type="number"
                callback={(value: any) => setWorkRVUs?.(value)}
                hasCustomResult={!checkToShowAdjustedFields(clinicalFTE)}
                param="workRVUsVal"
                service={service}
                responseValue="workRVUs"
                tooltip={t('work_rvus_tooltip')}
                region={region}
              />
              {checkToShowAdjustedFields(clinicalFTE)
                ? showAdjustedValues({
                    result: adjustedWorkRVUs,
                    fieldTitle: t('adjusted_worK_rvus'),
                    hasCustom: true,
                    param: 'workRVUsAdjustedVal',
                    responseValue: 'workRVUs',
                    notDolar: true,
                  })
                : null}
            </FieldsWithAdjustedContainer>
            <CompensationSingleReportField
              text={t('per_professional_collections')}
              result={profCollectionsperWorkRVUs}
              hasCustomResult
              param="percentCollectionsPaymentsVal"
              service={service}
              responseValue="percentCollectionsPayments"
            />
            <CompensationSingleReportField
              text={t('per_worK_rvus')}
              result={clinicalCompPerWorkRVU}
              hasCustomResult
              param="clinicalCompPerWorkRVUVal"
              service={service}
              responseValue="clinicalCompPerWorkRVU"
            />
          </InsideBox>
        )}
      </Box>
      <Box>
        {!service && <BoxHider />}
        <TotalCompBox>
          <CompensationSingleReportField
            text={t('total_compensation')}
            result={totalCompensation}
            hasCustomResult={!checkToShowAdjustedFields(physicianFTE)}
            param="totalCompVal"
            service={service}
            responseValue="totalComp"
            fontSize={20}
            tooltip={t('total_compensation_tooltip')}
            region={region}
            physicianType={physicianType}
            fontStyle="normal"
          />
        </TotalCompBox>
        {checkToShowAdjustedFields(physicianFTE)
          ? showAdjustedValues({
              result: totalCompAdjusted,
              fieldTitle: t('total_comp_adjusted'),
              hasCustom: true,
              param: 'totalCompAdjusted',
              responseValue: 'totalComp',
              fontsize: 20,
              extraFields: { totalCompVal: totalCompensation },
              fontStyle: 'oblique',
            })
          : null}
        <TotalCompBox>
          <CompensationSingleReportField
            text={t('total_clinical_comp')}
            result={totalClinicalComp}
            hasCustomResult={!checkToShowAdjustedFields(clinicalFTE)}
            param="clinicalCompVal"
            service={service}
            responseValue="clinicalComp"
            fontSize={20}
            region={region}
            physicianType={physicianType}
            fontStyle="normal"
          />
        </TotalCompBox>
        {checkToShowAdjustedFields(clinicalFTE)
          ? showAdjustedValues({
              result: totalAdjustedClinicalComp,
              fieldTitle: t('adjusted_total_clinical_comp'),
              hasCustom: true,
              param: 'clinicalCompAdjustedVal',
              responseValue: 'clinicalComp',
              fontsize: 20,
              fontStyle: 'oblique',
            })
          : null}
      </Box>
      <Box padding="0px">
        {!service && <BoxHider />}
        {dignity ? (
          <DignityReportGenerator
            loading={submiting}
            handleInputsChange={handleDignityReportInputChange}
            handleSubmit={handleSubmit}
          />
        ) : (
          <ReportsGenerator
            loading={submiting}
            handleInputsChange={handleReportGeneratorInputsChange}
            handleSubmit={handleSubmit}
            hasExperience
          />
        )}
      </Box>
      <Footer />
    </Page>
  );
}

// eslint-disable-next-line react/function-component-definition
export default () => (
  <TotalCompensationcontextProvider>
    <TotalCompensation />
  </TotalCompensationcontextProvider>
);
