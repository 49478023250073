import React from 'react';
import TitledTrendTableInterface from './interface';
import Title from '../../atoms/Title';
import TrendTable from '../../atoms/TrendTable';
import { Container, Footnote } from './style';

function TitledTrendTable({
  title,
  values,
  years,
  isPdf,
  id,
}: TitledTrendTableInterface) {
  return (
    <Container id={id}>
      <Title text={title ?? ''} />
      <TrendTable id="table" values={values} isPdf={isPdf} years={years} />
      {isPdf ? (
        <Footnote>* Blue percentages indicate year-over-year changes.</Footnote>
      ) : null}
    </Container>
  );
}

export default TitledTrendTable;
