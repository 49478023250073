import styled from 'styled-components';

interface AverageTextInterface {
  fontSize?: number;
  color?: string;
  fontWeight?: string;
}

interface AverageTextHolderInterface {
  height?: number;
  direction?: 'row' | 'column';
  marginTop?: number;
  alignContent?: 'flex-start' | 'center' | 'flex-end';
  gap?: number;
}

interface RowInterface {
  height?: number;
  marginTop?: number;
  marginBottom?: number;
  width?: string;
}

interface SupplermentalTile {
  width?: string;
  marginLeft?: number;
  fontWeight?: 'bold' | 'light';
  alignItens?: 'center' | 'flex-start' | 'flex-end';
}

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);

  > .frontPage {
    > :first-child {
      position: absolute;
    }
    > :last-child {
      background-color: transparent;
    }
  }

  > .appendixBPage {
    flex-direction: column;
  }
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 700px;
  padding: 50px 25px;
  align-items: center;
  position: relative;

  > .frontSection {
    margin: 70px 0 0;
  }

  > .logoSubtitle {
    margin-left: auto;
    margin-right: 45px;
    text-align: right;
  }

  > :first-child {
    position: absolute;
    top: 0;
  }

  > :last-child {
    position: absolute;
    bottom: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  margin-top: 60px;
  width: max-content;
  height: max-content;
  margin-right: 8%;
`;

export const AverageTextHolder = styled.div<AverageTextHolderInterface>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  width: 100%;
  align-items: ${(props) => props.alignContent ?? 'center'};
  height: ${(props) => props.height}px;
  margin-top: ${(props) => props.marginTop}px;
  justify-content: space-between;
  gap: ${(props) => `${props.gap ?? 0}px`};

  > .percentageIcon {
    width: max-content;
    margin: 0 20px 0 0;
  }

  > .percentileRanges {
    width: 51%;
    margin: 0;
    height: 82%;
  }

  > .percentText {
    padding-right: 40px;
  }

  > .percentileText {
    width: 50%;
  }

  > .paymentText {
    padding-right: 40px;
  }

  > .paddingLeftText {
    padding-left: 8px;
  }

  > .textPageTitle {
    align-self: flex-start;
    margin-top: 30px;
    font-size: 12px;
    font-weight: bold;
  }
`;

export const AverageText = styled.span<AverageTextInterface>`
  font-family: inherit;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize ?? 12}px;
  color: ${(props) => props.color ?? '#242424'};
`;

export const SpecialAbsoluteTextHolder = styled.div`
  display: flex;
  background-color: #e3e8ec;
  width: 422px;
  height: max-content;
  padding: 10px 80px 10px 15px;
  margin-right: -25px;
  margin-top: 20px;
  right: 0;
`;

export const Line = styled.div`
  width: 100%;
  height: 0;
  border-top: solid 1px #dbdbdb;
  margin: 2px 0;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  flex-direction: row;
  justify-content: space-between;
`;

export const AppendixColumn = styled.div`
  display: flex;
  width: 33%;
  height: max-content;
  flex-direction: column;

  > .appendixRow {
    margin-bottom: 1.5px;
  }
`;

export const AppendixPointscontainer = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  flex-direction: column;
  padding-left: 20px;

  > :nth-child(n) {
    margin-bottom: 10px;
    justify-content: flex-start;
    > .bigdot {
      display: block;
      height: 4px;
      width: 4px;
      background-color: black;
      border-radius: 100%;
    }
    > :nth-child(2) {
      width: 95%;
      margin-left: 8px;
    }
  }
`;

export const SupplementalFirstTile = styled.div<SupplermentalTile>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.alignItens ? props.alignItens : 'flex-end')};
  justify-content: start;
  height: 15px;
  width: ${(props) => props.width};
  font-weight: ${(props) => props.fontWeight ?? 'light'};
  font-size: 9px;
  font-family: inherit;
  margin-left: ${(props) => props.marginLeft ?? 0}px;
`;

export const ItemRow = styled.div<RowInterface>`
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  min-height: ${(props) => `${props.height}px`};
  align-items: center;
  height: max-content;
  padding: 3px 0;
  margin-top: ${(props) => `${props.marginTop ?? 0}px`};
  margin-bottom: ${(props) => `${props.marginBottom ?? 0}px`};
`;

export const PointItem = styled.li`
  font-family: inherit;
  font-size: 9.5px;
  justify-self: start;
  align-self: flex-start;
  margin-left: 20px;
  padding-right: 30px;
`;
