import styled from 'styled-components';

interface ResultContainerProps {
  visible?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ResultContainer = styled.div<ResultContainerProps>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')} !important;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #b6b7bd;
  width: 550px;
  max-height: 200px;
  z-index: 2;
`;
