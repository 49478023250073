import Api from './api';

export default class SearchBenchmarks {
  static async pdf(queryString: string) {
    return Promise.resolve(
      Api.get(`/api/search-pdf?${queryString}`)
        .then((response) => ({ failed: false, data: response.data }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
}
