import styled from 'styled-components';

export const Page = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f4f5f9;
`;

export const Header = styled.div`
  padding: 36px;
  background-color: #fafafa;

  > :first-child {
    margin: 0;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 80px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 24px;
  }
`;

export const OptionsContainer = styled.div`
  width: 100%;
  max-width: 566px;
  min-height: 800px;
  height: 100%;
  background-color: #fafafa;
  padding: 48px 56px 0;
  border: 1px solid #e3e4e6;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  &.selectYears {
    margin-bottom: 48px;
  }

  &.generateChart {
    > :first-child {
      display: flex;
      height: 48px;
      width: 100%;
      box-shadow: none;
      border: none;
      border-radius: 2px;
      color: #f4f5f9;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
    }
  }
`;

export const OptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const OptionH1 = styled.span`
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  color: var(--ecg-royal-blue);
`;

export const OptionTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--ecg-royal-blue);
`;

interface DetailsInterface {
  $fontSize?: string;
  $error?: boolean;
}

export const OptionDetails = styled.span<DetailsInterface>`
  font-size: ${(props) => props.$fontSize ?? '16px'};
  font-weight: 400;
  line-height: 24px;
  color: ${(props) =>
    props.$error ? 'var(--warning)' : 'var(--footer-light-gray)'};
`;

interface ChartProps {
  $alignItems?: boolean;
}

export const ChartContainer = styled.div<ChartProps>`
  width: 80%;
  min-height: 500px;
  height: 100%;
  margin-top: 0px;
  background-color: #fafafa;
  padding: 24px;
  border: 1px solid #e3e4e6;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 14px;

  color: var(--footer-light-gray);

  @media (min-width: 768px) {
    margin-right: 56px;
  }
`;

interface ChartInterface {
  $marginTop: number;
}

export const Chart = styled.div<ChartInterface>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;

  > :first-child {
    margin-top: ${(props) => props.$marginTop}px;
  }
`;

export const ChartTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: flex-start;
  width: 100%;
`;

export const ChartInfo = styled.div``;

export const Container = styled.div`
  display: flex;

  &.confirmButton {
    > :first-child {
      button {
        margin-top: 20px;
        height: 48px;
        width: 186px;
        background-color: transparent;
        box-shadow: none;
        border: solid 2px var(--ecg-royal-blue);
        border-radius: 2px;
        color: var(--ecg-royal-blue);
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
`;

export const Placeholder = styled.span`
  width: 100%;
  max-width: 364px;
  color: var(--footer-light-gray);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e3e4e6;
`;

export const TableContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  > :nth-child(2) {
    margin-top: 16px;
  }
`;
