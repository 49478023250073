import React, { useEffect, useState } from 'react';
import ExecutiveSummary from '../../components/templates/Reports/ExecutiveSummary';
import Analytics from '../analytics';

function ExecSummaryPDF() {
  const [pdfData, setPdfData] = useState<any>();
  const [docType, setDocType] = useState<string>('SELECTALL');

  const getExecSummaryData = async () => {
    const searchUrl = window.location.search;
    const urlParams = new URLSearchParams(searchUrl);
    const lookback = urlParams.get('lookback');
    const mdrId = urlParams.get('mdr_id') ?? 'SELECTALL';

    const response = await Analytics.getExecSum(
      lookback ?? 12,
      'execsum',
      mdrId,
    );

    if (!response.failed) {
      setPdfData(response.data);
      setDocType(mdrId);
    }
  };

  // @ts-ignore
  useEffect(() => {
    getExecSummaryData().then(null);
  }, []);

  return pdfData ? <ExecutiveSummary data={{ ...pdfData, docType }} /> : null;
}

export default ExecSummaryPDF;
