import styled from 'styled-components';

interface TextProps {
  fontSize?: number;
  fontWeight?: string;
  fontColor?: string;
  textAlign?: 'left' | 'justify' | 'right';
}

interface TextContainerProps {
  marginTop?: number;
  marginBottom?: number;
  direction?: 'row' | 'column';
}

interface RowProps {
  marginBottom?: number | string;
  borderBottom?: 'none' | 'solid';
}

interface TileProps {
  width?: number;
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  fontWeight?: number;
  isRed?: boolean;
  isExpired?: boolean;
}

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-pdf-height);
  padding: 0;
  top: 0;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0px 25px;
  margin: 20px 0px;

  > .explanationPage {
    > :nth-child(n) {
      margin-bottom: 10px;
    }
    > :last-child {
      margin-top: -5px;
    }
  }
`;

export const GeneralBlueText = styled.span<TextProps>`
  font-family: inherit;
  font-size: ${(props) => `${props.fontSize ? props.fontSize : 16}px`};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  color: ${(props) => props.fontColor ?? 'var(--ecg-royal-blue)'};
  text-align: left;
`;

export const GeneralContentText = styled.span<TextProps>`
  font-family: inherit;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  font-size: ${(props) => `${props.fontSize ? props.fontSize : 8}px`};
  color: var(--almost-black);
  margin-top: 5px;
  text-align: ${(props) => props.textAlign ?? 'left'};
`;

export const SubtitleContentText = styled(GeneralContentText)<TextProps>`
  margin-top: 5px;
  line-height: 8px;
`;

export const GeneralTextContainer = styled.div<TextContainerProps>`
  display: flex;
  height: max-content;
  width: 100%;
  flex-direction: ${(props) => props.direction ?? 'column'};
  margin-top: ${(props) => `${props.marginTop ?? 0}px`};
  margin-bottom: ${(props) => `${props.marginBottom ?? 0}px`};
  > .dottedLine {
    flex-direction: row;
    > :nth-child(1) {
      width: 2px;
      height: 2px;
      border-radius: 100%;
      margin-left: 15px;
      margin-right: 3px;
      background-color: #040404;
      align-self: center;
    }

    :nth-child(2) {
      margin: 0;
    }
  }
`;

export const OuterTotalSpending = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: max-content;
  height: max-content;
  margin-bottom: 15px;
  > :first-child {
    margin: 0 0 5px;
  }
`;

export const TotalSpendingRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const BlankSpace = styled.div`
  width: 100%;
  height: 65px;
  background-color: red;
`;

export const TableContainer = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  flex-direction: column;
`;

export const Row = styled.div<RowProps>`
  display: flex;
  margin-right: 40px;
  width: 100%;
  height: max-content;
  justify-content: space-between;
  margin-bottom: ${(props) => props.marginBottom}px;
  border-bottom: ${(props) => props.borderBottom ?? 'none'} 0.2px
    rgba(237, 238, 242, 0.7);
`;

export const Tile = styled.div<TileProps>`
  display: flex;
  font-size: 8px;
  line-height: 10px;
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  padding: 1px;
  font-weight: ${(props) => props.fontWeight};
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
  font-style: ${(props) => (props.isExpired ? 'italic' : 'normal')};

  > span {
    line-height: 12px;
    ${(props) =>
      props.isRed &&
      `
      color: #fff;
      background-color: #b13524;
  `}
  }
`;

export const VerticalTile = styled(Tile)`
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const LEGEND_CONTAINER_MEASURE = 50;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  right: 20px;
  height: ${LEGEND_CONTAINER_MEASURE}px;
  width: 70px;
  border: solid 1.5px var(--ecg-royal-blue);
  padding: 5px;
`;

interface LegendProps {
  isExpired?: boolean;
}

export const LegendContentText = styled(GeneralContentText)<LegendProps>`
  line-height: normal;
  font-size: 8px;
  margin-top: 0;
  font-style: ${(props) => (props.isExpired ? 'italic' : 'normal')};
`;
