import styled from 'styled-components';

interface SubtitleInterface {
  fontSize?: number;
}

export const Subtitle = styled.h2<SubtitleInterface>`
  width: 100%;
  color: var(--almost-black);
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
  line-height: 19px;
  text-align: left;
  cursor: pointer;
`;

export const CustomSubtitle = styled.h2`
  width: 100%;
  color: #5f6368;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  cursor: pointer;
`;
