import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Element } from './style';
import VerticalBarChartInterface from './interface';
import { formatValue } from '../../../utils/executiveSummary';
import {
  formatCurrencyToNumber,
  hasCurrencySymbol,
} from '../../../utils/trendAnalysis';

const colors = [
  'rgba(92, 198, 246, 1)',
  'rgba(0, 114, 175, 1)',
  'rgba(0, 88, 135, 1)',
  'rgba(0, 53, 94, 1)',
];

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function VerticalBarChart({
  id,
  xValues,
  yValues,
  isPdf = false,
}: VerticalBarChartInterface) {
  const circleTicksLabels = [
    '25th Percentile',
    'Median',
    '75th Percentile',
    '90th Percentile',
  ];

  const buildData = (values: any) => {
    let hasCurrency = false;
    const dataset = values.map((value: any) => {
      if (hasCurrencySymbol(value)) {
        hasCurrency = true;
      }
      return formatCurrencyToNumber(value);
    });

    return {
      dataset,
      hasCurrency,
    };
  };

  const { dataset, hasCurrency } = buildData(yValues);

  const data = {
    labels: (circleTicksLabels as string[]) || (xValues as string[]),
    datasets: [
      {
        data: dataset as string[],
        backgroundColor: colors,
        maxBarThickness: 100,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            family: 'Open Sans, sans-serif',
          },
        },
      },
      tooltip: {
        bodyFont: {
          size: 14,
        },
        borderRadius: 4,
        padding: 10,
        backgroundColor: '#151624BF',
        caretPadding: 10,
        yAlign: 'bottom' as const,
        displayColors: false,
        callbacks: {
          title: () => '',
          label: (context: any) => {
            let label = context.dataset.label || '';

            if (context.parsed.y !== null) {
              label = context.parsed.y;
            }
            return `Value: ${hasCurrency ? formatValue(label, true) : label}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          color: '#040404',
          font: {
            size: 15,
            weight: 'bold',
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback(value: any) {
            return hasCurrency ? formatValue(value, true) : value;
          },
          font: {
            size: 15,
          },
        },
      },
    },
  };

  const pdfOptions = {
    responsive: true,
    devicePixelRatio: 10,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          padding: 10,
          color: '#040404',
          font: {
            size: 8,
            weight: 'bold',
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback(value: any) {
            return hasCurrency ? formatValue(value, true) : value;
          },
          font: {
            size: 8,
            weight: 'normal',
          },
        },
      },
    },
  };

  return (
    <Element id={id}>
      <Bar
        id={`${id}-chart`}
        options={isPdf ? pdfOptions : options}
        data={data}
      />
    </Element>
  );
}

export default VerticalBarChart;
