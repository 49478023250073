import React from 'react';
import DataTable from 'react-data-table-component';
import { TableInterface } from './interface';
import {
  TableContainer,
  TableInfo,
  TableLegend,
  TableSubtitle,
  TableTitle,
  TableTopBorder,
  Container,
  StyledTableHeader,
  LegendsContainer,
  LegendText,
} from './style';

function Table({
  title,
  subtitle,
  dataInfo,
  columns,
  data,
  width,
  legendText,
  legends,
}: TableInterface) {
  const formattedColumns = columns.map((column) => ({
    ...column,
    name: <StyledTableHeader>{column.name}</StyledTableHeader>,
  }));

  return (
    <Container width={width}>
      <TableTopBorder />
      <TableTitle>{title}</TableTitle>
      <TableSubtitle>{subtitle}</TableSubtitle>
      <TableInfo>{dataInfo}</TableInfo>

      {legends && legends.length > 0 && (
        <LegendsContainer>
          <LegendText>{legendText}</LegendText>
          {legends.map((legend, index) => (
            <TableLegend even={index % 2 === 0}>{legend}</TableLegend>
          ))}
        </LegendsContainer>
      )}

      <TableContainer>
        <DataTable
          className="customTable"
          columns={formattedColumns}
          data={data}
          highlightOnHover
          responsive
          striped
          noDataComponent="No data found"
        />
      </TableContainer>
    </Container>
  );
}

export default Table;
