import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, MessageContainer, SlicesContainer } from './style';
import ConditionalPercentileIconInterface from './interface';
import getComparisonConditionalMessage from '../../../utils/getComparisonConditionalMessage';

function ConditionalPercentileIcon({
  percentileIndicator,
  slicesIndicator,
}: ConditionalPercentileIconInterface) {
  const { t } = useTranslation('input-results');

  const [message, setMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [color, setColor] = useState<string | undefined>('default');

  const iconColors: { [key: string]: string } = {
    90: '#ff4644',
    default: '#242424',
  };

  const getLightMessage = (percentileResponse: string) => {
    if (!percentileResponse) {
      return setErrorMessage(t('not_found_characteristic'));
    }

    const signal = percentileResponse.charAt(0);
    const percentileSplit = percentileResponse.split(signal)[1];
    const conditionalMessage = getComparisonConditionalMessage(signal!);

    if (!conditionalMessage) {
      setMessage('');
      setColor(undefined);
      return setErrorMessage(t('not_found_characteristic'));
    }

    setErrorMessage('');
    setMessage(t(conditionalMessage, { percentile: percentileSplit }));
    return setColor(percentileSplit === '90' ? '90' : 'default');
  };

  useEffect(() => {
    getLightMessage(percentileIndicator ?? '');
  }, [percentileIndicator]);

  return (
    <Container className="conditionalPercentile">
      {message && !errorMessage && (
        <MessageContainer color={iconColors[color ?? 'default']}>
          {message}
          <sup>TH</sup>
          PERCENTILE
        </MessageContainer>
      )}
      {slicesIndicator && !errorMessage ? (
        <SlicesContainer>
          {slicesIndicator.map((slice: string) => (
            <span key={slice}>{slice}</span>
          ))}
        </SlicesContainer>
      ) : null}
    </Container>
  );
}

export default ConditionalPercentileIcon;
