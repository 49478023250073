import PdfMicroService from '../services/pdfMicroService';

export async function buildPdf(
  url: string,
  pdfTitle: string,
  setPdfLoading?: any,
) {
  const buildResponse = await PdfMicroService.buildPdf(url, pdfTitle);

  if (!buildResponse.failed) {
    window.open(buildResponse.data.url, '_blank');
  }
  setPdfLoading(false);
}
