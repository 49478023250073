import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyInputAtom from '../../atoms/CurrencyInput';
import InputAtom from '../../atoms/Input';
import AtomInterface from '../../atoms/Input/interface';
import InputInterface from './interface';
import MessageModal from '../MessageModal';
import Subtitle from '../../atoms/Subtitle';
import { Container, Row } from './style';
import HelpTooltip from '../../atoms/HelpTooltip';

function InputPicker({
  placeholder,
  callback,
  type,
  width,
  subtitle,
  value,
  focus,
  min,
  max,
  disabled,
  propRef,
  delay,
}: AtomInterface) {
  switch (type) {
    case 'currency':
      return (
        <CurrencyInputAtom
          callback={callback}
          value={Number(value)}
          width={width}
          focus={focus}
          propRef={propRef}
          disabled={disabled}
          subtitle={subtitle}
        />
      );
    default:
      return (
        <InputAtom
          min={min}
          max={max}
          focus={focus}
          placeholder={placeholder}
          callback={callback}
          type={type}
          width={width}
          value={value}
          subtitle={subtitle}
          disabled={disabled}
          propRef={propRef}
          delay={delay}
        />
      );
  }
}

function Input({
  placeholder,
  callback,
  title,
  type,
  titleSide,
  width,
  tooltip,
  subtitle,
  value,
  focus,
  min,
  max,
  disabled,
  justifyContent,
  inputColor,
  titleSideColor,
  propRef,
  delay,
  exceptionTitle,
  exceptionMessage,
}: InputInterface) {
  const { t } = useTranslation('input');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const TEXT_SIDE = titleSide ?? 'upper';

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      (min !== undefined && Number(value) < min) ||
      (max !== undefined && Number(value) > max)
    ) {
      callback('');
      setIsModalOpen(true);
    }
  }, [value]);

  return (
    <Container
      titleSide={titleSide}
      justifyContent={justifyContent}
      inputColor={inputColor}
      IsModalOpen={isModalOpen}
    >
      {isModalOpen && (
        <MessageModal
          title={exceptionTitle}
          fisrtMessage={exceptionMessage}
          buttonLabel={t('close_label')}
          handleClose={setIsModalOpen}
        />
      )}
      {(TEXT_SIDE === 'upper' || TEXT_SIDE === 'left') && (
        <Row textColor={titleSideColor} titleSide={TEXT_SIDE}>
          {!!title && <Subtitle text={title} />}
          {!!tooltip && <HelpTooltip text={tooltip} size={15} />}
        </Row>
      )}
      <InputPicker
        min={min}
        max={max}
        focus={focus}
        placeholder={placeholder}
        callback={callback}
        type={type}
        width={width}
        value={value}
        subtitle={subtitle}
        disabled={disabled}
        propRef={propRef}
        delay={delay}
      />
      {(TEXT_SIDE === 'right' || TEXT_SIDE === 'bottom') && (
        <Row textColor={titleSideColor} titleSide={TEXT_SIDE}>
          {!!title && <Subtitle text={title} />}
          {!!tooltip && <HelpTooltip text={tooltip} size={15} />}
        </Row>
      )}
    </Container>
  );
}

export default Input;
