/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import addChildtoParent from 'src/utils/addChildToParent';
import { MDRanger } from 'src/global/PngIcons';
import {
  Document,
  Page,
  BenchMarkTitle,
  ItemRow,
  IconContainer,
  BetweenSectionText,
  HeaderFirstTile,
  HeaderGeneralTile,
  RowFirstTile,
  RowGeneraltile,
  CharacteristcTile,
  CharacteristicHolder,
  PageParent,
  Line,
} from './style';

import ReportHeader from '../../organisms/Reports/Header';
import ReportFooter from '../../organisms/Reports/Footer';

function SingleServiceTable(data: any, nowDate: string) {
  const [serviceNames, setServiceNames] = useState<string[]>(
    Array(50).fill(''),
  );

  const SPECIAL_STRUCTE_HANDLE_SECTIONS = ['Administration'];

  const TopSectionRow = (id: string) => (
    <ItemRow height={20} id={id}>
      <HeaderFirstTile>Characteristc</HeaderFirstTile>
      <HeaderGeneralTile>Providers</HeaderGeneralTile>
      <HeaderGeneralTile>Facilities</HeaderGeneralTile>
      <HeaderGeneralTile>Mean</HeaderGeneralTile>
      <HeaderGeneralTile>25%</HeaderGeneralTile>
      <HeaderGeneralTile>50 %</HeaderGeneralTile>
      <HeaderGeneralTile>75%</HeaderGeneralTile>
      <HeaderGeneralTile>90%</HeaderGeneralTile>
    </ItemRow>
  );

  const CharacteristcRow = (text: string, id: string) => {
    const { length } = text;
    return (
      <ItemRow marginBottom={length > 20 ? 5 : 0} id={id}>
        <CharacteristcTile>{text}</CharacteristcTile>
      </ItemRow>
    );
  };

  const dataRow = (dataBody: any, divId: string) => {
    const { slice, systems, hospitals, mean, q25, q50, q75, q90 } = dataBody;

    return (
      <ItemRow id={divId}>
        <RowFirstTile>{slice}</RowFirstTile>
        <RowGeneraltile>{systems}</RowGeneraltile>
        <RowGeneraltile>{hospitals}</RowGeneraltile>
        <RowGeneraltile>{mean}</RowGeneraltile>
        <RowGeneraltile>{q25}</RowGeneraltile>
        <RowGeneraltile>{q50}</RowGeneraltile>
        <RowGeneraltile>{q75}</RowGeneraltile>
        <RowGeneraltile>{q90}</RowGeneraltile>
      </ItemRow>
    );
  };

  const buildPage = (pageIndex: number) => (
    <PageParent id={`table-parent-page-${pageIndex}`}>
      <ReportHeader title={serviceNames[pageIndex]} />
      <Page id={`table-page-${pageIndex}`} />
      <ReportFooter nowDate={nowDate} />
    </PageParent>
  );

  let page = 0;

  const updateServices = (serviceName: string) => {
    const aux = serviceNames;
    if (!aux[page]) {
      aux.splice(page, 1, serviceName);
    }
    return aux;
  };

  let currentService = '';

  const addSectionFront = (sectionName: string, title: string) => {
    const parentId = `table-page-${page}`;

    document!.getElementById(parentId)!.innerHTML += renderToString(
      <IconContainer>
        <img src={MDRanger} style={{ width: 180 }} alt="mdlogo" />
      </IconContainer>,
    );

    document!.getElementById(parentId)!.innerHTML += renderToString(
      <BetweenSectionText>{title}</BetweenSectionText>,
    );

    if (sectionName !== 'RefRates') {
      document!.getElementById(parentId)!.innerHTML += renderToString(
        <BetweenSectionText>{sectionName}</BetweenSectionText>,
      );
    }

    page += 1;
  };
  const buildId = () => (Math.random() + 1).toString(36).substring(7);

  const buildTableBodyInformation = (usedBenchData: any, service: string) => {
    const { adminCharacteristics } = usedBenchData;
    adminCharacteristics.map((characteristic: any, index: number) => {
      setServiceNames([...updateServices(service)]);
      const charContentId = buildId();
      const isLast = index === adminCharacteristics.length - 1;
      const characteristicContent = (
        <CharacteristicHolder id={charContentId} marginBotton={isLast ? 25 : 0}>
          {CharacteristcRow(characteristic.name, '')}
          {characteristic.rows.map((body: any) => dataRow(body, ''))}
          <Line className="lineDivider" />
        </CharacteristicHolder>
      );
      page = addChildtoParent(
        charContentId,
        characteristicContent,
        'table-page',
        page,
        37,
      );
      return null;
    });
  };

  const adminSectionHandle = (adminSection: any, service: string) => {
    page += 1;
    const adminTitleId = buildId();
    const adminSectionTitle = (
      <BenchMarkTitle id={adminTitleId}>Administration</BenchMarkTitle>
    );

    page = addChildtoParent(
      adminTitleId,
      adminSectionTitle,
      'table-page',
      page,
    );

    adminSection.map((adminBench: any) => {
      const adminBenchId = buildId();
      const adminBenchTitle = (
        <BenchMarkTitle id={adminBenchId} className="adminBenchTitle">
          {adminBench.name}
        </BenchMarkTitle>
      );
      page = addChildtoParent(
        adminBenchId,
        adminBenchTitle,
        'table-page',
        page,
        47,
      );

      const topSectionId = buildId();
      page = addChildtoParent(
        topSectionId,
        TopSectionRow(topSectionId),
        'table-page',
        page,
        37,
      );
      return buildTableBodyInformation(adminBench, service);
    });
    page += 1;
  };

  const removeNotUsedPage = () => {
    for (let i = 0; i < 50; i += 1) {
      const currentPage = document!.getElementById(`table-page-${i}`);
      if (!currentPage?.firstChild) {
        document?.getElementById(`table-parent-page-${i}`)?.remove();
      }
    }
  };

  const buildPdfContent = async (states: any) => {
    await states.map((section: any) => {
      if (
        section.name &&
        section.name !== 'RefRates' &&
        section.services.length
      ) {
        addSectionFront(section.name, states.title);
      }
      return section?.services?.map((service: any, serviceIndex: number) => {
        currentService = service.name;
        service.benchmarks.map((benchmark: any) => {
          if (SPECIAL_STRUCTE_HANDLE_SECTIONS.includes(benchmark.name)) {
            return adminSectionHandle(
              benchmark.characteristics,
              currentService,
            );
          }
          setServiceNames([...updateServices(currentService)]);
          const titleId = buildId();
          const topSectionId = buildId();
          page = addChildtoParent(
            titleId,
            <BenchMarkTitle id={titleId} className="benchTitle">
              {benchmark.name}
            </BenchMarkTitle>,
            'table-page',
            page,
            62,
          );
          page = addChildtoParent(
            topSectionId,
            TopSectionRow(topSectionId),
            'table-page',
            page,
            37,
          );
          return benchmark.characteristics.map((characteristic: any) => {
            const characteristicId = buildId();
            page = addChildtoParent(
              characteristicId,
              CharacteristcRow(characteristic.name, characteristicId),
              'table-page',
              page,
              0,
            );
            characteristic.rows.map((body: any) => {
              const rowId = buildId();
              page = addChildtoParent(
                rowId,
                dataRow(body, rowId),
                'table-page',
                page,
              );
              return null;
            });
            const lineId = buildId();
            page = addChildtoParent(
              lineId,
              <Line id={lineId} className="lineDivider" />,
              'table-page',
              page,
            );
            return null;
          });
        });
        page += 1;
        if (serviceIndex === section.services.length - 1) {
          page += 1;
          return null;
        }
        return null;
      });
    });
  };

  useEffect(() => {
    buildPdfContent(data).then(() => {
      removeNotUsedPage();
    });
  }, []);

  return (
    <Document id="documentBody">
      {Array(50)
        .fill('')
        .map((_, index) => buildPage(index))}
    </Document>
  );
}

export default SingleServiceTable;
