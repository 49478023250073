export default function singleContractResponseHandler(data: any) {
  const states = Array.isArray(data) ? data : [data];
  states?.map((serviceSection: any) => {
    const alreadySortedTitles: string[] = [];

    const patternedBenchObjects: any[] = [];

    // modeling the objects to pattern used in the charts
    serviceSection?.benchmarks?.map((bench: any) =>
      patternedBenchObjects.push({
        title: bench.title,
        benchmark: bench.hat_long,
        service: bench.serviceset,
        userValue: bench.uservalue,
        slicedetail: bench.slice,
        q25: bench.q25,
        q50: bench.q50,
        q75: bench.q75,
        q90: bench.q90,
      }),
    );

    // returing a subarray of benchmarks that have the same title
    const separateByTitle = (benchArray: any[], title: string) =>
      benchArray?.filter((bench: any) => bench.title === title);

    const titleSort: any = {};

    // sorting the original array by { title: [subarray of benchmarks with the same title] }
    patternedBenchObjects?.map((bench: any) => {
      if (!alreadySortedTitles.includes(bench.title)) {
        titleSort[bench.title] = separateByTitle(
          patternedBenchObjects,
          bench.title,
        );
        alreadySortedTitles.push(bench.title);
      }
      return null;
    });

    // eslint-disable-next-line no-param-reassign
    serviceSection!.benchmarks = titleSort;
    return null;
  });

  return states;
}
