import pdfMicro from './pdfMicroConfig';

export default class PdfMicroService {
  static async buildPdf(url: string, filename: string) {
    return Promise.resolve(
      pdfMicro
        .post('/build', {
          url,
          filename: filename.replace(/[^a-zA-Z0-9.]/g, ''),
        })
        .then((response) => ({ failed: false, data: response.data }))
        .catch(() => ({ failed: true, data: [] })),
    );
  }
}
