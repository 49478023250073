import React, { useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import PlusButtonInterface from './interface';
import { Button } from './style';

function PlusButton({
  placeholder,
  callback,
  toggleable,
  bind,
}: PlusButtonInterface) {
  const [clicked, setClicked] = useState<boolean>(false);

  const handleClick = () => {
    let state = true;

    if (toggleable) {
      state = !clicked;
    }

    if (bind !== undefined) {
      setClicked(bind);
      return callback(state);
    }

    setClicked(state);
    return callback(state);
  };

  useEffect(() => {
    if (bind !== undefined) {
      setClicked(bind);
    }
  }, [bind]);

  return (
    <Button onClick={handleClick} clicked={clicked} tabIndex={-1}>
      {clicked ? <FaMinus /> : <FaPlus />}
      {placeholder}
    </Button>
  );
}

export default PlusButton;
