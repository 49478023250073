import React from 'react';
import TitledDoughnutChartInterface from './interface';
import Title from '../../atoms/Title';
import Subtitle from '../../atoms/Subtitle';
import DoughnutChart from '../../atoms/DoughnutChart';
import { Container } from './style';

function TitledDoughnutChart({
  title,
  subtitle,
  xValues,
  yValues,
  id,
  marginTop,
}: TitledDoughnutChartInterface) {
  return (
    <Container id={id}>
      <Title text={title ?? ''} />
      <Subtitle text={subtitle ?? ''} />
      <DoughnutChart
        xValues={xValues as string[]}
        yValues={yValues as number[]}
        marginTop={marginTop}
      />
    </Container>
  );
}

export default TitledDoughnutChart;
