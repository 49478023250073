function getComparisonConditionalMessage(signal: string) {
  let label = '';
  switch (signal) {
    case '>':
      label = 'over_percentile';
      break;
    case '<':
      label = 'under_percentile';
      break;
    case '=':
      label = 'equals_percentile';
      break;
    default:
      break;
  }
  return label;
}

export default getComparisonConditionalMessage;
