import styled, { keyframes } from 'styled-components';

const Ripple1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const Ripple2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
`;

const Ripple3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const LoaderContainer = styled.div<{
  color?: string;
  marginTop?: number;
}>`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 28px;

  > div {
    margin-top: ${(props) => props.marginTop ?? '0'}px;
    position: absolute;
    top: 33px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => props.color ?? 'var(--ecg-royal-blue)'};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 3px;
    animation: ${Ripple1} 0.6s infinite;
  }

  div:nth-child(2) {
    left: 4px;
    animation: ${Ripple2} 0.6s infinite;
  }

  div:nth-child(3) {
    left: 16px;
    animation: ${Ripple2} 0.6s infinite;
  }

  div:nth-child(4) {
    left: 28px;
    animation: ${Ripple3} 0.6s infinite;
  }
`;
