import styled from 'styled-components';

interface SignatureInterface {
  margin: number;
}

interface InformationInterface {
  padding?: string;
  marginTop?: number;
}

interface LineInterface {
  maginBottom?: number;
}

interface TCRTitleInterface {
  fontSize?: number;
  padding?: number;
  color?: string;
}

interface InformationContainerInterface {
  align?: string;
  width?: string;
  minHeight?: number;
  maxHeight?: number;
  padding?: string;
}

interface TextProps {
  fontSize?: number;
  fontWeight?: number;
  width?: string;
  lineHeight?: number;
  paddingLeft?: number;
  textstyle?: 'italic' | 'oblique' | 'normal';
}

export const Document = styled.div`
  display: flex;
  position: relative;
  background-color: #fefefe;
  height: max-content;
  flex-direction: column;
  width: var(--default-pdf-width);
`;

export const PageParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--default-pdf-height);
  padding: 0;
  top: 0;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 25px;
  margin: 20px 0;
`;

export const Line = styled.div<LineInterface>`
  display: flex;
  margin-left: 10px;
  width: calc(var(--default-pdf-width) - 50px);
  height: 0.5px !important;
  background-color: var(--secondary-light-gray);
  margin-bottom: ${(props) => `${props.maginBottom}px`};
`;

export const Information = styled.div<InformationInterface>`
  display: flex;
  flex-direction: row;
  text-align: justify;
  width: 100%;
  padding: ${(props) => props.padding};
  margin-top: ${(props) => `${props.marginTop}px`};
`;

export const InformationColumn = styled.div<InformationContainerInterface>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '100%')};
  align-items: ${(props) => props.align};
  padding: ${(props) => props.padding};
  min-height: ${(props) =>
    props.minHeight !== undefined ? `${props.minHeight}px` : '60px'};
  max-height: ${(props) =>
    props.maxHeight !== undefined ? `${props.maxHeight}px` : '86px'};
  overflow: visible;
`;

export const InformationRow = styled.div`
  display: flex;
  height: 13px;
  width: min-content;
  align-items: flex-start;
`;

export const JustifyInformationRow = styled(InformationRow)`
  width: 100%;
  justify-content: space-between;
`;

export const DetailsInformationColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const InformationText = styled.span<TextProps>`
  font-size: ${(props) => props.fontSize ?? 8}px;
  width: ${(props) => props.width};
  text-align: start;
  margin-bottom: 5px;
  white-space: nowrap;
  font-style: ${(props) => props.textstyle};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight ?? 8}px;
  padding-left: ${(props) => props.paddingLeft}px;

  &.contract {
    padding-bottom: 10px;
  }

  &.detailsNote {
    white-space: normal;
  }

  &.notes {
    padding-bottom: 5px;
    white-space: normal;
  }
`;

export const BlueInformationText = styled(InformationText)`
  color: var(--primary-light-blue);
`;

export const InformationItalicText = styled.span`
  font-size: 8px;
  font-family: inherit;
  text-align: justify;
  font-style: italic;
  font-weight: 100;
  white-space: nowrap;
`;

export const ApprovedByContainer = styled.div<SignatureInterface>`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: ${(props) => `${props.margin}px`} 30px 10px;
  box-sizing: border-box;
  max-width: 385px;
`;

export const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all; /* Adicione esta linha */
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const ApprovedByLine = styled.div`
  width: 100px;
  height: 0.5px;
  background-color: var(--almost-black);
`;

export const ApprovedByText = styled.span`
  margin-top: 5px;
  font-size: 8px;
  line-height: 8px;
`;

export const Charts = styled.div``;

export const TCRTitle = styled.span<TCRTitleInterface>`
  color: ${(props) => props.color ?? 'var(--primary-light-blue)'};
  font-family: inherit;
  font-size: ${(props) => props.fontSize ?? 12}px;
  font-weight: 900;
  letter-spacing: -0.2px;
  width: max-content;
  padding: ${(props) => props.padding ?? 10}px;
`;
