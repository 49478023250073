import React from 'react';
import TitledBarChartInterface from './interface';
import { Container } from './style';
import BarChart from '../../atoms/BarChart';
import SubTitle from '../../atoms/Subtitle';
import Title from '../../atoms/Title';

function TitledBarChart({
  id,
  title,
  subtitle,
  xValues,
  yValues,
  tickNumber,
  width,
  marginRight,
  bottomText,
  justifyChart,
  minHeight,
  rowWidth,
}: TitledBarChartInterface) {
  const canvaId = `chart${id}`;
  return (
    <Container id={id} justifyChart={justifyChart}>
      <Title text={title ?? ''} />
      <SubTitle text={subtitle ?? ''} />
      <BarChart
        id={canvaId}
        xValues={xValues}
        yValues={yValues}
        tickNumber={tickNumber}
        width={width}
        marginRight={marginRight}
        minHeight={minHeight}
        rowWidth={rowWidth}
        bottomText={bottomText}
      />
    </Container>
  );
}

export default TitledBarChart;
