import styled from 'styled-components';

export const Box = styled.section`
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 90%;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 23%);
  overflow: auto;

  > :nth-child(n) {
    margin-bottom: 15px;
  }

  > :nth-child(3) {
    font-size: 18px;
    margin-bottom: 0px;
  }

  > :nth-child(7) {
    margin-top: 15px;
  }
`;
