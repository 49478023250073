import styled from 'styled-components';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

interface ContainerInferface {
  $minWidth?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > :nth-child(1) {
    font-size: 18px;
  }
`;

export const OuterContainer = styled(FormControl)<ContainerInferface>`
  display: block;
  position: relative;
  min-height: 35px;
  min-width: auto;
  justify-content: start;
  align-items: flex-start;

  > :first-child {
    outline: 0;
    border-radius: 2px !important;
  }
`;

export const MenuContainer = styled(Select)<ContainerInferface>`
  & .MuiOutlinedInput-input {
    border-color: white;
    font-size: 18px;
    font-weight: 600;
    font-family: inherit;
    width: ${(props) => props.$minWidth || 'max-content'} !important;
  }

  .MuiSelect-select {
    min-height: 48px !important;
    padding: 5px 0 5px 24px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }

  .MuiChip-root {
    border-radius: 2px;
  }
`;

export const SelectContainer = styled(Select)`
  & .MuiOutlinedInput-input {
    border: none;
    font-size: 18px;
    font-family: inherit;
    outline: 0;
  }

  .MuiSelect-select {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    height: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 2px;
  }

  .MuiChip-root {
    border-radius: 2px;
  }
`;

export const SelectedItem = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
